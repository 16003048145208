import React, { useEffect, useState } from "react";
import "../App.css";
import { useParams } from "react-router-dom";
import "../styles/product.css";
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import Alert from "react-bootstrap/Alert";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { ItemSearch } from "../components/ItemSearch";
import { useHistory } from "react-router-dom";
import { App } from "../firebase";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import ReactPaginate from "react-paginate";
import { API_URL } from "../constant";

const popover = (
  <Popover id="popover-basic">
    <Popover.Header as="h3">Grading</Popover.Header>
    <Popover.Body>
      <p>
        Mint (M)
        <br />
        Near Mint (NM)
        <br />
        Excellent (EX) <br />
        Good (GD) <br />
        Light Played (LP) <br />
        Played (PL) <br />
        Poor
        <br />
        <a
          href="https://help.cardmarket.com/fr/CardCondition"
          target={"_blank"}
        >
          Plus d'information sur cardmarket
        </a>
      </p>
    </Popover.Body>
  </Popover>
);

function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems.map((e) => (
          <ItemSearch data={e} key={e?.id} noname={true} />
        ))}
    </>
  );
}

const Search = () => {
  const history = useHistory();
  const db = App.firestore();
  const { editor, codeCard, idSearch } = useParams();
  const data = history?.location?.state?.data || null;
  const [infoCard, setInfoCard] = useState(null);
  const [dataSearchList, setDataSearchList] = React.useState([]);
  const [username, setUser] = useState("");

  const [expansion, setExpansion] = React.useState(null);
  const [loadAddSearchCard, setLoadAddSearchCard] = useState(false);
  const [editMySearch, setEditMySearch] = React.useState(false);
  const [cloneMySearch, setcloneMySearch] = React.useState(false);

  const [stateCard, setStateCard] = React.useState("Mint");
  const [firstCard, setFirstCard] = React.useState(false);
  const [qteCard, setQteCard] = React.useState(null);
  const [langCard, setLangCard] = React.useState("fr");
  const [publishCard, setPublishCard] = React.useState("1");

  const [infoMySearch, setInfoMySearch] = React.useState(null);

  const hImSearch = () => {
    setcloneMySearch(false);
    setEditMySearch(false);
    setQteCard(null);
    setInfoMySearch(null);
    setLangCard("fr");
    setStateCard(null);
    setFirstCard(false);
    setPublishCard("1");
  };

  const sImSearch = (bdd) => {
    console.log("bdd", bdd)
    setInfoMySearch(bdd);
    setQteCard(bdd?.qte);
    setStateCard(bdd.etat);
    setLangCard(bdd.lang);
    setPublishCard(bdd.state);
    setFirstCard(bdd.first);
    setExpansion(bdd.expansion)
    setcloneMySearch(true);
  };

  const edit = (bdd) => {
    setInfoMySearch(bdd);
    setQteCard(bdd?.qte);
    setStateCard(bdd.etat);
    setLangCard(bdd.lang);
    setPublishCard(bdd.state);
    setFirstCard(bdd.first);
    setEditMySearch(true);
  };

  const UpdateSearchCard = (e) => {
    e.preventDefault();
    if (loadAddSearchCard) {
      return;
    }
    if (stateCard === null) {
      alert("Veuillez choisir un état minimum pour la carte.");
      return;
    }
    var d = {
      qte: Number(qteCard),
      etat: stateCard,
      lang: langCard,
      first: firstCard,
      state: Number(publishCard), // 1 = publish; 0 : brouillon
      _update_At: new Date(),
    };
    setLoadAddSearchCard(true);
    db.collection("searchs")
      .doc(infoMySearch.id)
      .update(d)
      .then(() => {
        setLoadAddSearchCard(false);
        hImSearch();
        window.location.reload();
        alert("Recherche modifiée.");
      })
      .catch((err) => {
        setLoadAddSearchCard(false);
        console.log("UpdateSearchCard err", err);
        alert("Une erreur s'est produite.");
      });
  };

  const copieSearchCard = (e) => {
    e.preventDefault();
    if (loadAddSearchCard) {
      return;
    }
    if (stateCard === null) {
      alert("Veuillez choisir un état minimum pour la carte.");
      return;
    } 
 
    var d = {
      userId: App.auth().currentUser?.uid,
      username: App.auth().currentUser?.displayName || "",
      card: infoCard.card,
      qte: Number(qteCard),
      expansion: expansion,
      etat: stateCard,
      lang: langCard,
      first: firstCard,  
      state: Number(publishCard),
      _create_At: new Date(),
      _update_At: new Date(),
      views: 0,
    };
    setLoadAddSearchCard(true);
    db.collection("searchs")
      .add(d)
      .then(() => {
        setLoadAddSearchCard(false);
        hImSearch();
        alert("Recherche ajoutée.");
      })
      .catch((err) => {
        setLoadAddSearchCard(false);
        console.log("copieSearchCard err", err);
        alert("Une erreur s'est produite.");
      });
  };

  const DeleteSearchCard = (e) => {
    e.preventDefault();
    if (loadAddSearchCard) {
      return;
    }
    db.collection("searchs")
      .doc(infoMySearch.id)
      .delete()
      .then(() => {
        setLoadAddSearchCard(false);
        hImSearch();
        history.push("/");
        alert("Recherche supprimer.");
      })
      .catch((err) => {
        setLoadAddSearchCard(false);
        console.log("DeleteSearchCard err", err);
        alert("Une erreur s'est produite.");
      });
  };
  function isMySearch() {
    return (
      idSearch && infoCard && infoCard.userId === App.auth().currentUser?.uid
    );
  }

  const getInfoCard = async (cb) => {
    if (infoCard === null && idSearch) {
      await App.firestore()
        .collection("searchs")
        .doc(idSearch)
        .get()
        .then(async (docd) => {
          var d = docd.data();
          d.id = docd.id;
          cb(d);
        })
        .catch((error) => {
          cb(null);
        });
    }
  };

  const getUsername = async (cb) => {
    if (infoCard?.username) {
      cb(infoCard?.username);
      return;
    }
    await App.firestore()
      .collection("users")
      .doc(infoCard?.userId)
      .get()
      .then(async (docd) => {
        var user = docd.data();
        if (user?.displayName) cb(user?.displayName);
      })
      .catch((err) => {
        cb("");
        console.log(" err", err);
      });
  };

  const getAllserchusercard = async (cb) => {
    await App.firestore()
      .collection("searchs")
      .where("userId", "==", infoCard?.userId)
      .where("state", "==", 1)
      .get()
      .then(async (querySnapshot) => {
        var data = [];
        querySnapshot.forEach((doc) => {
          if (doc.id !== infoCard?.id) {
            var d = doc.data();
            d.id = doc.id;
            data.push(d);
          }
        });
        cb(data);
      })
      .catch((err) => {
        cb([]);
        console.log(" err", err);
      });
  };
  const [view, setView] = useState(false);

  const UpdateView = () => {
    if (!view && !isMySearch()) {
      setView(true);
      var v = infoCard.views + 1;

      var d = {
        views: Number(v),
        _update_At: new Date(),
      };

      db.collection("searchs").doc(infoCard.id).update(d);
    }
  };

  useEffect(() => {
    if (infoCard) {
      UpdateView();
      getUsername((displayn) => {
        setUser(displayn);
      });
      // rechercher les cartes de l'user
      getAllserchusercard((d) => {
        setDataSearchList(d);
      });
    }
  }, [infoCard]);

  useEffect(() => {
    if (data) {
      // console.log("data", data);
      setInfoCard(data);
    } else {
      getInfoCard((d) => {
        if (d) {
          // console.log("data la", d);
          setInfoCard(d);
        } else {
          // recherche uniquement les informations editor+codecard
        }
      });
    }
  }, [data]);

  function getEx() {
    if (infoCard?.expansion && infoCard?.card?.card_sets) {
      var d = null;
      infoCard?.card?.card_sets.forEach((e) => {
        if (e.set_name === infoCard?.expansion) d = e;
      });
      return d;
    } else return null;
  }

  function PaginatedItems({ items, itemsPerPage }) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = React.useState(null);
    const [pageCount, setPageCount] = React.useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = React.useState(0);

    React.useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(items.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(items.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % items.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} username={username} />
        {items.length > itemsPerPage && (
          <div style={{ margin: "auto", width: "100%", marginTop: 30 }}>
            <ReactPaginate
              previousLabel="précedent"
              nextLabel="suivant"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pageCount}
              pageRangeDisplayed={itemsPerPage}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
              hrefAllControls
              // forcePage={currentPage}
              // onClick={(clickEvent) => {
              //   console.log("onClick", clickEvent);
              //   // Return false to prevent standard page change,
              //   // return false; // --> Will do nothing.
              //   // return a number to choose the next page,
              //   // return 4; --> Will go to page 5 (index 4)
              //   // return nothing (undefined) to let standard behavior take place.
              // }}
            />
          </div>
        )}
      </>
    );
  }
  const [loadingMessage, setLoadingMessage] = useState(false);
  const contactUser = () => {
    alert(
      "Votre message sera transmis à notre équipe avant d'etre visible par l'utilisateur. Veuillez respecter nos conditions d'utilisation ainsi que l'utilisateur."
    );
    let message = prompt("Quel est votre message ?");

    if (message?.length > 0) {
      // contacter l'utilisateur (pas le vendeur)
      setLoadingMessage(true);
      App.firestore()
        .collection("messages")
        .where("uids", "array-contains", App.auth().currentUser?.uid)
        .get()
        .then((snapshot) => {
          let d = null;
          snapshot.forEach((doc) => {
            // console.log(doc.id, "=>", doc.data());
            const uids = doc.data().uids;
            uids.forEach((id) => {
              if (infoCard?.userId === id) {
                d = doc.id;
              }
            });
          });

          const items = {};
          items[App.auth().currentUser?.uid] =
            App.auth().currentUser?.displayName;
          items[infoCard?.userId] = username;
          if (d) {
            // on va ajouter le message a cette identifiant
            App.firestore()
              .collection("messages")
              .doc(d)
              .collection("tchats")
              .add({
                createAt: new Date(),
                message: message,
                senderId: App.auth().currentUser?.uid,
                type: "text",
              })
              .then((res) => {
                App.firestore()
                  .collection("messages")
                  .doc(d)
                  .update({
                    unread: true,
                    updateAt: new Date(),
                    lastMessage: message,
                    lastMessageBy: App.auth().currentUser?.uid,
                  })
                  .then((res) => {
                    setLoadingMessage(false);
                    fetch(API_URL + "sendMessageClientOrder", {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        userId:  infoCard?.userId, 
                        isOrder: false,
                        msg: "Vous avez un nouveau message, cliquez sur le lien pour le voir" ,
                        title: "Vous avez un nouveau message" ,
                      }),
                    })
                    alert("Votre message à été envoyé.");

                   

                  })
                  .catch((err) => {
                    setLoadingMessage(false);
                    console.log("sendmessage  update error", err);
                    alert(
                      "Une erreur s'est produite, réessayer ou contacter nous."
                    );
                  });
              })
              .catch((err) => {
                setLoadingMessage(false);
                console.log("sendmessage  create error", err);
                alert(
                  "Une erreur s'est produite, réessayer ou contacter nous."
                );
              });
          } else {
            App.firestore()
              .collection("messages")
              .add({
                blocked: false,
                createAt: new Date(),
                displayName: items,
                lastMessage: message,
                lastMessageBy: App.auth().currentUser?.uid,
                uids: [App.auth().currentUser?.uid, infoCard?.userId],
                unread: true,
                updateAt: new Date(),
              })
              .then((res) => {
                App.firestore()
                  .collection("messages")
                  .doc(res.id)
                  .collection("tchats")
                  .add({
                    createAt: new Date(),
                    message: message,
                    senderId: App.auth().currentUser?.uid,
                    type: "text",
                  })
                  .then((res) => {
                    setLoadingMessage(false);
                    fetch(API_URL + "sendMessageClientOrder", {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        userId:  infoCard?.userId, 
                        isOrder: false,
                        msg: "Vous avez un nouveau message, cliquez sur le lien pour le voir" ,
                        title: "Vous avez un nouveau message" ,
                      }),
                    })
                    alert("Votre message à été envoyé.");
                  })
                  .catch((err) => {
                    setLoadingMessage(false);
                    console.log("sendmessage  create error", err);
                    alert(
                      "Une erreur s'esr produite, réessayer ou contacter nous."
                    );
                  });
              })
              .catch((err) => {
                setLoadingMessage(false);
                console.log("sendmessage error", err);
                alert(
                  "Une erreur s'esr produite, réessayer ou contacter nous."
                );
              });
          }
        })
        .catch((err) => {
          setLoadingMessage(false);
          console.log("sendmessage load error", err);
          alert("Une erreur s'esr produite, réessayer ou contacter nous.");
        });
    } else {
      setLoadingMessage(false);
      alert("Votre message est vide.");
    }
  };

  function inverserOrdre(nomComplet) { 
    var mots = nomComplet?.split(" ") || ["Utilisateur", "Inconnu"]; 
    if (mots.length >= 2) { 
        var nomInverse = mots[1] + " " + mots[0][0].toUpperCase() + ".";
        return nomInverse;
    } else { 
        return nomComplet;
    }
  } 

  return (
    <>
      {/* <p>switch a faire sur cart, enchères ou base de deck</p>
      <p>{editor}</p>
      <p>{codeCard}</p>
      <p>{idSearch}</p> */}
      <div class="container py-5 ">
        {isMySearch() && (
          <Alert key={"info"} variant={"info"}>
            Pour modifier cette recherche cliquez{" "}
            <a href="#" onClick={() => edit(infoCard)}>
              ici
            </a>
            {/* <a href={`/tableau-de-bord/product/edit/${idSearch}`}>ici</a>. */}
          </Alert>
        )}

        <div class="product-wrap clearfix product-deatil">
          <div class="row">
            <div class="col-md-5 col-sm-12 col-xs-12">
              <div class="product-image">
                {infoCard && (
                  <Carousel variant="dark">
                    {infoCard?.card?.card_images?.map((image) => (
                      <Carousel.Item>
                        <img
                          style={{
                            justifyItems: "center",
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            height: 356,
                            width: 246,
                          }}
                          className="d-block"
                          src={image?.image_url}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                )}
              </div>
            </div>
            {infoCard && (
              <div class="col-md-6 col-md-offset-1 col-sm-12 col-xs-12">
                <h2 class="name">
                  {infoCard?.lang === "fr"
                    ? infoCard?.card?.name
                    : infoCard?.card?.name_en}
                  <small class="fa fa-2x">
                    rechercher par <a href="javascript:void(0);">{inverserOrdre(username)}</a>{" "}
                    le{" "}
                    {new Date(
                      infoCard?._update_At?.toDate()
                    ).toLocaleDateString("fr-FR", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    })}
                  </small>
                </h2>
                <br />
                <p>
                  {infoCard?.qte} carte{infoCard?.qte > 1 ? "s" : ""} recherchée
                  {infoCard?.qte > 1 ? "s " : " "}
                  en{" "}
                  {getEx()
                    ?.set_rarity_code.replace("(", "")
                    .replace(")", "")}{" "}
                  <OverlayTrigger
                    trigger="click"
                    placement="right"
                    overlay={popover}
                  >
                    <a href="#">
                      <span style={{ color: "green" }}>{infoCard?.etat}</span>
                    </a>
                  </OverlayTrigger>
                  {" - "}
                  {infoCard?.expansion}
                </p>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isMySearch() || loadingMessage}
                  onClick={() => {
                    contactUser();
                  }}
                >
                  {loadingMessage
                    ? "Chargement ... "
                    : "Contacter l'utilisateur"}
                </Button>
                <hr />
                {App.auth().currentUser?.uid && (
                  <button
                    disabled={isMySearch()}
                    class="btn btn-white btn-default"
                    onClick={() => sImSearch(infoCard)}
                  >
                    <i class="fa fa-search"></i> Je recherche aussi cette carte
                  </button>
                )}
                {!isMySearch() && (
                  <>
                    {App.auth().currentUser?.uid && (
                      <a
                        class="btn btn-white btn-default"
                        href={`/tableau-de-bord?openaddcard=true&id=${idSearch}#card`}
                      >
                        <i class="fa fa-bolt"></i> Lui vendre cette carte
                      </a>
                    )}
                    {/* <button class="btn btn-white btn-default">
                      <i class="fa fa-bug"></i> Signaler
                    </button> */}
                  </>
                )}

                <p>
                  <br />
                  Partager avec :{" "}
                </p>
                <FacebookShareButton url={window.location.href}>
                  <div
                    style={{
                      backgroundColor: "blue",
                      width: 30,
                      height: 30,
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 15,
                      marginRight: 10,
                    }}
                  >
                    <i class="fa fa-facebook"></i>
                  </div>
                </FacebookShareButton>
                <TwitterShareButton url={window.location.href}>
                  <div
                    style={{
                      backgroundColor: "#00acee",
                      width: 30,
                      height: 30,
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 15,
                      marginRight: 10,
                    }}
                  >
                    <i class="fa fa-twitter"></i>
                  </div>
                </TwitterShareButton>
              </div>
            )}
          </div>
        </div>
        <br />
        {infoCard ? (
          <Tabs
            defaultActiveKey="description"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="description" title="Description">
              {infoCard ? (
                <>
                  <p class=" name">
                    <br />
                    Edité dans{" "}
                    <a href={`/search/${editor}`}>{infoCard?.expansion}</a>
                    <br />
                    Rareté: <a href="#">{getEx()?.set_rarity}</a>
                    <br />
                    Nombre: {Number(getEx()?.set_code.split("-")[1].slice(2))}
                    <br />
                    Première édition : {infoCard?.first ? "Oui" : "Non"}
                    <br />
                    Réimpressions:
                    {infoCard?.card?.card_sets?.map((e) => {
                      if (
                        !(
                          getEx()?.set_code === e.set_code &&
                          e.set_rarity_code === getEx()?.set_rarity_code
                        )
                      )
                        return (
                          <>
                            {" "}
                            <a href={`/search?q=${e.set_code.split("-")[0]}`}>
                              {e.set_code.split("-")[0]} - {e.set_rarity_code}
                            </a>
                          </>
                        );
                    })}
                  </p>
                  <p>
                    <br />
                    Description de la carte :
                    <br />
                    <span style={{ fontSize: 12 }}>{infoCard?.card?.desc}</span>
                  </p>
                </>
              ) : (
                <p>Chargement ...</p>
              )}
            </Tab>
            {dataSearchList?.length > 0 && (
              <Tab
                eventKey="cartes"
                title={
                  !isMySearch()
                    ? "Autres recherche de l'utilisateur"
                    : "Mes autres recherche"
                }
              >
                <div class="row row-cols-2 row-cols-sm-2 row-cols-md-6 g-3 py-3">
                  <PaginatedItems items={dataSearchList} itemsPerPage={12} />
                </div>
              </Tab>
            )}
          </Tabs>
        ) : (
          <p>Chargement ...</p>
        )}
      </div>
      <Modal
        show={editMySearch}
        onHide={hImSearch}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {infoMySearch?.lang === "fr"
              ? infoMySearch?.card?.name
              : infoMySearch?.card?.name_en}
          </Modal.Title>
        </Modal.Header>
        {/* Afficher les information de la rechercher */}
        {/* {infoMySearch} */}
        <Modal.Body>
          <div class="">
            <Form class="" onSubmit={UpdateSearchCard}>
              <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                <Col>
                  <Form.Group className="mb-3" controlId="qte">
                    <Form.Control
                      type="number"
                      min={1}
                      Placeholder="Quantité"
                      required
                      value={qteCard}
                      onChange={(e) => {
                        setQteCard(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Select
                      aria-label="Choisir l'état minimum de la carte"
                      value={stateCard}
                      onChange={(e) => {
                        setStateCard(e.target.value);
                      }}
                      required
                    >
                      <option value={null}>
                        Choisir l'état minimum de la carte
                      </option>
                      <option value="Mint">Mint</option>
                      <option value="Neart-Mint">Neart-Mint</option>
                      <option value="Excellent">Excellent</option>
                      <option value="Bon état">Bon état</option>
                      <option value="Assez Jouable">Assez Jouable</option>
                      <option value="Jouable">Jouable</option>
                      <option value="Mauvais">Mauvais</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className="mb-3">
                    <Form.Select
                      value={langCard}
                      onChange={(e) => {
                        setLangCard(e.target.value);
                      }}
                      required
                    >
                      <option value="fr">Langue Française</option>
                      <option value="en">Langue Anglaise</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Select
                      value={publishCard}
                      onChange={(e) => {
                        setPublishCard(e.target.value);
                      }}
                      required
                    >
                      <option value="1">Visible</option>
                      <option value="0">Brouillon</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="firstEdition"
                      name="firstEdition"
                      checked={firstCard}
                      onChange={(e) => setFirstCard(e.target.checked)}
                    />
                    <label class="form-check-label" for="firstEdition">
                      {"  Première edition"}
                    </label>
                  </Form.Group>
                </Col>

                <Col>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={loadAddSearchCard}
                  >
                    {loadAddSearchCard ? "Chargement..." : "Mettre à jour"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={DeleteSearchCard}>
            {loadAddSearchCard ? "Chargement..." : "Supprimer"}
          </Button>
          <Button variant="secondary" onClick={hImSearch}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={cloneMySearch}
        onHide={hImSearch}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {infoMySearch?.lang === "fr"
              ? infoMySearch?.card?.name
              : infoMySearch?.card?.name_en}
          </Modal.Title>
        </Modal.Header>
        {/* Afficher les information de la rechercher */}
        {/* {infoMySearch} */}
        <Modal.Body>
          <div class="">
            <Form class="" onSubmit={copieSearchCard}>
              <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                <Col>
                  <Form.Group className="mb-3" controlId="qte">
                    <Form.Control
                      type="number"
                      min={1}
                      Placeholder="Quantité"
                      required
                      value={qteCard}
                      onChange={(e) => {
                        setQteCard(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Select
                      aria-label="Choisir l'état minimum de la carte"
                      value={stateCard}
                      onChange={(e) => {
                        setStateCard(e.target.value);
                      }}
                      required
                    >
                      <option value={null}>
                        Choisir l'état minimum de la carte
                      </option>
                      <option value="Mint">Mint</option>
                      <option value="Neart-Mint">Neart-Mint</option>
                      <option value="Excellent">Excellent</option>
                      <option value="Bon état">Bon état</option>
                      <option value="Assez Jouable">Assez Jouable</option>
                      <option value="Jouable">Jouable</option>
                      <option value="Mauvais">Mauvais</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className="mb-3">
                    <Form.Select
                      value={langCard}
                      onChange={(e) => {
                        setLangCard(e.target.value);
                      }}
                      required
                    >
                      <option value="fr">Langue Française</option>
                      <option value="en">Langue Anglaise</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Select
                      value={publishCard}
                      onChange={(e) => {
                        setPublishCard(e.target.value);
                      }}
                      required
                    >
                      <option value="1">Visible</option>
                      <option value="0">Brouillon</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="firstEdition"
                      name="firstEdition"
                      checked={firstCard}
                      onChange={(e) => setFirstCard(e.target.checked)}
                    />
                    <label class="form-check-label" for="firstEdition">
                      {"  Première edition"}
                    </label>
                  </Form.Group>
                </Col>

                <Col>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={loadAddSearchCard}
                  >
                    {loadAddSearchCard
                      ? "Chargement..."
                      : "Ajouter la recherche"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hImSearch}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Search;
