import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ReactGA from "react-ga4";

// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

ReactGA.initialize("G-ZD1FPFDTSH");
const Config = require("./secure/config");
const stripePromise = loadStripe(Config.STRIPE_PK);

ReactDOM.render(
  <Router>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </Router>,
  document.getElementById("root")
);
