import React from "react";
import "../App.css";

const Cgvendor = ({ navigation }) => (
  <>
    <div
      class="album py-5 bg-light"
      style={{
        boxShadow:
          "inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15)",
      }}
    >
      <div class="container">
        <h1>Guide du vendeur</h1>
        <hr />
        <section>
          <p class="lead">
            Être vendeur sur yugioh.re implique de se plier à certaines
            obligations. Plus que tout, votre objectif est de faire en sorte que
            vos clients soient toujours satisfaits. A cette fin, voici quelques
            lignes directrices pour y parvenir. Elles se divisent en quatre
            points principaux :
          </p>
          <ol>
            <li>Mettre les articles en vente</li>
            <li>Envoyer les articles</li>
            <li>Communiquer avec les acheteurs</li>
            <li>Gérer les différends</li>
          </ol>
          <h2>1. Mettre les articles en vente</h2>
          <h3>Trouver l’article adéquat</h3>
          <p>
            Il est essentiel que vous ajoutiez votre article à la vente sur la
            page correspondante. La plupart des cartes à collectionner se
            déclinent sous plusieurs versions différentes (éditions multiples,
            promos, etc…). Il est primordial que vous n’ajoutiez jamais une
            carte à la vente sur la mauvaise page. Par exemple, offrir une carte
            promotionnelle comme s’il s’agissait d’une simple foil avec le
            commentaire «&nbsp;version promotionnelle&nbsp;» n’est pas
            acceptable et pourra causer de sérieux problèmes si vous veniez à la
            vendre.
          </p>
          <h3>Utilisez une description correcte</h3>
          <p>
            En règle générale, vous ne devriez pas avoir besoin d’utiliser la
            section commentaire, étant donné que les informations importantes
            peuvent et devraient être définies par les options de ventes.
            Cependant, dans certains cas de figure, il est possible que vous
            ayez besoin de fournir des informations complémentaires. Par exemple
            :
          </p>
          <ul>
            <li>Cartes estampillées (GP ou autre)</li>
            <li>
              Cartes endommagées (veuillez décrire les dégâts en détail, ex.
              endommagée par l’eau)
            </li>
            <li>
              Cartes dont l’avant et l’arrière ne sont pas dans le même état
            </li>
          </ul>
          <p>
            Si vous avez besoin de fournir des informations complémentaires pour
            une offre, veuillez vous assurer d’être aussi précis que possible.
            Souvenez-vous que tous les acheteurs ne sont pas forcément des
            collectionneurs ou des joueurs de longue date, et que donc ils ne
            connaissent pas forcément tous les acronymes et autres abréviations
            que vous pouvez utiliser. Rappelez-vous également que yugioh.re est
            un marché français ; tous vos commentaires devraient donc être
            publiés en <strong>français</strong>.
          </p>
          <h3>Soyez rigoureux dans l’évaluation de l’état de vos cartes</h3>
          <p>
            YGO à publié{" "}
            <a
              href="https://help.cardmarket.com/fr/CardCondition"
              target="_blank"
            >
              un guide complet
            </a>{" "}
            qui vous expliquera en détails comment évaluer l’état de vos cartes.
            L’évaluation d’une carte n’étant pas une science dure, il peut y
            avoir des cas particuliers. Voici deux règles qui devraient vous
            aider à évaluer correctement l’état de vos cartes :
          </p>
          <ul>
            <li>
              En cas de doute, préférez une évaluation{" "}
              <strong>revue à la baisse</strong>. Les cartes dont l’état serait
              surévalué entraîneront des plaintes.
            </li>
            <li>
              Si la carte n’a pas l’air d’être comme neuve, veuillez{" "}
              <strong>ne pas</strong> l’ajouter comme étant «&nbsp;Near
              Mint&nbsp;».
            </li>
          </ul>
          <h3>
            Ne présentez pas d’informations contradictoires / N’ajoutez pas des
            playsets «&nbsp;mixes&nbsp;»
          </h3>
          <p>
            Sur Yugioh.re, il existe deux types d’informations pour décrire vos
            offres : «&nbsp;lisibles par machine&nbsp;» et «&nbsp;texte
            libre&nbsp;». «&nbsp;Les informations «&nbsp;lisibles par
            machine&nbsp;» comprennent : <strong>Nom</strong>,
            <strong> Edition</strong>, Evaluation, Foil, Playset, Signée et
            Altérée.
          </p>
          <p>
            Ces données <strong>ne peuvent</strong> en <strong>aucune</strong>{" "}
            circonstance être outrepassées ou modifiées par un commentaire ;
            vous ne pouvez donc <strong>pas</strong> offrir :
          </p>
          <ul>
            <li>
              Une carte avec un nom différent de celui affiché (car ce serait
              une carte différente).
            </li>
            <li>
              Une carte du même nom mais d’une édition différente de celle
              affichée (cherchez simplement la carte adéquate et vendez-la sur
              la page correspondante).
            </li>
            <li>
              Une carte dans une langue différente de celle affichée (choisissez
              simplement la bonne langue lors de la mise en vente de la carte).
            </li>
          </ul>
          <p>
            Quand il s’agit d’évaluer vos cartes, vous devriez être rigoureux,
            et ne pas oublier qu’une carte NM ne peut être NM que si{" "}
            <strong>l’avant</strong> et <strong>l’arrière</strong> de la carte
            sont tous deux NM. Une carte associée à un commentaire
            «&nbsp;NM/EX&nbsp;» ne peut pas être présentée comme étant NM, mais
            seulement EX.
            <br /> Un <strong>Playset</strong> est un groupement de{" "}
            <strong>trois cartes identiques</strong> (c’est-à-dire, de la même
            édition, dans la même langue, dans le même état, etc…). Vous ne
            pouvez pas ajouter un playset à la vente si les cartes ne sont pas
            identiques. Veuillez dans ce cas les ajouter séparément.
          </p>
          <h2>2. Envoyer les articles</h2>
          <h3>
            Assurez-vous que votre commande contienne bien tous les articles
          </h3>
          <p>
            Veuillez vous assurer que vous avez bien emballé{" "}
            <strong>toutes</strong> les cartes achetées par votre client avant
            d’envoyer votre commande. Il n’y a rien de plus frustrant pour un
            acheteur que de recevoir une commande incomplète. Si cela devait
            arriver, cela signifie que vous allez tous deux perdre
            potentiellement du temps et de l’argent lorsque vous allez devoir
            vous assurer que l’acheteur reçoive bien tous ses articles. Si vous
            deviez vous rendre compte durant la préparation d’une commande qu’il
            vous manque une carte, <strong>n’envoyez pas votre commande</strong>
            . A la place, contactez l’acheteur. Il préfèrera peut-être annuler
            sa commande en totalité plutôt que de la recevoir avec une carte en
            moins.
          </p>
          <p>
            La règle générale est la suivante : si une carte venait à manquer
            dans une commande, tous les frais nécessaires pour arranger la
            situation (pour que l’acheteur reçoive les cartes manquantes) seront
            à la charge du vendeur.
          </p>
          <p>&nbsp;</p>
          <h3>Assurez-vous que votre commande contienne les bons articles</h3>
          <p>
            C’est plus ou moins la même chose que le point précédent : veuillez
            vous assurer que vous possédez <strong>exactement</strong> ce que
            l’acheteur vous a commandé. Veuillez donc vérifier que tous les
            détails de chaque article soient corrects (extension, langue,
            première édition). Vous ne connaissez pas les préférences de
            l’acheteur, et même si vous pensez que l’extension n’a pas
            d’importance puisque la carte «&nbsp;fait la même chose&nbsp;»,
            l’acheteur pense peut-être différemment, et il se pourrait qu’il
            achète cette carte en particulier afin de compléter son playset de
            quatre cartes identiques. De ce fait, si votre commande ne
            correspond pas exactement à ce qu’a choisi le client,{" "}
            <strong>ne l’envoyez pas </strong>; contactez plutôt l’acheteur.
            Tous les frais résultant d’un envoi de mauvaises cartes (tels que
            les frais de retour ou les frais additionnels que l’acheteur
            pourrait avoir à payer afin d’obtenir la bonne carte) seront à la
            charge du vendeur.
          </p>
          <h3>Contactez l’acheteur avant l’envoi en cas de problème</h3>
          <p>
            Au moindre problème (carte manquante, carte d’une édition
            différente, évaluation un peu trop optimiste de l’état d’une carte),
            vous devez <strong>contacter l’acheteur</strong> avant l’envoi. Il
            n’y a rien de plus frustrant pour un acheteur que de recevoir un
            article qu’il n’a pas commandé. Etant donné que vous, le vendeur,
            devrez-vous acquitter des frais résultant de cette erreur, il est
            dans votre intérêt de ne pas envoyer une commande qui ne
            contiendrait pas les bons articles.
          </p>
          <h3>Assurez-vous de ne pas vous tromper dans l’adresse</h3>
          <p>
            Lorsque vous envoyez une commande, vous devez vous assurer que vous
            avez correctement écrit <strong>l’adresse de l’acheteur</strong>.
            Veuillez vérifier que vous n’avez pas oublié la moindre information.
            Il vaut mieux s’y prendre à deux fois. Car, si vous ne le faites
            pas, vous devrez probablement gérer le courrier perdu, tout
            simplement parce que vous aurez oublié la deuxième partie du numéro
            de la rue ou que vous aurez interverti deux chiffres du code postal.
            <br /> De plus, vous devez vous assurer que vous avez bien indiqué
            une <strong>adresse de retour</strong> valide (c’est-à-dire, votre
            adresse). Si, pour n’importe quelle raison, votre courrier ne
            pouvait pas être livré (dans le cas où l’acheteur vous aurait
            communiqué une mauvaise adresse, ou que vous vous seriez trompé dans
            son adresse, voire même en cas d’erreur de la part des services
            postaux), le courrier vous sera renvoyé. En revanche, cela n’est
            possible que si vous avez indiqué une adresse de retour sur votre
            courrier. Tout courrier sans adresse de retour sera jeté. En outre,
            une adresse de retour permettra à l’acheteur d’identifier chaque
            envoi yugioh.re. En effet, certains acheteurs sont des
            professionnels qui peuvent avoir des dizaines de commandes à la
            fois, et en l’absence de nom sur l’enveloppe, ils auront beaucoup de
            mal à identifier votre commande yugioh.re, ce qui aura pour
            conséquence de retarder fortement la confirmation d’arrivée de votre
            courrier.
          </p>
          <h2>3. Communiquer avec les acheteurs</h2>
          <h3>Répondez dans un délai raisonnable</h3>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <p>
            En tant que vendeur, vous être responsable de vos commandes et de la
            satisfaction de votre acheteur. Même si vous êtes attentionné et que
            vous avez suivi les recommandations ci-dessus, il peut parfois
            arriver qu’une carte que vous avez envoyée pose problème. C’est là
            que le service client est extrêmement important. Et votre premier
            souci est de répondre à n’importe quel message envoyé par vos
            acheteurs dans un délai raisonnable. Il n’y a rien de pire que de
            contacter un vendeur parce qu’il a fait une erreur, pour se rendre
            compte qu’il ne répond pas. Votre acheteur aura l’impression que
            vous n’avez que faire des commandes que vous envoyez. Ne faites pas
            ça.
          </p>
          <p>&nbsp;</p>
          <p>
            En tant que vendeur, vous devriez répondre aux messages de vos
            acheteurs sous 24 heures. Ce délai peut varier occasionnellement,
            mais en règle générale, il est inacceptable de répondre après plus
            de deux jours ouvrés. Veuillez noter que rejeter la requête de votre
            acheteur («&nbsp;Bonjour, je suis désolé mais il manque une carte à
            la commande que je vous ai passée ?&nbsp;» - «&nbsp;Non, ce n’est
            pas vrai&nbsp;») n’est pas une réponse acceptable.Vous devriez
            toujours essayer de trouver un compromis et des solutions aux
            éventuels problèmes que vous pourriez rencontrer. Et si vous ne
            parvenez pas à un accord avec l’acheteur, vous devriez alors
            contacter notre service client, nous expliquer la situation et nous
            demander des conseils ou de l’aide pour faire le médiateur avec
            l’acheteur. Nous sommes là pour ça.
          </p>
          <p>
            <strong>
              Veuillez noter que nous sanctionnons les vendeurs qui ne donnent
              pas de suite aux problèmes signalés par leurs acheteurs.
            </strong>
          </p>
          <h3>Soyez respectueux</h3>
          <p>
            Lorsque vous communiquez sur yugioh.re, veuillez vous assurer que
            vous restez poli et que vous utilisez un langage correct. Prenez
            quelques secondes supplémentaires avant de commencer à écrire un
            message afin d’y inclure une salutation personnalisée pour votre
            acheteur. La communication n’en sera que plus aisée pour les deux
            parties impliquées. Une bonne communication est essentielle à la
            bonne résolution des problèmes. Vous n’imaginez pas combien de
            petites contestations ont conduit à de plus gros problèmes
            simplement parce que les gens utilisent un langage inadapté ou
            insultant.
          </p>
          <h3>Comment gérer les langues étrangères</h3>
          <p>
            yugioh.re est un marché européen. Nos utilisateurs viennent de
            plusieurs pays différents. Ainsi, il est fort probable que vous ayez
            des acheteurs dans plusieurs pays. Lorsque vous vendez à
            l’international sur yugioh.re, la langue de communication par défaut
            est le <strong>français</strong>. Vous ne pouvez pas répondre à un
            utilisateur étranger dans votre langue natale, de la même façon
            qu’il ne peut pas s’attendre à ce que vous puissiez lire une réponse
            dans sa propre langue. C’est pourquoi le français reste la meilleure
            solution.
          </p>
          <p>
            A travers les années, nous avons pu constater que beaucoup de
            problèmes résultent de simples malentendus. Dans certains cas,
            l’acheteur comme le vendeur proposaient la même solution à leur
            problème (ils étaient d’accord l’un avec l’autre), mais leur faible
            capacité à communiquer en français signifiait qu’ils ne pouvaient
            tout simplement pas se comprendre l’un l’autre.
            <br /> C’est aussi pourquoi nous déconseillons fortement l’usage de
            Google translate ou de tout autre traducteur automatique. Si votre
            français n’est pas assez bon pour que vous puissiez tenir une
            conversation normale telles que celles qui pourraient advenir dans
            le cadre d’une vente sur yugioh.re, vous devriez retirer la
            possibilité de vendre dans d’autres pays dans vos «&nbsp;options de
            vente&nbsp;». Si malgré tout vous aviez un problème de langue avec
            un autre utilisateur, veuillez contacter notre Service Client et
            nous laisser assurer la médiation avec votre acheteur.
          </p>
          <h2>4. Gérer les différends</h2>
          <h3>Plaintes concernant des articles manquants</h3>
          <p>
            Si votre acheteur vous contacte pour se plaindre que des articles
            sont manquants, la première chose que vous devriez faire est de
            revérifier votre stock. Notre expérience nous montre que, le plus
            souvent, les cartes manquantes sont le résultat d’un vendeur qui a
            tout simplement oublié de les envoyer. Si vous trouvez la carte,
            vous pouvez voir avec l’acheteur si celui préfère que vous la lui
            envoyiez dans un envoi séparé (vous devrez vous acquitter des frais
            de port supplémentaires) ou bien que vous lui offriez un
            remboursement. Si vous ne pouvez pas trouver la carte, alors un
            remboursement sera la solution la plus rapide dans la majorité des
            cas.
          </p>
          <p>
            Veuillez noter que : si une carte venait à manquer, l’acheteur
            pourrait être contraint de payer plus cher s’il devait se la
            procurer auprès d’un autre acheteur (le prix de la carte pourrait
            avoir changé, mais surtout, la plupart du temps, l’acheteur devra
            payer des frais de ports supplémentaires). C’est vous, le vendeur,
            qui devrez vous acquitter de ces frais supplémentaires. Par
            conséquent, assurez-vous de ne jamais envoyer de commande incomplète
            !
          </p>
          <h3>
            Plaintes concernant la description des articles (état, version,
            etc…)
          </h3>
          <p>
            Il s’agit du problème le plus courant, mais aussi le plus
            «&nbsp;délicat&nbsp;», qu’on peut rencontrer en vendant des cartes
            d’occasion. Il existe tellement de cartes qu’il vous arrivera
            peut-être parfois de vous tromper d’édition ou de langue. De plus,
            les cartes d’occasion doivent être évaluées et, malheureusement, une
            telle évaluation implique forcément une part de
            «&nbsp;subjectivité&nbsp;», ce qui signifie que vous rencontrerez
            très sûrement des problèmes d’évaluation au cours de votre carrière
            de vendeur.
          </p>
          <p>
            Compte tenu de la subjectivité inhérente à ces problèmes, la plupart
            d’entre eux devront être examinés et résolus par l’acheteur et le
            vendeur. Voici malgré tout quelques unes des meilleures choses que
            vous puissiez faire en tant que vendeur :
          </p>
          <ul>
            <li>
              Des erreurs peuvent se produire. Même si vous êtes sûr à 100%
              d’avoir envoyé la bonne carte, vous avez pu faire une erreur,
              alors veuillez prêter attention à la requête de l’acheteur.
            </li>
            <li>
              L’évaluation d’une carte est subjectif, et il n’y a pas de
              «&nbsp;vrai ou faux&nbsp;» entre les plus petites variations
              d’état. L’un des débats les plus courants sur yugioh.re est ainsi
              : «&nbsp;Est-ce que cette carte est NM ou EX ?&nbsp;» La vérité se
              situe souvent entre les deux, et il vaut donc mieux accepter qu’il
              y a un problème, mais qu’aucune des parties n’a tord.
            </li>
            <li>
              Si vous faites face à une erreur d’évaluation qui concerne une
              différence d’un seul degré (telle que la question du NM ou EX),
              notre règle d’or stipule qu’un remboursement à la hauteur de 10%
              de la valeur de la carte devrait faire pour une compensation
              adéquate.
            </li>
            <li>
              Si vous vous retrouvez coincé dans une discussion avec l’acheteur,
              veuillez contacter notre Service Client. Cependant, veuillez noter
              que, la plupart du temps, nous ne serons pas capable de vous aider
              à répondre à la question «&nbsp;ai-je évalué cette carte
              correctement ?&nbsp;» (c’est d’autant plus compliqué pour nous,
              étant donné que nous n’avons pas la carte entre nos mains), mais
              nous vous aiderons à trouver un moyen d’arriver à un compromis / à
              une solution qui, nous l’espérons, vous satisfera vous et votre
              acheteur.
            </li>
          </ul>
          <p>
            Ainsi, si vous deviez recevoir une plainte, veuillez{" "}
            <strong>ne pas rejeter la requête de l’acheteur</strong>. Ecoutez
            plutôt ce qu’il a à vous dire et{" "}
            <strong>essayez de trouver un compromis</strong>. Si cela n’est pas
            possible, veuillez contacter le Service Client et laissez nous faire
            le médiateur avec l’acheteur.
          </p>
          <h3>Faire un retour / un remboursement comme il se doit</h3>
          <p>
            Parfois, il n’y a pas d’autre solution que de
            «&nbsp;rembobiner&nbsp;» la transaction toute entière, c’est-à-dire,
            que l’acheteur renvoie sa commande complète au vendeur afin
            d’obtenir un remboursement. Actuellement, yugioh.re n’offre pas de
            fonction de retour «&nbsp;intégrée&nbsp;», et cela doit donc être
            fait «&nbsp;manuellement&nbsp;». Voici quelques conseils :
          </p>
          <h4>Informez yugioh.re du retour</h4>
          <p>
            Si vous avez convenu d’un retour (et, bien sûr, si vous ne pouvez
            pas vous mettre d’accord avec votre acheteur), veuillez en informer
            yugioh.re. Vous pouvez simplement nous dire que «&nbsp;telles cartes
            vont m’être retournées à cause d’un problème&nbsp;», ce sera
            amplement suffisant, mais nous avons besoin d’être tenus informés,
            afin que nous puissions vous aider.
          </p>
          <h4>Commandes bénéficiant du Service d'assurance de la poste</h4>
          <p>
            Si votre commande bénéficiait du service assurance, l’argent est
            probablement toujours chez yugioh.re. Dans ce cas, vous n’aurez
            sûrement qu’à envoyer un remboursement à l’acheteur pour les frais
            de port et lui demander de vous retourner la carte en question. Une
            fois que vous l’aurez reçue, vous pourrez lancer une procédure de
            remboursement de la valeur de l’article. Seulement après que cela
            aura été fait, l’acheteur pourra confirmer la réception de la
            commande, ce qui mettra fin à la transaction.
          </p>
          <p>
            Si l'acheteur avait déjà confirmé la réception de la commande, et
            qu’ainsi celle-ci a été «&nbsp;fermée&nbsp;» et l’argent versé sur
            votre compte, veuillez contacter notre Service Client pour que nous
            vous aidions à vous occuper du retour de vos articles.
          </p>
          <h4>Commandes ne bénéficiant pas du Service assurance</h4>
          <p>
            Pour les commandes plus petites qui n'ont pas de Service assurance,
            nous recommandons aux vendeurs de proposer un remboursement d'un
            montant équivalent à "coût d'envoi + valeur de l'article" après
            réception des articles envoyés par l'acheteur, à moins que le
            vendeur ait accepté de rembourser l'acheteur en amont. La méthode
            d'envoi utilisée par l'acheteur devrait être identique à celle que
            vous avez utilisée, ce afin d'offrir le même niveau de sécurité tout
            en gardant les coûts d'envoi faibles. Veuillez vous assurer que
            l'acheteur est satisfait par votre proposition de remboursement, et
            restez en contact avec l'acheteur pendant le processus de
            remboursement.
          </p>
          <br />
          <p>
            Si vous avez des question auxquelles nous n’avons pas répondu dans
            ce guide, ou si vous rencontrez des problèmes que nous n’avons pas
            mentionnés avec vos commandes, veuillez contacter notre Service
            Client dès que possible afin que nous puissions vous aider.
          </p>
        </section>
      </div>
    </div>
  </>
);
export default Cgvendor;
