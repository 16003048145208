import React from "react";
import { App } from "../firebase";
import "../App.css";

const Action3 = () => {
  const getUser = async (uid, cb) => {
    await App.firestore()
      .collection("users")
      .doc(uid)
      .get()
      .then(async (docd) => {
        var user = docd.data();
        user.id = docd.id;
        cb(user);
      })
      .catch((error) => {
        cb(null);
      });
  };
  const redirect = (us) => {
    if (us && (us?.role === "vendor" || us?.role === "pendingvendor")) {
      window.location.href = "/tableau-de-bord";
    } else {
      if (us && us?.role !== "vendor") {
        window.location.href = "/mon-compte#becomevendor";
      } else {
        if (!us) {
          window.location.href = "/inscription";
        }
      }
    }
  };

  React.useEffect(() => {
    App.auth().onAuthStateChanged((u) => {
      getUser(App.auth().currentUser?.uid, (u) => {
        if (u) {
          redirect(u);
        } else {
          redirect(null);
        }
      });
    });
    setTimeout(() => {
      window.location.href = "/mon-compte"; // redirection lente
    }, 5000);
  }, []);

  return (
    <>
      <div class="container marketing px-3 py-3 ">
        <p>Redirection ...</p>
      </div>
    </>
  );
};

const RegisterVendor = ({ navigation }) => (
  <>
    <Action3 />
  </>
);
export default RegisterVendor;
