import React, { useEffect } from "react";
import "../App.css";
import Carousel from "react-bootstrap/Carousel";
import { Item } from "../components/ItemCard";
import { ItemEnchere } from "../components/ItemEnchere";
import { ItemSearch } from "../components/ItemSearch";
import { ItemBasedeDeck } from "../components/ItemBDK";
import { App } from "../firebase";
import ReactN from "reactn";

const getOnly = (data, number) => {
  let d = [];
  data.forEach((dr) => {
    if (d.length !== number) {
      if (dr.qte > 0) d.push(dr);
    }
  });
  return d;
};

const ProductCard = () => {
  const saved = JSON.parse(localStorage.getItem("cardData"));
  const [dataList, setDataList] = React.useState(saved || []);
  const [loading, setLoading] = React.useState(false);

  const getAllSoldcard = async (cb) => {
    if (loading) {
      return;
    }
    setLoading(true);
    await App.firestore()
      .collection("cards")
      .where("state", "==", 1)
      // .where("qte", ">", 0)
      .orderBy("_update_At", "desc")
      // .limit(7)
      .get()
      .then(async (querySnapshot) => {
        var data = [];
        querySnapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          
          if (d.qte > 0){
            data.push(d);
          }
        });
        setLoading(false);
        // const res = data.sort(function (a, b) {
        //   // Turn your strings into dates, and then subtract them
        //   // to get a value that is either negative, positive, or zero.
        //   return b._update_At.toDate() - a._update_At.toDate();
        // });
        // console.log(res);
        cb(data);
      })
      .catch((err) => {
        setLoading(false);
        cb([]);
        console.log(" err", err);
      });
  };

  const diffHour = (d1) => {
    let date_1 = new Date(d1);
    let date_2 = new Date();
    let difference = date_2.getTime() - date_1.getTime();
    return Math.ceil(difference / (1000 * 3600));
  };

  useEffect(() => {
    if (dataList) {
      localStorage.setItem("cardData", JSON.stringify(dataList));
    }
  }, [dataList]);

  useEffect(() => {
    if (dataList && dataList?.data?.length > 0) {
      const TotalHour = diffHour(dataList.date);
      console.log(TotalHour + " hour to world Cup");
      if (TotalHour > 1) {
        // new Download Data
        setDataList(null);
        getAllSoldcard((d) => {
          setDataList({ data: d, date: new Date() });
        });
      }
    } else {
      // console.log("download data");
      getAllSoldcard((d) => {
        setDataList({ data: d, date: new Date() });
      });
    }
  }, []);

  return (
    <>
      <div
        class="album py-5 bg-light"
        style={{
          boxShadow:
            "inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15)",
        }}
      >
        <div class="container">
          <h2 class="pb-2 border-bottom ">
            Cartes
            {dataList && dataList?.data && dataList?.data?.length > 6 && (
              <a href="/cartes" class="btn btn-link">
                Voir plus
              </a>
            )}
          </h2>
          <div class="row row-cols-2 row-cols-sm-2 row-cols-md-6 g-3 py-3">
            {dataList &&
              dataList?.data &&
              getOnly(dataList?.data, 6).map((e, i) => (
                <>
                  {i === 3 ? (
                    <>
                      <Item data={e} />
                      {/* <script
                        async
                        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5193127212919741"
                        crossorigin="anonymous"
                      />
                      <ins
                        class="adsbygoogle"
                        style={{ display: "block" }}
                        data-ad-format="fluid"
                        data-ad-layout-key="+2t+rl+2h-1m-4u"
                        data-ad-client="ca-pub-5193127212919741"
                        data-ad-slot="3054878529"
                      />
                      <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                      </script> */}
                    </>
                  ) : (
                    <Item data={e} />
                  )}
                </>
              ))}
            {loading && <p>Chargement ...</p>}
          </div>
        </div>
      </div>

      {/* <div class="container px-3 py-3" id="icon-grid">
        <div class="row g-4 row-cols-1 row-cols-lg-3">
          <div
            class="col d-flex align-items-start"
            style={{ justifyContent: "center" }}
          >
            <a href="/faqs" class="btn btn-dark">
              Comment acheter
            </a>
          </div>
          <div class="col d-flex align-items-start">
            <div>
              <h6 style={{ textAlign: "center" }}>
                UNITY CARD le marché en ligne, où acheter et vendre des
                carte est très facile.
              </h6>
            </div>
          </div>
          <div
            class="col d-flex align-items-start"
            style={{ justifyContent: "center" }}
          >
            <a href="/conditions-vendeur" class="btn btn-dark">
              Comment vendre
            </a>
          </div>
        </div>
      </div> */}
    </>
  );
};

const BaseDeckList = () => {
  const [user, _] = ReactN.useGlobal("user");
  return (
    <>
      <div
        class="album py-5 bg-light"
        style={{
          boxShadow:
            "inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15)",
        }}
      >
        <div class="container">
          <h2 class="pb-2 border-bottom ">
            Base de deck
            <a href="/bases-de-deck" class="btn btn-link">
              Voir plus
            </a>
          </h2>
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
            <ItemBasedeDeck />
            <ItemBasedeDeck />
            <ItemBasedeDeck />
            <ItemBasedeDeck />
            <ItemBasedeDeck />
            <ItemBasedeDeck />
          </div>
        </div>
      </div>
      <div class="container marketing px-3 py-3 ">
        <div class="row featurette">
          <div class="col-md-7 order-md-2">
            <h2 class="featurette-heading fw-normal lh-1">
              Vendez vos bases de <span class="text-muted">deck.</span>
            </h2>
            <p class="lead">
              Jeux prêts à jouer, ... mieux qu'un deck de structure ou un
              starter pour redécouvrir le jeu de cartes Yu-Gi-Oh, ou simplement
              pour récupérer les meilleures cartes d'une méta rapidement!
            </p>
            {user && user?.role === "vendor" ? (
              <p>
                <a class="btn btn-lg btn-dark" href="/tableau-de-bord">
                  Mon compte
                </a>
              </p>
            ) : (
              <p>
                <a class="btn btn-lg btn-dark" href="/inscription-vendeur">
                  Devenir vendeur
                </a>
              </p>
            )}
          </div>
          <div
            class="col-md-5 order-md-1"
            style={{
              overflow: "hidden",
            }}
          >
            <img
              alt="yugioh.re yugioh vendeur"
              src={process.env.PUBLIC_URL + "/assets/images/BASED253451.png"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const EnchereCard = () => {
  return (
    <div
      class="album py-5 bg-light"
      style={{
        boxShadow:
          "inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15)",
      }}
    >
      <div class="container">
        <h2 class="pb-2 border-bottom ">
          Enchères
          <a href="/encheres" class="btn btn-link">
            Voir plus
          </a>
        </h2>
        <div class="row row-cols-2 row-cols-sm-2 row-cols-md-5 g-3 py-3">
          <ItemEnchere />
          <ItemEnchere />
          <ItemEnchere />
        </div>
      </div>
    </div>
  );
};
const CALL = () => {
  const [user, _] = ReactN.useGlobal("user");
  return (
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={process.env.PUBLIC_URL + "/assets/images/yugioh-1-2.png"}
          alt="First slide"
        />

        <Carousel.Caption>
          <div class="container sidebar-dash">
            <div class="carousel-caption text-start" style={{padding:0}}>
              <h1>Vendez vos cartes</h1>

              {user && user?.role === "vendor" ? (
                <>
                  <p>
                    Gérer vos ventes et livrez vos cartes sur toute La Réunion
                    et en métropole.
                  </p>
                  <p>
                    <a class="btn btn-lg btn-dark" href="/tableau-de-bord">
                      Mon compte
                    </a>
                  </p>
                </>
              ) : (
                <>
                  <p>
                    Devenez vendeur et livrez vos cartes sur toute La Réunion et
                    en métropole
                  </p>
                  <p>
                    <a class="btn btn-lg btn-dark" href="/devenir-vendeur">
                      Devenir vendeur
                    </a>
                  </p>
                </>
              )}
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      {/* <Carousel.Item>
        <img
          className="d-block w-100"
          src={process.env.PUBLIC_URL + "/assets/images/yugioh-1-3.png"}
          alt="First slide"
        />

        <Carousel.Caption>
          <div class="container">
            <div class="carousel-caption text-start">
              <h1>Les Enchères</h1>
              <p>
                Les ventes aux enchères de centaines de cartes pour acheter les
                meilleurs cartes au meilleur prix. Participez aux enchères en
                direct live, découvrez le détail des ventes et bien plus encore!
              </p>
              <p>
                <a class="btn btn-lg btn-dark" href="/encheres">
                  Ventes aux enchères
                </a>
              </p>
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item> */}
      {/* <Carousel.Item>
        <img
          className="d-block w-100"
          src={process.env.PUBLIC_URL + "/assets/images/yugioh-1-4.png"}
          alt="First slide"
        />

        <Carousel.Caption>
          <div class="container">
            <div class="carousel-caption text-start">
              <h1>Bases de deck</h1>
              <p>Les meilleures offres pour Base de deck Yu-Gi-Oh</p>
              <p>
                <a class="btn btn-lg btn-dark" href="/bases-de-deck">
                  Voir les bases de Deck
                </a>
              </p>
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item> */}
    </Carousel>
  );
};

const Action1 = () => {
  const [user, _] = ReactN.useGlobal("user");
  return (
    <>
      {/* <div class="container px-3 py-3" id="icon-grid">
        <div class="row g-4 row-cols-1 row-cols-lg-4">
          <div class="col d-flex align-items-start">
            <div>
              <p>Déjà beaucoup de cartes disponibles</p>
            </div>
          </div>
          <div class="col d-flex align-items-start">
            <div>
              <p>Facile et sécurisé</p>
            </div>
          </div>
          <div class="col d-flex align-items-start">
            <div>
              <p>Toujours au meilleur prix</p>
            </div>
          </div>
          <div class="col d-flex align-items-start">
            <a href="/inscription" class="btn btn-dark">
              Inscris-toi gratuitement
            </a>
          </div>
        </div> 
      </div>*/}
      <div class="container marketing px-3 py-3 ">
        <div class="row featurette">
          <div class="col-md-7">
            <h2 class="featurette-heading fw-normal lh-1">
              Vendez vos <span class="text-muted">cartes</span> rapidement.
            </h2>
            <p class="lead">
              Notre solution vous permettra de mettre rapidement vos cartes a la
              vente. Vous pourrez gagner de l'argent directement sur votre
              compte bancaire. La référence réunionnaise pour Yu-Gi-Oh! Achète
              et vends des cartes, des bases de deck YGO au meilleur prix.
            </p>
            {user && user?.role === "vendor" ? (
              <p>
                <a class="btn btn-lg btn-dark" href="/tableau-de-bord">
                  Mon compte
                </a>
                <a
                  href="/conditions-vendeur"
                  class="btn btn-lg btn-dark"
                  style={{ marginLeft: 20 }}
                >
                  Comment vendre
                </a>
              </p>
            ) : (
              <p>
                <a class="btn btn-lg btn-dark" href="/inscription-vendeur">
                  Devenir vendeur
                </a>

                <a
                  href="/conditions-vendeur"
                  class="btn btn-lg btn-dark"
                  style={{ marginLeft: 20 }}
                >
                  Comment vendre
                </a>
              </p>
            )}
          </div>
          <div
            class="col-md-5"
            style={{
              overflow: "hidden",
            }}
          >
            <img
              alt="yugioh.re yugioh vendeur"
              src={
                process.env.PUBLIC_URL +
                "/assets/images/best-place-to-sell-Yugioh-cards.jpg"
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};
const Action2 = () => {
  return (
    <>
      {/* <div class="container marketing px-3 py-3 ">
        <div class="row featurette">
          <div class="col-md-7">
            <h2 class="featurette-heading fw-normal lh-1">
              Des <span class="text-muted">enchères</span> au plus simple.
            </h2>
            <p class="lead">
              Comme une maison de vente aux enchères, faites envolé vos cartes à
              prix d'or. Quelque soit une carte gradée et notée par PSA et PCA a
              haute valeur ou simplement des starslights ou des ghosts rare.
            </p>
            <p>
              <a class="btn btn-lg btn-dark" href="/inscription-vendeur">
                Devenir vendeur
              </a>
            </p>
          </div>

          <div
            class="col-md-5"
            style={{
              overflow: "hidden",
            }}
          >
            <img
              alt="yugioh.re yugioh vendeur"
              src={
                process.env.PUBLIC_URL +
                "/assets/images/1639069974_984_Yu-Gi-Oh-cartes-rare-Yu-Gi-Oh-des-cartes-a-convoiter.jpg"
              }
            />
          </div>
        </div>
      </div> */}
      <div class="container marketing px-3 py-3 ">
        <div class="row">
          <div class="col-lg-4 ">
            <img
              alt=""
              src={process.env.PUBLIC_URL + "/assets/images/pay.jpg"}
              style={{
                width: 140,
                height: 140,
              }}
            />

            <h2 class="fw-normal">Paiement en ligne</h2>
            <p>Sécurisé par notre partenaire stripe.</p>
          </div>
          <div class="col-lg-4">
            <img
              alt=""
              src={process.env.PUBLIC_URL + "/assets/images/frais.png"}
              style={{
                width: 140,
                height: 140,
              }}
            />

            <h2 class="fw-normal">Frais transparent</h2>
            <p>
              Mise à la vente gratuite, seulement 5% de commission de vente ou
              5€ / mois <span style={{ fontSize: 10 }}>(1)</span>
            </p>
          </div>
          <div class="col-lg-4">
            <img
              alt=""
              src={process.env.PUBLIC_URL + "/assets/images/sold.png"}
              style={{
                width: 140,
                height: 140,
              }}
            />

            <h2 class="fw-normal">Vendez en quelques minutes</h2>
            <p>Inscription vendeur et ajout de carte simplifiés</p>
          </div>
        </div>
      </div>
    </>
  );
};
const UserSearch = () => {
  const searchCard = JSON.parse(localStorage.getItem("searchCard"));
  const [dataListSearch, setDataListSearch] = React.useState(searchCard || []);
  const [loading, setLoading] = React.useState(false);

  const getAllSearchCard = async (cb) => {
    if (loading) {
      return;
    }
    setLoading(true);
    await App.firestore()
      .collection("searchs")
      .where("state", "==", 1)
      .orderBy("_update_At", "desc")
      // .limit(7)
      .get()
      .then(async (querySnapshot) => {
        var data = [];
        querySnapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          data.push(d);
        });
        setLoading(false);
        cb(data);
      })
      .catch((err) => {
        setLoading(false);
        cb([]);
        console.log(" err", err);
      });
  };
  useEffect(() => {
    if (dataListSearch) {
      localStorage.setItem("searchCard", JSON.stringify(dataListSearch));
    }
  }, [dataListSearch]);

  const diffHour = (d1) => {
    let date_1 = new Date(d1);
    let date_2 = new Date();
    let difference = date_2.getTime() - date_1.getTime();
    return Math.ceil(difference / (1000 * 3600));
  };
  useEffect(() => {
    if (dataListSearch && dataListSearch?.data?.length > 0) {
      const TotalHour = diffHour(dataListSearch.date);
      console.log(TotalHour + " hour to world Cup");
      if (TotalHour > 1) {
        // new Download Data
        setDataListSearch(null);
        getAllSearchCard((d) => {
          setDataListSearch({ data: d, date: new Date() });
        });
      }
    } else {
      // console.log("download DataListSearch");
      getAllSearchCard((d) => {
        setDataListSearch({ data: d, date: new Date() });
      });
    }
  }, []);

  function inverserOrdre(nomComplet) { 
    var mots = nomComplet?.split(" ") || ["Utilisateur", "Inconnu"]; 
    if (mots.length >= 2) { 
        var nomInverse = mots[1] + " " + mots[0][0].toUpperCase() + ".";
        return nomInverse;
    } else { 
        return nomComplet;
    }
} 

  return (
    <div
      class="album py-5 bg-light"
      style={{
        boxShadow:
          "inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15)",
      }}
    >
      <div class="container">
        <h2 class="pb-2 border-bottom ">
          Les utilisateurs recherche aussi
          {dataListSearch?.data?.length > 6 && (
            <a href="/user-search" class="btn btn-link">
              Voir plus
            </a>
          )}
        </h2>
        <div class="row row-cols-2 row-cols-sm-2 row-cols-md-6 g-3 py-3">
          {dataListSearch &&
            dataListSearch?.data &&
            getOnly(dataListSearch?.data, 6).map((e) => ( 
              <ItemSearch data={e} key={e.id} username={inverserOrdre(e?.username) || ""}   />
            ))}
        </div>
      </div>
    </div>
  );
};

const Home = () => (
  <>
    <CALL />
    <ProductCard />
    <Action1 />
    {/* <EnchereCard /> */}
    {/* <BaseDeckList /> */}
    {/* <BecomeVendor /> */}
    <UserSearch />
    <Action2 />
  </>
);
export default Home;
