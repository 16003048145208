import React from "react";
import { useParams } from "react-router-dom";
import "../App.css";

const OffresCard = () => {
  const { codeCard } = useParams();
  return (
    <>
      <p>Afficher ici les offres des cartes correspondant {codeCard}</p>
    </>
  );
};
export default OffresCard;
