import React from "react";
import "../App.css";
import Carousel from "react-bootstrap/Carousel";
import { ItemBasedeDeck } from "../components/ItemBDK";

const ProductCard = () => {
  return (
    <div
      class="album py-5 bg-light"
      style={{
        boxShadow:
          "inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15)",
      }}
    >
      <div class="container">
        <div
          class="border-bottom"
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
          }}
        >
          <h2 class="pb-2  ">Bases de Deck</h2>
          <div class="form">
            <select
              class="form-select"
              id="floatingSelect"
              aria-label="Floating label select example"
            >
              <option selected>Dernier ajout</option>
              <option value="1">Populaire</option>
              <option value="2">Prix croissant</option>
              <option value="3">Prix décroissant</option>
            </select>
          </div>
        </div>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
          <ItemBasedeDeck />
          <ItemBasedeDeck />
          <ItemBasedeDeck />
          <ItemBasedeDeck />
          <ItemBasedeDeck />
          <ItemBasedeDeck />
        </div>
      </div>
    </div>
  );
};

const CALL = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={process.env.PUBLIC_URL + "/assets/images/yugioh-1-4.png"}
          alt="First slide"
        />

        <Carousel.Caption>
          <div class="container">
            <div class="carousel-caption text-start">
              <h1>Bases de deck</h1>
              <p>Les meilleures offres pour Base de deck Yu-Gi-Oh</p>
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

const BasedeDeck = () => {
  return (
    <>
      <CALL />
      <ProductCard />
    </>
  );
};
export default BasedeDeck;
