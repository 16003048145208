import React from "react";
import "../App.css";

const Cg = () => {
  return (
    <>
      <div
        class="album py-5 bg-light"
        style={{
          boxShadow:
            "inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15)",
        }}
      >
        <div class="container">
          <h1>Conditions générales</h1>
          <hr />
          <section style={{ marginBottom: "65.25", paddingBottom: "5rem" }}>
            <h2>Conditions d'utilisation</h2>
            <p class="lead">
              Ce sont les Termes et Conditions Générales pour la France à partir
              du 15 Décembre 2022 de Yugioh.re - ici et à partir de maintenant
              appelé "l'Opérateur".
            </p>
            <p class="lead">
              L’accès au site sa consultation et son utilisation sont
              subordonnés à l’acceptation sans réserve des présentes Conditions
              Générales d’Utilisation
            </p>
            <h2>1 Opérateur et cadre d'application</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                Le site internet <strong>www.yugioh.re</strong> (ci-après « le
                Site Internet ») est édité par l'entreprise FILAUMART SEBASTIEN
                CHARLES, E-mail:{" "}
                <a href="mailto:contact@yugioh.re">contact@yugioh.re</a>{" "}
                (ci-après « l'Opérateur »).
                <div class="row ml-2 my-3">
                  <p class="col-12 col-md-3">
                    Addresse
                    <br />
                    <p class="col-12 col-md-9">
                      205 Rue jules bertaut
                      <br />
                      97430 Le tampon
                      <br />
                      Ile de la Réunion
                      <br />
                      France
                      <p class="col-12 col-md-3">No. d’enregistrement</p>
                      <p class="col-12 col-md-9">82485041600028</p>
                    </p>
                  </p>
                </div>
                Yugioh.re est représentée par Filaumart Sébastien Charles.
              </li>
              <li>
                Ces Conditions Générales d'Utilisation définissent les termes et
                la conduite à adopter pour utiliser le Site Internet ainsi que
                pour tous les actes juridiques et les activités
                quasi-contractuelles entre l'Opérateur et vous. Il est possible,
                néanmoins, que des règles supplémentaires soient appliquées pour
                des services spéciaux. Ces règles vous seront communiquées avant
                d'être utilisées sous la forme d’une mise à jour des Conditions
                Générales d'Utilisation. Aucune modification de ces Conditions
                Générales d'Utilisation ne sera applicable sauf accord express
                et écrit de l’Opérateur.
              </li>
              <li>
                Ces Conditions Générales d'Utilisation sont également
                applicables pour l'achat et l'encaissement de bons d'achat, sauf
                mention contraire.
              </li>
            </ol>
            <h2>2 Description du service</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                Le Site Internet est un marché en ligne sur lequel les
                utilisateurs peuvent offrir, vendre et acheter des cartes de
                jeux et des accessoires. L'Opérateur agit en qualité d'agent qui
                met en place le contrat entre le vendeur et l'acheteur. Les
                utilisateurs du Site Internet peuvent mettre en vente, acheter
                et vendre des cartes à collectionner et gérer leurs ventes par
                le biais du Site Internet édité par l’Opérateur. L'Opérateur
                n’est pas parti au contrat conclu entre les utilisateurs du Site
                Internet. En revanche les contrats conclus entre les
                utilisateurs via le Site Internet n’ont d’effet juridique
                qu’entre eux.
              </li>
              <li>
                Il est nécessaire de créer un compte pour utiliser le Site
                Internet. L'utilisation du Site Internet par un utilisateur est
                autorisée uniquement dans le cadre des lois en vigueur et de ces
                Conditions Générales d'Utilisation.
              </li>
              <li>
                L'Opérateur vous permet d'utiliser ses services et données par
                le biais d'une Interface de Programmation Applicative (API) (cf.
                §9 de ces Conditions Générales d'Utilisation).
              </li>
              <li>
                Tous les articles proposés à la vente sur la plate-forme doivent
                être authentiques. La vente de contrefaçons/faux est strictement
                interdite. Les articles contrefaits vendus via la plateforme
                peuvent être confisqués et détruits par le fournisseur sans
                compensation. Il en va de même pour la restitution des
                contrefaçons par le biais d’une annulation de contrat. se
                réserve le droit de prendre d’autres mesures juridiques, en
                particulier le dépôt de plainte.
              </li>
            </ol>
            <h2>3 Enregistrement</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                La condition préalable à l’utilisation du Site Internet de
                l’Opérateur est l’ouverture d’un compte utilisateur.
                L’enregistrement pour l’utilisation du Site Internet est
                gratuite.
              </li>
              <li>
                Dans le cadre de l’enregistrement, vous renseignez d’abord votre
                nom, votre adresse et votre adresse e-mail, puis vous choisissez
                la devise locale utilisée par votre compte d’utilisateur et qui
                sera utilisée pour afficher les prix sur le Site Internet. Vous
                devez également choisir un nom d’utilisateur et un mot de passe.
                De plus, il faut confirmer avoir lu et accepté les CGU et avoir
                reçu les informations sur le droit de rétractation. L’Opérateur
                vous envoie ensuite un e-mail contenant un code d’activation
                pour vérifier votre adresse e-mail. Par cet e-mail, l’Opérateur
                vous propose de conclure un contrat d’utilisation. Cette
                proposition de contrat est acceptée en cliquant sur le lien lors
                de votre première connexion lorsque vous activez votre compte
                d’utilisateur. L’Opérateur se garde néanmoins le droit de
                refuser l’enregistrement sans avoir à motiver sa décision.
              </li>
              <li>
                Toutes les données personnelles (sauf vos nom et prénom)
                pourront être modifiées ultérieurement dans votre compte
                utilisateur.
              </li>
              <li>
                Le mot de passe que vous choisissez doit être confidentiel. Vous
                devez à tout moment vous assurer qu'aucun tiers ne puisse
                accéder à votre compte. Si vous avez perdu votre mot de passe,
                ou si vous pensez qu'un tiers a accédé à votre compte sans votre
                consentement, vous devez immédiatement en informer l'Opérateur.
                Afin de protéger votre compte contre les intrusions, vous êtes
                tenu de changer votre mot de passe régulièrement.
              </li>
              <li>
                Toute personne physique majeure d'au moins 18 ans, ainsi que
                toute personne morale, peut s’inscrire comme utilisateur. Dans
                le cas des personnes morales, l'inscription doit être effectuée
                par un représentant légal. Il est interdit d'ouvrir un compte
                pour un tiers sans autorisation, ainsi que d'avoir plusieurs
                comptes utilisateurs. L'Opérateur se réserve le droit de
                demander des pièces pertinentes (par ex. le numéro de RCS ou le
                Kbis,…) avant d'activer le compte utilisateur.
              </li>
              <li>
                Lors de l'inscription, l'utilisateur doit fournir l'intégralité
                de ses données personnelles. Si jamais ces données venaient à
                changer, l'utilisateur doit immédiatement en informer
                l'Opérateur ou alors actualiser son compte utilisateur. Ceci est
                valable notamment pour la cessation d'activité professionnelle,
                le redressement ou la liquidation judiciaire. Le compte
                utilisateur ne peut pas être cédé.
              </li>
              <li>
                L'Opérateur se réserve le droit de conditionner la fourniture de
                ses services à certaines conditions ( un contrôle des données
                personnelles, le nombre d’avis positifs ou négatifs ou le
                paiement de la commission contractuelle ).
              </li>
            </ol>
            <h2>4 Droit de rétractation</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                Dans les contrats conclus à distance, la loi accorde un droit de
                rétractation aux consommateurs de services même si ceux-ci sont
                gratuits. Conformément à la règlementation, nous vous informons
                donc de votre droit de rétractation :
                <dl class="row ml-2 my-3">
                  <dt class="col-12 col-md-3">Conditions d'annulation</dt>
                  <dd class="col-12 col-md-9">
                    Toute personne physique qui conclut un acte juridique qui ne
                    peut être attribuée à une activité professionnelle est un
                    consommateur.
                  </dd>
                  <dt class="col-12 col-md-3">
                    Droit de révocation - rétractation
                  </dt>
                  <dd class="col-12 col-md-9">
                    Vous pouvez vous rétracter de cet accord contractuel dans un
                    délai de 14 jours sans donner de raison. La période de
                    rétractation est de 14 jours à compter de l'établissement du
                    contrat. Pour exercer ce droit de rétractation, il suffit de
                    nous faire part de votre décision d'annulation par écrit
                    (par exemple une lettre envoyée par courrier ou mail) à
                    l'adresse ci dessus ou à{" "}
                    <a href="mailto:contact@yugioh.re">contact@yugioh.re</a>
                    . Pour ce faire, vous pouvez utiliser le modèle de
                    formulaire de rétractation suivant, mais ce n'est pas
                    obligatoire. Il suffit d'envoyer la notification avant
                    l’expiration du délai de 14 jours précité.
                  </dd>
                  <dt class="col-12 col-md-3">Conséquences de l'annulation</dt>
                  <dd class="col-12 col-md-9">
                    Si vous annulez ce contrat, tous les paiements que vous avez
                    effectués, frais de port inclus (hormis les frais
                    supplémentaires dus à votre choix d'une méthode d'envoi
                    différente et plus chère que celle que nous vous
                    proposions), vous seront remboursés dans un délai de 14
                    jours après réception de l'avis de rétractation du contrat.
                    Pour ce remboursement, nous utiliserons la méthode de
                    paiement que vous aviez utilisée pour la transaction, à
                    moins que nous ayons convenu autre chose avec vous ; aucun
                    frais ne sera appliqué. Si vous aviez demandé que la
                    fourniture du service commence pendant la période de
                    rétractation, vous devrez payer le montant correspondant aux
                    services reçus au moment d'exercer votre droit de
                    rétractation concernant ce contrat, au prorata des services
                    effectivement fourni au jour de la rétractation.{" "}
                  </dd>
                  <dt class="col-12 col-md-3">
                    Exclusion du droit de rétractation
                  </dt>
                  <dd class="col-12 col-md-9">
                    Le droit de rétractation ne s’applique qu’aux utilisateurs
                    ayant la qualité de consommateurs au sens de l’article 14 du
                    Code civil français au moment de la conclusion du contrat.{" "}
                    <span style={{ color: "#ff0000" }}>
                      Le droit de rétractation ne s’applique qu’aux utilisateurs
                      ayant la qualité de consommateurs au sens de l’article 14
                      du Code civil français au moment de la conclusion du
                      contrat. De plus, le droit de rétractation ne peut pas
                      être utilisé pour annuler des commandes sur Yugioh.re.
                    </span>
                  </dd>
                  <dt class="col-12 col-md-3">
                    Modèle du formulaire de révocation
                  </dt>
                  <dd class="col-12 col-md-9">
                    <p class="ml-3 ml-md-5">
                      <em>
                        (Si vous souhaitez résilier le contrat, merci de bien
                        vouloir remplir ce formulaire et de nous l'envoyer)
                      </em>
                    </p>
                    <dl class="row ml-2 my-3">
                      <dt class="col-12 col-md-3">À</dt>
                      <dd class="col-12 col-md-9"> 
                        YUGIOH.RE
                        <br />
                        205 Rue jules bertaut
                        <br />
                        97430 LE TAMPON REUNION
                      </dd>
                      <dt class="col-12 col-md-3">E-Mail</dt>
                      <dd class="col-12 col-md-9">
                        <a href="mailto:contact@yugioh.re">
                          contact@yugioh.re
                        </a>
                      </dd>
                      <dd class="col-12">
                        <ul>
                          <li>
                            Par la présente je déclare mon intention de résilier
                            mon contrat pour la fourniture des services suivants
                          </li>
                          <li>Commandés le (*) / reçus (*)</li>
                          <li>Nom et prénom du client</li>
                          <li>Adresse du client</li>
                          <li>
                            Signature du client (seulement si envoyé par
                            courrier)
                          </li>
                          <li>Date</li>
                        </ul>
                        <br />
                        <em>(*) Rayer la mention inutile.</em>
                      </dd>
                    </dl>
                  </dd>
                </dl>
              </li>
              <li>
                Le droit de rétractation ne s’applique pas si vous demandez
                expressément que la fourniture des services commence avant la
                fin de la période de résiliation.
              </li>
              <li>
                Les services fournis avant la demande de rétracation doivent
                être réglés et les paiements accordés.
              </li>
            </ol>
            <h2>5 Frais et conversion</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                La création d’un compte utilisateur, le réglage des offres de
                vente et la mise en ligne d’offres sont gratuits.
              </li>
              <li>
                Si un contrat de vente est conclu sur le Site Internet de
                l’Opérateur, celui-ci prélève des frais de service sur la base
                de la valeur de la marchandise directement auprès du vendeur. Le
                droit au paiement de l’Opérateur intervient lors de la
                conclusion du contrat de vente entre le vendeur et l’acheteur.
                Le droit au paiement intervient également lorsque le contrat
                entre les utilisateurs ne s’est pas réalisé ou qu’il ne
                satisfait pas les utilisateurs, sauf si la non-conclusion du
                contrat est imputable à l’Opérateur. Le droit au paiement est
                indépendant de l’exécution du contrat par les utilisateurs. Le
                droit au paiement du prestataire n’est pas conditionné notamment
                au paiement entre le vendeur et l’acheteur du prix convenu. Le
                montant des frais dépend du{" "}
                <a href="/commissions">Tableau de Commissions</a> de l’Opérateur
                en vigueur au moment de la mise en ligne de l’offre de vente.
              </li>
              <li>
                Pour une utilisation volontaire du système fiduciaire (n°15 de
                ces CGU) et l’acquisition de la monnaie (n°14 de ces CGU), des
                frais supplémentaires sont prélevés conformément au{" "}
                <a href="/commissions">Tableau de Commissions</a>. En outre,
                l’acheteur doit payer des frais de conversion s’il accepte une
                offre d’achat opérée dans une autre devise que celle qu’il a
                choisi dans ses réglages qui sont également disponibles dans le
                Tableau des Frais. La conversion de l’offre de vente dans la
                devise choisie par l’acheteur est opérée automatiquement sur
                base du taux de change du jour par Stripe (stripe.com) (ou, si
                Stripe ne devait plus proposer ce service, par un service
                comparable d’information sur le taux de change indiqué en amont
                sur la plateforme en ligne). Les frais de l’utilisation
                volontaire d’un service fiduciaire, ceux pour la monnaie de
                yugioh.Re et les frais de conversion sont indiqués
                spécifiquement à l’acheteur avant de soumettre une intention
                d’achat.
              </li>
            </ol>
            <h2>6 Durée du contrat</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                Le contrat entre l'Opérateur et l'utilisateur est conclu pour
                une durée indéterminée.
              </li>
              <li>
                Sans préjudice des dispositions prévues à l’article 17 ci-après
                qui sont toujours applicables, l'utilisateur peut résilier le
                contrat à tout moment par notification écrite à l'Opérateur 14
                jours avant la fin du mois. La résiliation sera effective à la
                fin du mois.
              </li>
              <li>
                Sans préjudice des dispositions prévues à l’article 17 ci-après
                qui sont toujours applicables, l'utilisateur, l'Opérateur peut
                résilier le contrat à tout moment par notification écrite à
                l'utilisateur 14 jours avant la fin du mois. La résiliation sera
                effective à la fin du mois. Il se réserve le droit de bloquer
                temporairement les comptes utilisateur sans préavis.
              </li>
            </ol>
            <h2>7 Conclusion de contrat entre utilisateurs</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                Mettre en vente des produits sur le Site Internet de l'Opérateur
                constitue une promesse de vente.
              </li>
              <li>
                Si un acheteur accepte ladite promesse en ajoutant un ou
                plusieurs produits à son panier et en confirmant l'achat, un
                contrat de vente est valablement établi entre l'acheteur et le
                vendeur.
              </li>
              <li>
                Le prix de vente doit être payé dans un délai d'une semaine
                après conclusion du contrat.
              </li>
              <li>
                Le vendeur doit effectuer l'envoi de la commande dans un délai
                d'une semaine après paiement, et doit également l'envoyer en
                utilisant la méthode d'envoi choisie par l'acheteur.
              </li>
              <li>
                Le prix de vente indiqué est le prix définitif et inclut toutes
                taxes applicables. Les frais de port ne sont pas inclus dans le
                prix de vente.
              </li>
              <li>
                Le champ de commentaire d’un produit est là uniquement pour
                étoffer les détails dudit produit ou de la commande. Les
                informations relatives au contrat (par exemple, concernant la
                garantie ou les conditions de conclusion du contrat de vente) ne
                peuvent pas être fournies dans le champ de commentaires. Ces
                informations ne font pas partie du contrat de vente entre le
                vendeur et l'acheteur. Toutes les informations figurant dans le
                champ de commentaires doivent être correctes et ne doivent pas
                contredire les informations sur le produit. En cas de
                contradiction entre les informations sur le produit et les
                informations figurant dans le champ de commentaires, seules les
                informations sur le produit s'appliquent.{" "}
              </li>
              <li>
                L'emplacement réel du produit mis à la vente doit toujours être
                indiqué. Si l'acheteur subit des frais supplémentaires par votre
                faute, vous êtes tenu de l'indemniser.
              </li>
              <li>
                Un retour en provenance ou à destination d'un pays non-membre de
                l'UE doit être marqué par l'acheteur comme "returned
                goods/return”. S'il ne le fait pas, les frais occasionnés seront
                déduits du prix d'achat pour être remboursés et crédités au
                vendeur.{" "}
              </li>
            </ol>
            <h2>8 Droits d'utilisation pour mettre en ligne du contenu</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                Vous concédez à l'Opérateur des droits simples d'utilisation
                illimités dans le temps et l'espace pour les contenus que vous
                publiez sur le Site Internet pendant le temps nécessaire à
                l’exploitation du Site Internet.
              </li>
              <li>
                En particulier, vous concédez à l'Opérateur le droit de mettre
                en ligne les contenus que vous publiez et de les reproduire
                (enregistrement sur les serveurs, etc..). De plus, vous concédez
                à l'Opérateur le droit de modifier ces contenus afin de mieux
                les présenter sur le site. Il peut s'agir de raccourcir ou
                modifier des textes ou de couper ou réduire des images.
                L'Opérateur a également le droit de reproduire, communiquer et
                publier ces contenus avec la meilleure qualité possible à
                travers différentes terminaux et différents réseaux (publics ou
                privés), en particulier le droit de rendre ces contenus
                disponibles à partir de terminaux fixes ou mobiles d'autres
                utilisateurs (par exemple des téléphones portables, etc..). Le
                droit de reproduction publique inclut le droit de diffuser du
                contenu sur le site.
              </li>
              <li>
                Si vous mettez en ligne du contenu sur le Site Internet, vous
                permettez à d'autres utilisateurs d'utiliser votre contenu dans
                une certaine mesure. Les textes mis en ligne peuvent être lus,
                et les images vues. Cela implique nécessairement que les
                contenus soient enregistrés dans les terminaux d'autres
                utilisateurs.
              </li>
            </ol>
            <h2>9 Utilisation de l'API</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                <strong>Utilisateurs</strong> : Afin de rendre l'utilisation de
                la plate-forme la plus agréable possible pour nos utilisateurs,
                nous vous fournissons un jeton d'accès permanent qui vous permet
                d'accéder et d'éditer partiellement les données de votre
                inventaire ainsi que les données publiques par le biais d'une
                interface de programmation applicative (API). Cela inclut les
                caractéristiques des produits, les articles disponibles, vos
                achats et ventes, votre panier ou vos listes de souhait. L'API
                ne peut être utilisée que pour gérer ses propres contenus.
                Présenter ses cartes à collectionner et leur prix respectif
                nécessite notre accord préalable par écrit. L'utilisation de
                l'API et des données à toute autre fin est strictement
                interdite. L'accès via l'API est une possibilité suplémentaire
                que nous fournissons afin de faciliter l'utilisation de notre
                Site Internet. Nous nous réservons le droit de retirer
                partiellement ou complètement l'API à l'avenir ou même
                d'interdire son utilisation par un utilisateur précis, par
                exemple lorsqu'il en fait un usage inaproprié.
              </li>
              <li>
                <strong>Développeurs</strong> : Les développeurs d'applications
                ou de logiciels similaires peuvent recevoir un code d'accès
                spécial s'ils le demandent. Ce code d'accès temporaire (24
                heures) permet un accès total aux données et droits de
                l'utilisateur après que l’utilisateur ait entré ses nom et mot
                de passe. L'Opérateur a le droit d'enregistrer tout accès
                effectué à partir d'une application tierce. Le développeur de
                l'application garantit respecter toutes les règlementations en
                vigueur, en particulier celles émanant des lois ou des accords
                conclus avec Yugioh.re. Le développeur peut être amené à
                indemniser l'Opérateur s'il est responsable, frais de procédures
                inclus.
              </li>
            </ol>
            <h2>10 Contenu illégal</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                Vous vous engagez auprès de l'Opérateur à ne pas mettre en ligne
                de contenu illégal sur le Site Internet. En particulier, vous ne
                fournirez ni ne mettrez en avant des contenus (en particulier
                des photos, des vidéos, des textes, des graphiques, des logos,
                etc...) qui ne respectent pas le Code civil français, la
                législation sur les stupéfiants, la législation sur les produits
                pharmaceutiques et la législation sur la détente d'armes, ou qui
                :
                <ul class="ml-3 ml-md-5">
                  <li>
                    sont diffamatoires ou irrespectueux de ceux concernés par
                    ces contenus ;
                  </li>
                  <li>
                    sont à caractère pornographique, violent, immoral ou violant
                    la loi sur la protection de l'enfance ;
                  </li>
                  <li>
                    constituent un harcèlement d'autres utilisateurs, en
                    particulier sous la forme de spam, conformément à l'article
                    7 de l'UWG (loi française pour un commerce juste) ;
                  </li>
                  <li>
                    sont protégés par la loi (en particulier par les droits
                    d'auteur, les marques déposées, les brevets, ou les modèles
                    d'utilité) sans autorisation préalable ;
                  </li>
                  <li>
                    participent à une concurrence déloyale, incluant le
                    démarchage de clients.
                  </li>
                </ul>
              </li>
              <li>
                De même, il est interdit de reproduire ou de diffuser les
                contenus du Site Internet ou des autres utilisateurs, à moins
                que cela ne se fasse dans le cadre de l'utilisation du Site
                Internet ou bien avec l'autorisation des utilisateurs concernés.
                De plus, vous ne devez pas utiliser des hyperliens dans vos
                offres. L'Opérateur se réserve le droit d'effacer ces liens
                immédiatement. L'utilisation de fausses offres dans le seul but
                de promouvoir des services en dehors du Site Internet ou pour
                obtenir des évaluations est interdit. Il est également interdit
                d'utiliser la communication postérieure au contrat établi sur
                Site Internet pour promouvoir vos propres services ou produits
                ou ceux d'un tiers.
              </li>
            </ol>
            <h2>11 Garanties et dédommagements</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                Vous garantissez à l'Opérateur que vous détenez les droits et
                autorisations pour concéder les droits indiqués concernant les
                contenus que vous mettrez en ligne. Vous garantissez en
                particulier de ne pas mettre en ligne de contenu qui violent les
                droits d'auteur, les marques déposées ou tout autre droit de
                propriété intellectuelle. De plus, vous garantissez de ne pas
                poster de contenu incitant à la concurrence déloyale. Vous êtes
                tenu de vous informer à cet égard et, en cas de doute, à
                demander l'autorisation au détenteur de ces droits. Le doute est
                en particulier permis si :
                <ul class="ml-3 ml-md-5">
                  <li>
                    cela concerne des œuvres protégées par des droits d'auteurs
                    ou des droits voisins, telles que des vidéos, photos,
                    graphiques, logos, marques ou textes tiers. Dans ce
                    contexte, vous n'êtes pas autorisé à modifier une œuvre
                    tierce et mettre en ligne le résultat sur le Site Internet
                    sans le consentement des ayants droit ;
                  </li>
                  <li>
                    un tiers a participé à la production de ces contenus, de
                    telle sorte qu'ils aient acquis des droits voisins ;
                  </li>
                  <li>
                    les contenus contiennent des informations sensibles sur des
                    particuliers ou des groupes de personnes, à moins que les
                    consentements et/ou les droits nécessaires aient été
                    obtenus.
                  </li>
                </ul>
              </li>
              <li>
                De plus, vous garantissez à l'Opérateur de ne pas mettre en
                ligne sur le marché en ligne de contenu qui porte atteinte aux
                droits de tiers ou de contenu illégal ou déclaré inacceptable
                par l'Opérateur.
              </li>
            </ol>
            <h2>12 Responsabilités de l'Opérateur</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                L'Opérateur est responsable conformément à la loi, sauf si les
                dommages proviennent d'une perte de données et qu'ils auraient
                pu être évités si vous aviez régulièrement effectué une copie
                complète des données importantes. La responsabilité de
                l'Opérateur pour des dommages non liés au contrat est exclue.
                Cela ne s'applique cependant pas si ces dommages concernent la
                vie, le corps ou la santé, ont été causés intentionnellement ou
                par négligence, résultent d'une absence de garantie ou d'une
                violation grave d'une obligation contractuelle. Toute
                responsabilité conformément à la loi sur la responsabilité des
                produits est exclue.
              </li>
              <li>
                L'Opérateur n'est responsable ni de la précision, ni de la
                qualité, ni de la complétude, ni de la fiabilité, ni du type ou
                de la crédibilité de contenu mis en ligne par les utilisateurs.
                Ces contenus ne représentent aucunement l'opinion de
                l'Opérateur, et en particulier l'Opérateur n'adhère pas à de
                tels contenus. L'Opérateur n'est pas responsable de
                l'établissement, de la mise en œuvre et de l’exécution des
                contrats entre utilisateurs. Il ne fait que fournir la
                plate-forme dans le but de permettre aux utilisateurs de
                proposer leurs offres.
              </li>
              <li>
                L'Opérateur n'est pas responsable des possibles dommages et/ou
                du mauvais usage des données personnelles qui pourraient être
                provoqués par l'utilisation d'une application tierce.
              </li>
            </ol>
            <h2>13 Vos responsabilités</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                Vous êtes pleinement responsable, des contributions et des
                contenus des offres de votre profil d'utilisateur.
              </li>
              <li>
                Si les services de l'Opérateur sont utilisés via un tiers, parce
                que vous avez manqué à vos obligations en portant atteinte aux
                droits de propriété intellectuelle de tiers, vous devrez
                entièrement indemniser l'Opérateur à la première demande, frais
                de procédures inclus. L'Opérateur vous informera immédiatement
                de cette demande en justice et vous donnera la possibilité de
                vous joindre à la défense, aussi longtemps que cela sera
                nécessaire et/ou possible. Ceci s'applique également aux
                demandes en justice de tiers parce que des contenus violent
                leurs droits d'auteur.
              </li>
              <li>
                Vous êtes responsable auprès de l'Opérateur et de tiers de vos
                propres apports, commentaires et autres contenus de votre profil
                d'utilisateur, en particulier ce qui concerne la justesse des
                contenus. Si des mesures légales sont décidées contre
                l'Opérateur par un autre utilisateur ou un tiers à cause
                d'apports, commentaires ou d'autres contenus de votre profil
                d'utilisateur illégaux, vous devrez indemniser l'Opérateur de
                tout dommage et intérêt.
              </li>
              <li>
                Le vendeur s’engage à ne proposer dans ses promesses de vente
                que des marchandises disponibles qu’il détient en toute légalité
                et dont il dispose. Le vendeur s’engage en cas d’indisponibilité
                de la marchandise en question, à procéder au retrait de la
                promesse de vente du Site Internet dans les plus brefs délais.
              </li>
              <li>
                L'utilisation du Site Internet via une application tierce ou
                tout autre programme similaire se fait à vos risques et périls.
              </li>
            </ol>
            <h2>14 Compte d’utilisateur et crédits yugioh.re</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                L’Opérateur met à disposition des utilisateurs enregistrés un
                compte d’utilisateur pour l’achat, la sauvegarde et le transfert
                de Crédits sur le Site Internet. Les crédits ne peuvent être
                utilisés que pour des achats sur le Site Internet. L’utilisation
                de ce compte d’utilisateur est gratuite. Le compte d’utilisateur
                est un pur compte de paiement par compensation des crédits
                achetés ou transféré aux utilisateurs sous la forme d’un compte
                de crédit.
              </li>
              <li>
                Vous pouvez charger votre compte d’utilisateurs de Crédits en
                achetant à l’Opérateur des crédits . En créditant des Crédits
                sur votre compte d’utilisateur, vous obtenez un droit au
                paiement à hauteur du solde de votre compte d’utilisateur envers
                l’Opérateur.
              </li>
              <li>
                La date à laquelle les Crédits sont crédités sur votre compte
                d’utilisateur dépend du choix que vous aurez fait entre l’achat
                normal gratuit ou l’achat accéléré soumis à des frais. Lors d’un
                achat normal, l’opération de crédit des crédits survient au plus
                tard à la fin du jour ouvrable qui suit le jour où votre
                paiement est parvenu à l’Opérateur. Cela permet à l’Opérateur
                d’avoir la possibilité de vérifier et de comptabiliser le
                paiement avant que les Crédits correspondants soient activés et
                crédités sur votre compte d’utilisateur. Lors d’un achat
                accéléré, l’opération de crédit des Crédits s’opère
                immédiatement après la réception de votre paiement par
                l’Opérateur. Le montant des frais pour l’achat accéléré est
                disponible dans le{" "}
                <a href="/commissions">Tableau de Commissions</a> de
                l’Opérateur.
              </li>
              <li>
                Les Crédits sont payables par virement bancaire. La méthode de
                paiement « Sofort » de compte bancaire en ligne débloqué avec un
                processus de PIN/TAN. Veuillez noter que la méthode de paiement
                « Sofort » n’est pas disponible auprès de certaines banques. Par
                le formulaire de paiement sécurisé de Stripe, ce dernier opère
                le transfert de manière automatique et en temps réel sur votre
                compte bancaire en ligne. L’Opérateur obtient le transfert de
                crédits directement et immédiatement. Tous les frais sont
                adressés à votre banque.
              </li>
            </ol>
            <h2>15 Système fiduciaire</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                Les utilisateurs peuvent utiliser le système fiduciaire de
                l’Opérateur lors de l’achat de cartes. L’utilisation du système
                fiduciaire est obligatoire lorsque l’Opérateur est porté à
                croire que la vente de cartes présente un risque de fraude élevé
                (par exemple quand l’acheteur n’a pas déjà acheté de cartes sur
                le Site Internet). Dans ce cas, vendeur et acheteur sont
                spécifiquement informés avant la conclusion du contrat de vente
                de l’utilisation obligatoire du compte fiduciaire.
              </li>
              <li>
                Si le système fiduciaire est utilisé, l’Opérateur garde le
                paiement (Crédits) de l’acheteur pour le compte de l’acheteur.
                Le paiement est crédité conformément aux dispositions suivantes
                sur le compte d’utilisateur du vendeur ou de l’acheteur :
              </li>
              <li>
                Lorsque l’acheteur confirme l’obtention de la commande, le
                montant du paiement est crédité sur le compte d’utilisateur du
                vendeur.
              </li>
              <li>
                Si l’acheteur ne confirme par l’obtention de la commande, le
                vendeur a la possibilité de prouver que l’acheteur a bien reçu
                la commande. S’il le fait, un délai d’une semaine est accordé à
                l’acheteur pour s’exprimer. Si l’acheteur ne s’exprime pas, le
                montant total du paiement est crédité sur le compte
                d’utilisateur du vendeur à la fin du délai d’une semaine.
              </li>
              <li>
                Si l’acheteur conteste auprès de l’Opérateur que la commande est
                arrivée dans un délai de sept jours pour une commande nationale
                et 14 jours pour une commande internationale après l’envoi de
                celle-ci, l’Opérateur accorde au vendeur un délai de 14 jours.
                Il incombe au vendeur, dans ce cas, de prouver qu’il a dûment
                accompli ses obligations. S’il ne le fait pas, l’Opérateur
                crédite le compte d’utilisateur de l’acheteur du montant du
                paiement après l’écoulement du délai.
              </li>
              <li>
                Si l’acheteur confirme que la commande est bien arrivée mais que
                le vendeur n’a pas accompli une de ses obligations
                contractuelles, le montant total du paiement est crédité sur le
                compte d’utilisateur du vendeur, l’Opérateur n’étant pas
                responsable, notamment pour les dommages causés par le
                transport. L’acheteur a la possibilité de faire appel aux
                garanties légales à l’encontre du vendeur. Les "autres
                violations des obligations contractuelles" se produisent
                lorsqu'une commande livrée est incorrecte, présente des défauts
                ou qu'un article diffère de l'article acheté a été livré (
                erreur de livraison ).
              </li>
              <li>
                L’encaissement du prix sur le compte d’utilisateur de l’un ou
                l’autre des partenaires contractuels, n’affecte pas la validité
                du contrat de vente entre les parties ..
              </li>
              <li>
                L’utilisation du système fiduciaire, impliquent des frais
                supplémentaires à la charge de l’acheteur les frais
                supplémentaires suivants incombent au vendeur. Cela ne
                s’applique pas lorsque l’utilisation du système fiduciaire est
                imposée conformément aux stipulations présentes sous le n° 15.1.
                Pour le montant des frais, merci de vous reporter au{" "}
                <a href="/commissions">Tableau de Commissions</a> de
                l’Opérateur.
              </li>
            </ol>
            <h2>16 Bons d'achat</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>L’acquisition d'un bon d'achat est exempt de frais.</li>
              <li>
                Le remboursement en espèces du Bon d’Achat ou de sa valeur
                résiduelle n’est pas possible. La valeur du Bon sera crédité sur
                votre compte utilisateur en tant que « credit special »
              </li>
              <li>
                Les bons d'achat sont valables trois ans après leur achat, après
                ce délai ils ne pourront plus être crédités sur votre compte
                utilisateur. Une fois crédité, le « crédit special » est valable
                sans limite de durée. Un bon d'achat est transférable.
              </li>
              <li>
                Vous pouvez payer vos achats (en totalité ou partiellement) avec
                votre "crédit spécial". La valeur résiduelle reste sur le compte
                utilisateur et peut être utilisé lors d’un prochain achat. Le
                "crédit spécial" sera toujours le moyen de paiement préférentiel
                et sera utilisé avant le "crédit normal" pour tout achat. . Si
                vous n’avez plus de « credit special » sur votre compte, le
                paiement s’effectuera à partir de votre solde disponible de
                votre compte utilisateur.
              </li>
              <li>
                Le bon d'achat est acheté à partir du solde disponible sur le
                compte utilisateur ou via un payment Paypal ou
                Sofortueberweisung.
              </li>
              <li>
                Le bon d'achat ne peut être utilisé que pour des achats
                effectués sur le Site Internet. Pour l'utiliser, il faut entrer
                le code du bon d'achat sur votre compte utilisateur dans
                l’espace dédié. La valeur du bon d'achat sera alors créditée
                sous forme de "crédit spécial".
              </li>
              <li>
                Le "crédit spécial" ne se confond pas avec le solde du compte
                utilisateur. Ce « credit special » ne peut être utilisé que pour
                acheter des biens sur le Site Internet.
              </li>
            </ol>
            <h2>
              17 Résiliation extraordinaire du contrat et suspension du compte
            </h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                L'Opérateur et vous avez le droit de procéder à une résiliation
                extraordinaire du contrat en cas de force majeure, sans préavis.
                La résiliation extraordinaire du contrat est possible en
                particulier en cas de violation majeures des présentes
                Conditions Générales d'Utilisation, en cas de violation de
                l'obligation de transmettre ses véritables données personnelles
                ou en cas de violation de l'interdiction de mettre en ligne des
                contenus illégaux. De même, une résiliation extraordinaire du
                contrat peut être mise en œuvre pour d'autres raisons majeures,
                comme la perte des données d'accès ou des soupçons de mauvais
                usage de ces données par un tiers. Toute résiliation de contrat
                doit être notifiée par écrit. ou en cas de violation de
                l’interdiction de mettre en ligne des contenus illégaux. En
                outre, une résiliation extraordinaire par le fournisseur pour
                une autre raison importante est possible dans les cas suivants :
                la perte ou l'abus présumé des données d'accès par un tiers,
                d'autres utilisations des données d'accès ou de la plate-forme
                par le membre qui mettent en danger ou altèrent la plate-forme
                ou sa fonctionnalité ou son intégrité, un soupçon raisonnable de
                comportement frauduleux, offensif ou illégal de la part de
                l’utilisateur ou si les intérêts légitimes du fournisseur
                rendent cette action nécessaire. Si vous n'avez pas utilisé
                votre compte utilisateur pendant une période de deux ans, le
                fournisseur est en droit de supprimer définitivement le compte
                utilisateur. La dernière date de connexion est déterminante à
                cet égard. Tous les crédits qui se trouvent sur votre compte
                utilisateur au moment de la suppression vous seront versés.
              </li>
              <li>
                Dans une moindre mesure, l'Opérateur peut vous bloquer en cas de
                violation des présentes Conditions Générales d'Utilisation, afin
                de vous obliger à remplir vos obligations. Vous serez informé de
                cette suspension par écrit. En cas de suspension ou de
                résiliation pour raison majeure de l'Opérateur, il vous est
                interdit de vous inscrire à nouveau sur le Site Internet pour
                ouvrir un nouveau compte sans l'accord préalable et express de
                l'Opérateur. En cas de violation des présentes CGU, le
                fournisseur peut également vous bloquer temporairement ou
                restreindre votre utilisation à titre de mesure d'atténuation,
                notamment pour vous inciter à respecter vos obligations. Un
                blocage peut également être ordonné pendant les enquêtes
                internes ou externes en cours, l'examen des cas ou le traitement
                général des tickets si le fournisseur le juge nécessaire pour
                assurer la sécurité ou l'intégrité de la plate-forme.
              </li>
              <li>
                Dans une encore moindre mesure, l'Opérateur se réserve le droit
                d'effacer les offres qui violent les règles des présentes
                Conditions Générales d'Utilisation. Pour déterminer la mesure la
                plus appropriée, l'Opérateur tiendra compte de vos intérêts
                légitime, et en particulier, si vous êtes responsable de cette
                violation.
              </li>
            </ol>
            <h2>18 Protection des données</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                La collecte de vos données sert à offrir un service Internet
                sûr, efficace et simple d'utilisation.
              </li>
              <li>
                Tous les renseignements concernant la protection et la sécurité
                des données se trouvent dans notre{" "}
                <a href="/confidentialite">
                  Déclaration de protection des données
                </a>
                .
              </li>
            </ol>
            <h2>19 Changements fonctionnels et transmissibilité du contrat</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                L'Opérateur se réserve le droit d'adapter, de mettre à jour,
                d'étendre, de restreindre ou de retirer des fonctionnalités du
                Site Internet.
              </li>
              <li>
                L'Opérateur a le droit à tout moment de transférer la totalité
                ou une partie de ses droits et obligations émanant de ce contrat
                a une tierce personne avec un préavis de quatre semaines. Dans
                ce cas, l'utilisateur a le droit extraordinaire de résilier son
                contrat d'utilisateur après notification du transfert de
                contrat.
              </li>
            </ol>
            <h2>20 Modification de ces Conditions Générales d'Utilisation</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                L'Opérateur se réserve le droit de modifier ces Conditions
                Générales d'Utilisation à tout instant et sans fournir de
                raison.
              </li>
              <li>
                Tout changement ou amendement sera communiqué aux utilisateurs
                par mail. Les nouvelles Conditions Générales d'Utilisation
                seront considérées acceptées, si vous ne portez pas à notre
                connaissance d'objection dans une période de six semaines à
                compter de la réception du mail. L’objection doit être
                communiquée par écrit. Dans ce mail, l'Opérateur vous informera
                de votre possibilité d’objection, des dates limites, du modèle
                d'objection et des conséquences de votre inactivité.
              </li>
            </ol>
            <h2>21 Disposition finale</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                Les relations contractuelles entre l'Opérateur et vous ainsi que
                ces Conditions Générales d'Utilisation sont soumises à la
                législation de la République Française.
              </li>
              <li>
                Les lois de protection des consommateurs applicables dans votre
                pays de résidence sont toujours applicables, si celles-ci vous
                offrent une protection plus importante.
              </li>
              <li>
                Pour tout conflit issu du contrat entre l'utilisateur et les
                présentes Conditions Générales d'Utilisation, la compétence
                judiciaire est localisée au bureau de l'Opérateur à l'ile de la
                réunion si l'utilisateur et un entrepreneur, une personne morale
                ou un fond public conformément au Code civil français.
              </li>
            </ol>
          </section>
        </div>
      </div>
    </>
  );
};
export default Cg;
