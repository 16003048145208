import { useEffect, useState } from "react";
import { App } from "../firebase";

export const ItemSearch = ({ data, noname = false, username = "" }) => {
  const [user, setUser] = useState(username); 
  function getEx() {
    if (data?.expansion && data?.card?.card_sets) {
      var d = null;
      data?.card?.card_sets.forEach((e) => {
        if (e.set_name === data?.expansion) d = e;
      });
      return d;
    } else return null;
  }

  function getCode() {
    var d = getEx()?.set_code || "";
    if (data?.lang === "fr") {
      return d.replace("EN", data?.lang.toUpperCase());
    } else {
      return d;
    }
  }

  const getUsername = async (cb) => {
    await App.firestore()
      .collection("users")
      .doc(data?.userId)
      .get()
      .then(async (docd) => {
        var user = docd.data();

        if (user?.displayName) cb(user?.displayName);
      })
      .catch((err) => {
        cb("");
        console.log(" err", err);
      });
  };
  const updateNameSearch = async (n) => {
    if (data?.username) {
      setUser(data?.username);
      return;
    }
    await App.firestore().collection("searchs").doc(data?.id).update({
      username: n,
    });
  };

  useEffect(() => {
    if (user === "" && !noname) {
      getUsername((n) => {
        updateNameSearch(n);
        setUser(n);
      });
    }
  }, []);
  return (
    <div class="col">
      <a
        class="card shadow-sm btn btn-sm btn-outline"
        href={`/search/${getCode().split("-")[0]}/${getCode().split("-")[1]}/${
          data?.id
        }`}
      >
        <img
          width="100%"
          style={{
            justifyItems: "center",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          className="d-block"
          src={data?.card?.card_images[0].image_url_small}
          alt="First slide"
        />

        <div class="card-body">
          <h6 class="price-container" style={{ height: "55px" }}>
            {data?.lang === "fr" ? data?.card.name : data?.card.name_en}
          </h6>
          {!noname && (
            <h4 class="price-container" style={{ fontSize: 12 }}>
              {user}
            </h4>
          )}
          <h6 class="price-container">{getEx()?.set_code.split("-")[0]}</h6>
        </div>
        <p
          style={{
            position: "absolute",
            backgroundColor: "black",
            top: 10,
            padding: 5,
            borderRadius: 17.5,
            width: 35,
            height: 35,
            justifyContent: "center",
            alignItems: "center",
            left: -10,
            color: "white",
            fontWeight: "bold",
          }}
        >
          {data?.qte < 10 && "x"}
          {data?.qte}
        </p>
      </a>
    </div>
  );
};
