import React from "react";
import "../App.css";

const Revocation = () => {
  return (
    <>
      <div
        class="album py-5 bg-light"
        style={{
          boxShadow:
            "inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15)",
        }}
      >
        <div class="container">
          <h1>Condition de révocation</h1>
          <hr />
          <section style={{ marginBottom: 74, paddingBottom: "5rem" }}>
            <p>
              Toute personne physique qui conclut une transaction légale qui ne
              peut être attribué à une activité professionnelle est un
              consommateur.
            </p>
            <h2>Droit de révocation</h2>
            <p>
              Vous pouvez révoquer cet accord contractuel dans un délai de 14
              jours sans donner de raison. La période de révocation est de 14
              jours à partir de l'établissement du contrat. Pour exercer ce
              droit de révocation, il suffit de nous faire part de votre
              décision d'annulation par écrit (par exemple une lettre envoyé par
              courrier ou mail) à 205 Rue jules bertaut, 97430 Le
              tampon ou à{" "}
              <a href="mailto:contact@yugioh.re">contact@yugioh.re</a>. Pour
              ce faire, vous pouvez utiliser le modèle de formulaire de
              révocation suivant, mais ce n'est pas obligatoire. Il suffit
              d'envoyer la notification dans le délai de 14 jours spécifié.
            </p>
            <h2>Conséquences de l'annulation</h2>
            <p>
              Si vous annulez ce contrat, tous les paiements que vous avez
              effectués, frais de port inclus{" "}
              <em>
                (hormis les frais supplémentaires dus à votre choix d'une
                méthode d'envoi différente de la moins chère que nous vous
                proposions)
              </em>
              , vous seront remboursés dans un délai de 14 jours après réception
              de l'avis de révocation du contrat. Pour ce remboursement, nous
              utiliserons la méthode de paiement que vous aviez utilisée pour la
              transaction, à moins que nous ayons convenu autre chose avec vous
              ; aucun frais ne sera appliqué. Si vous aviez demandé que la
              provision du service commence pendant la période de révocation,
              vous devrez payer le montant correspondant aux services reçus au
              moment d'exercer votre droit de révocation concernant ce contrat,
              comparé avec l'extension totale des services correspondants au
              contrat.
            </p>
            <h2>Exclusion du droit de révocation</h2>
            <p>
              Le droit de révocation n'est pas applicable si, au moment
              d'établir la transaction légale, vous exercez une activité
              commerciale ou professionnelle et êtes donc considéré comme
              entrepreneur <em>(article 14 du Code civil français)</em>.
            </p>
            <h2>Modèle du formulaire de révocation</h2>
            <p>
              <em>
                (Si vous souhaitez résilier le contrat, merci de bien vouloir
                remplir ce formulaire et de nous l'envoyer)
              </em>
            </p>
            <hr />
            <dl class="row">
              <dt class="col-12 col-md-3">À</dt>
              <dd class="col-12 col-md-9">
                205 Rue jules bertaut
                <br />
                97430 Le tampon
                <br />
                Ile de la réunion
              </dd>
              <dt class="col-12 col-md-3">E-Mail</dt>
              <dd class="col-12 col-md-9">
                <a href="mailto:contact@yugioh.re">contact@yugioh.re</a>
              </dd>
              <dd class="col-12">
                <ul class="mt-3">
                  <li>
                    Par la présente je déclare mon intention de résilier mon
                    contrat de la provision des services suivants
                  </li>
                  <li>Demandés le (*) / reçus (*)</li>
                  <li>Nom et prénom du client</li>
                  <li>Adresse du client</li>
                  <li>
                    Signature du client{" "}
                    <em>
                      <small>(seulement si envoyé par courrier)</small>
                    </em>
                  </li>
                  <li>Date</li>
                </ul>
                <p>
                  <em>(*) Rayer la mention inutile.</em>
                </p>
              </dd>
            </dl>
            <hr></hr>
          </section>
        </div>
      </div>
    </>
  );
};
export default Revocation;
