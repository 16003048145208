import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "react-chat-elements/dist/main.css";
import Compressor from "compressorjs";
import {
  MessageList,
  Navbar as NavbarComponent,
  Avatar,
} from "react-chat-elements";
import { App } from "../firebase";

/**
 *
 * ChatBox Component
 *
 * displays all the messages from chat history.
 * renders message text box for input.
 */
const ChatBox = (props) => {
  const db = App.firestore();
  const [messages, setMessages] = React.useState([]);
  const [state, setState] = React.useState({ messageText: "" });
  const bottomRef = React.useRef(null);
  const messageListReferance = React.createRef();
  /**
   *
   * Sends a message only if it is not falsy.
   */
  function onSendClicked() {
    if (!state.messageText) {
      return;
    }
    props.onSendClicked(state.messageText);
    setTimeout(() => {
      setState({ messageText: "" });
    }, 0);
  }
  function onMessageInputChange(e) {
    setState({ messageText: e.target.value });
  }
  /**
   *
   * @param {KeyboardEvent} e
   *
   * listen for enter pressed and sends the message.
   */
  function onMessageKeyPress(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      onSendClicked();
    }
  }

  const unsub = db
    .collection("messages")
    .doc(props?.targetUser?.id)
    .collection("tchats")
    .orderBy("createAt", "asc");
  const getMessages = async () => {
    // console.log("props.targetUser", props.targetUser);
    const uid = props.myid;
    try {
      unsub.onSnapshot((querySnapshot) => {
        var data = [];
        querySnapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          d.position = uid !== d.senderId ? "left" : "right";
          d.titleColor = uid !== d.senderId ? "green" : "blue";
          d.text = d.type === "text" ? d.message : "";
          if (d.type === "photo") {
            d.data = {
              uri: d.message,
            };
          }
          d.title = uid !== d.senderId ? props.targetUser?.name : "Vous";
          d.createAt = doc.data().createAt?.toDate() || new Date();
          data.push(d);
        });
        // data.push({
        //   width: 500,
        //   height: 500,
        //   position: "left",
        //   type: "photo",
        //   data: {
        //     uri: "https://firebasestorage.googleapis.com/v0/b/ygo-shop.appspot.com/o/messages%2F%20QzgZ3GO3gb%2Fimage.jpeg?alt=media&token=c1be1db6-24d6-4d6c-98b3-55a211e6bbb1",
        //   },
        // });
        setMessages(data);
      });
    } catch (err) {
      alert(err);
      console.log("onSnapshot error", err);
    }
  };

  useEffect(() => {
    if (props.targetUser) {
      getMessages();
      // console.log("props.targetUser", props.targetUser);
    }
  }, [props.targetUser]);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    messageListReferance.current?.lastElementChild?.scrollIntoViewIfNeeded();
  }, [messages]);

  // program to generate random strings

  // declare all characters
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  function generateString(length) {
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  const handleImageUpload = (e) => {
    // If no file selected, return
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    // If no image selected, return
    if (!/^image\//.test(file.type)) {
      alert(`Le fichier ${file.name} n'est pas une image.`);
      return false;
    }

    // ...
    // console.log(file);
    var storage = App.storage();
    var metadata = {
      contentType: file.type,
    };
    let ss = `messages/${generateString(10)}.jpeg`;
    const reference = storage.ref(ss);
    new Compressor(file, {
      maxHeight: 500,
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        const uploadTask = reference.put(compressedResult, metadata);

        uploadTask.on(
          App.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case App.storage.TaskState.PAUSED: // or 'paused'
                console.log("Upload is paused");
                break;
              case App.storage.TaskState.RUNNING: // or 'running'
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                alert(
                  "Une erreur est survenue lors de l'envoie de votre logo, vous n'avez pas l'autorisation d'envoyez des images."
                );
                break;
              case "storage/canceled":
                // User canceled the upload alert(
                alert(
                  "Une erreur est survenue lors de l'envoie de votre logo, vous avez annuler l'envoie."
                );
                break;

              // ...

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                alert(
                  "Une erreur est survenue lors de l'envoie de votre logo, réessayer plus tard."
                );
                break;
            }
          },
          () => {
            console.log("Image uploaded to the bucket!");
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(async (downloadURL) => {
                let message = {
                  type: "photo",
                  message: downloadURL,
                  senderId: props.myid,
                  createAt: new Date(),
                };
                await App.firestore()
                  .collection("messages")
                  .doc(props.targetUser?.id)
                  .collection("tchats")
                  .add(message);
              });
          }
        );
      },
    });
  };

  return (
    <div>
      {props.targetUser ? (
        <div>
          <NavbarComponent
            left={
              <div>
                {/* <Avatar
                  src={
                    "https://avatars.githubusercontent.com/u/80540635?v=4"
                  }
                  alt={"logo"}
                  size="large"
                  type="circle flexible"
                /> */}
                <p className="navBarText">{props.targetUser?.name}</p>
              </div>
            }
          />
          <div className="message-list-m" ref={bottomRef}>
            <MessageList
              referance={messageListReferance}
              className="message-list-l"
              lockable={false}
              toBottomHeight={"100%"}
              dataSource={messages}
            />
            <div id="section-1"> </div>
          </div>
          <Form>
            <Form.Group>
              <Row>
                <Col md={9}>
                  <Form.Control
                    type="text"
                    className="messageTextBox"
                    value={state?.messageText}
                    placeholder="Tapez un message ici (Limite 300 caractères)..."
                    autoFocus
                    onChange={(e) => {
                      onMessageInputChange(e);
                    }}
                    onKeyPress={(e) => {
                      onMessageKeyPress(e);
                    }}
                    maxLength={300}
                  />
                </Col>
                <Col md={3}>
                  <Button
                    disabled={!state?.messageText}
                    className="sendButton"
                    onClick={() => {
                      onSendClicked();
                    }}
                  >
                    Envoyer
                  </Button>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <div class="row g-3">
                    <div class="col-sm-6">
                      <label for="logo" class="form-label">
                        Envoyer un fichier
                      </label>
                      <input
                        type="file"
                        class="form-control"
                        id="logo"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </div>
      ) : (
        <div>
          <div>
            <p>Sélectionnez un utilisateur pour continuer une conversation.</p>
          </div>
        </div>
      )}
    </div>
  );
};
export default ChatBox;
