/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { App } from "../firebase";

import "../App.css";
import "../styles/cart.css";

const ColiPrix = (nbcard) => {
  let base = 1.13; // prix base envoie simple
  const baseSuivis = 0.5; // obligatoire
  let prixbycartplus = 0.28; 
  if (nbcard <= 5) {
    return base + prixbycartplus * nbcard + baseSuivis;
  } else {
    // a partir de 100gr
    base += 0.4;
    let t = base + prixbycartplus * nbcard + baseSuivis
    if (t > 10){
      return 9.69
    }else{
      return t;
    }
    
  }
};

const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const getVendor = async (id, data, cb) => {
  await App.firestore()
    .collection("vendor")
    .doc(id)
    .get()
    .then(async (docd) => {
      var d = docd.data();
      d.id = docd.id;
      if (d) cb(d, data);
    });
};

const Panier = () => {
  const [cards, setCards] = useState(
    JSON.parse(localStorage.getItem("cardData"))
  );
  const history = useHistory();
  const db = App.firestore();
  const [loading, setLoading] = useState(true);
  const [loadingResume, setLoadingResume] = useState(true);
  const [loadingResumeL, setLoadingResumeL] = useState(true);
  const [cart, setCart] = useState([]);
  const [panierU, setPanierU] = useState(null);

  const getAllSoldcard = async (cb) => {
    setLoading(true);
    await App.firestore()
      .collection("cards")
      .where("state", "==", 1)
      .orderBy("_update_At", "desc")
      .get()
      .then(async (querySnapshot) => {
        var data = [];
        querySnapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          data.push(d);
        });
        setLoading(false);
        cb(data);
      })
      .catch((err) => {
        console.log(" err", err);
        setLoading(false);
        cb([]);
      });
  };

  const prepareCart = (panier) => {
    // preparation du panier et modeliser par vendeur;
    const pByProducts = groupBy(panier.products, "vendorId");
    let finald = [];
    const len = Object.keys(pByProducts).length;
    setPanierU(panier);
    for (const [key, value] of Object.entries(pByProducts)) {
      getVendor(key, value, (vendor, data) => {
        let ds = vendor;
        ds.products = data;
        // ajouter les commentaires
        if (panier?.comments?.length > 0) {
          panier?.comments?.forEach((c) => {
            if (ds.uid === c.uid) {
              ds.comment = c.comment;
            }
          });
        }
        finald.push(ds);
        if (finald.length === len) {
          setCart(finald);
          setLoading(false);
          // console.log("finald", finald);
        }
      });
    }
  };

  const bye = () => {
    alert("Aucun produit au panier");
    history.push("/");
  };

  const getPanier = (iduser) => {
    setLoading(true);
    db.collection("carts")
      .where("uid", "==", iduser)
      .where("status", "==", "progress")
      .limit(1)
      .get()
      .then((querySnapshot) => {
        var data = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          var d = doc.data();
          d.id = doc.id;
          data.push(d);
        });

        if (data?.length === 1) {
          if (data[0]?.products.length === 0) {
            bye();
            setLoading(false);
            return;
          }
          prepareCart(data[0]);
        } else {
          bye();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("getPanier err", err);
        alert("Une erreur s'est produite");
        setLoading(false);
      });
  };

  useEffect(() => {
    App.auth().onAuthStateChanged((u) => {
      if (u) {
        getPanier(App.auth().currentUser?.uid);
      } else {
        alert("Connexion nécessaire.");
        history.push("/connexion");
      }
    });
  }, []);

  const getProduct = (p) => {
    let ps = null;
    // console.log("cards", cards);
    cards.data.forEach((e) => {
      if (e.id === p.id) {
        ps = e;
      }
    });
    return ps;
  };

  const LineItem = ({ p, index }) => {
    const [prepDel, setPrepDel] = useState(false)
    const product = getProduct(p);

    useEffect(() => {
      // console.log("product", product);

      if (product === null) {
        getAllSoldcard((d) => {
          const s = { data: d, date: new Date() };
          setCards(s);
          localStorage.setItem("cardData", JSON.stringify(s));
        });
      }
    }, []);

    function getEx() {
      if (product?.expansion && product?.card?.card_sets) {
        var d = null;
        product?.card?.card_sets.forEach((e) => {
          if (e.set_name === product?.expansion) d = e;
        });
        return d;
      } else return null;
    }

    function getCode() {
      var d = getEx()?.set_code || "";
      if (product?.lang === "fr") {
        return d.replace("EN", product?.lang.toUpperCase());
      } else {
        return d;
      }
    }

    const del =() =>{
      if (prepDel){
        return
      }
      if (confirm(`Supprimer ${product?.lang === "fr"? product?.card.name: product?.card.name_en} du panier ?`)){
        setPrepDel(true)
        console.log('p',index,  p)
        console.log('panierU', panierU);
        let np = []
        panierU.products?.forEach((pn)=> {
          if (p.id !== pn.id){
            np.push(pn)
          }
        })
        // rajouter le nombre de p.qte a cards. p.id
        db.collection('cards').doc(p.id).get().then((doc) => {
          const nqte = doc.data().qte + p.qte;
          db.collection('cards').doc(p.id).update({_update_At: new Date(), qte: nqte})
        })

        if (np?.length > 0){
          // mettre a jour le panier 
          const currentTime = new Date().getTime();
          const delay = new Date(currentTime + 2 * 60 * 60 * 1000); 
          db.collection('carts')
          .doc(panierU.id)
          .update({_update_At: new Date(), delay: delay,products: np })
          .then(() => {
            getPanier(App.auth().currentUser?.uid);
          }).catch(() => {
            setPrepDel(false)
            alert("Une erreur s'est produite réessayer")
          }) 
        }else{
          // supprimer le panier et aller a la page d'accueil
          db.collection('carts')
          .doc(panierU.id)
          .delete().then(()=> {
            window.location.href = "/";
          }).catch(() => {
            setPrepDel(false)
            alert("Une erreur s'est produite réessayer")
          }) 
          
        }
      }
    }

    return (
      <tr>
        <th scope="row">{index + 1}</th>
        <td>
          {prepDel ? <span>Chargement...</span>: (<a onClick={del} class="text-danger">
            <i class="ri-delete-bin-3-line"></i>
          </a>)}
          
        </td>
        <td>
          {/* <img
            src="https://themesbox.in/admin-templates/olian/html/light-vertical/assets/images/ecommerce/product_01.svg"
            class="img-fluid"
            width="35"
            alt="product"
          /> */}
          <img
            width="35"
            style={{
              justifyItems: "center",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              position: "relative",
              top: -15,
            }}
            class="img-fluid"
            src={product?.card?.card_images[0]?.image_url}
            alt={product?.card?.name}
          />
        </td>
        <td>
          <a
            href="#"
            onClick={() => {
              history.push({
                pathname: `/product/${getCode().split("-")[0]}/${
                  getCode().split("-")[1]
                }/${product?.id}`,
                state: {
                  data: product,
                },
              });
            }}
          >
            {product?.lang === "fr"
              ? product?.card.name
              : product?.card.name_en}
          </a>
        </td>
        <td>
          <div class="form-group mb-0">
            <p style={{ textAlign: "left" }}>{p.qte}</p>
            {/* <input
              type="number"
              class="form-control cart-qty"
              name="cartQty1"
              id="cartQty1"
              value="1"
            /> */}
          </div>
        </td>
        <td>{product?.price}€</td>
        <td class="text-right">
          {Number(Number(product?.price) * p.qte).toFixed(2)}€
        </td>
      </tr>
    );
  };

  const VendorItem = ({
    item,
    setComment,
    modeLivraison,
    prixSub,
    prixShip,
  }) => {
    const [livraison, setLivraison] = useState(true);
    const [message, setMessage] = useState(item?.comment ? item.comment : null);
    const actualABN = item?.subscription_info?.name;

    const getNbTotalP = () => {
      let t = 0;
      item?.products.forEach((e) => {
        t += e.qte;
      });
      return t;
    };

    const tl = ColiPrix(getNbTotalP());

    const handleMessageChange = (event) => {
      // 👇️ update textarea value
      setMessage(event.target.value);
      if (event.target.value != null && event.target.value !== "") {
        setComment(event.target.value, item.id);
      } else {
        setComment(null, item.id);
      }
    };

    const checkIfOnlyShipping = () => {
      // uniquement la livraison;
      let d = true;
      item?.products?.forEach((e) => {
        const card = getProduct(e);
        if (!card?.livraison) d = false;
      });
      return d;
    };

    const checkIfOnlyMainpropre = () => {
      // uniquement la Main propre;
      let d = true;
      item?.products?.forEach((e) => {
        const card = getProduct(e);
        if (!card?.mainpropre) d = false;
      }); 
         
      return d;
    };


    const mainpropre = () => {
      // le client veut seulement en main propre
      setLivraison(false);
      modeLivraison(false, item.id);
      console.log("main propre")
    };

   

    const onlyLivraison = () => {
      // le client veut seulement en livraison
      setLivraison(true);
      modeLivraison(true, item.id);
      console.log("livraison")
    };

    useEffect(() => {
      onlyLivraison();
      if (!checkIfOnlyShipping() && checkIfOnlyMainpropre()) {
        mainpropre();
      }
    }, []);

    const sub = () => {
      // prix sous total:
      let sub = 0;
      item?.products?.forEach((d) => {
        const ds = Number(getProduct(d)?.price) * d.qte;
        sub += ds;
      });
      return sub?.toFixed(2);
    };

    const taxpercent = () => {
      if (actualABN === "Free") {
        return "6,4% + 0,25cts";
      } else {
        return "1,4% + 0,25cts";
      }
    };

    const total = () => {
      if (livraison) {
        return Number(Number(sub()) + tl).toFixed(2);
      } else {
        return Number(sub()).toFixed(2);
      }
    };
    useEffect(() => {
      // envoyer le prix total sous total
      prixSub(sub(), item.id);
      // envoyer le prix livraison sous total
      prixShip(tl, item.id);
    }, []);

    return (
      <div class="card" style={{ padding: 20, marginBottom: 30 }}>
        <h6 class="card-title mb-3">
          Vendeur:{" "}
          <a
            href="#"
            onClick={() => {
              history.push({
                pathname: `/vendor/${item.id}`,
                state: {
                  data: item,
                },
              });
            }}
          >
            <b>{item?.name}</b>
          </a>
        </h6>
        <div class="cart-head">
          <div class="table-responsive">
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Action</th>
                  <th scope="col">Photo</th>
                  <th scope="col">Carte</th>
                  <th scope="col">Qté</th>
                  <th scope="col">Prix</th>
                  <th scope="col" class="text-right">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {item?.products.map((p, index) => (
                  <>
                    <LineItem p={p} index={index} />
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div class="cart-body">
          <div class="row">
            <div class="col-md-12 order-2 order-lg-1 col-lg-5 col-xl-6">
              <div class="order-note">
                <div class="form-group">
                  <label for="specialNotes">Commentaire pour le vendeur</label>
                  <textarea
                    class="form-control"
                    name="specialNotes"
                    id="specialNotes"
                    rows="3"
                    value={message}
                    onChange={handleMessageChange}
                    placeholder="Ajouter votre commentaire ici"
                  />
                </div>
              </div>

              {!livraison && (
                <p>
                  <small style={{ fontSize: 10 }}>
                    Cette commande sera traité pour un retrait en main propre,
                    vous pourrez prendre contact avec votre vendeur après le
                    règlement de la commande.
                  </small>
                  {checkIfOnlyShipping() && (
                    <a
                      onClick={onlyLivraison}
                      style={{ cursor: "pointer", color: "#0a58ca" }}
                    >
                      Je change d'avis pour une livraison.
                    </a>
                  )}
                </p>
              )}
              {!checkIfOnlyMainpropre() && (
                <small style={{ fontSize: 10 }}>
                  Cette commande ne peut pas être récupérer en main propre.(7)
                </small>
              )}
            </div>
            <div class="col-md-12 order-1 order-lg-2 col-lg-7 col-xl-6">
              <div class="order-total table-responsive ">
                <table class="table table-borderless text-right">
                  <tbody>
                    <tr>
                      <td>Sous total :</td>
                      <td>{sub()}€</td>
                    </tr>
                    {checkIfOnlyShipping() && livraison && (
                      <>
                        <tr>
                          <td>
                            Livraison + suivis
                            <span style={{ fontSize: 10 }}>(8)</span> :
                          </td>
                          <td>{tl.toFixed(2)}€</td>
                        </tr>
                        {checkIfOnlyMainpropre() && (
                          <a 
                            onClick={mainpropre}
                            style={{ cursor: "pointer", color: "#0a58ca", textDecoration:"underline" }}
                          >
                            <small style={{ fontSize: 9 }}>
                              Je souhaite une livraison en main propre{" "}
                              <span> (3)</span>
                            </small>
                          </a>
                        )}
                      </>
                    )}
                    

                    {/* <tr>
                      <td>
                        Frais{" "}
                        <span style={{ fontSize: 10 }}>({taxpercent()})</span> :
                      </td>
                      <td>$180.00</td>
                    </tr> */}
                    <tr>
                      <td class="f-w-7 font-18">
                        <h4>Total :</h4>
                      </td>
                      <td class="f-w-7 font-18">
                        <h4>{total()}€</h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const setCommentVendorOrder = (message, id) => {
    let actualcart = cart;
    actualcart.forEach((element, index) => {
      if (element.id === id) {
        actualcart[index].comment = message;
      }
    });
    setCart(actualcart);
  };

  const modeLivraison = (livraison, id) => {
    let actualcart = cart;
    actualcart.forEach((element, index) => {
      if (element.id === id) {
        actualcart[index].modeLivraison = livraison
          ? "livraison"
          : "mainpropre";
      }
    });
    // console.log("actualcart", actualcart)
    setCart(actualcart);
  };

  const prixSub = (prix, id) => {
    let actualcart = cart;
    actualcart.forEach((element, index) => {
      if (element.id === id) {
        actualcart[index].subPrice = Number(prix);
      }
    });
    setCart(actualcart);
    setTimeout(() => {
      setLoadingResume(false);
    }, 100);
  };
  const prixShip = (prix, id) => {
    let actualcart = cart;
    actualcart.forEach((element, index) => {
      if (element.id === id) {
        actualcart[index].shipPrice = Number(prix);
      }
    });
    setCart(actualcart);
    setTimeout(() => {
      setLoadingResumeL(false);
    }, 100);
  };

  const totalsub = () => {
    let t = 0;
    cart.forEach((element) => {
      t = Number(t) + Number(element?.subPrice);
    });
    return t;
  };

   
  const [loadsa, setLoadSa] = useState(false);
   
  const saveIfComment = () => {
    if (loadsa) {
      return;
    }
    let hascomment = false;
    // console.log("cart", cart);
    let comments = [];
    cart.forEach((c) => {
      if (c?.comment != null && c?.comment.length > 0) {
        comments?.push({ uid: c.uid, comment: c.comment });
        hascomment = true;
      }
    });
    // console.log("saveIfComment", cart) 
    // console.log("test", panierU)
    const cs =  [...cart] 
    // if (hascomment) {
      // console.log("comments", comments);
      // if comment
       
      setLoadSa(true);
      App.firestore()
        .collection("carts")
        .doc(panierU.id)
        .update({ comments: comments })
        .then((res) => {
          // setLoadSa(false);
          history.push({
            pathname: "/confirm-adress",
            state: {
              cart: cs,
              panier: panierU,
            },
          });
        })
        .catch((err) => {
          setLoadSa(false);
          console.log("err", err);
          alert("Une erreur s'est produite, contactez nous.");
        });
    // } else {
    //   // else no comment
    //   history.push({
    //     pathname: "/confirm-adress",
    //     state: {
    //       cart: cart,
    //       panier: panierU,
    //     },
    //   });
    // }
  };

  return (
    <>
      {!loading && (
        <div class="container padding-bottom-3x mb-1">
          <div class=" mb-3">
            <div class="card-body">
              <div class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                <div class="step completed">
                  <div class="step-icon-wrap">
                    <div class="step-icon">
                      <i class="pe-7s-cart"></i>
                    </div>
                  </div>
                  <h4 class="step-title">Panier</h4>
                </div>
                <div class="step ">
                  <div class="step-icon-wrap">
                    <div class="step-icon">
                      <i class="pe-7s-config"></i>
                    </div>
                  </div>
                  <h4 class="step-title">Validation de l'adresse</h4>
                </div>
                <div class="step ">
                  <div class="step-icon-wrap">
                    <div class="step-icon">
                      <i class="pe-7s-medal"></i>
                    </div>
                  </div>
                  <h4 class="step-title">Paiement</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div class="container marketing px-3 py-3 ">
        {!loading ? (
          <div class="row">
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-lg-10 col-xl-8">
                  {!loadsa && cart.map((d) => (
                    <VendorItem
                      item={d}
                      key={d.id}
                      setComment={setCommentVendorOrder}
                      modeLivraison={modeLivraison}
                      prixSub={prixSub}
                      prixShip={prixShip}
                    />
                  ))}
                </div>
                <div class="col-lg-2 col-xl-4">
                  <div class="card position-sticky top-0">
                    <div class="p-3 bg-light bg-opacity-10">
                      <h6 class="card-title mb-3">Résumé de la commande</h6>
                      <div class="d-flex justify-content-between mb-1 small">
                        <span>Sous-total</span>{" "}
                        <span>
                          {!loadingResume ? totalsub().toFixed(2) : 0}€
                        </span>
                      </div>
                      {/* <div class="d-flex justify-content-between mb-1 small">
                        <span>Livraison</span>{" "}
                        <span>
                          {!loadingResumeL ? totalLivraison().toFixed(2) : 0}€
                        </span>
                      </div> */}
                      <hr />
                      {/* <div class="d-flex justify-content-between mb-4 small">
                        <span>TOTAL</span>{" "}
                        <strong class="text-dark">
                          {!loadingResume && !loadingResumeL
                            ? Number(totalsub() + totalLivraison()).toFixed(2)
                            : 0}
                          €
                        </strong>
                      </div> */}
                      <a
                        class="btn btn-primary w-100 mt-2"
                        // href="/confirm-adress"
                        onClick={saveIfComment}
                      >
                        {loadsa
                          ? "Chargement"
                          : "Choisir l'adresse de livraison"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p style={{ textAlign: "center", marginTop: 200, marginBottom: 200 }}>
            Chargement ...
          </p>
        )}
      </div>
    </>
  );
};
export default Panier;
