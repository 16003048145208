export const ItemEnchere = () => {
  return (
    <div class="col">
      <a
        class="card shadow-sm btn btn-sm btn-outline"
        href="/enchere/<Edité-dans>/<code-card>/<code-product>"
      >
        <img
          width="100%"
          style={{
            justifyItems: "center",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          className="d-block"
          src="https://static.cardmarket.com/img/b9c258c20a1c152769027712df2936d0/items/5/LDS3/667634.jpg"
          alt="First slide"
        />
        <div class="card-body">
          <h6 class="price-container">Nom de la carte</h6>
          <h4 class="price-container">
            <span style={{ fontSize: 10 }}>A partir de </span>
            12,80 €
          </h4>
          <h6 class="price-container">LED9</h6>
          <h7 class="price-container">12 participants</h7>
        </div>
        <p
          style={{
            position: "absolute",
            backgroundColor: "green",
            top: 10,
            padding: 4,
            left: -10,
            color: "white",
          }}
        >
          Enchère en cours
        </p>
      </a>
    </div>
  );
};
