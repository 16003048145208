import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCG6GVl2ivdufeO_gr0Kcsvy0PxsdBM4So",
  authDomain: "ygo-shop.firebaseapp.com",
  projectId: "ygo-shop",
  storageBucket: "ygo-shop.appspot.com",
  messagingSenderId: "726444876247",
  appId: "1:726444876247:web:c50a41434daa97602d2b92",
  measurementId: "G-YFWFY59DGW",
};
app.initializeApp(firebaseConfig);
app.auth().languageCode = "fr";

// Initialize Firebase
export const App = app;
