import React, { useEffect, useState } from "react";
import ReactN from "reactn"; 
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar'; 
import { App } from "../firebase";

const Header = () => {
  const [userG, setUserG] = ReactN.useGlobal("user");
  const [menuOpen, setMenuOpen] = useState(false);
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [search, setSearch] = useState("");

  React.useEffect(() => {
    App.auth().onAuthStateChanged((u) => {
      if (u && userG === undefined) {
        getUser(App.auth().currentUser?.uid, (u) => {
          setUserG(u);
        });
      } else {
        setUserG(null);
      }
    });
  }, []);

  const getUser = async (uid, cb) => {
    await App.firestore()
      .collection("users")
      .doc(uid)
      .get()
      .then(async (docd) => {
        var user = docd.data();
        user.id = docd.id;
        cb(user);
      })
      .catch((error) => {
        cb(null);
      });
  };
  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (size.width > 811 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  return ( 
       <Navbar expand="lg" sticky="top" class="navbar navbar-expand-lg" style={{background:"white"}} >
      <Container>
        <Navbar.Brand href="/">
        <img
              alt=""
              src={
                process.env.PUBLIC_URL + "/assets/images/Yu-Gi-Oh!_(Logo).jpeg"
              }
              style={{
                // maxWidth: 120,
                height: 40,
              }}
            />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav"> 
          <Nav className="container-fluid" style={{justifyContent:'end'}}>
            <Nav.Link href="/cartes">Cartes</Nav.Link>
            <Nav.Link href="/vendeurs">Vendeurs</Nav.Link> 


            {userG === null ? (
                <>
                <Nav.Link href="/connexion">Connexion</Nav.Link> 
                <Nav.Link href="/inscription">Inscription</Nav.Link>  
                </>
              ) : userG?.role === "vendor" ||
                userG?.role === "pendingvendor" ? ( 
                <Nav.Link href="/tableau-de-bord">Mon compte</Nav.Link>  
              ) : (
                <Nav.Link href="/mon-compte">Mon compte</Nav.Link>  
              )}


<form
            class="col-12 col-lg-auto mb-3 mb-lg-0"
            role="search"
            onSubmit={(e) => {
              e.preventDefault();
              // console.log('search', )
              window.location.href = `/search?q=${search}`;
            }}
          >
            <input
              type="search"
              class="form-control"
              placeholder="Rechercher..."
              aria-label="Rechercher"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </form>
          <a
            href="/panier"
            class="nav-link link-dark px-2"
            style={{ marginLeft: 20 }}
          >
            <i class="fa fa-shopping-cart" />
          </a>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>  
  );
};

export default Header;
