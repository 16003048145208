import React from "react";
import { useHistory } from "react-router-dom";
import { App } from "../firebase";
import { API_URL } from "../constant";
import "../App.css";
import "../styles/cart.css";
const Success = () => {
  const history = useHistory();
  const cId = history?.location?.state?.pId || null;
  const link3Dsecure = history?.location?.state?.link || null;

  const [cart, setCart] = React.useState(null);

  React.useEffect(() => {
    App.auth().onAuthStateChanged((u) => {
      if (u && cId) {
        watch(cId, u.uid);
      }
    });
    if (cId === null) {
      history.push("/");
    }
  }, []);

  const watch = (nId, userId) => {
    // console.log("requesting...", id);

    fetch(API_URL + "watch", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cartId: nId,
        userId: userId,
      }),
    })
      .then((res) => res.json())
      .then(function (response) {
        // console.log(response); // This will sometime be empty
        if (response.success) {
          if (response.cart) {
            //soit paid ou cancelled
            // console.log("response", response.cart.status);
            setCart(response.cart);
            if (response.cart.status === "paid") {
              //   finish paid
            } else {
              // controle response.cart.payment.status
              if (response.cart.payment.status === "succeeded") {
                //   init cart paid + create order correctly
              } else {
                if (response.cart.payment?.last_payment_error) {
                  // alert(response.cart.payment?.last_payment_error.message);
                } else {
                  setTimeout(() => {
                    watch(nId, userId);
                  }, 1000);
                }
              }
            }
          } else {
            if (response.retry) {
              setTimeout(() => {
                watch(nId, userId);
              }, 1000);
            }
          }
        } else {
          alert(response.error);
        }
      })
      .catch(function (error) {
        console.log("People we have an error!", error);
        alert(error.error);
      });
  };

  return (
    <>
      <link
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
        rel="stylesheet"
      />
      <section class="mail-seccess section">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 offset-lg-3 col-12">
              {cart?.payment?.status === "succeeded" ? (
                <div class="success-inner">
                  <h1>
                    <i class="fa fa-check-circle"></i>
                    <span>Votre commande est validé!</span>
                  </h1>
                  <p>
                    Le vendeur va préparer votre commande, vous pouvez également
                    le contacter directement si nécessaire
                  </p>
                  <a
                    href="/tableau-de-bord#your-order"
                    class="btn btn-dark btn-lg"
                  >
                    Voir les commandes
                  </a>
                </div>
              ) : cart?.payment?.status === "canceled" ? (
                <div class="success-inner">
                  <h1>
                    <i class="fa fa-uncheck-circle"></i>
                    <span>Chargement ...</span>
                  </h1>
                  <p>
                    Le vendeur va préparer votre commande, vous pouvez également
                    le contacter directement si nécessaire
                  </p>
                  <a href="#" class="btn btn-dark btn-lg">
                    Retour à l'accueil
                  </a>
                </div>
              ) : cart?.payment?.last_payment_error ? (
                <div class="success-inner">
                  <h1>
                    <i class="fa fa-times-circle"></i>
                    <span>Une erreur s'est produite</span>
                  </h1>
                  <p>{cart?.payment?.last_payment_error?.message}</p>
                  <button
                    onClick={() => {
                      history.push({
                        pathname: "/pay",
                        state: {
                          cart: history?.location?.state?.cart,
                          panier: history?.location?.state?.panier,
                        },
                      });
                    }}
                    class="btn btn-dark btn-lg"
                  >
                    Réessayer avec une autre carte
                  </button>
                </div>
              ) : (
                link3Dsecure && (
                  <div
                    class="success-inner"
                    style={{ paddingTop: 150, paddingBottom: 150 }}
                  >
                    <h1>
                      {/* <i class="fa fa-check-circle"></i> */}
                      <span>Confirmer votre paiement</span>
                    </h1>
                    <p>
                      Fenêtre de validation 3D secure va s'ouvrir, sinon cliquer
                      sur le lien ci-dessous.
                    </p>
                    <a
                      href={link3Dsecure}
                      target="_blank"
                      class="btn btn-dark btn-lg"
                    >
                      ouvrir dans un nouvel onglet
                    </a>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Success;
