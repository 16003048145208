import React, { useState, useEffect } from "react";
import "../App.css";
import { useParams, useHistory } from "react-router-dom";
import "../styles/product.css";
import Carousel from "react-bootstrap/Carousel";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import Alert from "react-bootstrap/Alert";
import { App } from "../firebase";
import { Item } from "../components/ItemCard";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Badge from "react-bootstrap/Badge";
import { ItemEnchere } from "../components/ItemEnchere";
import { ItemBasedeDeck } from "../components/ItemBDK";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import ReactPaginate from "react-paginate";
import { API_URL } from "../constant";

const popover = (
  <Popover id="popover-basic">
    <Popover.Header as="h3">Grading</Popover.Header>
    <Popover.Body>
      <p>
        Mint (M)
        <br />
        Near Mint (NM)
        <br />
        Excellent (EX) <br />
        Good (GD) <br />
        Light Played (LP) <br />
        Played (PL) <br />
        Poor
        <br />
        <a
          href="https://help.cardmarket.com/fr/CardCondition"
          target={"_blank"}
        >
          Plus d'information sur cardmarket
        </a>
      </p>
    </Popover.Body>
  </Popover>
);

const Products = () => {
  const history = useHistory();
  const db = App.firestore();
  const data = history?.location?.state?.data || null;
  const { editor, codeCard, userProduct } = useParams();
  const [actualABN, setActualABN] = useState("Free");
  const [infoCard, setInfoCard] = useState(null);
  const [dataProductList, setDataProductList] = React.useState([]);
  const [infoMySearch, setInfoMySearch] = React.useState(null);
  const [qteCard, setQteCard] = React.useState(null);
  const [stateCard, setStateCard] = React.useState("Mint");
  const [langCard, setLangCard] = React.useState("fr");
  const [publishCard, setPublishCard] = React.useState("1");
  const [firstCard, setFirstCard] = React.useState(false);
  const [editMySearch, setEditMySearch] = React.useState(false);
  const [username, setUser] = useState("");
  const [vendor, setVendor] = useState(null);
  const [view, setView] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [loadAddSearchCard, setLoadAddSearchCard] = useState(false);
  const [loadAddCartCard, setLoadAddCartCard] = useState(false);
  const [livraison, setLivraison] = React.useState(false);
  const [mainpropre, setMainPropre] = React.useState(false);
  const [prix, setPrix] = React.useState(null);
  const [vendeur, setVendeur] = useState(null);
  const [loadingStripe, setLoadingStripe] = React.useState(false);
  const [actualABNInfo, setActualABNInfo] = useState(false);

  const { register, handleSubmit, setValue, getValues } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: { number: 1 },
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });

  function isMyProduit() {
    return (
      userProduct && infoCard && infoCard.userId === App.auth().currentUser?.uid
    );
  }
  const getInfoVendor = async (cb) => {
    if (App.auth().currentUser?.uid) {
      await App.firestore()
        .collection("vendor")
        .doc(App.auth().currentUser?.uid)
        .get()
        .then((docd) => {
          var vendor = docd.data();
          vendor.id = docd.id;
          cb(vendor);
        })
        .catch((error) => {
          cb(null);
        });
    }
  };

  const [loadingprepare, setLoadingprepare] = useState(false);
  const prepareInfoVendor = () => {
    if (loadingprepare || vendeur) {
      return;
    }
    setLoadingprepare(true);
    getInfoVendor((vendor) => {
      setVendeur(vendor);
      // console.log("vendor", vendor);
      if (vendor.complete) {
        if (vendor.subscription === "complete") {
          // verifié l'abonnement du vendeur
          if (vendor.subscription_info?.name) {
            setLoadingprepare(false);
            setActualABN(vendor.subscription_info?.name);

            if (vendor.subscription_info?.name !== "Free") {
              checkABN(vendor.subscription_info);
            } else {
              if (vendor.subscription_info?.name === "Free") {
                // l'utilisateur a un abonnement gratuit !
                setActualABN("free")
              }
            }
          }
        } else {
          setLoadingprepare(false);
        }
      } else {
        setLoadingprepare(false);
      }
    });
  };
  const checkABN = (abn) => {
    if (abn._canceled_at === null && abn.subscription_id) {
      // on va regarder si l'abonnement est toujours valide

      fetch(`${API_URL}getInfoSubscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // eslint-disable-next-line object-shorthand
          subscription_id: abn.subscription_id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("getInfoSubscription data", data);
          if (data.success) {
            setActualABNInfo(data.subscription);
            if (data.subscription.status === "active") {
              // tout va bien.
            } else {
              // l'abonnement à expirer ...
              setActualABN("Free");
            }
          } else {
            setActualABN("Free");
          }
        })
        .catch((error) => {
          setLoadingStripe(false);
          console.log("getStripeVendorInfo error", error);
          // eslint-disable-next-line no-alert
        });
    }
  };

  const DeleteMyCard = (e) => {
    e.preventDefault();
    if (loadAddSearchCard) {
      return;
    }
    setLoadAddSearchCard(true);
    db.collection("cards")
      .doc(infoMySearch.id)
      .delete()
      .then(() => {
        setLoadAddSearchCard(false);
        hImSearch();
        history.push("/");
        alert("La carte sera supprimée dans quelques minutes.");
      })
      .catch((err) => {
        setLoadAddSearchCard(false);
        console.log("DeleteSearchCard err", err);
        alert("Une erreur s'est produite.");
      });
  };

  function getPrix() {
    const cal = prix * 0.05;
    return `soit de ${Number(cal).toFixed(2)}€`;
  }

  const UpdateSearchCard = (e) => {
    e.preventDefault();
    if (loadAddSearchCard) {
      return;
    }
    if (stateCard === null) {
      alert("Veuillez choisir un état minimum pour la carte.");
      return;
    }
    var d = {
      price: Number(prix).toFixed(2),
      livraison: livraison,
      mainpropre: mainpropre,
      qte: Number(qteCard),
      etat: stateCard,
      lang: langCard,
      first: firstCard,
      _update_At: new Date(),
      state: Number(qteCard) > 0 ? 1 : 0,
    };
    setLoadAddSearchCard(true);

    db.collection("cards")
      .doc(infoMySearch.id)
      .update(d)
      .then(() => {
        setLoadAddSearchCard(false);
        hImSearch();
        window.location.reload();
        alert("Carte modifiée.");
      })
      .catch((err) => {
        setLoadAddSearchCard(false);
        console.log("carte err", err);
        alert("Une erreur s'est produite.");
      });
  };
  const getVendor = async (cb) => {
    await App.firestore()
      .collection("vendor")
      .doc(infoCard?.userId)
      .get()
      .then(async (docd) => {
        var data = docd.data();
        if (data) cb(data);
      })
      .catch((err) => {
        cb("");
        console.log(" err", err);
      });
  };

  const getUsername = async (cb) => {
    await App.firestore()
      .collection("users")
      .doc(infoCard?.userId)
      .get()
      .then(async (docd) => {
        var user = docd.data();
        if (user?.displayName) cb(user?.displayName);
      })
      .catch((err) => {
        cb("");
        console.log(" err", err);
      });
  };
  const getInfoCard = async (cb, force = false) => {
    if ((infoCard === null && userProduct) || force) {
      await db
        .collection("cards")
        .doc(userProduct)
        .get()
        .then(async (docd) => {
          var d = docd.data();
          d.id = docd.id;
          cb(d);
        })
        .catch((error) => {
          cb(null);
        });
    }
  };
  const getAllserchusercard = async (cb) => {
    await App.firestore()
      .collection("cards")
      .where("userId", "==", infoCard?.userId)
      .where("state", "==", 1)
      .get()
      .then(async (querySnapshot) => {
        var data = [];
        querySnapshot.forEach((doc) => { 
          if (doc.id !== infoCard?.id) {
            var d = doc.data();
            d.id = doc.id; 
            if (d.qte > 0){
              data.push(d);
            }
          }
        });
        cb(data);
      })
      .catch((err) => {
        cb([]);
        console.log(" err", err);
      });
  };

  function Items({ currentItems }) {
    return (
      <>
        {currentItems &&
          currentItems.map((e) => <Item data={e} key={e?.id} noname={true} />)}
      </>
    );
  }

  function PaginatedItems({ items, itemsPerPage }) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = React.useState(null);
    const [pageCount, setPageCount] = React.useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = React.useState(0);

    React.useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(items.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(items.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % items.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        {items.length > itemsPerPage && (
          <div style={{ margin: "auto", width: "100%", marginTop: 30 }}>
            <ReactPaginate
              previousLabel="précedent"
              nextLabel="suivant"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pageCount}
              pageRangeDisplayed={itemsPerPage}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
              hrefAllControls
              // }}
            />
          </div>
        )}
      </>
    );
  }

  const UpdateView = () => {
    if (!view && !isMyProduit()) {
      setView(true);
      var v = infoCard.views + 1;

      var d = {
        views: Number(v),
        _update_At: new Date(),
      };

      db.collection("cards").doc(infoCard.id).update(d);
    }
  };

  useEffect(() => {
    if (infoCard) {
      UpdateView();
      getUsername((displayn) => {
        setUser(displayn);
      });
      getVendor((vendor) => {
        setVendor(vendor);
      });
      // rechercher les cartes de l'user
      getAllserchusercard((d) => {
        setDataProductList(d);
      });
    }
  }, [infoCard]);

  useEffect(() => {
    if (data) {
      // console.log("data", data);
      setInfoCard(data);

      // check disponibility et updateit
      if (
        !(userProduct && data && data.userId === App.auth().currentUser?.uid)
      ) {
        getInfoCard((d) => {
          if (d) {
            // console.log("data la", d);
            setInfoCard(d);
          } else {
            // recherche uniquement les informations editor+codecard
            alert("Carte supprimée par son utilisateur.");
            window.location.href = "/";
          }
        });
      }
    } else {
      getInfoCard((d) => {
        if (d) {
          // console.log("data la", d);
          setInfoCard(d);
        } else {
          // recherche uniquement les informations editor+codecard
          alert(
            "Cette carte n'est plus disponible, elle sera complètement supprimée d'ici quelques minutes."
          );
          window.location.href = "/";
        }
      });
    }
  }, [data]);

  const edit = (bdd) => {
    prepareInfoVendor();
    setInfoMySearch(bdd);
    setPrix(Number(bdd?.price).toFixed(2));
    setQteCard(bdd?.qte);
    setStateCard(bdd.etat);
    setLangCard(bdd.lang);
    setPublishCard(bdd.state);
    setFirstCard(bdd.first);
    setLivraison(bdd?.livraison || false);
    setMainPropre(bdd?.mainpropre || false);
    setEditMySearch(true);
  };

  const hImSearch = () => {
    setEditMySearch(false);
    setQteCard(null);
    setInfoMySearch(null);
    setLangCard("fr");
    setStateCard(null);
    setFirstCard(false);
    setPublishCard("1");
  };

  const sImSearch = (bdd) => {
    setInfoMySearch(bdd);
    setQteCard(bdd?.qte);
    setStateCard(bdd.etat);
    setLangCard(bdd.lang);
    setPublishCard(bdd.state);
    setFirstCard(bdd.first);
  };
  function getEx() {
    if (infoCard?.expansion && infoCard?.card?.card_sets) {
      var d = null;
      infoCard?.card?.card_sets.forEach((e) => {
        if (e.set_name === infoCard?.expansion) d = e;
      });
      return d;
    } else return null;
  }

  const getAllCartsByUser = async (cb) => {
    await App.firestore()
      .collection("carts")
      .where("uid", "==", App.auth().currentUser?.uid || "")
      .where("status", "==", "progress")
      .limit(1)
      .get()
      .then(async (querySnapshot) => {
        var data = [];
        querySnapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          data.push(d);
        });
        cb(data[0]);
      })
      .catch((err) => {
        cb(null);
        console.log(" err", err);
      });
  };

  function AddToCard(e) {
    //seulement si c'est pas ma carte
    if (!isMyProduit() && infoCard?.qte > 0) {
      if (e?.number) {
        setLoadAddCartCard(true);
        const number = Number(e.number);
        // getcard pour vérifié si toujours disponible
        getInfoCard((d) => {
          if (d) {
            setInfoCard(d);
            if (d?.qte >= number) {
              //récuprerer le panier actuel de l'utilisateur
              getAllCartsByUser((cart) => {
                const currentTime = new Date().getTime();
                const delay = new Date(currentTime + 2 * 60 * 60 * 1000);
                if (cart) {
                  // console.log("cart", cart);
                  let isadd = false;
                  // -> check si le panier contient déja cette carte
                  cart.products.forEach((element, index) => {
                    if (element.id === infoCard?.id) {
                      isadd = true;
                      let newp = cart.products;
                      // si oui ajouter la quantité voulu et rediriger vers le panier
                      newp[index].qte = Number(element.qte + number);
                      // augmenter de now + 2 heures le delai du panier actuel
                      App.firestore()
                        .collection("carts")
                        .doc(cart.id)
                        .update({_update_At: new Date(), products: newp, delay: delay })
                        .then(() => {
                          App.firestore()
                            .collection("cards")
                            .doc(infoCard?.id)
                            .update({
                              qte: Number(d?.qte - number),
                            })
                            .then(() => {
                              setLoadAddCartCard(true);
                              // -> puis rediriger vers le panier
                              window.location.href = "/panier";
                            })
                            .catch((err) => {
                              setLoadAddCartCard(false);
                              console.log(
                                "setLoadAddCartCard  create error",
                                err
                              );
                              alert(
                                "Une erreur s'est produite, réessayer ou contacter nous."
                              );
                            });
                        })
                        .catch((err) => {
                          setLoadAddCartCard(false);
                          console.log("setLoadAddCartCard  create error", err);
                          alert(
                            "Une erreur s'est produite, réessayer ou contacter nous."
                          );
                        });
                    }
                  });
                  if (!isadd) {
                    // -> sinon ajouter cette carte au panier existant
                    // augmenter de + 2 heure le delai du panier actuel
                    let nar = cart.products;
                    nar.push({
                      id: infoCard.id,
                      qte: number,
                      type: "cards",
                      vendorId: infoCard.userId,
                    });

                    App.firestore()
                      .collection("carts")
                      .doc(cart.id)
                      .update({
                        products: nar,
                        delay: delay,
                        _update_At: new Date(),
                      })
                      .then(() => {
                        App.firestore()
                          .collection("cards")
                          .doc(infoCard?.id)
                          .update({
                            qte: Number(d?.qte - number),
                          })
                          .then(() => {
                            setLoadAddCartCard(true);
                            // -> puis rediriger vers le panier
                            window.location.href = "/panier";
                          })
                          .catch((err) => {
                            setLoadAddCartCard(false);
                            console.log(
                              "setLoadAddCartCard  create error",
                              err
                            );
                            alert(
                              "Une erreur s'est produite, réessayer ou contacter nous."
                            );
                          });
                      })
                      .catch((err) => {
                        setLoadAddCartCard(false);
                        console.log("setLoadAddCartCard  create error", err);
                        alert(
                          "Une erreur s'est produite, réessayer ou contacter nous."
                        );
                      });
                  }
                } else {
                  // si l'utilisateur n'a aucun panier en cours créer le panier en y ajoutant cette carte.

                  const dm = {
                    uid: App.auth().currentUser?.uid,
                    _create_At: new Date(),
                    _update_At: new Date(),
                    delay: delay,
                    status: "progress",
                    products: [
                      {
                        id: infoCard?.id,
                        qte: number,
                        type: "cards",
                        vendorId: infoCard.userId,
                      },
                    ],
                  };
                  // console.log(dm);
                  App.firestore()
                    .collection("carts")
                    .add(dm)
                    .then((res) => {
                      // confirmer l'ajout au panier en modifiant le produit actuelle (sans updateAt) et retirer la quantité exacte ajoutée
                      App.firestore()
                        .collection("cards")
                        .doc(infoCard?.id)
                        .update({
                          qte: Number(d?.qte - number),
                        })
                        .then((res) => {
                          setLoadAddCartCard(true);
                          // -> puis rediriger vers le panier
                          window.location.href = "/panier";
                        })
                        .catch((err) => {
                          setLoadAddCartCard(false);
                          console.log("setLoadAddCartCard  create error", err);
                          alert(
                            "Une erreur s'est produite, réessayer ou contacter nous."
                          );
                        });
                    })
                    .catch((err) => {
                      setLoadAddCartCard(false);
                      console.log("setLoadAddCartCard  create error", err);
                      alert(
                        "Une erreur s'est produite, réessayer ou contacter nous."
                      );
                    });
                }
              });
            } else {
              setLoadAddCartCard(false);
              alert(
                "Le stock de la carte a été mis à jour, modifier votre demande."
              );
            }
          } else {
            setLoadAddCartCard(false);
            // recherche uniquement les informations editor+codecard
            alert(
              "Cette carte n'est plus disponible, elle sera complètement supprimée d'ici quelques minutes."
            );
            window.location.href = "/";
          }
        }, true);
      }
    }
  }
  const contactUser = () => {
    alert(
      "Votre message sera transmis à notre équipe avant d'etre visible par le vendeur. Veuillez respecter nos conditions d'utilisation ainsi que le vendeur."
    );
    let message = prompt("Quel est votre message ?");

    if (message?.length > 0) {
      // contacter l'utilisateur (pas le vendeur)
      setLoadingMessage(true);
      App.firestore()
        .collection("messages")
        .where("uids", "array-contains", App.auth().currentUser?.uid)
        .get()
        .then((snapshot) => {
          let d = null;
          snapshot.forEach((doc) => {
            // console.log(doc.id, "=>", doc.data());
            const uids = doc.data().uids;
            uids.forEach((id) => {
              if (infoCard?.userId === id) {
                d = doc.id;
              }
            });
          });

          const items = {};
          items[App.auth().currentUser?.uid] =
            App.auth().currentUser?.displayName;
          items[infoCard?.userId] = username;
          if (d) {
            // on va ajouter le message a cette identifiant
            App.firestore()
              .collection("messages")
              .doc(d)
              .collection("tchats")
              .add({
                createAt: new Date(),
                message: message,
                senderId: App.auth().currentUser?.uid,
                type: "text",
              })
              .then((res) => {
                App.firestore()
                  .collection("messages")
                  .doc(d)
                  .update({
                    unread: true,
                    updateAt: new Date(),
                    lastMessage: message,
                    lastMessageBy: App.auth().currentUser?.uid,
                  })
                  .then((res) => {
                    setLoadingMessage(false);
                    fetch(API_URL + "sendMessageClientOrder", {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        userId:  infoCard?.userId, 
                        isOrder: false,
                        msg: "Vous avez un nouveau message, cliquez sur le lien pour le voir" ,
                        title: "Vous avez un nouveau message" ,
                      }),
                    })
                    alert("Votre message à été envoyé.");
                  })
                  .catch((err) => {
                    setLoadingMessage(false);
                    console.log("sendmessage  update error", err);
                    alert(
                      "Une erreur s'esr produite, réessayer ou contacter nous."
                    );
                  });
              })
              .catch((err) => {
                setLoadingMessage(false);
                console.log("sendmessage  create error", err);
                alert(
                  "Une erreur s'esr produite, réessayer ou contacter nous."
                );
              });
          } else {
            App.firestore()
              .collection("messages")
              .add({
                blocked: false,
                createAt: new Date(),
                displayName: items,
                lastMessage: message,
                lastMessageBy: App.auth().currentUser?.uid,
                uids: [App.auth().currentUser?.uid, infoCard?.userId],
                unread: true,
                updateAt: new Date(),
              })
              .then((res) => {
                App.firestore()
                  .collection("messages")
                  .doc(res.id)
                  .collection("tchats")
                  .add({
                    createAt: new Date(),
                    message: message,
                    senderId: App.auth().currentUser?.uid,
                    type: "text",
                  })
                  .then((res) => {
                    setLoadingMessage(false);
                    fetch(API_URL + "sendMessageClientOrder", {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        userId:  infoCard?.userId, 
                        isOrder: false,
                        msg: "Vous avez un nouveau message, cliquez sur le lien pour le voir" ,
                        title: "Vous avez un nouveau message" ,
                      }),
                    })
                    alert("Votre message à été envoyé.");
                  })
                  .catch((err) => {
                    setLoadingMessage(false);
                    console.log("sendmessage  create error", err);
                    alert(
                      "Une erreur s'esr produite, réessayer ou contacter nous."
                    );
                  });
              })
              .catch((err) => {
                setLoadingMessage(false);
                console.log("sendmessage error", err);
                alert(
                  "Une erreur s'esr produite, réessayer ou contacter nous."
                );
              });
          }
        })
        .catch((err) => {
          setLoadingMessage(false);
          console.log("sendmessage load error", err);
          alert("Une erreur s'esr produite, réessayer ou contacter nous.");
        });
    } else {
      setLoadingMessage(false);
      alert("Votre message est vide.");
    }
  };
  return (
    <>
      {/* <p>switch a faire sur cart, enchères ou base de deck</p>
      <p>{editor}</p>
      <p>{codeCard}</p>
      <p>{userProduct}</p> */}
      <div class="container py-5 ">
        {isMyProduit() && (
          <Alert key={"info"} variant={"info"}>
            Pour modifier ce produit cliquez{" "}
            <a href="#" onClick={() => edit(infoCard)}>
              ici
            </a>
            .
          </Alert>
        )}
        {infoCard && (
          <div class="product-wrap clearfix product-deatil">
            <div class="row">
              <div class="col-md-5 col-sm-12 col-xs-12">
                <div class="product-image">
                  {infoCard && (
                    <Carousel variant="dark">
                      {infoCard?.card?.card_images?.map((image) => (
                        <Carousel.Item>
                          <img
                            style={{
                              justifyItems: "center",
                              display: "block",
                              marginLeft: "auto",
                              marginRight: "auto",
                              height: 356,
                              width: 246,
                            }}
                            className="d-block"
                            src={image?.image_url}
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  )}
                </div>
              </div>

              <div class="col-md-6 col-md-offset-1 col-sm-12 col-xs-12">
                <h2 class="name">
                  {infoCard?.lang === "fr"
                    ? infoCard?.card?.name
                    : infoCard?.card?.name_en}
                  <small class="fa fa-2x">
                    Vendu par{" "}
                    <a href={`/vendor/${vendor?.id}`}>{vendor?.name}</a>{" "}
                    {/* {typeof infoCard?._update_At?.toDate() !== undefined &&
                      " le "}
                    {typeof infoCard?._update_At?.toDate() !== undefined &&
                      new Date(
                        infoCard?._update_At?.toDate()
                      ).toLocaleDateString("fr-FR", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })} */}
                  </small>
                </h2>
                <hr />
                <h3 class="price-container">{infoCard?.price} €</h3>
                <br />
                <Form
                  onSubmit={handleSubmit(AddToCard, (err) => {
                    console.log(err);
                  })}
                >
                  <Form.Group className="mb-3" controlId="number">
                    <Form.Control
                      type="number"
                      name="number"
                      placeholder="1"
                      min={1}
                      max={infoCard?.qte}
                      style={{ width: 70 }}
                      onChange={(e) => setValue("number", e.target.value)}
                      ref={register("number")}
                    />
                    <Form.Text className="text-muted">
                      {infoCard?.qte} disponible en{" "}
                      {infoCard.rarete || getEx()
                        ?.set_rarity_code.replace("(", "")
                        .replace(")", "")}{" "}
                      <OverlayTrigger
                        trigger="click"
                        placement="right"
                        overlay={popover}
                      >
                        <a href="#">
                          <span style={{ color: "green" }}>
                            {infoCard?.etat}
                          </span>
                        </a>
                      </OverlayTrigger>
                      {" - "}
                      {infoCard?.expansion}
                    </Form.Text>
                  </Form.Group>
                  {App.auth().currentUser?.uid ? (
                    <Button
                      variant="success"
                      type="submit"
                      disabled={
                        loadAddCartCard || isMyProduit() || infoCard?.qte === 0
                      }
                    >
                      {loadAddCartCard
                        ? "Chargement ... "
                        : "Ajouter au panier"}
                    </Button>
                  ) : (
                    <a
                      className="btn btn-success"
                      href="/connexion"
                      onClick={() => {
                        localStorage.setItem(
                          "redirectAfterlogin",
                          window.location.href
                        );
                      }}
                    >
                      Pour commander, connectez-vous.
                    </a>
                  )}
                </Form>
                <div class="certified">
                  {vendor && (
                    <ul>
                      {infoCard?.mainpropre && (
                        <li>
                          <a href="javascript:void(0);">
                            Main propre<span>{vendor?.address?.city}</span>
                          </a>
                        </li>
                      )}
                      {infoCard?.livraison && (
                        <li>
                          <a href="javascript:void(0);">
                            Livraison<span>3-5 Jours</span>
                          </a>
                        </li>
                      )}
                      <li>
                        <a href="javascript:void(0);">
                          Certifié
                          <span>{infoCard?.badge || "Nouveau vendeur"} </span>
                        </a>
                      </li>
                    </ul>
                  )}
                </div>
                <hr />
                {/* <button class="btn btn-white btn-default">
                  <i class="fa fa-star"></i> Ajouter à mes souhaits
                </button> */}
                {!isMyProduit() && (<>
                  { App.auth().currentUser?.uid ? (
                  <button
                    class="btn btn-white btn-default"
                    onClick={() => {
                      contactUser();
                    }}
                  >
                    <i class="fa fa-envelope"></i>{" "}
                    {loadingMessage
                      ? "Chargement ... "
                      : " Contacter le vendeur"}
                  </button>
                ) : (
                  <a
                    class="btn btn-white btn-default"
                    href="/connexion"
                    onClick={() => {
                      localStorage.setItem(
                        "redirectAfterlogin",
                        window.location.href
                      );
                    }}
                  >
                    <i class="fa fa-envelope"></i> Connectez-vous pour contacter
                    le vendeur
                  </a>
                )}
                </>)}
                
                {/* <a
                  class="btn btn-white btn-default"
                  href="/tableau-de-bord?openaddcard=true&id=1234#card"
                >
                  <i class="fa fa-bolt"></i> Vendre cette carte
                </a> */}
                {/* <button class="btn btn-white btn-default">
                  <i class="fa fa-bug"></i> Signaler
                </button> */}
                <p>
                  <br />
                  Partager avec :{" "}
                </p>
                <FacebookShareButton url={window.location.href}>
                  <div
                    style={{
                      backgroundColor: "blue",
                      width: 30,
                      height: 30,
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 15,
                      marginRight: 10,
                    }}
                  >
                    <i class="fa fa-facebook"></i>
                  </div>
                </FacebookShareButton>
                <TwitterShareButton url={window.location.href}>
                  <div
                    style={{
                      backgroundColor: "#00acee",
                      width: 30,
                      height: 30,
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 15,
                      marginRight: 10,
                    }}
                  >
                    <i class="fa fa-twitter"></i>
                  </div>
                </TwitterShareButton>
              </div>
            </div>
          </div>
        )}
        <br />
        {infoCard ? (
          <Tabs
            defaultActiveKey="description"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="description" title="Description">
              <p class=" name">
                <br />
                Edité dans <a href={`/search?q=${editor}`}>{infoCard?.expansion}</a>
                <br />
                Rareté: <a href="#">{getEx()?.set_rarity}</a>
                <br />
                Nombre: {Number(getEx()?.set_code.split("-")[1].slice(2))}
                <br />
                Première édition : {infoCard?.first ? "Oui" : "Non"}
                <br />
                Réimpressions:
                {infoCard?.card?.card_sets?.map((e) => {
                  if (
                    !(
                      getEx()?.set_code === e.set_code &&
                      e.set_rarity_code === getEx()?.set_rarity_code
                    )
                  )
                    return (
                      <>
                        {" "}
                        <a href={`/search?q=${e.set_code.split("-")[0]}`}>
                          {e.set_code.split("-")[0]} - {e.set_rarity_code}
                        </a>
                      </>
                    );
                })}
              </p>
              {/* <a href={`/offres/${codeCard}`}>Montrer les autres offres </a> */}
              <p>
                <br />
                Description de la carte :
                <br />
                <span style={{ fontSize: 12 }}>{infoCard?.card?.desc}</span>
              </p>
            </Tab>
            <Tab eventKey="profile" title="Vendeur et avis">
              <p>Société : {vendor?.name}</p>
              <p>
                Adresse : Information communiqué lors d'une livraison en main
                propre uniquement.
              </p>
              {/* <p>Siren : </p> */}

              {/* <p>
                <b>Avis</b> : 120 Avis
                <br />
                <span style={{ color: "green" }}>Bon</span>: 118
                <br />
                <span style={{ color: "red" }}>Mauvais</span>: 2
              </p> */}
            </Tab>
            <Tab eventKey="cartes" title="Autres cartes du vendeur">
              <div class="row row-cols-2 row-cols-sm-2 row-cols-md-6 g-3 py-3">
                <PaginatedItems items={dataProductList} itemsPerPage={12} />
              </div>
              {/* <p>
                <a href="#">Voir plus</a>
              </p> */}
            </Tab>
            {/* <Tab eventKey="deck" title="Bases de deck du vendeur">
            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
              <ItemBasedeDeck />
              <ItemBasedeDeck />
              <ItemBasedeDeck />
            </div>
            <p>
              <a href="#">Voir plus</a>
            </p>
          </Tab>
          <Tab eventKey="encheres" title="Enchères du vendeur (pro)">
            <div class="row row-cols-2 row-cols-sm-2 row-cols-md-5 g-3 py-3">
              <ItemEnchere />
            </div>
            <p>
              <a href="#">Voir plus</a>
            </p>
          </Tab> */}
          </Tabs>
        ) : (
          <p>Chargement ...</p>
        )}
      </div>
      <Modal
        show={editMySearch}
        onHide={hImSearch}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {infoMySearch?.lang === "fr"
              ? infoMySearch?.card?.name
              : infoMySearch?.card?.name_en}
          </Modal.Title>
        </Modal.Header>
        {/* Afficher les information de la rechercher */}
        {/* {infoMySearch} */}
        <Modal.Body>
          <div class="">
            <Form class="" onSubmit={UpdateSearchCard}>
              <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                <Col>
                  <Form.Group className="mb-3" controlId="tarif">
                    <Form.Label>Tarif €</Form.Label>
                    <Form.Control
                      type="number"
                      step="0.01"
                      placeholder="Entrer votre prix"
                      required
                      value={prix}
                      onChange={(e) => {
                        setPrix(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col>
                <Form.Group className="mb-3" controlId="actualABN">
                <Form.Label>
                  {actualABN === "Free" && (<> 
                      <Badge>Gratuit</Badge>{" "}
                      <small style={{ fontSize: 11 }}>
                        (1) Frais de: 5% {getPrix()}
                      </small></> 
                  )}</Form.Label>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                <Col>
                  <Form.Group className="mb-3" controlId="qte">
                    <Form.Control
                      type="number"
                      min={1}
                      Placeholder="Quantité"
                      required
                      value={qteCard}
                      onChange={(e) => {
                        setQteCard(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Select
                      aria-label="Choisir l'état minimum de la carte"
                      value={stateCard}
                      onChange={(e) => {
                        setStateCard(e.target.value);
                      }}
                      required
                    >
                      <option value={null}>
                        Choisir l'état minimum de la carte
                      </option>
                      <option value="Mint">Mint</option>
                      <option value="Neart-Mint">Neart-Mint</option>
                      <option value="Excellent">Excellent</option>
                      <option value="Bon état">Bon état</option>
                      <option value="Assez Jouable">Assez Jouable</option>
                      <option value="Jouable">Jouable</option>
                      <option value="Mauvais">Mauvais</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                <Col>
                  <Form.Group className="mb-3">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="firstEdition"
                      name="firstEdition"
                      checked={firstCard}
                      onChange={(e) => setFirstCard(e.target.checked)}
                    />
                    <label class="form-check-label" for="firstEdition">
                      {"  Première edition"}
                    </label>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Select
                      value={langCard}
                      onChange={(e) => {
                        setLangCard(e.target.value);
                      }}
                      required
                    >
                      <option value="fr">Langue Française</option>
                      <option value="en">Langue Anglaise</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                <Col>
                  <Form.Group className="mb-3" controlId="livraison">
                    <Form.Check
                      type="checkbox"
                      label="Livraison"
                      class="form-check-input"
                      id="Livraison"
                      name="Livraison"
                      checked={livraison}
                      onChange={(e) => setLivraison(e.target.checked)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="mainpropre">
                    <Form.Check
                      type="checkbox"
                      label="Main propre"
                      class="form-check-input"
                      id="mainpropre"
                      name="mainpropre"
                      checked={mainpropre}
                      onChange={(e) => setMainPropre(e.target.checked)}
                    />
                  </Form.Group>
                </Col>
                {mainpropre && (
                  <Col>
                    <p style={{ fontSize: 10 }}>
                      Vous pourrez discuter après la commande du lieu de
                      transaction.
                    </p>
                  </Col>
                )}
                <Col>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={loadAddSearchCard}
                  >
                    {loadAddSearchCard ? "Chargement..." : "Mettre à jour"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={DeleteMyCard}>
            {loadAddSearchCard ? "Chargement..." : "Supprimer"}
          </Button>
          <Button variant="secondary" onClick={hImSearch}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Products;
