export const ItemBasedeDeck = () => {
  return (
    <div class="col">
      <a
        href="/deckbase/<code-deck>"
        style={{
          textDecoration: "none",
          color: "black",
        }}
      >
        <div class="card shadow-sm">
          {/* background */}
          <img
            width="100%"
            style={{
              justifyItems: "center",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            className="d-block"
            alt="First slide"
            src={process.env.PUBLIC_URL + "/assets/images/bg_basededeck.jpg"}
          />
          {/* photo left */}
          <img
            width="100%"
            style={{
              position: "absolute",
              width: "30%",
              top: "5%",
              left: "7%",
            }}
            className="d-block"
            src="https://static.cardmarket.com/img/b9c258c20a1c152769027712df2936d0/items/5/LDS3/667634.jpg"
            alt="First slide"
          />
          {/* photo right */}
          <img
            width="100%"
            style={{
              position: "absolute",
              width: "20%",
              top: "5%",
              left: "55%",
            }}
            className="d-block"
            src="https://static.cardmarket.com/img/b9c258c20a1c152769027712df2936d0/items/5/LDS3/667634.jpg"
            alt="First slide"
          />

          {/* photo right */}
          <img
            width="100%"
            style={{
              position: "absolute",
              width: "20%",
              top: "13%",
              right: "10%",
            }}
            className="d-block"
            src="https://static.cardmarket.com/img/b9c258c20a1c152769027712df2936d0/items/5/LDS3/667634.jpg"
            alt="First slide"
          />

          {/* photo right */}

          <img
            width="100%"
            style={{
              position: "absolute",
              width: "20%",
              top: "25%",
              left: "53%",
            }}
            className="d-block"
            src="https://static.cardmarket.com/img/b9c258c20a1c152769027712df2936d0/items/5/LDS3/667634.jpg"
            alt="First slide"
          />

          <div
            style={{
              width: "100%",
              top: 0,
              bottom: 0,
              position: "absolute",
              backgroundColor: "transparent",
            }}
            className="d-block"
          />

          <div class="card-body">
            <h6 class="price-container">Nom de base de deck</h6>
            <h4 class="price-container">230,80 €</h4>
          </div>
        </div>
      </a>
    </div>
  );
};
