import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const popover = (
  <Popover id="popover-basic">
    <Popover.Header as="h3">Description (commune)</Popover.Header>
    <Popover.Body>
      <p>
        Lorsque cette carte est Invoquée Normalement : vous pouvez cibler 1
        monstre "HÉROS" de max. Niveau 4 ("Soldat Liquide, HÉROS Élémentaire"
        exclu) dans votre Cimetière ; Invoquez-le Spécialement. Si cette carte
        est utilisée comme Matériel Fusion pour un monstre "HÉROS", et est
        envoyée au Cimetière ou bannie : vous pouvez piocher 2 cartes, puis
        défaussez 1 carte. Vous ne pouvez utiliser qu'1 effet de "Soldat
        Liquide, HÉROS Élémentaire" par tour, et uniquement une fois le tour.
      </p>
    </Popover.Body>
  </Popover>
);

export const ItemSimple = () => {
  return (
    <div class="col">
      <OverlayTrigger trigger="click" placement="top" overlay={popover}>
        <div class="card shadow-sm btn btn-sm btn-outline">
          <img
            width="100%"
            style={{
              justifyItems: "center",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            className="d-block"
            src="https://static.cardmarket.com/img/b9c258c20a1c152769027712df2936d0/items/5/LDS3/667634.jpg"
            alt="First slide"
          />

          <div class="card-body">
            <h6 class="price-container">Nom de la carte</h6>
            <h6 class="price-container">LED9</h6>
          </div>

          <p
            style={{
              position: "absolute",
              backgroundColor: "black",
              top: 10,
              padding: 5,
              borderRadius: 17.5,
              width: 35,
              height: 35,
              justifyContent: "center",
              alignItems: "center",
              left: -10,
              color: "white",
              fontWeight: "bold",
            }}
          >
            x2
          </p>
        </div>
      </OverlayTrigger>
    </div>
  );
};
