import React from "react";
import "../App.css";
import { useParams } from "react-router-dom";
import "../styles/product.css";

import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import Alert from "react-bootstrap/Alert";
import { Item } from "../components/ItemCard";
import { ItemEnchere } from "../components/ItemEnchere";
import { ItemBasedeDeck } from "../components/ItemBDK";
import { ItemSimple } from "../components/SimpleCard";

const DeckBase = () => {
  const { userProduct } = useParams();

  function isMyProduit() {
    return true;
  }

  return (
    <>
      {/* <p>switch a faire sur cart, enchères ou base de deck</p>
      <p>{userProduct}</p> */}
      <div class="container py-5 ">
        {isMyProduit() && (
          <Alert key={"info"} variant={"info"}>
            Pour modifier ce deck cliquez{" "}
            <a href={`/tableau-de-bord/product/edit/${userProduct}`}>ici</a>.
          </Alert>
        )}

        <div class="product-wrap clearfix product-deatil">
          <div class="row">
            <div class="col-md-5 col-sm-12 col-xs-12">
              <div class="product-image">
                <div class="card shadow-sm">
                  {/* background */}
                  <img
                    width="100%"
                    style={{
                      justifyItems: "center",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    className="d-block"
                    alt="First slide"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/bg_basededeck.jpg"
                    }
                  />
                  {/* photo left */}
                  <img
                    width="100%"
                    style={{
                      position: "absolute",
                      width: "30%",
                      top: "5%",
                      left: "7%",
                    }}
                    className="d-block"
                    src="https://static.cardmarket.com/img/b9c258c20a1c152769027712df2936d0/items/5/LDS3/667634.jpg"
                    alt="First slide"
                  />
                  {/* photo right */}
                  <img
                    width="100%"
                    style={{
                      position: "absolute",
                      width: "20%",
                      top: "5%",
                      left: "55%",
                    }}
                    className="d-block"
                    src="https://static.cardmarket.com/img/b9c258c20a1c152769027712df2936d0/items/5/LDS3/667634.jpg"
                    alt="First slide"
                  />

                  {/* photo right */}
                  <img
                    width="100%"
                    style={{
                      position: "absolute",
                      width: "20%",
                      top: "13%",
                      right: "10%",
                    }}
                    className="d-block"
                    src="https://static.cardmarket.com/img/b9c258c20a1c152769027712df2936d0/items/5/LDS3/667634.jpg"
                    alt="First slide"
                  />

                  {/* photo right */}

                  <img
                    width="100%"
                    style={{
                      position: "absolute",
                      width: "20%",
                      top: "25%",
                      left: "53%",
                    }}
                    className="d-block"
                    src="https://static.cardmarket.com/img/b9c258c20a1c152769027712df2936d0/items/5/LDS3/667634.jpg"
                    alt="First slide"
                  />

                  <div
                    style={{
                      width: "100%",
                      top: 0,
                      bottom: 0,
                      position: "absolute",
                      backgroundColor: "transparent",
                    }}
                    className="d-block"
                  />
                </div>
              </div>
            </div>

            <div class="col-md-6 col-md-offset-1 col-sm-12 col-xs-12">
              <h2 class="name">
                Nom du deck de base
                <small class="fa fa-2x">
                  vendu par <a href="javascript:void(0);">Adeline</a>
                </small>
              </h2>
              <hr />
              <h3 class="price-container">120,80 €</h3>
              <br />
              <Button variant="success" type="submit">
                Ajouter au panier
              </Button>
              <div class="certified">
                <ul>
                  <li>
                    <a href="javascript:void(0);">
                      Main propre<span>La possession</span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      Livraison<span>3-5 Jours</span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      Certifié<span>Nouveau vendeur</span>
                    </a>
                  </li>
                </ul>
              </div>
              <hr />
              <button class="btn btn-white btn-default">
                <i class="fa fa-star"></i> Ajouter à mes souhaits
              </button>
              <button class="btn btn-white btn-default">
                <i class="fa fa-envelope"></i> Contacter le vendeur
              </button>
              <button class="btn btn-white btn-default">
                <i class="fa fa-bug"></i> Signaler
              </button>
              <p>
                {" "}
                Partager avec :{"  "}
                <FacebookShareButton url={window.location.href}>
                  <div
                    style={{
                      backgroundColor: "blue",
                      width: 30,
                      height: 30,
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 15,
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  >
                    <i class="fa fa-facebook"></i>
                  </div>
                </FacebookShareButton>
                <TwitterShareButton url={window.location.href}>
                  <div
                    style={{
                      backgroundColor: "#00acee",
                      width: 30,
                      height: 30,
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 15,
                      marginRight: 10,
                    }}
                  >
                    <i class="fa fa-twitter"></i>
                  </div>
                </TwitterShareButton>
              </p>
            </div>
          </div>
        </div>
        <br />
        <Tabs
          defaultActiveKey="description"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="description" title="Liste des cartes">
            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-6 g-3 py-3">
              <ItemSimple />
              <ItemSimple />
              <ItemSimple />
              <ItemSimple />
              <ItemSimple />
              <ItemSimple />
            </div>
          </Tab>
          <Tab eventKey="profile" title="Vendeur">
            <p>Société : </p>
            <p>
              Adresse : Information communiqué lors d'une livraison en main
              propre uniquement.
            </p>
            <p>Siren : </p>
          </Tab>
          <Tab eventKey="cartes" title="Autres cartes du vendeur">
            <div class="row row-cols-2 row-cols-sm-2 row-cols-md-6 g-3 py-3">
              <Item />
              <Item />
              <Item />
              <Item />
              <Item />
              <Item />
            </div>
          </Tab>
          <Tab eventKey="deck" title="Bases de deck du vendeur">
            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
              <ItemBasedeDeck />
              <ItemBasedeDeck />
              <ItemBasedeDeck />
            </div>
          </Tab>
          <Tab eventKey="encheres" title="Enchères du vendeur (pro)">
            <div class="row row-cols-2 row-cols-sm-2 row-cols-md-5 g-3 py-3">
              <ItemEnchere />
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};
export default DeckBase;
