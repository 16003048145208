import React, { useEffect } from "react";
import "../App.css";
import Badge from "react-bootstrap/Badge";
import { App } from "../firebase";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { API_URL } from "../constant";
const moment = require("moment");
moment.locale("fr", {
  months:
    "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split(
      "_"
    ),
  monthsShort:
    "janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.".split("_"),
  monthsParseExact: true,
  weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split("_"),
  weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
  weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY HH:mm",
  },
  calendar: {
    sameDay: "[Aujourd’hui à] LT",
    nextDay: "[Demain à] LT",
    nextWeek: "dddd [à] LT",
    lastDay: "[Hier à] LT",
    lastWeek: "dddd [dernier à] LT",
    sameElse: "L",
  },
  relativeTime: {
    future: "dans %s",
    past: "il y a %s",
    s: "quelques secondes",
    m: "une minute",
    mm: "%d minutes",
    h: "une heure",
    hh: "%d heures",
    d: "un jour",
    dd: "%d jours",
    M: "un mois",
    MM: "%d mois",
    y: "un an",
    yy: "%d ans",
  },
  dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
  ordinal: function (number) {
    return number + (number === 1 ? "er" : "e");
  },
  meridiemParse: /PD|MD/,
  isPM: function (input) {
    return input.charAt(0) === "M";
  },
  // In case the meridiem units are not separated around 12, then implement
  // this function (look at locale/id.js for an example).
  // meridiemHour : function (hour, meridiem) {
  //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
  // },
  meridiem: function (hours, minutes, isLower) {
    return hours < 12 ? "PD" : "MD";
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // Used to determine first week of the year.
  },
});

const cardData = JSON.parse(localStorage.getItem("cardData"));

const Item = ({ data }) => {
  const history = useHistory();
  const open = () => {
    history.push({
      pathname: `/vendor/${data.id}`,
      state: {
        data: data,
      },
    });
  };
  const [sold, setSold] = React.useState(0)
  const [actualABN, setActualABN] = React.useState("Free"); 
  const [actualABNInfo, setActualABNInfo] = React.useState(null);

  useEffect(() => {
    const db = App.firestore();
    db.collection(`vendor/${data.id}/orders`)  
    .get()
    .then((querySnapshot) => {
      var ds = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        var d = doc.data();
        d.id = doc.id;
        ds.push(d);
      }); 
      let b = 0
      // console.log('b vendor', ds)
      ds?.forEach((d)=> {  
        d.data?.products?.forEach((dss) => {
         b += dss?.qte || 0;
        });
        // console.log("b", b)
      }) 
      setSold(b)
  })
   
      // console.log("vendor", vendor);
      if (data.complete && data.subscription === "complete" && data.subscription_info?.name) { 
        setActualABN(data.subscription_info?.name); 
        setActualABNInfo(data.subscription_info)
      }    
  }, [])

  function gAbn (){

    console.log(actualABNInfo);
    // verifié l'abonnement du vendeur
    if (actualABN === "Free"){
      return { show: false };
    }else{
      return { name: actualABN, show: true };
    } 
  };

  const contact = () => {};

  const getAEV = () => {
    // get article en vente
    let d = [];
    cardData?.data?.forEach((ca) => {
      if (ca.userId === data.uid && ca.qte > 0) {
        d.push(ca);
      }
    });
    return String(d.length);
  };

  return (
    <div class="col">
      <a onClick={open}>
        <div class="card shadow-sm">
          <img
            width="100%"
            style={{
              justifyItems: "center",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              objectFit: "cover",
            }}
            height={231}
            className="d-block"
            src={
              data?.hero
                ? data?.hero
                : process.env.PUBLIC_URL + "/assets/images/imgbasevendeur.png"
            }
            alt="First slide"
          />
          <div class="card-body">
            <h4 class="price-container">{data?.name || "Vendeur inconnu"}</h4>
            <h6 class="price-container">Articles en vente : {getAEV()}</h6>
            <h6 class="price-container">
              Membre depuis le{" "}
              {moment.unix(data?._create_At?.seconds).format("DD-MM-YYYY")}
            </h6>
            <div class="d-flex justify-content-between align-items-center">
              {/* <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-sm btn-outline-secondary"
                  onClick={open}
                >
                  Voir
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-outline-secondary"
                  onClick={contact}
                >
                  Contacter
                </button>
              </div> */}
              <small class="text-muted">
                 {sold} vente{sold > 1 ? "s" : ""}
              </small>
            </div>
          </div>
          {gAbn()?.show && (
            <h6 style={{ position: "absolute", padding: 10 }}>
              <Badge bg="primary">{gAbn()?.name}</Badge>
            </h6>
          )}
        </div>
      </a>
    </div>
  );
};
function Items({ currentItems }) {
  return (
    <>
      {currentItems && currentItems.map((e) => <Item data={e} key={e?.id} />)}
    </>
  );
}

const Vendors = () => {
  const saved = JSON.parse(localStorage.getItem("vendors"));
  const [order, setOrder] = React.useState(0);
  const [dataSearchList, setDataSearchList] = React.useState(saved || []);
  const [data, setData] = React.useState(saved || []);
  const [reload, setReload] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const getAllSoldcard = async (cb) => {
    setLoading(true);
    await App.firestore()
      .collection("vendor")
      .where("complete", "==", true)
      .orderBy("_create_At", "desc")
      .get()
      .then(async (querySnapshot) => {
        var data = [];
        querySnapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          data.push(d);
        });
        setLoading(false);
        cb(data);
      })
      .catch((err) => {
        setLoading(false);
        cb([]);
        console.log(" err", err);
      });
  };

  const diffHour = (d1) => {
    let date_1 = new Date(d1);
    let date_2 = new Date();
    let difference = date_2.getTime() - date_1.getTime();
    return Math.ceil(difference / (1000 * 3600));
  };

  React.useEffect(() => {
    if (dataSearchList) {
      localStorage.setItem("vendors", JSON.stringify(dataSearchList));
    }
  }, [dataSearchList]);

  React.useEffect(() => {
    if (dataSearchList && dataSearchList?.data?.length > 0) {
      const TotalHour = diffHour(dataSearchList.date);
      console.log(TotalHour + " hour to world Cup");
      if (TotalHour > 1) {
        // new Download Data
        setDataSearchList(null);
        getAllSoldcard((d) => {
          setDataSearchList({ data: d, date: new Date() });
          setData({ data: d, date: new Date() });
        });
      }
    } else {
      // console.log("download data");
      getAllSoldcard((d) => {
        setDataSearchList({ data: d, date: new Date() });
        setData({ data: d, date: new Date() });
      });
    }
  }, []);

  const getOrderByName = (cb) => {
    const datas = data;
    datas?.data?.sort(function (a, b) {
      return a?.card?.name.localeCompare(b?.card?.name);
    });
    // console.log("datas", datas);
    cb(datas);
  };
  const getOrderDescName = (cb) => {
    getOrderByName((d) => {
      cb({ date: d.date, data: d.data.reverse() });
    });
  };

  const getOrderAscName = (cb) => {
    getOrderByName(cb);
  };

  React.useEffect(() => {
    if (order && data) {
      switch (order) {
        case "0":
          setDataSearchList(saved);
          setReload(true);
          setTimeout(() => {
            setReload(false);
          }, 100);
          break;
        case "1":
          getOrderAscName((d) => {
            setDataSearchList(d);
            setReload(true);
            setTimeout(() => {
              setReload(false);
            }, 100);
          });
          break;
        case "2":
          getOrderDescName((d) => {
            setDataSearchList(d);
            setReload(true);
            setTimeout(() => {
              setReload(false);
            }, 100);
          });
          break;
        default:
          setDataSearchList(data);
          setReload(true);
          setTimeout(() => {
            setReload(false);
          }, 100);
          break;
      }
    }
  }, [order, data]);

  function PaginatedItems({ items, itemsPerPage }) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = React.useState(null);
    const [pageCount, setPageCount] = React.useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = React.useState(0);

    React.useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(items?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(items?.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % items.length;
      // console.log(
      //   `User requested page number ${event.selected}, which is offset ${newOffset}`
      // );
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        {items?.length > itemsPerPage && (
          <div style={{ margin: "auto", width: "100%", marginTop: 30 }}>
            <ReactPaginate
              previousLabel="précedent"
              nextLabel="suivant"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pageCount}
              pageRangeDisplayed={itemsPerPage}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
              hrefAllControls
            />
          </div>
        )}
      </>
    );
  }

  return (
    <div
      class="album py-5 bg-light"
      style={{
        boxShadow:
          "inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15)",
      }}
    >
      <div class="container">
        <div
          class="border-bottom"
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
          }}
        >
          <h2 class="pb-2  ">Vendeurs</h2>
          <div class="form" onSubmit={(e) => e.preventDefault()}>
            <select
              class="form-select"
              id="floatingSelect"
              aria-label="Floating label select example"
              value={order}
              onChange={(e) => {
                setOrder(e.target.value);
              }}
            >
              <option selected value="0">
                Dernier ajout
              </option>
              <option value="1">A-Z</option>
              <option value="2">Z-A</option>
            </select>
          </div>
        </div>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
          {loading && <p>Chargement ...</p>}
          {!reload ? (
            <PaginatedItems items={dataSearchList?.data} itemsPerPage={12} />
          ) : (
            <p>Chargement ...</p>
          )}
        </div>
      </div>
    </div>
  );
};

const Vendeurs = () => {
  return (
    <>
      <Vendors />
    </>
  );
};
export default Vendeurs;
