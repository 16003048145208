import Layout from "./components/Layout";
import "./styles/output.css";
import React from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import Home from "./pages/Home";
import Card from "./pages/Card";
import Encheres from "./pages/Encheres";
import BasedeDeck from "./pages/BasedeDeck";
import Vendeurs from "./pages/Vendeurs";
import Faqs from "./pages/Faqs";
import Apropos from "./pages/Apropos";
import Login from "./pages/Login";
import Register from "./pages/Register";
import BecomeVendor from "./pages/BecomeVendor";
import RegisterVendor from "./pages/RegisterVendor";
import DashboardVendor from "./pages/DashVendor";
import Cgvendor from "./pages/Cgvendor";
import MentionLegales from "./pages/MentionLegales";
import Cg from "./pages/ConditionsGenerales";
import PolitiqueRetour from "./pages/PolitiqueRetour";
import Confidentialite from "./pages/Confidentialite";
import Products from "./pages/Products";
import OffresCard from "./pages/OffresCard";
import EditProduct from "./pages/EditProduct";
import Search from "./pages/Search";
import DeckBase from "./pages/DeckBase";
import Enchere from "./pages/Enchere";
import SearchListCard from "./pages/SearchListCard";
import Vendor from "./pages/vendor";
import Panier from "./pages/Panier";
import ValidAdress from "./pages/ValidAdress";
import Pay from "./pages/Pay";
import Success from "./pages/Success";
import ResultSearch from "./pages/ResultSearch";
import ForgetPass from "./pages/ForgetPass";
import Revocation from "./pages/Revocation";
import Commissions from "./pages/Commissions";
import DSecure from "./pages/Dsecure";

function App() {
  const history = useHistory();

  React.useEffect(() => {
    history.listen((location, action) => {
      if (action === "PUSH" || action === "REPLACE" || action === "POP") {
        window.scrollTo(0, 0);
      }
    });
  }, []);

  return (
    <>
      <Layout>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/cartes" exact>
            <Card />
          </Route>
          <Route path="/encheres" exact>
            <Encheres />
          </Route>
          <Route path="/bases-de-deck" exact>
            <BasedeDeck />
          </Route>
          <Route path="/vendeurs" exact>
            <Vendeurs />
          </Route>
          {/* <Route path="/faqs" exact>
            <Faqs />
          </Route> */}
          <Route path="/a-propos" exact>
            <Apropos />
          </Route>
          <Route path="/connexion" exact>
            <Login />
          </Route>
          <Route path="/inscription" exact>
            <Register />
          </Route>
          <Route path="/devenir-vendeur" exact>
            <BecomeVendor />
          </Route>
          <Route path="/inscription-vendeur" exact>
            <RegisterVendor />
          </Route>
          <Route path="/mon-compte" exact>
            <DashboardVendor />
          </Route>
          <Route path="/tableau-de-bord" exact>
            <DashboardVendor />
          </Route>
          <Route path="/tableau-de-bord/product/edit/:userProduct" exact>
            <EditProduct />
          </Route>
          <Route path="/conditions-vendeur" exact>
            <Cgvendor />
          </Route>
          <Route path="/mention-legales" exact>
            <MentionLegales />
          </Route>
          <Route path="/conditions-generales" exact>
            <Cg />
          </Route>
          <Route path="/conditions-revocation" exact>
            <Revocation />
          </Route>
          <Route path="/commissions" exact>
            <Commissions />
          </Route>
          {/* <Route path="/politique-de-retour-et-remboursement" exact>
            <PolitiqueRetour />
          </Route> */}
          <Route path="/confidentialite" exact>
            <Confidentialite />
          </Route>
          <Route path="/product/:editor/:codeCard/:userProduct" exact>
            <Products />
          </Route>
          <Route path="/enchere/:editor/:codeCard/:userProduct" exact>
            <Enchere />
          </Route>
          <Route path="/deckbase/:userProduct" exact>
            <DeckBase />
          </Route>

          <Route path="/search/:editor/" exact>
            <ResultSearch />
          </Route>
          <Route path="/search/:editor/:codeCard/" exact>
            <ResultSearch />
          </Route>
          <Route path="/search/:editor/:codeCard/:idSearch" exact>
            <Search />
          </Route>
          <Route path="/offres/:codeCard" exact>
            <OffresCard />
          </Route>
          <Route path="/user-search" exact>
            <SearchListCard />
          </Route>
          <Route path="/vendor/:id" exact>
            <Vendor />
          </Route>
          <Route path="/panier" exact>
            <Panier />
          </Route>
          <Route path="/confirm-adress" exact>
            <ValidAdress />
          </Route>
          <Route path="/pay" exact>
            <Pay />
          </Route>
          <Route path="/success" exact>
            <Success />
          </Route>
          <Route path="/3dsecure" exact>
            <DSecure />
          </Route>
          <Route path="/search" exact>
            <ResultSearch />
          </Route>
          <Route path="/forgetpass" exact>
            <ForgetPass />
          </Route>
        </Switch>
      </Layout>
    </>
  );
}

export default App;
