import React from "react";
import "../App.css";
import { useParams } from "react-router-dom";
import "../styles/product.css";
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import Alert from "react-bootstrap/Alert";
import { Item } from "../components/ItemCard";
import Table from "react-bootstrap/Table";
import Popover from "react-bootstrap/Popover";
import { ItemEnchere } from "../components/ItemEnchere";
import { ItemBasedeDeck } from "../components/ItemBDK";

function Encheriseur() {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>First Name</th>
          <th>Date</th>
          <th>Prix</th>
        </tr>
      </thead>
      <tbody>
        <tr style={{ backgroundColor: "orange" }}>
          <td style={{ color: "white", fontWeight: "bold" }}>3</td>
          <td style={{ color: "white", fontWeight: "bold" }}>Mark</td>
          <td style={{ color: "white", fontWeight: "bold" }}>
            12/02/2022 - 11:10
          </td>
          <td style={{ color: "white", fontWeight: "bold" }}>12,80 €</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Jacob</td>
          <td>12/02/2022 - 11:08</td>
          <td>9.50 €</td>
        </tr>
        <tr>
          <td>1</td>
          <td>Larry the Bird</td>
          <td>12/02/2022 - 10:00</td>
          <td>8.00 €</td>
        </tr>
      </tbody>
    </Table>
  );
}

const Enchere = () => {
  const { editor, codeCard, userProduct } = useParams();

  function isMyProduit() {
    return true;
  }

  return (
    <>
      {/* <p>switch a faire sur cart, enchères ou base de deck</p>
      <p>{editor}</p>
      <p>{codeCard}</p>
      <p>{userProduct}</p> */}
      <div class="container py-5 ">
        {isMyProduit() && (
          <Alert key={"info"} variant={"info"}>
            Pour modifier cette enchère cliquez{" "}
            <a href={`/tableau-de-bord/product/edit/${userProduct}`}>ici</a>.
          </Alert>
        )}

        <div class="product-wrap clearfix product-deatil">
          <div class="row">
            <div class="col-md-5 col-sm-12 col-xs-12">
              <div class="product-image">
                <p
                  style={{
                    position: "relative",
                    backgroundColor: "green",
                    top: 10,
                    padding: 4,
                    textAlign: "center",
                    color: "white",
                    maxWidth: 200,
                    margin: "auto",
                    zIndex: 100,
                  }}
                >
                  Enchère en cours
                </p>
                <Carousel variant="dark">
                  <Carousel.Item>
                    <img
                      style={{
                        justifyItems: "center",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      className="d-block"
                      src="https://static.cardmarket.com/img/b9c258c20a1c152769027712df2936d0/items/5/LDS3/667634.jpg"
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      style={{
                        justifyItems: "center",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      className="d-block"
                      src="https://static.cardmarket.com/img/b9c258c20a1c152769027712df2936d0/items/5/LDS3/667634.jpg"
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      style={{
                        justifyItems: "center",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      className="d-block"
                      src="https://static.cardmarket.com/img/b9c258c20a1c152769027712df2936d0/items/5/LDS3/667634.jpg"
                      alt="First slide"
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>

            <div class="col-md-6 col-md-offset-1 col-sm-12 col-xs-12">
              <h2 class="name">
                Nom de la carte
                <small class="fa fa-2x">
                  vendu par <a href="javascript:void(0);">Adeline</a>
                </small>
              </h2>
              <hr />
              <h3 class="price-container">
                12,80 €{" "}
                <span style={{ fontSize: 12 }}>
                  <a href="#">11 enchères</a>
                </span>
              </h3>
              <br />
              <Form style={{ backgroundColor: "#cbcbcb40", padding: 20 }}>
                <Form.Group className="mb-3" controlId="number">
                  <Form.Text className="text-muted">
                    Temps restant:
                    <p
                      style={{ color: "red", fontSize: 16, fontWeight: "bold" }}
                    >
                      11heures 10min 5s
                    </p>
                  </Form.Text>
                  <Form.Control
                    type="text"
                    placeholder="0,20"
                    style={{ width: 100 }}
                  />
                </Form.Group>

                <Button
                  variant="success"
                  style={{ fontWeight: "bold" }}
                  type="submit"
                >
                  Enchérir pour 0,20 €
                </Button>
              </Form>
              <div class="certified">
                <ul>
                  <li>
                    <a href="javascript:void(0);">
                      Main propre<span>La possession</span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      Livraison<span>3-5 Jours</span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      Certifié<span>Nouveau vendeur</span>
                    </a>
                  </li>
                </ul>
              </div>
              <hr />
              <button class="btn btn-white btn-default">
                <i class="fa fa-star"></i> Ajouter à mes souhaits
              </button>
              <button class="btn btn-white btn-default">
                <i class="fa fa-envelope"></i> Contacter le vendeur
              </button>

              <button class="btn btn-white btn-default">
                <i class="fa fa-bug"></i> Signaler
              </button>
              <p>
                <br />
                Partager avec :{" "}
              </p>
              <FacebookShareButton url={window.location.href}>
                <div
                  style={{
                    backgroundColor: "blue",
                    width: 30,
                    height: 30,
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 15,
                    marginRight: 10,
                  }}
                >
                  <i class="fa fa-facebook"></i>
                </div>
              </FacebookShareButton>
              <TwitterShareButton url={window.location.href}>
                <div
                  style={{
                    backgroundColor: "#00acee",
                    width: 30,
                    height: 30,
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 15,
                    marginRight: 10,
                  }}
                >
                  <i class="fa fa-twitter"></i>
                </div>
              </TwitterShareButton>
            </div>
          </div>
        </div>
        <br />
        <Tabs
          defaultActiveKey="encheriseur"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="encheriseur" title="Enchèriseur">
            <div class="row row-cols-2 row-cols-sm-2 row-cols-md-5 g-3 py-3">
              <Encheriseur />
            </div>
          </Tab>
          <Tab eventKey="description" title="Description">
            <p class=" name">
              <br />
              Edité dans <a href="#">Duellistes Légendaires : Saison 3</a>
              <br />
              Rareté: <a href="#">Commune</a>
              <br />
              Nombre: 103
              <br />
              Réimpressions: <a href="#">LED6</a> <a href="#">DP23</a>
            </p>
            <a href={`/offres/${codeCard}`}>Montrer les autres offres </a>
            <p>
              <br />
              Description de la carte :
              <br />
              <span style={{ fontSize: 12 }}>
                Lorsque cette carte est Invoquée Normalement : vous pouvez
                cibler 1 monstre "HÉROS" de max. Niveau 4 ("Soldat Liquide,
                HÉROS Élémentaire" exclu) dans votre Cimetière ; Invoquez-le
                Spécialement. Si cette carte est utilisée comme Matériel Fusion
                pour un monstre "HÉROS", et est envoyée au Cimetière ou bannie :
                vous pouvez piocher 2 cartes, puis défaussez 1 carte. Vous ne
                pouvez utiliser qu'1 effet de "Soldat Liquide, HÉROS
                Élémentaire" par tour, et uniquement une fois le tour.
              </span>
            </p>
          </Tab>
          <Tab eventKey="profile" title="Vendeur">
            <p>Société : </p>
            <p>
              Adresse : Information communiqué lors d'une livraison en main
              propre uniquement.
            </p>
            <p>Siren : </p>
          </Tab>
          <Tab eventKey="cartes" title="Autres cartes du vendeur">
            <div class="row row-cols-2 row-cols-sm-2 row-cols-md-6 g-3 py-3">
              <Item />
              <Item />
              <Item />
              <Item />
              <Item />
              <Item />
            </div>
          </Tab>
          <Tab eventKey="deck" title="Bases de deck du vendeur">
            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
              <ItemBasedeDeck />
              <ItemBasedeDeck />
              <ItemBasedeDeck />
            </div>
          </Tab>
          <Tab eventKey="encheres" title="Enchères du vendeur (pro)">
            <div class="row row-cols-2 row-cols-sm-2 row-cols-md-5 g-3 py-3">
              <ItemEnchere />
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};
export default Enchere;
