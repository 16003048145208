import React, { useEffect, useState } from "react";
import "../App.css";
import Col from "react-bootstrap/Col";
import ReactN from "reactn";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import ReactPaginate from "react-paginate";
import { App } from "../firebase";
import { Item } from "../components/ItemCard";
// import { useParams } from "react-router-dom";
const ResultSearch = () => {
  const saved = JSON.parse(localStorage.getItem("cardData"));
  // console.log("saved", saved);
  const [cards, setAllData] = useState(saved || null);
  const [loadData, setloading] = useState(false);
  // const { editor, codeCard } = useParams(); // pas tout le temps
  const [search, setSearch] = React.useState("");
  const [type, setType] = React.useState(null);
  const [expansion, setExpansion] = React.useState(null);
  const [order, setOrderBy] = React.useState("0");
  React.useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const q = urlParams.get("q");
    const t = urlParams.get("type");
    const e = urlParams.get("expansion");
    // const o = urlParams.get("order");
    // const p = urlParams.get("page") || 1;
    // console.log("search", q);
    setSearch(q);
    setType(t);
    setExpansion(e);
    // setOrderBy(o);
  }, []);

  const download = async (cb) => {
    setloading(true);
    await App.firestore()
      .collection("cards")
      .where("state", "==", 1)
      .orderBy("_update_At", "desc")
      .get()
      .then(async (querySnapshot) => {
        var data = [];
        querySnapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          data.push(d);
        });
        setloading(false);
        cb(data);
      })
      .catch((err) => {
        setloading(false);
        cb([]);
        console.log(" err", err);
      });
  };

  const diffHour = (d1) => {
    let date_1 = new Date(d1);
    let date_2 = new Date();
    let difference = date_2.getTime() - date_1.getTime();
    return Math.ceil(difference / (1000 * 3600));
  };

  function Items({ currentItems }) {
    return (
      <>
        {currentItems &&
          currentItems.map((e) => <Item data={e} key={e?.id} noname={true} />)}
      </>
    );
  }

  function PaginatedItems({ items, itemsPerPage }) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = React.useState(null);
    const [pageCount, setPageCount] = React.useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = React.useState(0);

    React.useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(items.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(items.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % items.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        {items.length > itemsPerPage && (
          <div style={{ margin: "auto", width: "100%", marginTop: 30 }}>
            <ReactPaginate
              previousLabel="précedent"
              nextLabel="suivant"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pageCount}
              pageRangeDisplayed={itemsPerPage}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
              hrefAllControls
              // }}
            />
          </div>
        )}
      </>
    );
  }

  function orderByT(data) {
    function compare(a, b) {
      if (a.card.name < b.card.name) {
        return -1;
      }
      if (a.card.name > b.card.name) {
        return 1;
      }
      return 0;
    }
    const res = data.sort((a, b) => {
      switch (order) {
        case "0":
          return a.price - b.price;
        case "1":
          return b.price - a.price;
        case "2":
          return compare(a, b);
        case "3":
          return compare(b, a);
        default:
          // eslint-disable-next-line array-callback-return
          return;
      }
    });
    return res;
  }

  function resultSearchs() {
    function getEx(data) {
      if (data?.expansion && data?.card?.card_sets) {
        var d = null;
        data?.card?.card_sets.forEach((e) => {
          if (e.set_name === data?.expansion) d = e;
        });
        return d;
      } else return null;
    }

    function getCode(data) {
      var d = getEx(data)?.set_code || "";
      if (data?.lang === "fr") {
        return d.replace("EN", data?.lang.toUpperCase());
      } else {
        return d;
      }
    }

    if (cards && cards?.data?.length > 0) {
      let res = [];
      cards?.data.forEach((d) => {
        if (
          d.card.name.toLowerCase().includes(search.toLowerCase()) ||
          d?.card.name_en?.toLowerCase().includes(search.toLowerCase()) ||
          getCode(d).toLowerCase().includes(search.toLowerCase())
        ) {
          if (d.qte > 0){
            res.push(d);
          }
          
        }
      });
      const dr = orderByT(res);
      return dr;
    } else {
      return [];
    }
  }
  useEffect(() => {
    if (cards) {
      localStorage.setItem("cardData", JSON.stringify(cards));
    }
  }, [cards]);

  useEffect(() => {
    if (cards && cards?.data?.length > 0) {
      // console.log("c'est pas null", cards.data);
      const TotalHour = diffHour(cards.date);
      console.log(TotalHour + " hour to world Cup");
      if (TotalHour > 1) {
        // new Download Data
        setAllData(null);
        download((d) => {
          setAllData({ data: d, date: new Date() });
        });
      }
    } else {
      // console.log("download data");
      if (loadData) {
        return;
      }
      download((d) => {
        setAllData({ data: d, date: new Date() });
      });
    }
  }, []);

  return (
    <>
      {/* <p>recherche a faire sur carte</p>
      <p>{editor}</p>
      <p>{codeCard}</p> */}
      <div
        class="album py-5 bg-light"
        style={{
          boxShadow:
            "inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15)",
        }}
      >
        <div class="container">
          <div
            class="border-bottom"
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
            }}
          >
            <h2 class="pb-2  ">Résultats de la recherche</h2>
          </div>
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                // console.log('search', )
                window.location.href = `/search?q=${search}&type=${type}&expansion=${expansion}&order=${order}&page=1`;
              }}
              style={{
                background: "rgba(1,33,105,0.05)",
                padding: "40px 40px",
                width: "100%",
                borderRadius: 8,
              }}
            >
              <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                {/* <Col>
                  <p>
                    <b>Type</b>
                  </p>
                  <Form.Select
                    aria-label="Default select example"
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                  >
                    <option>Choisir</option>
                    <option value="1">Carte</option>
                    <option value="2">Enchère</option>
                    <option value="3">Bases de Deck</option>
                  </Form.Select>
                </Col> */}

                {/* <Col>
                  <p>
                    <b>Extension</b>
                  </p>
                  <Form.Select
                    aria-label="Default select example"
                    value={expansion}
                    onChange={(e) => {
                      setExpansion(e.target.value);
                    }}
                  >
                    <option>Choisir</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </Col> */}
                <Col>
                  <p>
                    <b>Nom ou code extension</b>
                  </p>
                  <Form.Control
                    placeholder="Nom ou code extension"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </Col>
                <Col>
                  <p>
                    <br />
                  </p>

                  <Button variant="dark" type="submit">
                    Rechercher
                  </Button>
                </Col>
              </Row>
              <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                <Col  >
                  <p>
                    <br />
                    <b>Afficher par</b>
                  </p>
                  <Form.Select
                    aria-label="Default select example"
                    value={order}
                    onChange={(e) => {
                      setOrderBy(e.target.value);
                    }}
                  >
                    <option value="0">Prix Croissant</option>
                    <option value="1">Prix Décroissant</option>
                    <option value="2">A-Z</option>
                    <option value="3">Z-A</option>
                  </Form.Select>
                </Col>
              </Row>
            </Form>
          </div>
          <div class="row row-cols-2 row-cols-sm-2 row-cols-md-6 g-3 py-3">
            {resultSearchs().length === 0 && search.length > 0 && (
              <p>Aucun résultat.</p>
            )}
            <PaginatedItems items={resultSearchs()} itemsPerPage={12} />
          </div>
        </div>
        {/* <div class="container">
          <ul class="pager">
            {page > 1 && (
              <li>
                <a
                  href={`/search?q=${search}&type=${type}&expansion=${expansion}&order=${order}&page=${
                    page - 1 > 0 ? page - 1 : 1
                  }`}
                >
                  Précedent
                </a>
              </li>
            )}
            <li>page {page}</li>
            {page < maxPage && (
              <li>
                <a
                  href={`/search?q=${search}&type=${type}&expansion=${expansion}&order=${order}&page=${
                    page + 1 > maxPage ? maxPage : page + 1
                  }`}
                >
                  Suivante
                </a>
              </li>
            )}
          </ul>
        </div> */}
      </div>
    </>
  );
};
export default ResultSearch;
