import React from "react";
import "../App.css";

const MentionLegales = () => {
  return (
    <>
      <div
        class="album py-5 bg-light"
        style={{
          boxShadow:
            "inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15)",
        }}
      >
        <div class="container">
          <h1>Mention Legales</h1>
          <hr />
          <ul class="nav flex-column">
            <li class="nav-item mb-2">
              <a href="/conditions-generales" class="nav-link p-0 text-muted">
                {"Conditions générales >"}
              </a>
            </li>
            <li class="nav-item mb-2">
              <a href="/confidentialite" class="nav-link p-0 text-muted">
                {"Déclaration de protection des données >"}
              </a>
            </li>
            <li class="nav-item mb-2">
              <a href="/conditions-revocation" class="nav-link p-0 text-muted">
                {"Conditions de révocation >"}
              </a>
            </li>
            <li class="nav-item mb-2">
              <a href="/commissions" class="nav-link p-0 text-muted">
                {"Tableau de Commissions >"}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default MentionLegales;
