import React from "react";
import ReactN from "reactn";
import { useHistory } from "react-router-dom";

import { App } from "../firebase";
import "../App.css";
import "../styles/cart.css";


const ValidAdress = () => {
  const history = useHistory();
  const cart = history?.location?.state?.cart || null;
  const panier = history?.location?.state?.panier || null;
  // console.log("data", cart);

  if (cart === null || panier === null) {
    history.push("/panier");
  }
  // user
  const [user, setUser] = ReactN.useGlobal("user");
  // console.log("user", user);

  const totalsub = () => {
    let t = 0;
    cart.forEach((element) => {
      t = Number(t) + Number(element?.subPrice);
    });
    return t;
  };

  const totalLivraison = () => {
    let t = 0;
    cart.forEach((element) => {
      if (element?.modeLivraison !== "mainpropre") {
        t = Number(t) + Number(element?.shipPrice);
      }
    });
    return t;
  };

  const [loadsa, setLoadSa] = React.useState(false);
  const goPay = () => {
    if (loadsa) {
      return;
    }
    setLoadSa(true);


    let livraisons = []; 

    cart.forEach((c) => {
      if (c?.modeLivraison !== "mainpropre") {
        livraisons.push({uid: c.uid, livraison : Number(c?.shipPrice)})
      }
    }); 
    App.firestore()
        .collection("carts")
        .doc(panier.id)
        .update({ livraisons: livraisons })
        .then(() => {
          history.push({
            pathname: "/pay",
            state: {
              cart: cart,
              panier: panier,
            },
          });
        }).catch((err) => {
          setLoadSa(false);
          console.log("err", err);
          alert("Une erreur s'est produite, contactez nous.");
        });
    
  };

  return (
    <>
      <div class="container padding-bottom-3x mb-1">
        <div class=" mb-3">
          <div class="card-body">
            <div class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
              <div class="step completed">
                <a href="/panier">
                  <div class="step-icon-wrap">
                    <div class="step-icon">
                      <i class="pe-7s-cart"></i>
                    </div>
                  </div>
                </a>
                <h4 class="step-title">Panier</h4>
              </div>
              <div class="step completed">
                <div class="step-icon-wrap">
                  <div class="step-icon">
                    <i class="pe-7s-config"></i>
                  </div>
                </div>
                <h4 class="step-title">Validation de l'adresse</h4>
              </div>
              <div class="step ">
                <div class="step-icon-wrap">
                  <div class="step-icon">
                    <i class="pe-7s-medal"></i>
                  </div>
                </div>
                <h4 class="step-title">Paiement</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container marketing px-3 py-3 ">
        <div class="row">
          <div class="card-body">
            <div class="row justify-content-center">
              <div class="col-lg-10 col-xl-8">
                <div class="card" style={{ padding: 20, marginBottom: 30 }}>
                  <h6 class="card-title mb-3">Adresse de facturation</h6>
                  <p>
                    <div class="d-flex justify-content-between mb-1 small">
                      <span>
                        {user?.address?.name}
                        {user?.address?.complement && <br />}
                        {user?.address?.complement}
                        {user?.address?.city && <br />}
                        {user?.address?.zipcode} {user?.address?.city}
                        {user?.address?.country && <br />}
                        {user?.address?.country}{" "}
                        <a href="/tableau-de-bord#profil">
                          {user?.address
                            ? "Changer l'adresse"
                            : "Ajouter l'adresse"}
                        </a>
                        <br />
                        {user?.phone}{" "}
                        <a href="/tableau-de-bord#profil">
                          {user?.phone
                            ? "Changer le numéro"
                            : "Ajouter votre numéro"}
                        </a>
                      </span>
                    </div>
                  </p>
                </div>

                <div class="card" style={{ padding: 20, marginBottom: 30 }}>
                  <h6 class="card-title mb-3">Adresse de livraison</h6>
                  <p>
                    <div class="d-flex justify-content-between mb-1 small">
                      <span>
                        {user?.location?.name}
                        {user?.location?.complement && <br />}
                        {user?.location?.complement}
                        {user?.location?.city && <br />}
                        {user?.location?.zipcode} {user?.location?.city}
                        {user?.location?.country && <br />}
                        {user?.location?.country}{" "}
                        <a href="/tableau-de-bord#profil">
                          {user?.location
                            ? "Changer l'adresse"
                            : "Ajouter l'adresse si différente de l'adresse de facturation"}
                        </a>
                      </span>
                    </div>
                  </p>
                </div>
              </div>
              <div class="col-lg-2 col-xl-4">
                <div class="card position-sticky top-0">
                  <div class="p-3 bg-light bg-opacity-10">
                    <h6 class="card-title mb-3">Résumé de la commande</h6>
                    <div class="d-flex justify-content-between mb-1 small">
                      <span>Sous-total</span>{" "}
                      <span>{totalsub().toFixed(2)}€</span>
                    </div>
                    <div class="d-flex justify-content-between mb-1 small">
                      <span>Livraison</span>{" "}
                      <span>{totalLivraison().toFixed(2)}€</span>
                    </div>
                    <hr />
                    {/* <div class="d-flex justify-content-between mb-1 small">
                      <span>
                        Frais de service{" "}
                        <span style={{ fontSize: 10 }}>(7)</span>
                      </span>{" "}
                      <span>$20.00</span>
                    </div> */}
                    <div class="d-flex justify-content-between mb-4 small">
                      <span>TOTAL</span>{" "}
                      <strong class="text-dark">
                        {Number(totalsub() + totalLivraison()).toFixed(2)}€
                      </strong>
                    </div>
                    {user?.address && (
                      <a class="btn btn-primary w-100 mt-2" onClick={goPay}>
                        {loadsa ? "Chargement" : "Procéder au paiement"}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ValidAdress;
