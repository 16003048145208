/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import ReactN from "reactn";
import { useHistory } from "react-router-dom";
import { CardElement,  useStripe, useElements } from "@stripe/react-stripe-js";

import "../App.css";
import "../styles/cart.css";
import { API_URL } from "../constant";
import Button from "react-bootstrap/Button";
import { App } from "../firebase";
import Alert from "react-bootstrap/Alert"; 


const Pay = () => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const cart = history?.location?.state?.cart || null;
  const panier = history?.location?.state?.panier || null;
  const [user, setUser] = ReactN.useGlobal("user");
  const [loading, setLoading] = React.useState(false);
  const [cardBank, setCards] = React.useState([]);
  const [useCard, setShowCard] = React.useState(null);
  const [loadCard, setLoadCard] = React.useState(false);
  const [useNewCard, setUseNewCard] = React.useState(false);
  const [error, setError] = useState(null);
  // const [clientSecret, setClientSecret] = React.useState('');
  const appearance = {
    theme: 'stripe',
  };
  const options = {
    // clientSecret,
    appearance,
  };
  // console.log("cart", cart);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      setError(error.message);
    } else {
      // Send the token to your server to create a new card for the customer
      console.log('Token created:', token);
      // Here you can send the token to your server to associate it with the customer
      // and create a new card for the customer in Stripe
    }
  };

  const getCardsUser = async (customerId = user.customerStripeId) => {
    setCards([]);
    setLoadCard(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      customerId: customerId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(API_URL + "list-method-card", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data.cards && data.cards?.length > 0) {
          // console.log("cardBank", data.cards);
          setCards(data.cards);
          setUseNewCard(false);
        } else {
          setCards([]);
          setUseNewCard(true);
        }
        setLoadCard(false);
      })
      .catch((err) => {
        setLoadCard(false);
        console.log(err);
        setCards([]);
        alert("Une erreur s'est produite");
      });
  };

  if (cart === null || panier === null) {
    history.push("/panier");
  }

  const totalLivraison = () => {
    let t = 0;
    cart?.forEach((element) => {
      if (element?.modeLivraison !== "mainpropre") {
        t = Number(t) + Number(element?.shipPrice);
      }
    });
    return t;
  };

  const totalsub = () => {
    let t = 0;
    cart?.forEach((element) => {
      t = Number(t) + Number(element?.subPrice);
    });
    return t;
  };

  const addCardIt = (token, cusId = null, payWith) => {
    if (loading){
      return
    }
    if (!token.id){
      alert("Votre carte est invalide")
      return
    }
    setLoading(true);
    fetch(API_URL + "payment-method-card", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tcard: token.id,
        customerId: cusId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.id) {
          // alert("Carte ajoutée");
          payWith(data.id);
        } else {
          // console.log("data", data);
          setLoading(false);
          if (data.error) {
            if (data.error.raw.message === "Your card number is incorrect.") {
              alert("Le numéro de carte est incorrect");
            } else {
              alert(data.error.raw.message);
            }
          } else {
            alert("Une erreur s'est produite");
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        alert(err.error.raw.message);
      });
  };
  const [pId , setPid] = useState(null);
  function on3DSComplete() {
    // Hide the 3DS UI 
    // Check the PaymentIntent
    fetch(API_URL + "watch", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cartId: panier.id,
        userId: App.auth().currentUser?.uid,
      }),
    })
      .then((res) => res.json())
      .then(function (response) {
        // console.log("on3DSComplete", response )
        if (response.success) {
          if (response.cart) {
            if (response.cart.status === "paid") {
              let livraison = [];

              cart?.forEach((c) => {
                if (c?.shipPrice && c?.uid) {
                  livraison.push({ shipPrice: c?.shipPrice, uid: c?.uid });
                }
              });

              fetch(API_URL + "transfert", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ 
                  userId: user.customerStripeId,
                  cartId: panier?.id, 
                  uid: user.id, 
                  livraison: livraison
                }),
              })
                .then((res) => res.json())
                .then((data) => {
                  console.log('transfert', data)
                  //   finish paid 
                  
                  document.getElementById('iframe-container').innerHTML = "";
                  history.push({
                    pathname: "/success",
                    state: {
                      pId: panier?.id,
                      link: null,
                      cart: cart,
                      panier: panier,
                    },
                  });
                }).catch((err) => {
                  console.log('err no transfert', err)
                  //   finish paid 
                  document.getElementById('iframe-container').innerHTML = "";
                  history.push({
                    pathname: "/success",
                    state: {
                      pId: panier?.id,
                      link: null,
                      cart: cart,
                      panier: panier,
                    },
                  });
                })
              
            } else { 
              alert("Le paiement a été refuser par votre banque");
              document.getElementById('iframe-container').innerHTML = "";
            }
          }
        }else {
          alert(response.error);
        }

      }).catch(function (error) {
        console.log("People we have an error!", error);
        alert(error.error);
      });
  }


  useEffect(()=>{
    window.addEventListener('message', function(ev) {
      if (ev.data === '3DS-authentication-complete') {
        on3DSComplete();
      }
    }, false);
  }, [])

  const payIt = (cardId) => {
    setLoading(true);
    // alert(`pay with ${cardId}`);

    let livraison = [];

    cart?.forEach((c) => {
      // console.log("cart", cart)
      if (c?.shipPrice && c?.uid && c?.modeLivraison != "mainpropre") {
        livraison.push({ shipPrice: c?.shipPrice, uid: c?.uid });
      }
    });

    const { hostname, protocol, port } = window.location;

    // Combine the hostname, protocol, and port
    let locationWithoutPathname = protocol + '//' + hostname;
    if (port) {
        locationWithoutPathname += ':' + port;
    }
    // console.log("livraison", livraison) 
    fetch(API_URL + "pay", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cardId: cardId,
        userId: user.customerStripeId,
        cartId: panier?.id,
        livraison: livraison,
        uid: user.id,
        return_url : locationWithoutPathname + "/3dsecure"
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("data", data);
        if (data.success) {
          // var link;
          //afficher le 3D secure
          if (data.payment.next_action) {
            let next_action = data.payment.next_action;
            if (next_action.use_stripe_sdk) {
              const link = next_action.use_stripe_sdk.stripe_js;
              // window.open(link, "_newtab"); 
              if (link != undefined){
                const strWindowFeatures =
                "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes";
              window.open(
                next_action.use_stripe_sdk.stripe_js,
                "CNN_WindowName",
                strWindowFeatures
              );
              history.push({
                pathname: "/success",
                state: {
                  pId: panier?.id,
                  link: link,
                  cart: cart,
                  panier: panier,
                },
              });
              }else{
                // v2 3d secure
                alert("Une erreur s'est produite avec 3D secure v2, , recharger la page et réessayer");
              }
              
            }else{ 
              // alert("Une erreur s'est produite");
              if (next_action.type === "redirect_to_url"){
                const stripe3DSecureURL = next_action.redirect_to_url.url;
                setPid(data.payment?.id)
                // Create iframe element
                const iframe = document.createElement('iframe');
                iframe.id = 'secure-iframe';

                // const iframe2= document.createElement('iframe');
                // iframe2.id = 'secure-iframe-s';
                iframe.src = stripe3DSecureURL; 
                // Append iframe to container
                document.getElementById('iframe-container').appendChild(iframe); 
              }else{
                alert("Une erreur s'est produite avec votre paiement, recharger la page et réessayer.");
              }
            }
          } else {
            // c'est payer...
            history.push({ pathname: "/success", state: { pId: panier?.id } });
          }
          // redirection vers la page de validation
          // setTimeout(() => {
          //   history.push({
          //     pathname: "/booking-recap/" + id + "?pay=checking",
          //     state: {
          //       next_action: link,
          //     },
          //   });
          setLoading(null);
          // }, 1500);
        } else {
          setLoading(null);
          alert("Une erreur s'est produite, recharger la page et réessayer");
        }
      })
      .catch((err) => {
        setLoading(null);
        console.log(err);
        alert("Une erreur s'est produite, recharger la page et réessayer.");
      });
  };

  const makePayment = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    
    if (user) {
      if (useCard != null && useCard?.cardId) {
        payIt(useCard?.cardId);
      } else {
        if (useNewCard){
          if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            alert("Une erreur s'est produite.");
            return;
          }
          const cardElement = elements.getElement(CardElement);
          const { error, token } = await stripe.createToken(cardElement);
          if (error) {
            setError(error.message);
          } else {
            // Send the token to your server to create a new card for the customer
            // console.log('Token created:', token); 
            // Here you can send the token to your server to associate it with the customer
            // and create a new card for the customer in Stripe
            addCardIt(token, user.customerStripeId, (cId) => {
              payIt(cId); 
            });
          }
        } else{
          alert("Une erreur s'est produite.");
        }
        
      }
    } else {
      console.log("user", user);
      alert("Une erreur s'est produite.");
    }
  };

  function BankCard({ data, getCardsUser }) {
    const selectThisCard = async (cardId, number) => {
      // eslint-disable-next-line no-restricted-globals 
      setShowCard({ cardId: cardId, number: number });
    };

    if (data.card.exp_year < new Date().getFullYear()) {
      return;
    }
    return (
      <Alert>
        <Alert.Heading>Visa</Alert.Heading>
        <p>**** **** **** {data.card.last4}</p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => selectThisCard(data.id, data.card.last4)}
            variant="outline-success"
          >
            Choisir pour payer
          </Button>
        </div>
      </Alert>
    );
  }

  const sSCard = () => {
    if (user) {
      getCardsUser(user.customerStripeId);
    } else {
      getUser(App.auth().currentUser?.uid, (u) => {
        if (u){ 
          setUser(u); 
          getCardsUser(u.customerStripeId);
        } else{
          getUser(App.auth().currentUser?.uid, (u) => {
            if (u){ 
              setUser(u); 
              getCardsUser(u.customerStripeId);
            } else{
              alert('Une erreur s\'est produite lors de la récupération des informations personnelle, essayer de recharger la page.')
            }
          });
        }
      });
    }
  };
  const getUser = async (uid, cb) => {
    await App.firestore()
      .collection("users")
      .doc(uid)
      .get()
      .then(async (docd) => {
        var user = docd.data();
        user.id = docd.id;
        cb(user);
      })
      .catch((error) => {
        cb(null);
      });
  };
  useEffect(() => {
    sSCard();
  }, []);

  return (
    <>
      <div class="container padding-bottom-3x mb-1">
        <div class=" mb-3">
          <div class="card-body">
            <div class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
              <div class="step completed">
                <a href="/panier">
                  <div class="step-icon-wrap">
                    <div class="step-icon">
                      <i class="pe-7s-cart"></i>
                    </div>
                  </div>
                </a>
                <h4 class="step-title">Panier</h4>
              </div>
              <div class="step completed">
                <a
                  href="#"
                  onClick={() => {
                    history.push({
                      pathname: "/confirm-adress",
                      state: {
                        cart: cart,
                        panier: panier,
                      },
                    });
                  }}
                >
                  <div class="step-icon-wrap">
                    <div class="step-icon">
                      <i class="pe-7s-config"></i>
                    </div>
                  </div>
                </a>
                <h4 class="step-title">Validation de l'adresse</h4>
              </div>
              <div class="step completed">
                <div class="step-icon-wrap">
                  <div class="step-icon">
                    <i class="pe-7s-medal"></i>
                  </div>
                </div>
                <h4 class="step-title">Paiement</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <form onSubmit={makePayment}>
          <div class="row">
            <div class="col-lg-9">
              <div class="accordion" id="accordionPayment">
                <div class="accordion-item mb-3">
                  <h2 class="h5 px-4 py-3 accordion-header d-flex justify-content-between align-items-center">
                    <div
                      class="  w-100 collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseCC"
                      aria-expanded="false"
                    >
                      {/* <input class="form-check-input" type="radio" name="payment" id="payment1"/> */}
                      <label class="form-check-label pt-1" for="payment1">
                        Paiement par carte
                      </label>
                    </div>
                    <span>
                      <svg
                        width="34"
                        height="25"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill-rule="nonzero" fill="#333840">
                          <path d="M29.418 2.083c1.16 0 2.101.933 2.101 2.084v16.666c0 1.15-.94 2.084-2.1 2.084H4.202A2.092 2.092 0 0 1 2.1 20.833V4.167c0-1.15.941-2.084 2.102-2.084h25.215ZM4.203 0C1.882 0 0 1.865 0 4.167v16.666C0 23.135 1.882 25 4.203 25h25.215c2.321 0 4.203-1.865 4.203-4.167V4.167C33.62 1.865 31.739 0 29.418 0H4.203Z"></path>
                          <path d="M4.203 7.292c0-.576.47-1.042 1.05-1.042h4.203c.58 0 1.05.466 1.05 1.042v2.083c0 .575-.47 1.042-1.05 1.042H5.253c-.58 0-1.05-.467-1.05-1.042V7.292Zm0 6.25c0-.576.47-1.042 1.05-1.042H15.76c.58 0 1.05.466 1.05 1.042 0 .575-.47 1.041-1.05 1.041H5.253c-.58 0-1.05-.466-1.05-1.041Zm0 4.166c0-.575.47-1.041 1.05-1.041h2.102c.58 0 1.05.466 1.05 1.041 0 .576-.47 1.042-1.05 1.042H5.253c-.58 0-1.05-.466-1.05-1.042Zm6.303 0c0-.575.47-1.041 1.051-1.041h2.101c.58 0 1.051.466 1.051 1.041 0 .576-.47 1.042-1.05 1.042h-2.102c-.58 0-1.05-.466-1.05-1.042Zm6.304 0c0-.575.47-1.041 1.051-1.041h2.101c.58 0 1.05.466 1.05 1.041 0 .576-.47 1.042-1.05 1.042h-2.101c-.58 0-1.05-.466-1.05-1.042Zm6.304 0c0-.575.47-1.041 1.05-1.041h2.102c.58 0 1.05.466 1.05 1.041 0 .576-.47 1.042-1.05 1.042h-2.101c-.58 0-1.05-.466-1.05-1.042Z"></path>
                        </g>
                      </svg>
                    </span>
                  </h2>
                  {/* <div id="collapseCC" class="accordion-collapse collapse show" data-bs-parent="#accordionPayment" style=""> */}
                  <div class="accordion-body">
                    {loadCard ? (
                      <p>Chargement ....</p>
                    ) : (
                      <>
                        {cardBank.length > 0 && !useNewCard && (
                          <Button
                            variant="btn-link"
                            onClick={() => {
                              setShowCard(null);
                              setUseNewCard(true);
                            }}
                          >
                            Utiliser une nouvelle carte
                          </Button>
                        )}
                      </>
                    )}
                    {!useNewCard &&
                      cardBank.map((data) => {
                        return (
                          <BankCard data={data} getCardsUser={getCardsUser} />
                        );
                      })}
                    {useNewCard && (
                      <>
                        {cardBank.length > 0 && (
                          <h6>Utiliser une nouvelle Carte</h6>
                        )}

                  {/* <form onSubmit={handleSubmit}> */}
                        <div style={{ marginBottom: '20px' }}>
                          <CardElement />
                        </div>
                        {error && <div style={{ color: 'red' }}>{error}</div>}
                        {/* <button type="submit" disabled={!stripe}>
                          Add Card
                        </button> */}
                      {/* </form> */}
                        {/* <div class="mb-3">
                          <label class="form-label">Numéro de carte</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="N° de carte"
                            name="cardnumber"
                            minLength="16"
                            maxLength="16"
                            required
                            disabled={loading}
                          />
                        </div>

                        <div class="row">
                          <div class="col-lg-6">
                            <div class="mb-3">
                              <label class="form-label">
                                Mois d'expiration
                              </label>
                              <input
                                class="form-control"
                                type="number"
                                id="expmonth"
                                name="expmonth"
                                min={1}
                                max={12}
                                placeholder="(1 - 12)"
                                required
                                disabled={loading}
                              />
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="mb-3">
                              <label class="form-label">
                                Année d'éxpiration
                              </label>
                              <input
                                class="form-control"
                                type="number"
                                id="expyear"
                                name="expyear"
                                min={2024} 
                                placeholder="YYYY"
                                required
                                disabled={loading}
                              />
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="mb-3">
                              <label class="form-label">CVV</label>
                              <input
                                type="text"
                                name="cvv"
                                placeholder="CVV"
                                minLength={3}
                                required
                                disabled={loading}
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div> */}
                      </>
                    )}
                  </div>
                </div>
                {/* </div>   */}
              </div>
            </div>
            <div class="col-lg-3">
              <div class="card position-sticky top-0">
                <div class="p-3 bg-light bg-opacity-10">
                  <h6 class="card-title mb-3">Résumé de la commande</h6>
                  <div class="d-flex justify-content-between mb-1 small">
                    <span>Nombre de sous-commande</span>{" "}
                    <span>{cart?.length || "0"}</span>
                  </div>
                  <div class="d-flex justify-content-between mb-1 small">
                    <span>Sous-total</span>
                    <span>{totalsub().toFixed(2)}€</span>
                  </div>
                  <div class="d-flex justify-content-between mb-1 small">
                    <span>{totalLivraison() > 0 && 'Livraison'}</span>
                    <span>{totalLivraison() > 0 ? `${totalLivraison().toFixed(2)}` : "Main propre"}</span>
                  </div>
                  {/* <div class="d-flex justify-content-between mb-1 small">
                  <span>Coupon (Code: NEWYEAR)</span>{" "}
                  <span class="text-danger">-$10.00</span>
                </div> */}
                  <hr />
                  <div class="d-flex justify-content-between mb-4 small">
                    <span>TOTAL</span>{" "}
                    <strong class="text-dark">
                      {Number(totalsub() + totalLivraison()).toFixed(2)}€
                    </strong>
                  </div>
                  <div class="form-check mb-1 small">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="tnc"
                      required
                      disabled={loading}
                    />
                    <label class="form-check-label" for="tnc">
                      J'accepte les{" "}
                      <a href="/conditions-generales" target="_blank">
                        termes et conditions
                      </a>
                    </label>
                  </div>
                  {user && user.newsletter ? (
                    <div />
                  ) : (
                    <div class="form-check mb-3 small">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        name="newsletter"
                      />
                      <label class="form-check-label" for="subscribe">
                        Recevez la newsletter sur les mises à jour des produits
                        et les événements. Si vous changez d'avis, vous pouvez
                        vous désinscrire à tout moment dans le bas de page du
                        site.{" "}
                        <a href="/confidentialite" target="_blank">
                          Confidentialité et protection des données
                        </a>
                      </label>
                    </div>
                  )}

                  {/* href="/success" */}
                  <input
                    type="submit"
                    disabled={
                      (!loadCard & (cardBank.length >= 0) &&
                        !useNewCard &&
                        useCard?.cardId === undefined) ||
                      loadCard ||
                      loading
                    }
                    class="btn btn-success w-100 mt-2"
                    value={
                      loading
                        ? "Chargement ..."
                        : useCard != null
                        ? `Payer avec *${useCard.number}`
                        : "Payer"
                    }
                  />

                  <p style={{ textAlign: "center" }}>
                    <span style={{ fontSize: 10, color: "green" }}>
                      <i class="pe-7s-lock"></i> Paiement sécurisé avec stripe
                      et 3D secure
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form> 
      </div>


    </>
  );
};
export default Pay;
