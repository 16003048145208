import { useHistory } from "react-router-dom";

export const Item = ({ data }) => {
  const history = useHistory();

  function getEx() {
    if (data?.expansion && data?.card?.card_sets) {
      var d = null;
      data?.card?.card_sets.forEach((e) => {
        if (e.set_name === data?.expansion) d = e;
      });
      return d;
    } else return null;
  }

  function getCode() {
    var d = getEx()?.set_code || "";
    if (data?.lang === "fr") {
      return d.replace("EN", data?.lang.toUpperCase());
    } else {
      return d;
    }
  }

  return (
    <div class="col">
      <a
        class="card shadow-sm btn btn-sm btn-outline"
        href="#"
        onClick={() => {
          history.push({
            pathname: `/product/${getCode().split("-")[0]}/${
              getCode().split("-")[1]
            }/${data?.id}`,
            state: {
              data: data,
            },
          });
        }}
      >
        <img
          width="100%"
          style={{
            justifyItems: "center",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          className="d-block"
          src={data?.card?.card_images[0]?.image_url}
          alt={data?.card?.name}
        />
        <div
          class="card-body"
          style={{
            paddingRight: 0,
            paddingLeft: 0,
          }}
        >
          <h6 class="price-container" style={{ height: "55px" }}>
            {data?.lang === "fr" ? data?.card?.name : data?.card?.name_en}
            {data?.lang !== "fr" && " (EN)"}
          </h6>
          <h4 class="price-container">{data?.price} €</h4>
          <h6 class="price-container">{getCode().split("-")[0]}</h6>
        </div>
        <p
          style={{
            position: "absolute",
            backgroundColor: "black",
            top: 10,
            padding: 5,
            borderRadius: 17.5,
            width: 35,
            height: 35,
            justifyContent: "center",
            alignItems: "center",
            left: -10,
            color: "white",
            fontWeight: "bold",
          }}
        >
          {data?.qte < 10 && "x"}
          {data?.qte}
        </p>
      </a>
    </div>
  );
};
