import React, { useEffect } from "react"; 
const DSecure = () => {
   

   useEffect(()=>{
    window.top.postMessage('3DS-authentication-complete');
   }, [])

  return (
    <>
       <p style={{textAlign:'center', paddingTop: 100, paddingBottom: 100}}>Chargement en cours...</p>
    </>
  );
};
export default DSecure;
