import React, { useEffect } from "react";
import "../App.css";
import { useForm } from "react-hook-form";
import { App } from "../firebase";
import { useHistory } from "react-router-dom";
import { API_URL } from "../constant";

const Action3 = () => {
  const db = App.firestore();
  const history = useHistory();

  const [loadingUser, setLoadingUser] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  App.auth().languageCode = "fr";

  const redirection = (user) => {
    const redi = localStorage.getItem("redirectAfterlogin");
    if (redi) {
      window.location.href = redi;
      localStorage.removeItem("redirectAfterlogin");
      return;
    }
    switch (user?.role) {
      case "vendor":
        history.push("/tableau-de-bord#dashboard");
        break;
      case "pendingvendor":
        history.push("/tableau-de-bord#becomevendor");
        break;

      default:
        // redirection à l'accueil
        history.push("/");
        break;
    }
  };

  useEffect(() => {
    // check if user is login
    setLoadingUser(true);
    App.auth().onAuthStateChanged((u) => {
      if (u) {
        setLoadingUser(true);
        //redirection au compte
        getUser(u.uid, (user) => {
          redirection(user);
        });
      } else {
        setLoadingUser(false);
      }
    });
    setTimeout(() => {
      setLoadingUser(false);
    }, 5000);
  }, []);

  const getUser = async (uid, cb) => {
    await App.firestore()
      .collection("users")
      .doc(uid)
      .get()
      .then(async (docd) => {
        var user = docd.data();
        user.id = docd.id;
        cb(user);
      })
      .catch((error) => {
        cb(null);
      });
  };

  const { register, handleSubmit, setValue } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });

  const signUp = (value) => {
    if (loading) {
      return;
    }
    if (value.name == null || value.name.trim() === "") {
      alert("Votre nom est obligatoire");
      return;
    }
    if (value.firstname == null || value.firstname.trim() === "") {
      alert("Votre prénom est obligatoire");
      return;
    }
    if (value.mail == null || value.mail.trim() === "") {
      alert("Votre email est obligatoire");
      return;
    }
    if (value.password !== value.confirmPassword) {
      alert("Vos mot de passe ne correspondent pas.");
      return;
    }
    setLoading(true);
    App.auth()
      .createUserWithEmailAndPassword(value.mail, value.password)
      .then((res) => {
        save(value);
      })
      .catch((err) => {
        setLoading(false);
        //do something with the error
        console.log("signUp catch", err.code);
        if (err.code === "auth/email-already-in-use") {
          alert("Cette email est déjà enregistrer, connectez-vous.");
        } else {
          alert("Une erreur s'est produite, réessayer plus tard.");
        }
      });
  };
  const sendEmailWelcome = (email, displayName, isvendor, newsletter) => {
    alert("Bienvenue sur UNITY CARD.");

    fetch(`${API_URL}welcome`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // eslint-disable-next-line object-shorthand
        email: email,
        displayname: displayName,
        isvendor: isvendor,
        newsletter: newsletter,
      }),
    }) 

    setLoading(false);
  };

  async function save(value) {
    const { currentUser: fuser } = App.auth();
    App.auth().languageCode = "fr";
    setLoading(true);
    const displayName = `${value.name.trim()} ${value.firstname.trim()}`;
    db.collection("users")
      .doc(fuser.uid)
      .set({
        uid: fuser.uid,
        email: fuser.email,
        isAdmin: false,
        prenom: value.firstname.trim(),
        displayName: displayName,
        nom: value.name.trim(),
        newsletter: value.newsletters === true ? true : false,
        role: value.registerCheckVendor ? "pendingvendor" : "customer",
        pending_vendor_id: null,
        _create_At: new Date(),
        _update_At: new Date(),
      })
      .then(async () => {
        // displayName
        App.auth().currentUser.updateProfile({
          displayName: displayName,
        });
        if (value.newsletters === true) {
          db.collection("newsletters")
            .doc(fuser.uid)
            .set({
              email: fuser.email,
              newsletter: true,
              _create_At: new Date(),
              _update_At: new Date(),
              last_send: null,
              uid: fuser.uid,
            })
            .catch((err) => {
              console.log("error newsletters", err);
            });
        }
        if (value.registerCheckVendor) {
          //sendEmail vérification
          App.auth()
            .currentUser.sendEmailVerification({
              url: window.location.origin + "/tableau-de-bord#dashboard",
            })
            .then(() => {
              alert("Email de vérification envoyé !");
            });
        }

        fetch(API_URL + "create-customer", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: fuser.email }),
        })
          .then((res) => res.json())
          .then((data) => {
            db.collection("users").doc(fuser.uid).update({
              customerStripeId: data.id,
              _update_At: new Date(),
            });
          });
        setTimeout(() => {
          sendEmailWelcome(
            fuser.email,
            displayName,
            value.registerCheckVendor,
            value.newsletters
          );
        }, 350);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", JSON.stringify(error));
        alert("Une erreur s'est produite, réessayer plus tard");
      });
  }

  return (
    <>
      <div
        class="tab-content"
        style={{ width: 400, paddingTop: "5%", paddingBottom: "5%" }}
      >
        {loadingUser ? (
          <div
            class="tab-pane fade show active"
            id="pills-login"
            role="tabpanel"
            aria-labelledby="tab-login"
          >
            <p style={{ textAlign: "center" }}>Chargement ... </p>
          </div>
        ) : (
          <div
            class="tab-pane fade show active"
            id="pills-login"
            role="tabpanel"
            aria-labelledby="tab-login"
          >
            <form
              onSubmit={handleSubmit(signUp, (err) => {
                console.log(err);
              })}
            >
              <div class="text-center mb-3 p-4">
                <h2>S'inscrire</h2>
              </div>

              <div class="form-outline mb-4">
                <label class="form-label" for="name">
                  Nom
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  class="form-control"
                  required
                  onChange={(e) => setValue("name", e.target.value)}
                  ref={register("name")}
                />
              </div>

              <div class="form-outline mb-4">
                <label class="form-label" for="firstname">
                  Prénom
                </label>
                <input
                  type="text"
                  id="firstname"
                  name="firstname"
                  class="form-control"
                  required
                  onChange={(e) => setValue("firstname", e.target.value)}
                  ref={register("firstname")}
                />
              </div>

              <div class="form-outline mb-4">
                <label class="form-label" for="email">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  class="form-control"
                  required
                  onChange={(e) => setValue("mail", e.target.value)}
                  ref={register("mail")}
                />
              </div>

              <div class="form-outline mb-4">
                <label class="form-label" for="password">
                  Mot de passe
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  minLength={8}
                  class="form-control"
                  required
                  onChange={(e) => setValue("password", e.target.value)}
                  ref={register("password")}
                />
              </div>

              <div class="form-outline mb-4">
                <label class="form-label" for="confirmPassword">
                  Confirmer le mot de passe
                </label>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  class="form-control"
                  minLength={8}
                  required
                  onChange={(e) => setValue("confirmPassword", e.target.value)}
                  ref={register("confirmPassword")}
                />
              </div>

              <div class="form-check d-flex ">
                <input
                  class="form-check-input me-2"
                  type="checkbox"
                  name="registerCheckVendor"
                  id="registerCheckVendor"
                  aria-describedby="Cochez si vous souhaitez devenir vendeur sur la plateforme et être diriger vers l'espace de création de compte vendeur"
                  onChange={(e) =>
                    setValue("registerCheckVendor", e.target.checked)
                  }
                  ref={register("registerCheckVendor")}
                />
                <label class="form-check-label" for="registerCheckVendor">
                  Je suis vendeur
                </label>
              </div>
              <div class="form-check d-flex  ">
                <input
                  class="form-check-input me-2"
                  type="checkbox"
                  id="newsletters"
                  name="newsletters"
                  onChange={(e) => setValue("newsletters", e.target.checked)}
                  ref={register("newsletters")}
                />

                <label class="form-check-label" for="newsletters">
                  Je souhaite recevoir les newsletters
                  <span style={{ fontSize: 10 }}>(6)</span>
                </label>
              </div>
              <div class="form-check d-flex  mb-4">
                <input
                  class="form-check-input me-2"
                  type="checkbox"
                  id="registerCheck"
                  required
                  name="registerCheck"
                  onChange={(e) => {
                    setValue("registerCheck", e.target.checked);
                  }}
                  ref={register("registerCheck")}
                />

                <label class="form-check-label" for="registerCheck">
                  J'ai lu et j'accepte les{" "}
                  <a target={"_blank"} href="/conditions-generales">
                    conditions générales de vente
                  </a>
                </label>
              </div>

              <button
                type="submit"
                class="btn btn-dark btn-block mb-3"
                disabled={loading}
              >
                {loading ? "Chargement ..." : "S'inscrire"}
              </button>
            </form>
            <div class="text-center">
              <p>
                Déjà un compte ? <a href="/connexion">Connexion</a>
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const Register = ({ navigation }) => (
  <div class="container  p-4 d-flex justify-content-center">
    <Action3 />
  </div>
);
export default Register;
