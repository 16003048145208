import React from "react";
import "../App.css";

const Action3 = () => {
  return (
    <>
      <div class="container marketing px-3 py-3 ">
        <div class="row featurette">
          <div class="col-md-7">
            <h2 class="featurette-heading fw-normal lh-1">
              Vendez vos <span class="text-muted">cartes</span> rapidement.
            </h2>
            <p class="lead">
              Notre solution vous permettra de mettre rapidement vos cartes a la
              vente. Vous pourrez gagner de l'argent directement sur votre
              compte bancaire. La référence réunionnaise pour Yu-Gi-Oh! Achète
              et vends des cartes, des bases de deck YGO au meilleur prix.
            </p>
            <p>
              <a class="btn btn-lg btn-dark" href="/inscription-vendeur">
                Devenir vendeur
              </a>
            </p>
            <a href="/conditions-vendeur" class="btn btn-dark">
              Comment vendre
            </a>
          </div>
          <div
            class="col-md-5"
            style={{
              overflow: "hidden",
            }}
          >
            <img
              alt="yugioh.re yugioh vendeur"
              src={
                process.env.PUBLIC_URL +
                "/assets/images/best-place-to-sell-Yugioh-cards.jpg"
              }
            />
          </div>
        </div>

        {/* <hr class="featurette-divider" />

        <div class="row featurette">
          <div class="col-md-7 order-md-2">
            <h2 class="featurette-heading fw-normal lh-1">
              Vendez vos bases de <span class="text-muted">deck.</span>
            </h2>
            <p class="lead">
              Jeux prêts à jouer, ... mieux qu'un deck de structure ou un
              starter pour redécouvrir le jeu de cartes Yu-Gi-Oh, ou simplement
              pour récupérer les meilleures cartes d'une méta rapidement!
            </p>
            <p>
              <a class="btn btn-lg btn-dark" href="/inscription-vendeur">
                Devenir vendeur
              </a>
            </p>
          </div>
          <div
            class="col-md-5 order-md-1"
            style={{
              overflow: "hidden",
            }}
          >
            <img
              alt="yugioh.re yugioh vendeur"
              src={process.env.PUBLIC_URL + "/assets/images/BASED253451.png"}
            />
          </div>
        </div>

        <hr class="featurette-divider" />

        <div class="row featurette">
          <div class="col-md-7">
            <h2 class="featurette-heading fw-normal lh-1">
              Des <span class="text-muted">enchères</span> au plus simple.
            </h2>
            <p class="lead">
              Comme une maison de vente aux enchères, faites envolé vos cartes à
              prix d'or. Quelque soit une carte gradée et notée par PSA et PCA a
              haute valeur ou simplement des starslights ou des ghosts rare.
            </p>
            <p>
              <a class="btn btn-lg btn-dark" href="/inscription-vendeur">
                Devenir vendeur
              </a>
            </p>
          </div>

          <div
            class="col-md-5"
            style={{
              overflow: "hidden",
            }}
          >
            <img
              alt="yugioh.re yugioh vendeur"
              src={
                process.env.PUBLIC_URL +
                "/assets/images/1639069974_984_Yu-Gi-Oh-cartes-rare-Yu-Gi-Oh-des-cartes-a-convoiter.jpg"
              }
            />
          </div>
        </div> */}
      </div>
    </>
  );
};

const BecomeVendor = ({ navigation }) => (
  <>
    <Action3 />
  </>
);
export default BecomeVendor;
