import React from "react";
import "../App.css";
import Carousel from "react-bootstrap/Carousel";
import { ItemEnchere } from "../components/ItemEnchere";

const Item = () => {
  return (
    <div class="col">
      <div class="card shadow-sm">
        <svg
          class="bd-placeholder-img card-img-top"
          width="100%"
          height="225"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          aria-label="Placeholder: Thumbnail"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
        >
          <title>Placeholder</title>
          <rect width="100%" height="100%" fill="#55595c" />
          <text x="50%" y="50%" fill="#eceeef" dy=".3em">
            Thumbnail
          </text>
        </svg>

        <div class="card-body">
          <p class="card-text">
            This is a wider card with supporting text below as a natural lead-in
            to additional content. This content is a little bit longer.
          </p>
          <div class="d-flex justify-content-between align-items-center">
            <div class="btn-group">
              <button type="button" class="btn btn-sm btn-outline-secondary">
                View
              </button>
              <button type="button" class="btn btn-sm btn-outline-secondary">
                Edit
              </button>
            </div>
            <small class="text-muted">9 mins</small>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductCard = () => {
  return (
    <div
      class="album py-5 bg-light"
      style={{
        boxShadow:
          "inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15)",
      }}
    >
      <div class="container">
        <div
          class="border-bottom"
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
          }}
        >
          <h2 class="pb-2  ">Enchères</h2>
          <div class="form">
            <select
              class="form-select"
              id="floatingSelect"
              aria-label="Floating label select example"
            >
              <option selected>Dernier ajout</option>
              <option value="1">Populaire</option>
              <option value="2">Prix croissant</option>
              <option value="3">Prix décroissant</option>
            </select>
          </div>
        </div>
        <div class="row row-cols-2 row-cols-sm-2 row-cols-md-5 g-3 py-3">
          <ItemEnchere />
          <ItemEnchere />
          <ItemEnchere />
        </div>
      </div>
    </div>
  );
};

const CALL = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={process.env.PUBLIC_URL + "/assets/images/yugioh-1-3.png"}
          alt="First slide"
        />

        <Carousel.Caption>
          <div class="container">
            <div class="carousel-caption text-start">
              <h1>Les Enchères</h1>
              <p>
                Les ventes aux enchères de centaines de cartes pour acheter les
                meilleurs cartes au meilleur prix. Participez aux enchères en
                direct live, découvrez le détail des ventes et bien plus encore!
              </p>
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

const Encheres = () => {
  return (
    <>
      <CALL />
      <ProductCard />
    </>
  );
};
export default Encheres;
