import React, { useEffect, useState } from "react";
import "../App.css";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "../styles/product.css";
import Carousel from "react-bootstrap/Carousel";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { Item } from "../components/ItemCard";
import ReactPaginate from "react-paginate";
import { App } from "../firebase";
import { ItemEnchere } from "../components/ItemEnchere";
import { ItemBasedeDeck } from "../components/ItemBDK";

const Vendor = () => {
  const { id } = useParams();
  const history = useHistory();
  const [data, setData] = useState(history?.location?.state?.data || null);
  const [dataProductList, setDataProductList] = React.useState([]);

  function isMyProduit() {
    return true;
  }
  const gAbn = () => {
    // verifié l'abonnement du vendeur
    if (data?.subscription_info?.name) {
      if (data.subscription_info?.name !== "Free") {
        return { name: data.subscription_info?.name, show: true };
      } else {
        if (data.subscription_info?.name === "Free") {
          // l'utilisateur a un abonnement gratuit !
          return { show: false };
        }
      }
    }
  };

  const getVendor = async (cb) => {
    await App.firestore()
      .collection("vendor")
      .doc(id)
      .get()
      .then(async (docd) => {
        const data = docd.data();
        if (data) {
          cb(data);
        } else {
          cb(null);
        }
      })
      .catch((err) => {
        cb(null);
        console.log(" err", err);
      });
  };

  useEffect(() => {
    if (data === null) {
      getVendor((v) => {
        if (v) {
          setData(v);
          getAllserchusercard((d) => {
            setDataProductList(d);
          });
        } else {
          window.location.href = "/vendeurs";
          alert("Identifiant vendeur invalide");
        }
      });
    } else {
      getAllserchusercard((d) => {
        setDataProductList(d);
      });
    }
  }, [id]);

  const getAllserchusercard = async (cb) => {
    await App.firestore()
      .collection("cards")
      .where("userId", "==", id)
      .where("state", "==", 1)
      .get()
      .then(async (querySnapshot) => {
        var data = [];
        querySnapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          if (d.qte > 0){
            data.push(d);
          }
           
        });
        cb(data);
      })
      .catch((err) => {
        cb([]);
        console.log(" err", err);
      });
  };

  function Items({ currentItems }) {
    return (
      <>
        {currentItems &&
          currentItems.map((e) => <Item data={e} key={e?.id} noname={true} />)}
      </>
    );
  }

  function PaginatedItems({ items, itemsPerPage }) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = React.useState(null);
    const [pageCount, setPageCount] = React.useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = React.useState(0);

    React.useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(items.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(items.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % items.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        {items.length > itemsPerPage && (
          <div style={{ margin: "auto", width: "100%", marginTop: 30 }}>
            <ReactPaginate
              previousLabel="précedent"
              nextLabel="suivant"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pageCount}
              pageRangeDisplayed={itemsPerPage}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
              hrefAllControls
              // }}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {/* <p>switch a faire sur cart, enchères ou base de deck</p>
      <p>{id}</p>  */}
      <div class="container py-5 ">
        <div class="product-wrap clearfix product-deatil">
          <div class="row">
            <div class="col-md-5 col-sm-12 col-xs-12">
              <div class="product-image">
                <Carousel variant="dark">
                  <Carousel.Item>
                    <img
                      width="100%"
                      style={{
                        justifyItems: "center",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      className="d-block"
                      src={
                        data?.hero
                          ? data?.hero
                          : process.env.PUBLIC_URL +
                            "/assets/images/imgbasevendeur.png"
                      }
                      alt="First slide"
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>

            <div class="col-md-6 col-md-offset-1 col-sm-12 col-xs-12">
              <h2 class="name">{data?.name}</h2>
              <div class="certified">
                <ul>
                  <li>
                    <a href="javascript:void(0);">
                      Main propre<span>{data?.address?.city}</span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      Livraison<span>3-5 Jours</span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      Certifié
                      <span>
                        {gAbn()?.show ? gAbn()?.name : "Nouveau vendeur"}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              <hr />
              {/* <button class="btn btn-white btn-default">
                <i class="fa fa-envelope"></i> Contacter le vendeur
              </button> */}
              {/* <button class="btn btn-white btn-default">
                <i class="fa fa-bug"></i> Signaler
              </button> */}
              <p>
                <br />
                Partager avec :{" "}
              </p>
              <FacebookShareButton url={window.location.href}>
                <div
                  style={{
                    backgroundColor: "blue",
                    width: 30,
                    height: 30,
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 15,
                    marginRight: 10,
                  }}
                >
                  <i class="fa fa-facebook"></i>
                </div>
              </FacebookShareButton>
              <TwitterShareButton url={window.location.href}>
                <div
                  style={{
                    backgroundColor: "#00acee",
                    width: 30,
                    height: 30,
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 15,
                    marginRight: 10,
                  }}
                >
                  <i class="fa fa-twitter"></i>
                </div>
              </TwitterShareButton>
            </div>
          </div>
        </div>
        <br />
        <Tabs
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="profile" title="Vendeur">
            <p>Société : {data?.name}</p>
            <p>
              Adresse : Information communiqué lors d'une livraison en main
              propre uniquement.
            </p>

            {/* <p>
              <b>Avis</b> : 120 Avis
              <br />
              <span style={{ color: "green" }}>Bon</span>: 118
              <br />
              <span style={{ color: "red" }}>Mauvais</span>: 2
            </p> */}
            {/* <p>Siren : </p> */}
          </Tab>
          <Tab eventKey="cartes" title="Cartes du vendeur">
            <div class="row row-cols-2 row-cols-sm-2 row-cols-md-6 g-3 py-3">
              <PaginatedItems items={dataProductList} itemsPerPage={12} />
            </div>
          </Tab>
          {/* <Tab eventKey="deck" title="Bases de deck du vendeur">
            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
              <ItemBasedeDeck />
              <ItemBasedeDeck />
              <ItemBasedeDeck />
            </div>
            <p>
              <a href="#">Voir plus</a>
            </p>
          </Tab>
          <Tab eventKey="encheres" title="Enchères du vendeur (pro)">
            <div class="row row-cols-2 row-cols-sm-2 row-cols-md-5 g-3 py-3">
              <ItemEnchere />
            </div>
            <p>
              <a href="#">Voir plus</a>
            </p>
          </Tab> */}
        </Tabs>
      </div>
    </>
  );
};
export default Vendor;
