import React from "react";
import ReactN from "reactn";
import { API_URL } from "../constant";
import { App } from "../firebase";
 

const Footer = () => {
  // user
  const [user, setUser] = ReactN.useGlobal("user");
  const [email, setEmail] = React.useState(null);

  React.useEffect(() => {
    App.auth().onAuthStateChanged((u) => {
      if (u) {
        getUser(App.auth().currentUser?.uid, (u) => {
          setUser(u);
        });
      } else {
        setUser(null);
      }
    });
  }, []);

  const getUser = async (uid, cb) => {
    await App.firestore()
      .collection("users")
      .doc(uid)
      .get()
      .then(async (docd) => {
        var user = docd.data();
        user.id = docd.id;
        cb(user);
      })
      .catch((error) => {
        cb(null);
      });
  };

  const delNews = async () => {
    await App.firestore()
      .collection("newsletters")
      .doc(App.auth().currentUser?.uid)
      .update({
        _update_At: new Date(),
        newsletter: false,
      });
  };

  const subscribe = async (e) => {
    e.preventDefault();
    if (App.auth().currentUser?.uid) {
      await App.firestore()
        .collection("newsletters")
        .doc(App.auth().currentUser?.uid)
        .update({
          _update_At: new Date(),
          newsletter: true,
          email: email,
          _create_At: new Date(),
          last_send: null,
          uid: App.auth().currentUser?.uid,
        })
        .then(async () => {
          alert("Vous êtes maintenant abonné à la newsletter.");
          // Si utilisateur connecté lié l'abonner

          await App.firestore()
            .collection("users")
            .doc(App.auth().currentUser?.uid)
            .update({
              _update_At: new Date(),
              newsletter: true,
            })
            .then(() => {
              getUser((user) => {
                setUser(user);
              });
              fetch(`${API_URL}/subscribenews`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  // eslint-disable-next-line object-shorthand
                  email: email,
                  displayname: App.auth().currentUser?.displayName || "",
                  newsletter: true,
                }),
              });
            });
        })
        .catch((error) => {
          console.log("error", error);
          alert(
            "Une erreur s'est produite, réessayer plus tard ou contactez nous."
          );
        });
    } else {
      await App.firestore()
        .collection("newsletters")
        .doc(App.auth().currentUser?.uid)
        .set({
          email: email,
          newsletter: true,
          _create_At: new Date(),
          _update_At: new Date(),
          last_send: null,
          uid: null,
        })
        .then(async () => {
          alert("Vous êtes maintenant abonné à la newsletter.");
        })
        .catch((error) => {
          console.log("error", error);
          alert(
            "Une erreur s'est produite, réessayer plus tard ou contactez nous."
          );
        });
    }
  };

  const unsubscribe = async () => {
    await App.firestore()
      .collection("users")
      .doc(App.auth().currentUser?.uid)
      .update({
        _update_At: new Date(),
        newsletter: false,
      })
      .then(async () => {
        getUser((user) => {
          setUser(user);
        });
        fetch(`${API_URL}/subscribenews`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // eslint-disable-next-line object-shorthand
            email: App.auth().currentUser?.email || "",
            displayname: App.auth().currentUser?.displayName || "",
            newsletter: false,
          }),
        });
        setTimeout(() => {
          delNews();
          alert("Vous êtes maintenant désabonné de la newsletter.");
        }, 100);
      })
      .catch((error) => {
        alert(
          "Une erreur s'est produite, réessayer plus tard ou contactez nous."
        );
      });
  };

  return (
    <div class="container">
      <footer class="py-5">
        <div class="row">
          <div class="col-6 col-md-3 mb-3">
            <h5>Vendeurs</h5>
            <ul class="nav flex-column">
              {user && user?.role !== "vendor" && (
                <li class="nav-item mb-2">
                  <a href="/devenir-vendeur" class="nav-link p-0 text-muted">
                    Devenir vendeur
                  </a>
                </li>
              )}

              {user &&
                (user?.role === "pendingvendor" || user?.role === "vendor") && (
                  <li class="nav-item mb-2">
                    <a href="/tableau-de-bord" class="nav-link p-0 text-muted">
                      Tableau de bord de vendeur
                    </a>
                  </li>
                )}
              <li class="nav-item mb-2">
                <a href="/conditions-vendeur" class="nav-link p-0 text-muted">
                  Guide du vendeur
                </a>
              </li>
              {/* <li class="nav-item mb-2">
                <a href="/faqs" class="nav-link p-0 text-muted">
                  FAQs
                </a>
              </li> */}
            </ul>
          </div>

          <div class="col-6 col-md-4 mb-3">
            <h5>Légales</h5>
            <ul class="nav flex-column">
              <li class="nav-item mb-2">
                <a href="/mention-legales" class="nav-link p-0 text-muted">
                  Mention légales
                </a>
              </li>
              <li class="nav-item mb-2">
                <a href="/conditions-generales" class="nav-link p-0 text-muted">
                  Conditions générales
                </a>
              </li>
              {/* <li class="nav-item mb-2">
                <a
                  href="/politique-de-retour-et-remboursement"
                  class="nav-link p-0 text-muted"
                >
                  Politique de retour et remboursement
                </a>
              </li> */}
              <li class="nav-item mb-2">
                <a href="/a-propos" class="nav-link p-0 text-muted">
                  A propos
                </a>
              </li>
            </ul>
          </div>

          <div class="col-md-4 offset-md-1 mb-3">
            <h5>S'abonner à la newletters</h5>
            <p style={{ fontSize: 12 }}>
              Résumé mensuel des nouveautés de la part de nos vendeurs.
            </p>
            {user && user.newsletter ? (
              <p style={{ fontSize: 14 }}>
                Vous êtes actuellement abonné à la newsletter.
                <br />
                <a href="#" onClick={unsubscribe}>
                  Se désabonner
                </a>
              </p>
            ) : (
              <form onSubmit={subscribe}>
                <div class="d-flex flex-column flex-sm-row w-100 gap-2">
                  <label for="newsletter1" class="visually-hidden">
                    Adresse email
                  </label>
                  <input
                    id="newsletter1"
                    type="email"
                    required
                    class="form-control"
                    placeholder="Adresse email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <button class="btn btn-dark" type="submit">
                    S'abonner
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>

        <p style={{ fontSize: 11 }}>
          <span style={{ fontSize: 10 }}>(1) </span>
          Commission : commission fixe de 5% hors frais de paiement de 2.9% +
          0.30€, ses frais sont relatifs au service de stripe, permettant de
          sécuriser et proposer des solutions de paiement à niveau. Un
          abonnement dit "pro" est proposé avec des services supplémentaires,
          aucune commission ne sera prélevée hors frais de paiement par carte.
          Souscription a l'abonnement lors de la création de votre compte ou
          depuis votre espace vendeur. Vous pouvez vous désabonner à tout moment
          depuis votre espace vendeur.
          <span style={{ fontSize: 10 }}>(2) </span>
          Stripe est notre solution partenaire qui permet aux vendeurs de la
          platforme de créer un compte pour commencer a vendre et faire un
          virement sur leur compte bancaire en 3 jours ouvrable.
          <span style={{ fontSize: 10 }}>(3) </span>Les versements des clients
          apparait sur le compte stripe vendeur après avoir indiqué l'envoie de
          la commande pour une livraison et pour une livraison en main propre,
          le vendeur indiquera manuellement d'avoir livré la commande. Pour
          votre sécurité veuillez prendre en photo la commande livré et/ou signé
          un document de livraison en main propre.
          <span style={{ fontSize: 10 }}>(4) </span>L'abonnement pro est
          uniquement réservé au vendeur souhaitant bénéficier des
          fonctionnalités avancé du site. Cet abonnement est payable
          mensuellement et automatiquement renouvelé chaque mois. Vous avez la
          possibilité de vous désabonner dans le menu 'abonnement' de votre
          tableau de board vendeur. Chaque facture sera envoyée par email ou
          téléchargeable dans le menu 'abonnement'.
          <span style={{ fontSize: 10 }}>(5) </span>Abonnement Pro: Aucune
          commission ne sera prélévé sur la vente hors frais de paiement par
          carte de 1,4% + 0,25€, ses frais sont relatifs au service de stripe,
          permettant de sécuriser et proposer des solutions de paiement.
          <span style={{ fontSize: 10 }}>(6) </span> Communication Emailing: Une
          fois par semaine le vendeur pourra choisir une liste de carte, base de
          deck, enchères qui sera ajouté dans la newsletters.{" "}
          <span style={{ fontSize: 10 }}>(7) </span>L'une des cartes du vendeur
          n'a été configurer que pour un mode en livraison, il n'est donc pas
          possible de proposer une récupération en main propre. Vous pouvez
          cependant juste après la commande, envoyée un message au vendeur pour
          lui demander s'il est possible de faire une exception pour une
          livraison en main propre avec la référence de la commande. Le vendeur peut ne pas vous rembousé les frais de livraison payé{" "}
          <span style={{ fontSize: 10 }}>(8) </span>Le tarif proposé pouvant
          être mise à jour sur la page de validation de commande, selon
          l'adresse de livraison choisie; Ici l'adresse de livraison par défaut
          est utilisé. Ce tarif pourra être modifié après la validation de la
          commande si le vendeur prouve avoir payé sa livraison plus chère; voir
          conditions générales. Toutes les livraison de vendeur doivent par
          sécurité ce faire avec un suivis afin de garantir l'arrivage des
          cartes et aussi traité les paiements.
          <span style={{ fontSize: 10 }}>(9) </span>Frais de service lié à la
          commission de la plate-forme si vous êtes éligible (due aux
          abonnements des vendeurs) liés à ladite commande incluant les frais de
          paiement de stripe de 2.9% + 0,30. {" "}
          <span style={{ fontSize: 10 }}>(10) </span>Frais de livraison sont lié à la
          au nombre de carte incluant la commande ainsi qu'un forfait d'emballage / carte, 
          le vendeur se doit de protéger vos cartes avec les moyens permettant la securisation 
          des cartes pour leur transport. Il n'est cependant pas obliger d'utiliser des toploaders. Le prix de livraison ne peut dépasser 9.69. 
          Pour les grosses commandes de carte privilégier la livraison en main propre. plus d'informations sur le site de la poste: https://www.laposte.fr/tarif-lettre-suivie
          <br/>
          Yu-Gi-Oh est une marque déposée de KONAMI. 
        </p>
        <div class="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
          <p style={{ fontSize: 12 }}>
            &copy; 2022-2024 Filaumart Sébastien, UNITY CARD.{" "}
            <a class="link-dark" href="/confidentialite">
              Confidentialité et protection des données
            </a>
          </p>
          <ul class="list-unstyled d-flex">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/paiement.png"}
              alt=""
              style={{ height: 50, position: "relative", top: -20 }}
            />
          </ul>
          {/* <ul class="list-unstyled d-flex">
            <li class="ms-3">
              <a class="link-dark" href="#">
                Facebook
              </a>
            </li>
            <li class="ms-3">
              <a class="link-dark" href="#">
                Twitter
              </a>
            </li>
          </ul> */}
        </div>
      </footer>
    </div>
  );
};
export default Footer;
 