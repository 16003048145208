import React from "react";
import "../App.css";

const Commissions = () => {
  return (
    <>
      <div
        class="album py-5 bg-light"
        style={{
          boxShadow:
            "inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15)",
        }}
      >
        <div class="container">
          <h1>Tableau des commissions</h1>
          <hr />
          <section style={{ marginBottom: "74", paddingBottom: "5rem" }}>
            <table class="table table-sm mb-3 text-center align-middle">
              <thead class="thead-default">
                <tr>
                  <th>Catégorie</th>
                  <th>Action</th>
                  <th>Utilisateur particulier</th>
                  <th>Utilisateur professionnel</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowspan="2" class="color-primary align-middle">
                    <strong>Compte Utilisateur</strong>
                  </td>
                  <td>Ouverture d'un compte</td>
                  <td colspan="2">Pas de frais</td>
                </tr>
                <tr>
                  <td>Fermeture d'un compte</td>
                  <td colspan="2">Pas de frais</td>
                </tr>
                {/* <tr>
                  <td rowspan="4" class="color-primary align-middle">
                    <strong>Crédits Cardmarket</strong>
                  </td>
                  <td>Crédit différé</td>
                  <td colspan="2">
                    Pas de frais<sup>1</sup>
                  </td>
                </tr>
                <tr>
                  <td>Crédit instantané</td>
                  <td colspan="2">
                    <strong>0,35 €</strong> + <strong>5 %</strong>
                    <br />
                    du montant transféré <sup>1, 2, 3</sup>
                  </td>
                </tr>
                <tr>
                  <td>Retrait (€)</td>
                  <td colspan="2">
                    Pas de frais <sup>1</sup>
                  </td>
                </tr>
                <tr>
                  <td>Retrait (£)</td>
                  <td colspan="2">0.32 £</td>
                </tr> */}
                <tr>
                  <td rowspan="5" class="color-primary align-middle">
                    <strong>Achat / Vente</strong>
                  </td>
                  <td>Achat</td>
                  <td colspan="2">Pas de frais</td>
                </tr>
                <tr>
                  <td>Service Assurance (bientôt)</td>
                  <td colspan="2">
                    <strong>0,5 % - 1 %</strong>
                    <br /> de la valeur totale des articles (en fonction de
                    l'envoi choisi) <sup>2, 3</sup>
                  </td>
                </tr>
                <tr>
                  <td>Vente de cartes et de lots</td>
                  <td colspan="2">
                    <strong>5 %</strong>
                    <br /> de la valeur de l'article pour chaque article vendu{" "}
                    <sup>2, 3, 4</sup>
                  </td>
                </tr>
                <tr>
                  <td>Vendre d'autres produits (bientôt)</td>
                  <td colspan="2">
                    <strong>5 %</strong>
                    <br /> de la valeur de l'article pour chaque article vendu{" "}
                    <sup>2, 3, 4</sup>
                  </td>
                </tr>
                <tr>
                  <td>Frais de change des devises</td>
                  <td colspan="2">
                    <strong>3 %</strong> <sup>2, 3</sup>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>Tous les prix incluent la TVA.</p>
            <ul class="list-unstyled">
              <li>
                <small>
                  <sup>1</sup> Il peut y avoir des frais supplémentaires de la
                  part de votre banque ou service de paiement et sur lesquels
                  nous n'avons aucune influence.
                </small>
              </li>
              <li>
                <small>
                  <sup>2</sup> Tous les frais sont arrondis au centime d'euro
                  supérieur. Cela signifie que des frais d'au moins 0,01 €
                  s'appliquent. hors frais de Stripe de 1,4% + 0,25 €
                </small>
              </li>
              <li>
                <small>
                  <sup>3</sup> Les vendeurs professionnels des pays de l'UE hors
                  de France avec un numéro de TVA intra-communautaire valide ne
                  paie pas la TVA.
                </small>
              </li>
              <li>
                <small>
                  <sup>4</sup> La commission n'est pas plafonnée par article.{" "}
                </small>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </>
  );
};
export default Commissions;
