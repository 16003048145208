import React, { useEffect } from "react";
import "../App.css";
import { useForm } from "react-hook-form";
import { App } from "../firebase";
import { useHistory } from "react-router-dom";
import { Alert } from "react-bootstrap";

const Action3 = () => {
  const history = useHistory();
  const [logout, setAlertLogout] = React.useState(
    history.location.state?.logout || false
  );
  const [deleteU, setAlertDelete] = React.useState(
    history.location.state?.delete || false
  );
  const [forgetPass, setForgetPass] = React.useState(
    history.location.state?.forgetpass || false
  );
  const [loadingUser, setLoadingUser] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  App.auth().languageCode = "fr";

  const redirection = (user) => {
    const redi = localStorage.getItem("redirectAfterlogin");
    if (redi) {
      window.location.href = redi;
      localStorage.removeItem("redirectAfterlogin");
      return;
    }

    switch (user?.role) {
      case "vendor":
        history.push("/tableau-de-bord#dashboard");
        break;
      case "pendingvendor":
        history.push("/tableau-de-bord#becomevendor");
        break;

      default:
        // redirection à l'accueil
        history.push("/");
        break;
    }
  };

  const getUser = async (uid, cb) => {
    await App.firestore()
      .collection("users")
      .doc(uid)
      .get()
      .then(async (docd) => {
        var user = docd.data();
        user.id = docd.id;
        cb(user);
      })
      .catch((error) => {
        console.log("error", error);
        cb(null);
      });
  };

  useEffect(() => {
    // check if user is login
    setLoadingUser(true);
    App.auth().onAuthStateChanged((u) => {
      if (u) {
        setLoadingUser(true);
        //redirection au compte
        getUser(u.uid, (user) => {
          // console.log("user", user);
          App.auth().languageCode = "fr";
          redirection(user);
        });
      } else {
        setLoadingUser(false);
      }
    });
    setTimeout(() => {
      setLoadingUser(false);
      setTimeout(() => {
        setAlertLogout(false);
        setAlertDelete(false);
        setForgetPass(false);
      }, 5000);
    }, 5000);
  }, []);

  const { register, handleSubmit, setValue } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });

  const logIn = (value) => {
    if (loading) {
      return;
    }
    setLoading(true);
    App.auth()
      .signInWithEmailAndPassword(value.mail.trim(), value.password.trim())
      .then((res) => {
        setLoading(false);
        //redirection automatique
      })
      .catch((err) => {
        setLoading(false);
        //do something with the error
        console.log(err.code);
        // alert(getTranslation(err.code));
        alert("Identifiant invalide");
      });
  };

  return (
    <>
      <div
        class="tab-content"
        style={{ width: 300, paddingTop: "5%", paddingBottom: "5%" }}
      >
        {loadingUser ? (
          <div
            class="tab-pane fade show active"
            id="pills-login"
            role="tabpanel"
            aria-labelledby="tab-login"
          >
            <p style={{ textAlign: "center" }}>Chargement ... </p>
          </div>
        ) : (
          <div
            class="tab-pane fade show active"
            id="pills-login"
            role="tabpanel"
            aria-labelledby="tab-login"
          >
            {logout && <Alert>Déconnexion réussi</Alert>}
            {deleteU && <Alert>Compte supprimé</Alert>}
            {forgetPass && (
              <Alert>
                Lien de réinitialisation de mot de passe envoyé par email.
                vérifiez vos spams.
              </Alert>
            )}
            <form
              onSubmit={handleSubmit(logIn, (err) => {
                console.log(err);
              })}
            >
              <h2 class="text-center p-4">Connexion</h2>

              <div class="form-outline mb-4">
                <label class="form-label" for="email">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  class="form-control"
                  required
                  onChange={(e) => setValue("mail", e.target.value)}
                  ref={register("mail")}
                />
              </div>

              <div class="form-outline mb-4">
                <label class="form-label" for="password">
                  Mot de passe
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  class="form-control"
                  required
                  onChange={(e) => setValue("password", e.target.value)}
                  ref={register("password")}
                />
              </div>
              <div class="row mb-4">
                <div class="col-md-7 d-flex justify-content-left">
                  <a href="/forgetpass">Mot de passe oublié?</a>
                </div>
              </div>

              <button
                type="submit"
                class="btn btn-dark btn-block mb-4"
                disabled={loading}
              >
                {loading ? "Chargement ... " : "Connexion"}
              </button>
            </form>

            <div class="text-center">
              <p>
                Pas de compte ? <a href="/inscription">S'inscrire</a>
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const Login = ({ navigation }) => (
  <div class="container  p-4 d-flex justify-content-center">
    <Action3 />
  </div>
);
export default Login;
