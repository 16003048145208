import React, { useEffect, useState } from "react";
import "../App.css";
import { App } from "../firebase";

import { ItemSearch } from "../components/ItemSearch";
import ReactPaginate from "react-paginate";

function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems.map((e) => <ItemSearch data={e} key={e?.id} />)}
    </>
  );
}

const ProductCard = () => {
  const saved = JSON.parse(localStorage.getItem("searchCard"));
  const [order, setOrder] = useState(0);
  const [dataSearchList, setDataSearchList] = React.useState(saved || []);
  const [data, setData] = React.useState(saved || []);
  const [reload, setReload] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const getAllsearchcard = async (cb) => {
    setLoading(true);
    await App.firestore()
      .collection("searchs")
      .where("state", "==", 1)
      .orderBy("_update_At", "desc")
      .get()
      .then(async (querySnapshot) => {
        var data = [];
        querySnapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          data.push(d);
        });
        setLoading(false);
        cb(data);
      })
      .catch((err) => {
        setLoading(false);
        cb([]);
        console.log(" err", err);
      });
  };
  const diffHour = (d1) => {
    let date_1 = new Date(d1);
    let date_2 = new Date();
    let difference = date_2.getTime() - date_1.getTime();
    return Math.ceil(difference / (1000 * 3600));
  };

  React.useEffect(() => {
    if (dataSearchList) {
      localStorage.setItem("searchCard", JSON.stringify(dataSearchList));
    }
  }, [dataSearchList]);

  React.useEffect(() => {
    if (dataSearchList && dataSearchList?.data?.length > 0) {
      const TotalHour = diffHour(dataSearchList.date);
      console.log(TotalHour + " hour to world Cup");
      if (TotalHour > 1) {
        // new Download Data
        setDataSearchList(null);
        getAllsearchcard((d) => {
          setDataSearchList({ data: d, date: new Date() });
          setData({ data: d, date: new Date() });
        });
      }
    } else {
      // console.log("download data");
      getAllsearchcard((d) => {
        setDataSearchList({ data: d, date: new Date() });
        setData({ data: d, date: new Date() });
      });
    }
  }, []);

  function PaginatedItems({ items, itemsPerPage }) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = React.useState(null);
    const [pageCount, setPageCount] = React.useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = React.useState(0);

    React.useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(items.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(items.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % items.length;
      // console.log(
      //   `User requested page number ${event.selected}, which is offset ${newOffset}`
      // );
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        {items.length > itemsPerPage && (
          <div style={{ margin: "auto", width: "100%", marginTop: 30 }}>
            <ReactPaginate
              previousLabel="précedent"
              nextLabel="suivant"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pageCount}
              pageRangeDisplayed={itemsPerPage}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
              hrefAllControls
            />
          </div>
        )}
      </>
    );
  }

  const getOrderByName = (cb) => {
    const datas = data;
    datas?.data.sort(function (a, b) {
      return a?.card?.name.localeCompare(b?.card?.name);
    });
    // console.log("datas", datas);
    cb(datas);
  };
  const getOrderDescName = (cb) => {
    getOrderByName((d) => {
      cb({ date: d.date, data: d.data.reverse() });
    });
  };

  const getOrderAscName = (cb) => {
    getOrderByName(cb);
  };

  useEffect(() => {
    if (order && data) {
      // console.log("order", order);
      switch (order) {
        case "0":
          setDataSearchList(saved);
          setReload(true);
          setTimeout(() => {
            setReload(false);
          }, 100);
          break;
        case "1":
          getOrderAscName((d) => {
            setDataSearchList(d);
            setReload(true);
            setTimeout(() => {
              setReload(false);
            }, 100);
          });
          break;
        case "2":
          getOrderDescName((d) => {
            setDataSearchList(d);
            setReload(true);
            setTimeout(() => {
              setReload(false);
            }, 100);
          });
          break;
        case "3":
          const res = data?.data.sort((a, b) => {
            return a.price - b.price;
          });
          setDataSearchList({ date: data?.date, data: res });
          setReload(true);
          setTimeout(() => {
            setReload(false);
          }, 100);
          break;
        case "4":
          const res3 = data?.data.sort((a, b) => {
            return b.price - a.price;
          });
          setDataSearchList({ date: data?.date, data: res3 });
          setReload(true);
          setTimeout(() => {
            setReload(false);
          }, 100);
          break;
        default:
          setDataSearchList(data);
          setReload(true);
          setTimeout(() => {
            setReload(false);
          }, 100);
          break;
      }
    }
  }, [order, data]);

  return (
    <div
      class="album py-5 bg-light"
      style={{
        boxShadow:
          "inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15)",
      }}
    >
      <div class="container">
        <div
          class="border-bottom"
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
          }}
        >
          <h2 class="pb-2  ">Les utilisateurs recherche</h2>
          <div class="form" onSubmit={(e) => e.preventDefault()}>
            <select
              class="form-select"
              id="floatingSelect"
              aria-label="Floating label select example"
              value={order}
              onChange={(e) => {
                setOrder(e.target.value);
              }}
            >
              <option selected value="0">
                Dernier ajout
              </option>
              <option value="1">Ordre croissant</option>
              <option value="2">Ordre décroissant</option>
              {/* <option value="3">Prix croissant</option>
              <option value="4">Prix décroissant</option> */}
            </select>
          </div>
        </div>
        <div class="row row-cols-2 row-cols-sm-2 row-cols-md-6 g-3 py-3">
          {loading && <p>Chargement ...</p>}
          {!reload ? (
            <PaginatedItems items={dataSearchList.data} itemsPerPage={12} />
          ) : (
            <p>Chargement ...</p>
          )}
        </div>
      </div>
    </div>
  );
};
const SearchListCard = () => {
  return (
    <>
      <ProductCard />
    </>
  );
};
export default SearchListCard;
