/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import ReactN from "reactn";
import "../App.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Container from "react-bootstrap/Container";  
import Navbar from 'react-bootstrap/Navbar';   
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';


import UserList from "../components/UserList";
import ChatBox from "../components/ChatBox";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";
import { ItemAddCard } from "../components/ItemAddCard";
import Alert from "react-bootstrap/Alert";
import Collapse from "react-bootstrap/Collapse";
import { App } from "../firebase";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useForm } from "react-hook-form";
import Compressor from "compressorjs";
import { API_URL } from "../constant";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const Config = require("../secure/config.js");

function addLeadingZeros(num, totalLength) {
  return String(num).padStart(totalLength, "0");
}

const getAllNameVendor = (data) => {
  let vn = "";
  data?.data?.forEach((v, i) => {
    if (v.name) {
      vn += v.name;
      if (data?.data[i + 1]?.name) {
        vn += ", ";
      }
    }
  });
  return vn;
};

const totalsub = (cart) => {
  let t = 0;
  cart?.forEach((element) => {
    const qte = Number(element.qte)
    const price = Number(element.product.price)
    const d = price * qte
    t = Number(t) + Number(d);
  });
  return t;
};

const getPriceTotal = (d) => { 
    const sub = totalsub(d?.data.products);
    const livraison = (d?.data?.livraison != null && d?.data?.livraison > 0 ? d?.data?.livraison : 0);

    return Number(sub + livraison).toFixed(2);
}

function BankCard({ data, getCardsUser }) {
  const [show, setShow] = React.useState(true);
  const delCardsUser = async (cardId, number) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Supprimer cette carte **** " + number + "?")) {
      // continue
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        payId: cardId,
      });
      var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(API_URL + "payment-method-card", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.success) {
            setShow(true);
            alert("Carte bancaire supprimée");
            getCardsUser();
          } else {
            alert("Une erreur s'est produite");
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Une erreur s'est produite");
        });
    }
  };

  if (show) {
    return (
      <Alert>
        <Alert.Heading>Visa</Alert.Heading>
        <p>**** **** **** {data.card.last4}</p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={() => setShow(false)} variant="outline-danger">
            Supprimer
          </Button>
        </div>
      </Alert>
    );
  }
  return (
    <Alert style={{ paddingTop: 15, paddingBottom: 15 }}>
      <p>Êtes vous sur de vouloir supprimer la carte XXXX XXXX XXXX 4242 ?</p>
      <hr />
      <div className="d-flex justify-content-end">
        <Button
          onClick={() => {
            delCardsUser(data.id, data.card.last4);
          }}
          variant="outline-danger"
        >
          Supprimer
        </Button>
        <Button
          onClick={() => setShow(true)}
          variant="outline-primary"
          style={{ marginLeft: 10 }}
        >
          Annuler
        </Button>
      </div>
    </Alert>
  );
}

function Items({ currentItems, click, searchCardSelect }) {
  return (
    <>
      {currentItems &&
        currentItems.map((item) => (
          <ItemAddCard
            click={() => click(item)}
            isSelect={
              searchCardSelect != null
                ? searchCardSelect?.id === item?.id
                : false
            }
            data={item}
            key={item?.id}
          />
        ))}
    </>
  );
}

function PaginatedItems({ items, itemsPerPage, searchCardSelect, click }) {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items
        currentItems={currentItems}
        click={click}
        searchCardSelect={searchCardSelect}
      />
      {items.length > itemsPerPage && (
        <div style={{ margin: "auto", width: "100%", marginTop: 30 }}>
          <ReactPaginate
            previousLabel="précedent"
            nextLabel="suivant"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={pageCount}
            pageRangeDisplayed={itemsPerPage}
            marginPagesDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName="pagination justify-content-center"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
            // eslint-disable-next-line no-unused-vars
            // hrefBuilder={(page, pageCount, selected) =>
            //   page >= 1 && page <= pageCount
            //     ? `/page/${page}`
            //     : "#"
            // }
            hrefAllControls
            // forcePage={currentPage}
            onClick={(clickEvent) => {
              console.log("onClick", clickEvent);
              // Return false to prevent standard page change,
              // return false; // --> Will do nothing.
              // return a number to choose the next page,
              // return 4; --> Will go to page 5 (index 4)
              // return nothing (undefined) to let standard behavior take place.
            }}
          />
        </div>
      )}
    </>
  );
}

const DashboardVendor = () => {
  const history = useHistory();
  var a = window.location.href;
  const key = a.split("#")[1] || "dashboard";
  const db = App.firestore();
  const [state, setState] = useState({
    signInModalShow: false,
    users: [], // Avaiable users for signing-in
    userChatData: [], // this contains users from which signed-in user can chat and its message data.
    user: null, // Signed-In User
    selectedUserIndex: null,
    showChatBox: false, // For small devices only
    showChatList: true, // For small devices only
    error: false,
    errorMessage: "",
  });
  // user
  const [user, setUser] = ReactN.useGlobal("user");

  // paysubscription
  const [name, setName] = React.useState(
    App.auth().currentUser?.displayName || null
  );

  // Initialize an instance of stripe.
  const stripe = useStripe();
  const elements = useElements();

  const [pwdModal, setPwdModal] = React.useState(false);

  //new vendor colaspe step
  const [openVendorStep1, setOpenVendorStep1] = React.useState(false);
  const [openVendorStep2, setOpenVendorStep2] = React.useState(false);
  const [openVendorStep3, setOpenVendorStep3] = React.useState(false);
  const [openVendorStep4, setOpenVendorStep4] = React.useState(false);
  const [openVendorStep5, setOpenVendorStep5] = React.useState(false);

  useEffect(() => {
    if (openVendorStep4 && !openVendorStep5) {
      // véifiation si le vendeur a un compte vérifié
      if (App.auth().currentUser?.emailVerified) {
        getUser(App.auth().currentUser?.uid, (u) => {
          setUser(u);
          if (u.pending_vendor_id) {
            db.collection("pendingvendor")
              .doc(u.pending_vendor_id)
              .update({
                _step_4: "complete",
                _update_At: new Date(),
              })
              .then(() => {
                setOpenVendorStep4(false);
                setOpenVendorStep5(true);
              });
          }
        });
      }
    }
  }, [openVendorStep4]);

  //new form vendor become info

  const [loadingForm1, setLoadingForm1] = React.useState(false);

  async function createMessage(text) {
    let message = {
      type: "text",
      message: text,
      senderId: user.uid,
      createAt: new Date(),
    };

    await db
      .collection("messages")
      .doc(state.userChatData[state.selectedUserIndex]?.id)
      .collection("tchats")
      .add(message)
      .then(async () => {
        await db
          .collection("messages")
          .doc(state.userChatData[state.selectedUserIndex]?.id)
          .update({
            lastMessageBy: user.uid,
            lastMessage: text,
            unread: true,
          })
          .then(() => {
            getTargetuser().uids?.forEach((uid) => {
              if (uid != user.uid){ 
                fetch(API_URL + "sendMessageClientOrder", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    userId:  uid, 
                    isOrder: false,
                    msg: "Vous avez un nouveau message, cliquez sur le lien pour le voir" ,
                    title: "Vous avez un nouveau message" ,
                  }),
                })
              }
            })
            
            setState({
              selectedUserIndex: 0,
              userChatData: state.userChatData,
            });
          });
      });
  }

  function toggleViews() {
    setState({
      showChatBox: !state.showChatBox,
      showChatList: !state.showChatList,
    });
  }

  useEffect(() => {
    if (user && key === "messages") {
      refreshTchatList();
    } else {
      // Stop listening for changes
      // if (unsub) unsub();
    }
  }, [user, key]);

  const unsub = db
    .collection("messages")
    .where("uids", "array-contains", App.auth().currentUser?.uid || "")
    .orderBy("updateAt", "desc");
  function refreshTchatList() {
    try {
      unsub.onSnapshot((querySnapshot) => {
        var data = [];
        querySnapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          d.createAt = doc.data().createAt?.toDate() || new Date();
          d.updateAt = doc.data().updateAt?.toDate() || new Date();

          let res = "Aucun nom";
          d.uids.forEach((uid) => {
            if (uid !== user.uid) {
              let keys = Object.keys(d.displayName);
              let values = Object.values(d.displayName);
              keys.forEach((key, index) => {
                if (key === uid) {
                  res = values[index];
                }
              });
            }
          });

          d.name = res;
          data.push(d);
        });
        setState({
          selectedUserIndex: state.selectedUserIndex,
          userChatData: data,
        });
      });
    } catch (err) {
      alert(err);
      console.log("onSnapshot error", err);
    }
  }
  const read = async (index) => {
    await db.collection("messages").doc(state.userChatData[index]?.id).update({
      unread: false,
    });
  };

  function hasUnreadOrder() {
    if (myVendorOrders?.length > 0) {
      let df = 0;
      myVendorOrders?.forEach((d) => {
        // console.log('myVendorOrders l',d )
        if ((d?.status === "paid" || d?.status === "Payé") && d?.orderFinished !== true)
        // if (d.unread && d.lastMessageBy !== user.uid) {
          df += 1;
        // }
      });
      return `${df}`;
    }
    return "0";
  }

  function hasUnreadMessage() {
    if (state?.userChatData) {
      let df = 0;
      state?.userChatData?.forEach((d) => {
        if (d.unread && d.lastMessageBy !== user.uid) {
          df += 1;
        }
      });
      return `${df}`;
    }
    return "0";
  }

  function getTargetuser() {
    return state?.userChatData[state?.selectedUserIndex] || null;
  }

  function onChatClicked(e) {
    for (let index = 0; index < state.userChatData.length; index++) {
      if (state.userChatData[index].id === e.user.id) {
        if (
          state.userChatData[index].unread &&
          state.userChatData[index].lastMessageBy !== user.uid
        ) {
          read(index);
        }
        setTimeout(() => {
          state.userChatData[index].unread = 0;
          setState({
            selectedUserIndex: index,
            userChatData: state.userChatData,
          });
        }, 200);
        return;
      }
    }
  }

  const registerVendorForm = (value) => {
    // formulaire niveau1 vendeur
    if (loadingForm1) {
      return;
    }

    // return;
    setLoadingForm1(true);

    db.collection("pendingvendor")
      .doc(user.uid)
      .set({
        uid: user.uid,
        email: user.email,
        name: value.name.trim(),
        subscription: "pending",
        subscription_info: null,
        customerStripeId: null,
        address: {
          name: value.address.trim(),
          complement: value.complement.trim(),
          city: value.city.trim(),
          state: value.state.trim(),
          country: value.country.trim(),
          zipcode: value.zipcode.trim(),
        },
        phone: value.phone || null,
        logo: null,
        hero: null,
        _step_1: "complete",
        _step_2: "pending",
        _step_3: "pending",
        _step_4: "pending",
        _step_5: "pending",
        complete: false,
        _sign_cgv_At: new Date(), // permet de savoir quand l'utilisateur a signé pour la dernière fois le contrat du vendeur
        _create_At: new Date(),
        _update_At: new Date(),
      })
      .then(async () => {
        //Etape suivante :
        setOpenVendorStep1(false);
        setOpenVendorStep2(true);

        db.collection("users")
          .doc(user.uid)
          .update({ pending_vendor_id: user.uid,role: 'pendingvendor' });
        if (value.logo || value.hero) {
          var storage = App.storage();
          var metadata = {
            contentType: "image/jpeg",
          };

          if (value.logo) {
            let ss = `images/${user.uid}/logo.jpeg`;
            const reference = storage.ref(ss);
            new Compressor(value.logo, {
              maxHeight: 260,
              quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
              success: (compressedResult) => {
                // compressedResult has the compressed file.
                // Use the compressed file to upload the images to your server.
                const uploadTask = reference.put(compressedResult, metadata);

                uploadTask.on(
                  App.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                  (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    var progress =
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log("Upload is " + progress + "% done");
                    switch (snapshot.state) {
                      case App.storage.TaskState.PAUSED: // or 'paused'
                        console.log("Upload is paused");
                        break;
                      case App.storage.TaskState.RUNNING: // or 'running'
                        console.log("Upload is running");
                        break;
                    }
                  },
                  (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                      case "storage/unauthorized":
                        // User doesn't have permission to access the object
                        alert(
                          "Une erreur est survenue lors de l'envoie de votre logo, vous n'avez pas l'autorisation d'envoyez des images."
                        );
                        break;
                      case "storage/canceled":
                        // User canceled the upload alert(
                        alert(
                          "Une erreur est survenue lors de l'envoie de votre logo, vous avez annuler l'envoie."
                        );
                        break;

                      // ...

                      case "storage/unknown":
                        // Unknown error occurred, inspect error.serverResponse
                        alert(
                          "Une erreur est survenue lors de l'envoie de votre logo, réessayer plus tard."
                        );
                        break;
                    }
                  },
                  () => {
                    console.log("Image uploaded to the bucket!");
                    uploadTask.snapshot.ref
                      .getDownloadURL()
                      .then((downloadURL) => {
                        db.collection("pendingvendor")
                          .doc(user.uid)
                          .update({ logo: downloadURL });
                      });
                  }
                );
              },
            });
          }

          if (value.hero) {
            let sshero = `images/${user.uid}/hero.jpeg`;
            const referencehero = storage.ref(sshero);
            new Compressor(value.hero, {
              maxHeight: 260,
              quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
              success: (compressedResult) => {
                // compressedResult has the compressed file.
                // Use the compressed file to upload the images to your server.
                const uploadTask = referencehero.put(
                  compressedResult,
                  metadata
                );

                uploadTask.on(
                  App.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                  (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    var progress =
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log("Upload is " + progress + "% done");
                    switch (snapshot.state) {
                      case App.storage.TaskState.PAUSED: // or 'paused'
                        console.log("Upload is paused");
                        break;
                      case App.storage.TaskState.RUNNING: // or 'running'
                        console.log("Upload is running");
                        break;
                    }
                  },
                  (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                      case "storage/unauthorized":
                        // User doesn't have permission to access the object
                        alert(
                          "Une erreur est survenue lors de l'envoie de votre logo, vous n'avez pas l'autorisation d'envoyez des images."
                        );
                        break;
                      case "storage/canceled":
                        // User canceled the upload alert(
                        alert(
                          "Une erreur est survenue lors de l'envoie de votre logo, vous avez annuler l'envoie."
                        );
                        break;

                      // ...

                      case "storage/unknown":
                        // Unknown error occurred, inspect error.serverResponse
                        alert(
                          "Une erreur est survenue lors de l'envoie de votre bannière, réessayer plus tard."
                        );
                        break;
                    }
                  },
                  () => {
                    console.log("Image uploaded to the bucket!");
                    uploadTask.snapshot.ref
                      .getDownloadURL()
                      .then((downloadURL) => {
                        db.collection("pendingvendor")
                          .doc(user.uid)
                          .update({ hero: downloadURL });
                      });
                  }
                );
              },
            });
          }
        }
      })
      .catch((err) => {
        console.log("registerVendorForm", err);
      });
  };

  const { register, handleSubmit, setValue, getValues } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });
  const [userformhasAdresseLocation, setUFHAL] = useState(false);
  const updateVendorForm = (value) => {
    // formulaire niveau1 vendeur
    if (loadingForm1) {
      return;
    }

    // return;
    setLoadingForm1(true);

    db.collection("vendor")
      .doc(user.uid)
      .update({
        name: value.name?.trim(),
        address: {
          name: value.address?.trim(),
          complement: value.complement ? value.complement?.trim() : null,
          city: value.city?.trim(),
          state: value.state?.trim(),
          country: value.country?.trim(),
          zipcode: value.zipcode?.trim(),
        },
        phone: value.phone || null,
        _update_At: new Date(),
      })
      .then(async () => {
        if (value.logo || value.hero) {
          var storage = App.storage();
          var metadata = {
            contentType: "image/jpeg",
          };

          if (value.logo) {
            let ss = `images/${user.uid}/logo.jpeg`;
            const reference = storage.ref(ss);
            new Compressor(value.logo, {
              maxHeight: 260,
              quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
              success: (compressedResult) => {
                // compressedResult has the compressed file.
                // Use the compressed file to upload the images to your server.
                const uploadTask = reference.put(compressedResult, metadata);

                uploadTask.on(
                  App.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                  (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    var progress =
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log("Upload is " + progress + "% done");
                    switch (snapshot.state) {
                      case App.storage.TaskState.PAUSED: // or 'paused'
                        console.log("Upload is paused");
                        break;
                      case App.storage.TaskState.RUNNING: // or 'running'
                        console.log("Upload is running");
                        break;
                    }
                  },
                  (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                      case "storage/unauthorized":
                        // User doesn't have permission to access the object
                        alert(
                          "Une erreur est survenue lors de l'envoie de votre logo, vous n'avez pas l'autorisation d'envoyez des images."
                        );
                        break;
                      case "storage/canceled":
                        // User canceled the upload alert(
                        alert(
                          "Une erreur est survenue lors de l'envoie de votre logo, vous avez annuler l'envoie."
                        );
                        break;

                      // ...

                      case "storage/unknown":
                        // Unknown error occurred, inspect error.serverResponse
                        alert(
                          "Une erreur est survenue lors de l'envoie de votre logo, réessayer plus tard."
                        );
                        break;
                    }
                  },
                  () => {
                    console.log("Image uploaded to the bucket!");
                    uploadTask.snapshot.ref
                      .getDownloadURL()
                      .then((downloadURL) => {
                        db.collection("vendor")
                          .doc(user.uid)
                          .update({ logo: downloadURL });
                      });
                  }
                );
              },
            });
          }

          if (value.hero) {
            let sshero = `images/${user.uid}/hero.jpeg`;
            const referencehero = storage.ref(sshero);
            new Compressor(value.hero, {
              maxHeight: 260,
              quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
              success: (compressedResult) => {
                // compressedResult has the compressed file.
                // Use the compressed file to upload the images to your server.
                const uploadTask = referencehero.put(
                  compressedResult,
                  metadata
                );

                uploadTask.on(
                  App.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                  (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    var progress =
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log("Upload is " + progress + "% done");
                    switch (snapshot.state) {
                      case App.storage.TaskState.PAUSED: // or 'paused'
                        console.log("Upload is paused");
                        break;
                      case App.storage.TaskState.RUNNING: // or 'running'
                        console.log("Upload is running");
                        break;
                    }
                  },
                  (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                      case "storage/unauthorized":
                        // User doesn't have permission to access the object
                        alert(
                          "Une erreur est survenue lors de l'envoie de votre logo, vous n'avez pas l'autorisation d'envoyez des images."
                        );
                        break;
                      case "storage/canceled":
                        // User canceled the upload alert(
                        alert(
                          "Une erreur est survenue lors de l'envoie de votre logo, vous avez annuler l'envoie."
                        );
                        break;

                      // ...

                      case "storage/unknown":
                        // Unknown error occurred, inspect error.serverResponse
                        alert(
                          "Une erreur est survenue lors de l'envoie de votre bannière, réessayer plus tard."
                        );
                        break;
                    }
                  },
                  () => {
                    console.log("Image uploaded to the bucket!");
                    uploadTask.snapshot.ref
                      .getDownloadURL()
                      .then((downloadURL) => {
                        db.collection("vendor")
                          .doc(user.uid)
                          .update({ hero: downloadURL });
                      });
                  }
                );
              },
            });
          }
          setTimeout(() => {
            alert(
              "Données vendeur mis à jour! (Il est possible que la mise à jours prenne un peu temps avant d'etre disponible en ligne.)"
            );
            setLoadingForm1(false);
          }, 1000);
        } else {
          alert(
            "Données vendeur mis à jour! (Il est possible que la mise à jours prenne un peu temps avant d'etre disponible en ligne.)"
          );
          setLoadingForm1(false);
        }
      })
      .catch((err) => {
        console.log("updateVendorForm", err);
      });
  };

  function ItemsMyCards({ currentItems }) {
    return (
      <>
        {currentItems &&
          currentItems.map((e) => <ItemMyCard data={e} key={e?.id} />)}
      </>
    );
  }

  function ItemsSearch({ currentItems }) {
    return (
      <>
        {currentItems &&
          currentItems.map((e) => <ItemListSearch data={e} key={e.id} />)}
      </>
    );
  }

  function PaginatedItemsSearch({ items, itemsPerPage }) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = React.useState(null);
    const [pageCount, setPageCount] = React.useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = React.useState(0);

    React.useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(items.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(items.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % items.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };

    return (
      <>
        <ItemsSearch currentItems={currentItems} />
        {items.length > itemsPerPage && (
          <div style={{ margin: "auto", width: "100%", marginTop: 30 }}>
            <ReactPaginate
              previousLabel="précedent"
              nextLabel="suivant"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pageCount}
              pageRangeDisplayed={itemsPerPage}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
              hrefAllControls
              // }}
            />
          </div>
        )}
      </>
    );
  }

  const ItemMyOrder = ({ data , isVendor}) => {
    const [status, setStatus] = useState({
      color: "black",
      name: "Chargement ..",
    })
    const [amount, setAmount] = useState(0);
    const [pIntent, setPaymentIntent] = useState(null);
    // console.log("ItemMyOrder", data);
    const value = data;

    const getAllNameVendor = () => {
      let vn = "";
      data?.data?.forEach((v, i) => {
        if (v.name) {
          vn += v.name;
          if (data?.data[i + 1]?.name) {
            vn += ", ";
          }
        }
      });
      return vn;
    };
    useEffect(()=>{
      getstatus()
    }, [])

    function getstatus() {
      let v = {
        color: "black",
        name: "Chargement ..",
      };
      if (!isVendor && value?.status) {
        // status if "Livré" = color = green

        v.name = "Payé";
        v.color = "green";
        setStatus(v);
        //  status if "Payé" = color = black
        // si status payé = check All subcommand et récuprer tout les status
      } else {
        // récupérer le status du paiement
        let payId = data?.payId;
        fetch(API_URL + "checkPay", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pId: payId,
          }),
        })
          .then((res) => res.json())
          .then(function (response) {
            value.paymentIntent = response.paymentIntent || null
            // console.log("response", response); // This will sometime be empty
            setAmount(response.paymentIntent.amount / 100)
            setPaymentIntent(response.paymentIntent);
            if (response.success) {
              // si le status === successed => Etat = Payé
              if (response.paymentIntent.status === "succeeded") {
                value.status = "Payé";
                v.name = "Payé";
                v.color = 'green';
                setStatus(v);
              }else{
                if (response.paymentIntent.status === "requires_payment_method"){
                  if (response.paymentIntent.last_payment_error){
                    if (response.paymentIntent.last_payment_error.code === "payment_intent_authentication_failure"){
                       
                      v.name = "Refusé";
                      v.color = 'red';
                      setStatus(v);
                    }else{  
                      v.name = "Annulé";
                      v.color = 'red';
                      setStatus(v);
                    }
                    
                  }else{ 
                    v.name = "Annulé-";
                    v.color = 'red';
                    setStatus(v);
                  }
                }else{ 
                  v.name = "En cours";
                  v.color = 'orange';
                  setStatus(v);
                }
              }
              
            } else {
              // alert(response.error);
              v.name = "Invalide";
              v.color = "orange";
              setStatus(v);
            }
          })
          .catch(function (error) {
            console.log("People we have an error!", error);
            v.name = "Invalide";
            v.color = "orange";
            setStatus(v);
            // alert(error.error);
          });
        // sinon status === canceled => Etat = Annulé
        // enregistrer le status
      }
      return v;
    }

    

    
    // console.log('lkj', data)
    return (
      <tr>
        <td>
          {`#${addLeadingZeros(
      data?.orderNumber,
      5
    )}`} {!isVendor ?  getAllNameVendor() : getUserById(data?.userId)?.displayName || ""}
        </td>
         <td>
          {typeof data?.date?.toDate() !== undefined &&
            new Date(data?.date?.toDate()).toLocaleDateString("fr-FR", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
        </td>
        <td>
          {
            !isVendor ? <>
            { data?.livraisons != null && data?.livraisons > 0
            ? "Lettre + suivis"
            : "En main propre"} 
            </>: <>
            { data?.data?.livraison != null && data?.data?.livraison > 0
            ? "Lettre + suivis"
            : "En main propre"} 
            </>
          }
          
          {!isVendor &&  data?.data?.map((d) => {
            if (data?.livraisons != null && data?.livraisons > 0 && d?.suivis?.number) {
              return (
                <>
                  <br />
                  <a href="#">{d?.suivis?.number}</a>
                </>
              );
            }
          })}
        </td>
        <td style={{ color: status?.color || "black" }}>
          {status?.name}
        </td>
        <td>{!isVendor ? data.amount : getPriceTotal(data)}€</td>
        <td>
          {status?.name === "Payé" && (
            <a
              href="#your-order"
              onClick={() => {
                const pIntentd = pIntent || data.paymentIntent
                  let da = {
                    order: data,
                    payment: pIntentd, 
                    isVendor: isVendor
                  }
                  sYOrder(da); // set data to show 
                

              }}
            >
              <i class="fa fa-eye"></i>
            </a>
          )}
          {isVendor && data?.orderFinished && 
          <i style={{paddingLeft:10, color:'green'}} class="fa fa-check"></i>}
          
        </td>
      </tr>
    );
  };

  function ItemsMyOrder({ currentItems, isVendor }) {
    return (
      <>
        {currentItems &&
          currentItems.map((e) => <ItemMyOrder data={e} key={e?.id} isVendor={isVendor} />)}
      </>
    );
  }

  function PaginatedItemsMyOrder({ items, itemsPerPage, isVendor = false }) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = React.useState(null);
    const [pageCount, setPageCount] = React.useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = React.useState(0);

    React.useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(items.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(items.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % items.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };

    return (
      <>
        <ItemsMyOrder currentItems={currentItems} isVendor={isVendor}/>
        {items.length > itemsPerPage && (
          <div style={{ margin: "auto", width: "100%", marginTop: 30 }}>
            <ReactPaginate
              previousLabel="précedent"
              nextLabel="suivant"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pageCount}
              pageRangeDisplayed={itemsPerPage}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
              hrefAllControls
            />
          </div>
        )}
      </>
    );
  }

  function PaginatedItemsMyCards({ items, itemsPerPage }) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = React.useState(null);
    const [pageCount, setPageCount] = React.useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = React.useState(0);

    React.useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(items.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(items.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % items.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };

    return (
      <>
        <ItemsMyCards currentItems={currentItems} />
        {items.length > itemsPerPage && (
          <div style={{ margin: "auto", width: "100%", marginTop: 30 }}>
            <ReactPaginate
              previousLabel="précedent"
              nextLabel="suivant"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pageCount}
              pageRangeDisplayed={itemsPerPage}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
              hrefAllControls
            />
          </div>
        )}
      </>
    );
  }

  const updateUserForm = (value) => {
    // formulaire niveau1 vendeur
    if (loadingForm1) {
      return;
    }

    // return;
    setLoadingForm1(true);
    // console.log("ddd", value);
    // return;
    db.collection("users")
      .doc(user.uid)
      .update({
        nom: value?.lastName?.trim(),
        prenom: value?.firstName?.trim(),
        displayName: `${value?.lastName?.trim()} ${value.firstName?.trim()}`,
        address: {
          name: value.addressperso?.trim(),
          complement: value.complementperso
            ? value.complementperso?.trim()
            : null,
          city: value.cityperso?.trim(),
          state: value.stateperso?.trim(),
          country: value.countryperso?.trim(),
          zipcode: value.zipcodeperso?.trim(),
        },
        location: userformhasAdresseLocation
          ? {
              name: value.addresspersolocation?.trim(),
              complement: value.complementpersolocation
                ? value.complementpersolocation?.trim()
                : null,
              city: value.citypersolocation?.trim(),
              state: value.statepersolocation?.trim(),
              country: value.countrypersolocation?.trim(),
              zipcode: value.zipcodepersolocation?.trim(),
            }
          : null,
        phone: value.phoneperso || null,
        _update_At: new Date(),
      })
      .then(async () => {
        alert("Données utilisateur mis à jour!");
        setLoadingForm1(false);
      })
      .catch((err) => {
        setLoadingForm1(false);
        console.log("updateVendorForm", err);
      });
  };

  const [showCardPlus, setShowCardPlus] = React.useState(false);
  const [showEncherePlus, setShowEncherePlus] = React.useState(false);
  const [showDeckPlus, setShowDeckPlus] = React.useState(false);
  const [showSearchPlus, setSearchDeckPlus] = React.useState(false);
  const [showOrder, setShowOrder] = React.useState(false);
  const [editOrder, setEditOrder] = React.useState(false);
  // information concernant la commande en tant que VENDEUR a voir ou modifié
  const [orderInfo, setOrderInfo] = React.useState(null);
  // information concernant la commande en tant que VENDEUR a voir ou modifié
  const [yourorderInfo, setYourOrderInfo] = React.useState(null);
  const [seeYourOrder, setSeeYourOrder] = React.useState(false);
  const [editMyCard, setEditMyCard] = React.useState(false);
  const [infoMyCard, setInfoMyCard] = React.useState(null);

  const [editMyEnchere, setEditMyEnchere] = React.useState(false);
  const [infoMyEnchere, setInfoMyEnchere] = React.useState(null);

  const [editMyBDD, setEditMyBDD] = React.useState(false);
  const [infoMyBDD, setInfoMyBDD] = React.useState(null);

  const [editMySearch, setEditMySearch] = React.useState(false);
  const [infoMySearch, setInfoMySearch] = React.useState(null);

  const [openMessage, setOpenMessage] = React.useState(false);
  const [dataMessages, setDataMessages] = React.useState(null);
  const [openChangePass, setOpenChangePass] = React.useState(false);
  const [showCards, setShowCards] = React.useState(false);

  const [search, setSearch] = React.useState("");
  const [idSearch, setIdSearch] = React.useState(null);
  const [cmdId, setCmdId] = React.useState(null);
  const [expansion, setExpansion] = React.useState(null);
  const [expansionName, setExpansionName] = React.useState(null);
  const [order, setOrderBy] = React.useState("0");
  const [openNewListCard, setOpenNewListCard] = React.useState(false);
  const [subscriptionData, setSubscriptionData] = React.useState(null);
  const [subscriptionModalPay, setSubscriptionModalPay] = React.useState(false);
  const [paymentIntent, setPaymentIntent] = React.useState(null);
  const [accountIsValid, setAccountIsValid] = useState(false);
  const [isVendorOrPendingVendor, setVorPV] = useState(false);
  const [actualABNInfo, setActualABNInfo] = useState(false);
  const [actualABN, setActualABN] = useState("Free");
  const [stateCard, setStateCard] = React.useState("Mint");
  const [firstCard, setFirstCard] = React.useState(false);
  const [livraison, setLivraison] = React.useState(false);
  const [mainpropre, setMainPropre] = React.useState(false);
  const [prix, setPrix] = React.useState(null);
  const [qteCard, setQteCard] = React.useState(null);
  const [langCard, setLangCard] = React.useState("fr");
  const [publishCard, setPublishCard] = React.useState("1");
  const [loadAddSearchCard, setLoadAddSearchCard] = useState(false);
  const [extensionListCard, setExtensionListCard] = useState([]);

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const options2 = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const [hideDevenirVendeur, setHideDevenirVendeur] = React.useState(false);

  const hcCardP = () => {
    setShowCardPlus(false);
    window.location.replace("tableau-de-bord#card");
  };
  const hsCardP = () => {
    getExtensionList();
    setShowCardPlus(true);
  };
  const hcEnchereP = () => setShowEncherePlus(false);
  const hsEnchereP = () => {
    getExtensionList();
    setShowEncherePlus(true);
  };
  const hcDeckP = () => setShowDeckPlus(false);
  const hsDeckP = () => {
    getExtensionList();
    setShowDeckPlus(true);
  };
  const hcSearchP = () => {
    setSearchDeckPlus(false);
    setSearchCardSelect(null);
    // setExpansion(null); on garde ici la liste choisi et l'extension et la langue
    setQteCard(null);
    setStateCard("Mint");
    setFirstCard(false);
    setPublishCard("1");
  };
  const hsSearchP = () => {
    getExtensionList();
    setSearchDeckPlus(true);
  };

  const hOrder = () => {
    setShowOrder(false);
    setOrderInfo(null);
  };
  const sOrder = (data) => {
    setOrderInfo(data);
    setShowOrder(true);
  };

  const hEOrder = () => {
    setEditOrder(false);
    setOrderInfo(null);
  };
  const sEOrder = (data) => {
    setOrderInfo(data);
    setEditOrder(true);
  };

  const hYOrder = () => {
    setSeeYourOrder(false);
    setorderTraitement(false)
    setYourOrderInfo(null);
  };
  const [orderTraitement, setorderTraitement] = useState(false);

  const sYOrder = (data) => {
    setorderTraitement(false)
    setYourOrderInfo(data);
    if (data?.isVendor && data?.order?.orderFinished){
      setorderTraitement(true);
    } 
    setSeeYourOrder(true);
  };

  const hImCOrder = () => {
    setQteCard(null);
    setLangCard("fr");
    setStateCard(null);
    setFirstCard(false);
    setPublishCard("1");
    setPrix(null);
    setEditMyCard(false);
    setInfoMyCard(null);
  };

  const sImCOrder = (card) => {
    setInfoMyCard(card);
    setPrix(Number(card?.price).toFixed(2));
    setQteCard(card?.qte);
    setStateCard(card.etat);
    setLangCard(card.lang);
    setPublishCard(card.state);
    setFirstCard(card.first);
    setLivraison(card?.livraison || false);
    setMainPropre(card?.mainpropre || false);

    setEditMyCard(true);
  };

  const hImEOrder = () => {
    setEditMyEnchere(false);
    setInfoMyEnchere(null);
  };

  const sImEOrder = (enchere) => {
    setInfoMyEnchere(enchere);
    setEditMyEnchere(true);
  };

  const hImBDD = () => {
    setEditMyBDD(false);
    setInfoMyBDD(null);
  };

  const sImBDD = (bdd) => {
    getExtensionList();
    setInfoMyBDD(bdd);
    setEditMyBDD(true);
  };

  const hImSearch = () => {
    setEditMySearch(false);
    setQteCard(null);
    setInfoMySearch(null);
    setLangCard("fr");
    setStateCard(null);
    setFirstCard(false);
    setPublishCard("1");
  };

  const sImSearch = (bdd) => {
    setInfoMySearch(bdd);
    setQteCard(bdd?.qte);
    setStateCard(bdd.etat);
    setLangCard(bdd.lang);
    setPublishCard(bdd.state);
    setFirstCard(bdd.first);
    setEditMySearch(true);
  };

  const hMessages = () => {
    setOpenMessage(false);
    setDataMessages(null);
  };

  const sMessages = (messages) => {
    setDataMessages(messages);
    setOpenMessage(true);
  };

  const hideChangePass = () => {
    setOpenChangePass(false);
  };

  const showChangePass = () => {
    setOpenChangePass(true);
  };

  const hsCard = () => {
    setShowCards(false);
  };

  const getUser = async (uid, cb) => {
    await App.firestore()
      .collection("users")
      .doc(uid)
      .get()
      .then(async (docd) => {
        var user = docd.data();
        user.id = docd.id;
        cb(user);
      })
      .catch((error) => {
        cb(null);
      });
  };

  const sSCard = () => {
    if (user) {
      getCardsUser(user.customerStripeId);
    } else {
      getUser(App.auth().currentUser.uid, (u) => {
        setUser(u);
        getCardsUser(u);
      });
    }
    setShowCards(true);
  };

  if (key === "card" && window.location.href.includes("openaddcard=true")) {
    setTimeout(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const id = urlParams.get("id");
      if (id) {
        setShowCardPlus(true);
        setIdSearch(id);
        // test sinon faire la recherche direct de l'id de la carte pour préremplir les champs
        if (id === "1234") {
          setSearch("Nom de la carte de test");
        }
      }
    }, 300);
  }

  if (key === "supportclient" && window.location.href.includes("cmd=")) {
    setTimeout(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const cmd = urlParams.get("cmd");
      if (cmd) {
        setCmdId(cmd);
      }
    }, 300);
  }

  const [loadingStripe, setLoadingStripe] = React.useState(false);

  useEffect(() => {
    if (key === "becomevendor" && openVendorStep2 && user) {
      getStripeVendorInfo();
    }
  }, [openVendorStep2]);

  const getStripeVendorInfo = () => {
    console.log('getStripeVendorInfo')
    getInfoVendorPending(user.pending_vendor_id, (pendingVendor) => {
      if (pendingVendor?.customerStripeId && !pendingVendor.complete) {
        setLoadingStripe(true);
        fetch(`${API_URL}getInfoStripeConnect`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // eslint-disable-next-line object-shorthand
            stripe_pro_id: pendingVendor.customerStripeId,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            // console.log("getStripeVendorInfo data", data);
            if (data.success) {
              setLoadingStripe(false);
              if (
                !data.accountLink?.charges_enabled ||
                !data.accountLink?.payouts_enabled
              ) {
                alert("Votre compte stripe n'est pas complétement configurer!");
              } else {
                if (
                  data.accountLink?.charges_enabled &&
                  data.accountLink?.payouts_enabled
                ) {
                  db.collection("pendingvendor")
                    .doc(user.uid)
                    .update({ _step_2: "complete", _update_At: new Date() })
                    .then(() => {
                      setOpenVendorStep2(false);
                      setOpenVendorStep3(true);
                    });
                  // le compte est valider
                  alert("Votre compte stripe est complétement configurer!");
                }
              }
            } else {
              setLoadingStripe(false);
              alert("Une erreur s'est produite");
            }
          })
          .catch((error) => {
            setLoadingStripe(false);
            console.log("getStripeVendorInfo error", error);
            // eslint-disable-next-line no-alert
          });
      }
    });
  };
  const [pwd, setPWd] = React.useState(null);
  const [pwdLoading, setPWdLoading] = React.useState(null);
  const deleteUser = (e) => {
    e.preventDefault();
    if (pwdLoading) {
      return;
    }
    if (pwd.trim().length > 0) {
      // console.log("dez", pwd);
      setPWdLoading(true);
      const user = App.auth().currentUser;
      App.auth()
        .signInWithEmailAndPassword(user.email, pwd.trim())
        .then(() => {
          db.collection("pendingvendor").doc(user.uid).delete();
          db.collection("newsletters").doc(user.uid).delete();
          db.collection("users")
            .doc(user.uid)
            .delete()
            .then(() => {
              user
                .delete()
                .then(() => {
                  // User deleted.

                  App.auth()
                    .signOut()
                    .then((res) => {
                      history.push({
                        pathname: "/connexion",
                        state: { delete: true },
                      });
                      //do something else with res
                    })
                    .catch((err) => {
                      setPWdLoading(false);
                      alert("Une erreur s'est produite, réessayer plus tard.");
                    });
                })
                .catch((error) => {
                  setPWdLoading(false);
                  alert("Une erreur s'est produite, réessayer plus tard.");
                });
            })
            .catch((error) => {
              setPWdLoading(false);
              alert("Une erreur s'est produite, réessayer plus tard.");
            });
        })
        .catch(() => {
          setPWdLoading(false);
          alert("Mot de passe invalide");
        });
    } else {
      setPWdLoading(false);
      alert("Le mot de passe ne peut être vide");
    }
  };

  const [invoicesSub, setInvoiceSub] = React.useState([]);
  const [invoicesSubLoad, setInvoiceSubLoad] = React.useState(false);
  const [dataSearchList, setDataSearchList] = React.useState([]);
  const [myCards, setMyCards] = React.useState([]);
  const [loadmycards, setMyCardsLoad] = React.useState(false);

  const [myOrders, setMyOrders] = React.useState([]);
  const [loadmyorders, setMyOrdersLoad] = React.useState(false);

  const [myVendorOrders, setMyVendorOrders] = React.useState([]);
  const [loadmyvendororders, setMyVendorOrdersLoad] = React.useState(false);
  const [users, setUsers] = React.useState([]);


  const getUserById = (uid) => {
    let us = null;
    users?.forEach((u) => {
      if (u.id === uid){
        us = u
      }
    })
    return us;
  }

  const loadVListOrder = () => {
    if (loadmyvendororders) {
      return;
    } 
    db.collection('users').get()
    .then((querySnapshot) => {
      var data = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          var d = doc.data();
          d.id = doc.id;
          data.push(d);
        });
        // console.log('data', data)
        setUsers(data)
    })
    .catch((err) => {
      console.log("getusers err", err);
        
    })
    setMyVendorOrdersLoad(true); 
    db.collection(`vendor/${user.id}/orders`) 
      .orderBy("date", "desc")
      .get()
      .then((querySnapshot) => {
        var data = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          var d = doc.data();
          d.id = doc.id;
          data.push(d);
        });
        // console.log('data', data)
        setMyVendorOrders(data);
        setMyVendorOrdersLoad(false);
      })
      .catch((err) => {
        setMyVendorOrders([]);
        setMyVendorOrdersLoad(false);
        console.log("setMyOrders err", err, user.id);
        // alert("Une erreur s'est produite");
      });
  }

  const loadSMyListOrder = () => {
    if (loadmyorders) {
      return;
    }
    setMyOrdersLoad(true);
    db.collection("orders")
      .where("userId", "==", user.id)
      .orderBy("date", "desc")
      .get()
      .then((querySnapshot) => {
        var data = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          var d = doc.data();
          d.id = doc.id;
          data.push(d);
        });
        setMyOrders(data);
        setMyOrdersLoad(false);
      })
      .catch((err) => {
        setMyOrders([]);
        setMyOrdersLoad(false);
        console.log("setMyOrders err", err, user.id);
        alert("Une erreur s'est produite");
      });
  };

  const loadSMyListCard = () => {
    if (loadmycards) {
      return;
    }
    setMyCardsLoad(true);
    db.collection("cards")
      .where("userId", "==", user.id)
      .orderBy("_update_At", "desc")
      .get()
      .then((querySnapshot) => {
        var data = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          var d = doc.data();
          d.id = doc.id;
          data.push(d);
        });
        setMyCardsLoad(false);
        setMyCards(data);
      })
      .catch((err) => {
        setMyCards([]);
        setMyCardsLoad(false);
        console.log("setMyCards err", err, user.id);
        alert("Une erreur s'est produite");
      });
  };

  useEffect(() => {
    if (key === "subscription" && user) {
      if (user.id) {
        setInvoiceSubLoad(true);
        fetch(`${API_URL}getInvoiceSubscription`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // eslint-disable-next-line object-shorthand
            customerId: user.customerStripeId,
          }),
        })
          .then((res) => res.json())
          .then((d) => {
            // console.log("getInvoiceSubscription data", d.data.length);
            if (d.success) {
              setInvoiceSub(d.data);
            }
            setInvoiceSubLoad(false);
          });
      }
    }

    if (key === "search" && user) loadSearchListCard();
    if (key === "card" && user) loadSMyListCard();
    if (key === "your-order" && user) loadSMyListOrder();
    if (key === "order" && user) loadVListOrder();
    if (key === "dashboard" && user) loadVListOrder();
  }, [key, user, setInvoiceSub, setInvoiceSubLoad]);

  const loadSearchListCard = () => {
    db.collection("searchs")
      .where("userId", "==", user.id)
      .orderBy("_update_At", "desc")
      .get()
      .then(async (querySnapshot) => {
        var data = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          var d = doc.data();
          d.id = doc.id;
          data.push(d);
        });
        setDataSearchList(data);
        // console.log("setDataSearchList", data);
      })
      .catch((err) => {
        setDataSearchList([]);
        console.log("loadSearchListCard err", err);
        alert("Une erreur s'est produite");
      });
  };

  const ItemMyCard = ({ data }) => {
    console.log("ItemMyCard", data)
    function getEx() {
      if (data?.expansion && data?.card?.card_sets) {
        var d = null;
        data?.card?.card_sets.forEach((e) => {
          if (e.set_name === data?.expansion || e.set_code.includes(data?.expansion)) d = e;
        });
        return d;
      } else return null;
    }

    function getCode() {
      var d = getEx()?.set_code || "";
      if (data?.lang === "fr") {
        return d.replace("EN", data?.lang.toUpperCase());
      } else {
        return d;
      }
    }

    return (
      <tr>
        <td>
          {data?.lang === "fr" ? data?.card?.name : data?.card?.name_en} (
          {data?.etat})
        </td>
        <td style={{ fontSize: 14 }}> 
          {data.rarete || getEx()?.set_rarity_code.replace("(", "").replace(")", "")}
        </td>
        <td style={{ fontSize: 14, lineHeight: "13px" }}>
          {getCode()}
          <br />
          {data?.first && (
            <span style={{ fontSize: 10, color: "blue" }}>
              Première Edition
            </span>
          )}
        </td>
        <td>{data?.qte}</td>
        <td style={{ fontSize: 14 }}>{data?.price}€</td>
        <td style={{ lineHeight: "13px", fontSize: 12 }}>
          Dernière modification
          <br />
          <b style={{ fontSize: 10 }}>
            {new Date(data?._update_At?.toDate()).toLocaleDateString(
              "fr-FR",
              options2
            )}
          </b>
        </td>
        <td>{data?.views || "0"}</td>
        <td>
          <a
            href="#"
            onClick={() => {
              history.push({
                pathname: `/product/${getCode().split("-")[0]}/${
                  getCode().split("-")[1]
                }/${data?.id}`,
                state: {
                  data: data,
                },
              });
            }}
          >
            <i class="fa fa-eye"></i>
          </a>
          <a
            href="#card"
            onClick={() => {
              sImCOrder(data);
            }}
            style={{ paddingLeft: 20 }}
          >
            <i class="fa fa-edit"></i>
          </a>
        </td>
      </tr>
    );
  };

  const ItemListSearch = ({ data }) => {
    function getEx() {
      if (data?.expansion && data?.card?.card_sets) {
        var d = null;
        data?.card?.card_sets.forEach((e) => {
          if (e.set_name === data?.expansion) d = e;
        });
        return d;
      } else return null;
    }

    function getCode() {
      var d = getEx()?.set_code || "";
      if (data?.lang === "fr") {
        return d.replace("EN", data?.lang.toUpperCase());
      } else {
        return d;
      }
    }

    return (
      <tr>
        <td>
          {data?.lang === "fr" ? data?.card?.name : data?.card?.name_en} (
          {data?.etat})
        </td>
        <td style={{ fontSize: 14 }}>
          {getEx()?.set_rarity_code.replace("(", "").replace(")", "")}
        </td>
        <td style={{ fontSize: 14, lineHeight: "13px" }}>
          {getCode()}
          <br />
          {data?.first && (
            <span style={{ fontSize: 10, color: "blue" }}>
              Première Edition
            </span>
          )}
        </td>
        <td>{data?.qte}</td>
        <td style={{ lineHeight: "13px", fontSize: 12 }}>
          Dernière modification
          <br />
          <b style={{ fontSize: 10 }}>
            {new Date(data?._update_At?.toDate()).toLocaleDateString(
              "fr-FR",
              options2
            )}
          </b>
        </td>
        <td style={{ color: data?.state === 1 ? "green" : "red" }}>
          {data?.state === 1 ? "oui" : "non"}
        </td>
        <td>{data?.views || "0"}</td>
        <td>
          {data?.state === 1 && (
            <a
              href="#"
              onClick={() => {
                history.push({
                  pathname: `/search/${getCode().split("-")[0]}/${
                    getCode().split("-")[1]
                  }/${data?.id}`,
                  state: {
                    data: data,
                  },
                });
              }}
            >
              <i class="fa fa-eye"></i>
            </a>
          )}

          <a
            href="#search"
            onClick={() => {
              sImSearch(data); //data show to search modal
            }}
            style={{ paddingLeft: 20 }}
          >
            <i class="fa fa-edit"></i>
          </a>
        </td>
      </tr>
    );
  };

  const linkStripe = () => {
    if (!loadingStripe) {
      getInfoVendor((vendor) => {
        setLoadingStripe(true);

        fetch(`${API_URL}proLoginlinkStripe`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // eslint-disable-next-line object-shorthand
            stripe_pro_id: vendor?.customerStripeId || null,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            // console.log("data", data);
            // if (data.success) {
            //   // setLoadingStripe(false);
            //   window.location.href = data.accountLink.url;
            // } else {
            //   setLoadingStripe(false);
            //   alert("Une erreur s'est produite");
            // }

            if (data.success) {
              setLoadingStripe(false);
              const strWindowFeatures =
                "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes";
              window.open(
                data.loginlink.url,
                "CNN_WindowName",
                strWindowFeatures
              );
            } else {
              // console.log("data", data);
              if (
                data.error &&
                data.error.raw &&
                data.error.raw.message ===
                  "Cannot create a login link for an account that has not completed onboarding."
              ) {
                setLoadingStripe(false);
                alert(
                  "Vous devez avant tout terminer la configuration de votre compte, contactez nous."
                );
              } else {
                alert("Une erreur s'est produite");
              }
            }
          })
          .catch((error) => {
            setLoadingStripe(false);
            console.log("data", error);
            // eslint-disable-next-line no-alert
            alert("Une erreur s'est produite");
          });
      });
    }
  };

  const createStripe = () => {
    if (!loadingStripe) {
      getInfoVendorPending(user.pending_vendor_id, (pendingVendor) => {
        if (pendingVendor.customerStripeId === null) {
          // envoyez une request a api pour créer et lié un compte stripe professionnel à cet utilisateur.
          setLoadingStripe(true);
          fetch(`${API_URL}procreateStripe`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              // eslint-disable-next-line object-shorthand
              email: user.email,
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              // console.log("data", data);
              if (data.success) {
                db.collection("pendingvendor")
                  .doc(user.id)
                  .update({
                    customerStripeId: data.stripe_pro_id,
                    _update_At: new Date(),
                  })
                  .then(async (doc) => {
                    // setLoadingStripe(false);
                    window.location.href = data.accountLink.url;
                  });
              } else {
                setLoadingStripe(false);
                alert("Une erreur s'est produite");
              }
            })
            .catch((error) => {
              setLoadingStripe(false);
              console.log("data", error);
              // eslint-disable-next-line no-alert
              alert("Une erreur s'est produite");
            });
        } else {
          setLoadingStripe(true);

          fetch(`${API_URL}prolinkStripe`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              // eslint-disable-next-line object-shorthand
              stripe_pro_id: pendingVendor.customerStripeId,
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              // console.log("data", data);
              if (data.success) {
                // setLoadingStripe(false);
                window.location.href = data.accountLink.url;
              } else {
                setLoadingStripe(false);
                alert("Une erreur s'est produite");
              }
            })
            .catch((error) => {
              setLoadingStripe(false);
              console.log("data", error);
              // eslint-disable-next-line no-alert
              alert("Une erreur s'est produite");
            });
        }
      });
    }
  };

  const modalPayHide = () => {
    setSubscriptionModalPay(false);
    setSubscriptionData(null);
  };
  const modalPayShow = (data) => {
    setSubscriptionData(data);
    setSubscriptionModalPay(true);
  };

  const createSubscription = async (price, name, reload = false) => {
    const priceId = price.id;
    setLoadingStripe(true);
    if (user.customerStripeId) {
      if (price.type === "recurring") {
        fetch(`${API_URL}priceToSub`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // eslint-disable-next-line object-shorthand
            price_id: priceId,
            customerStripeId: user.customerStripeId,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            // console.log("createSubscription data", data);
            if (data.success) {
              setLoadingStripe(false);
              const t = {
                subscriptionId: data.subscription.subscriptionId,
                clientSecret: data.subscription.clientSecret,
                pending_setup_intent: data.subscription.pending_setup_intent,
                price: price,
                name: name,
                reload: reload,
              };
              modalPayShow(t);
            } else {
              setLoadingStripe(false);
              alert("Une erreur s'est produite");
            }
          })
          .catch((error) => {
            setLoadingStripe(false);
            console.log("getInfoStripePrice error", error);
            // eslint-disable-next-line no-alert
          });
      } else {
        alert("Une erreur s'est produite");
      }
    } else {
      alert("Une erreur s'est produite");
    }
  };
  const getInfoStripePrice = (id, name, reload = false) => {
    setLoadingStripe(true);
    fetch(`${API_URL}getInfoStripePrice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // eslint-disable-next-line object-shorthand
        stripe_price_id: id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("getInfoStripePrice data", data);
        if (data.success) {
          createSubscription(data.price, name, reload);
        } else {
          setLoadingStripe(false);
          alert("Une erreur s'est produite");
        }
      })
      .catch((error) => {
        setLoadingStripe(false);
        console.log("getInfoStripePrice error", error);
        // eslint-disable-next-line no-alert
      });
  };

  const subs = (t, reload = false) => {
    getUser(App.auth().currentUser?.uid, (u) => {
      setUser(u);
    });
    switch (t) {
      case "Pro":
        getInfoStripePrice(Config.ABNPRO, "Pro", reload);
        break;
      case "Entreprise":
        getInfoStripePrice(Config.ABNENTREPRISE, "Entreprise", reload);
        break;

      case "Free":
        if (actualABN !== "Free") {
          if (confirm("Se désabonner et continuer gratuitement?")) {
            setLoadingStripe(true);
            getUser(App.auth().currentUser?.uid, (u) => {
              if ((u && u.pending_vendor_id) || (u && reload)) {
                db.collection(reload ? "vendor" : "pendingvendor")
                  .doc(reload ? u.id : u.pending_vendor_id)
                  .update({
                    subscription: "complete",
                    subscription_info: {
                      subscription_id: null,
                      name: "Free",
                      price_id: null,
                      pay_id: null,
                      status: "succeeded",
                      payment_method: null,
                      livemode: null,
                      _create_At: new Date(),
                      _canceled_at: null,
                    },
                    _step_3: "complete",
                    _update_At: new Date(),
                  })
                  .then(() => {
                    if (reload) {
                      window.location.reload();
                    }
                    setLoadingStripe(false);
                    setOpenVendorStep3(false);
                    setOpenVendorStep4(true);
                    alert(`Votre abonnement Gratuit à été valider.`);
                  })
                  .catch((err) => {
                    setLoadingStripe(false);
                    alert(
                      "Uune erreur s'est produite lors de l'enregistrement des données. Contactez nous."
                    );
                  });
              }
            });
          }
        } else {
          setLoadingStripe(true);
          getUser(App.auth().currentUser?.uid, (u) => {
            if ((u && u.pending_vendor_id) || (u && reload)) {
              db.collection(reload ? "vendor" : "pendingvendor")
                .doc(reload ? u.id : u.pending_vendor_id)
                .update({
                  subscription: "complete",
                  subscription_info: {
                    subscription_id: null,
                    name: "Free",
                    price_id: null,
                    pay_id: null,
                    status: "succeeded",
                    payment_method: null,
                    livemode: null,
                    _create_At: new Date(),
                    _canceled_at: null,
                  },
                  _step_3: "complete",
                  _update_At: new Date(),
                })
                .then(() => {
                  if (reload) {
                    window.location.reload();
                  }
                  setLoadingStripe(false);
                  setOpenVendorStep3(false);
                  setOpenVendorStep4(true);
                  alert(`Votre abonnement Gratuit à été valider.`);
                })
                .catch((err) => {
                  setLoadingStripe(false);
                  alert(
                    "Uune erreur s'est produite lors de l'enregistrement des données. Contactez nous."
                  );
                });
            }
          });
        }

        break;

      default:
        break;
    }
  };

  const getInfoVendor = async (cb) => {
    if (user) {
      await App.firestore()
        .collection("vendor")
        .doc(user.id)
        .get()
        .then((docd) => {
          var vendor = docd.data();
          vendor.id = docd.id;
          cb(vendor);
        })
        .catch((error) => {
          cb(null);
        });
    }
  };

  const [to, setToSold] = React.useState(0)
  useEffect(()=>{
    getInfoVendor((vendor) => { 
      if (vendor?.customerStripeId){ 
        fetch(`${API_URL}charges`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // eslint-disable-next-line object-shorthand
            customerStripeId: vendor?.customerStripeId
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data){ 
              let total = 0
              data?.charges?.data?.forEach((d) => {
                total += d.amount;
              })
              setToSold(total)
            }
            
          }) 
      }
      
      })
  }, [user])

  function totalSold () {
    let b = to / 100;
    
    return b.toFixed(2)

  }

  function calNbrCartSold () {
    let b = 0
    myVendorOrders?.forEach((d)=> { 
      // if (d.status === 'paid'){
        d.data?.products.forEach((ds) => {
         b += ds?.qte || 0;
        });
      // } 
    }) 
    return b;
  }

  const checkABN = (abn) => {
    if (abn._canceled_at === null && abn.subscription_id) {
      // on va regarder si l'abonnement est toujours valide

      fetch(`${API_URL}getInfoSubscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // eslint-disable-next-line object-shorthand
          subscription_id: abn.subscription_id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("getInfoSubscription data", data, abn.subscription_id);
          if (data.success) {
            setActualABNInfo(data.subscription);
            if (data.subscription.status === "active") {
              // tout va bien.
            } else {
              // l'abonnement à expirer ...
              setActualABN("Free");
            }
          } else {
            // alert("Une erreur s'est produite ");
            setActualABN("Free");
          }
        })
        .catch((error) => {
          setLoadingStripe(false);
          console.log("getStripeVendorInfo error", error);
          // eslint-disable-next-line no-alert
        });
    }
  };

  const [vendeur, setVendeur] = useState(null);
  // set user information
  useEffect(() => {
    if (vendeur) {
      // console.log("vendeur", vendeur);
      setValue("name", vendeur.name);
      setValue("phone", vendeur.phone);
      setValue("address", vendeur.address?.name || null);
      setValue("complement", vendeur?.address?.complement || null);
      setValue("country", vendeur.address?.country || null);
      setValue("state", vendeur.address?.state || null);
      setValue("city", vendeur.address?.city || null);
      setValue("zipcode", vendeur.address?.zipcode || null);
    }
  }, [vendeur]);

  useEffect(() => {
    if (user) {
      // console.log("user ---", user);
      // votre compte utilisateur
      setValue("lastName", user.nom || "");
      setValue("firstName", user.prenom || "");
      setValue("username", user?.displayName || "");
      setValue("email", user?.email || "");
      setValue("phoneperso", user?.phone || null);
      setValue("addressperso", user.address?.name || null);
      setValue("complementperso", user?.address?.complement || null);
      setValue("countryperso", user.address?.country || null);
      setValue("stateperso", user.address?.state || null);
      setValue("cityperso", user.address?.city || null);
      setValue("zipcodeperso", user.address?.zipcode || null);
      setUFHAL(user.location != null ? true : false);
      setValue("same-address", user.location != null ? true : false);
      if (user?.location !== null) {
        setValue("addresspersolocation", user.location?.name || null);
        setValue("complementpersolocation", user.location?.complement || null);
        setValue("countrypersolocation", user.location?.country || null);
        setValue("statepersolocation", user.location?.state || null);
        setValue("citypersolocation", user.location?.city || null);
        setValue("zipcodepersolocation", user.location?.zipcode || null);
      }
    }
  }, [user]);
  const [loadingprepare, setLoadingprepare] = useState(false);
  const prepareInfoVendor = () => {
    setLoadingprepare(true);
    getInfoVendor((vendor) => {
      setVendeur(vendor);
      // console.log("vendor", vendor);
      if (vendor.complete) {
        if (vendor.subscription === "complete") {
          // verifié l'abonnement du vendeur
          if (vendor.subscription_info?.name) {
            setLoadingprepare(false);
            setAccountIsValid(true);
            setActualABN(vendor.subscription_info?.name);

            if (vendor.subscription_info?.name !== "Free") {
              checkABN(vendor.subscription_info);
            } else {
              if (vendor.subscription_info?.name === "Free") {
                // l'utilisateur a un abonnement gratuit !
              }
            }
          }
        } else {
          setLoadingprepare(false);
        }
      } else {
        setLoadingprepare(false);
      }
    });
  };

  const [loadEx, setLoadEx] = useState(false);
  const [extensionList, setExpansionList] = useState([]);
  const [loadSearch, setLoadSearch] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [searchCardSelect, setSearchCardSelect] = useState(null);

  const comparerParDate = (a, b) => new Date(b.tcg_date) - new Date(a.tcg_date);

  const getExtensionList = () => {
    if (loadEx || extensionList.length !== 0) {
      return;
    }

    setLoadEx(true);
    fetch(`https://db.ygoprodeck.com/api/v7/cardsets.php`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLoadEx(false);
        // console.log("setExpansionList data", data);
        const tableauTrié = data.sort(comparerParDate);
        setExpansionList(tableauTrié);
      })
      .catch((error) => {
        setLoadEx(false);
        console.log("getExtensionList error", error);
        // eslint-disable-next-line no-alert
      });
  };
  const [forceReload, setReloadF] = useState(false);
  const [alldata, setAlldata] = useState(null)
  const searchCard = () => {
    if (loadSearch || (search.trim() === "" && expansion === null)) {
      return;
    }
    setLoadSearch(true);
    if (alldata){
      let res = [] 
      alldata?.data?.forEach((d) => {
        let add = false 
        // console.log('d', d)
        d?.card_sets?.forEach((ds) => { 
          if (ds.set_code.includes(expansion)) {
            d.set_code = ds.set_code;
            add = true
          };
        })
      if (add){
        res.push(d);
      }
      }) 
      if (order === "0") setSearchList({data :res});
      if (order === "1") { setSearchList({ data: res.reverse() });}
      if (order === "2"){
        res.sort((a, b) => (a.set_code > b.set_code) ? 1 : ((b.set_code > a.set_code) ? -1 : 0));
        // console.log('res', res)
        setSearchList({data :res});
      }
      setLoadSearch(false);
    }else{
      let query = `https://db.ygoprodeck.com/api/v7/cardinfo.php`;
      fetch(query, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          let res = [] 
          setAlldata(data)
          data?.data?.forEach((d) => {
            let add = false 
            // console.log('d', d)
            d?.card_sets?.forEach((ds) => { 
              if (ds.set_code.includes(expansion)) {
                d.set_code = ds.set_code;
                add = true
              };
            })
          if (add){
            res.push(d);
          }
          }) 
          if (order === "0") setSearchList({data :res});
          if (order === "1") { setSearchList({ data: res.reverse() });}
          if (order === "2"){
            res.sort((a, b) => (a.set_code > b.set_code) ? 1 : ((b.set_code > a.set_code) ? -1 : 0));
            // console.log('res', res)
            setSearchList({data :res});
          }
          setLoadSearch(false);
        }).catch((error) => {
          setLoadSearch(false);
          console.error('error', error)
        })
    }
    // console.log("search", expansion, search, typeof order);
    


      

    // var query = `https://db.ygoprodeck.com/api/v7/cardinfo.php?fname=${search.trim()}&language=fr`;
    // if (expansion) query += "&cardset=" + expansion;
    // // console.log("query", query);
    // fetch(query, {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     setLoadSearch(false);
    //     // console.log("searchCard data", data);
    //     if (order === "0") setSearchList(data);
    //     if (order === "1") {
    //       setSearchList({ data: data.data.reverse() });
    //     }
    //   })
    //   .catch((error) => {
    //     setLoadSearch(false);
    //     console.log("searchCard error", error);
    //     // eslint-disable-next-line no-alert
    //   });
    // window.location.href = `/search?q=${search}&type=${type}&expansion=${expansion}&order=${order}&page=1`;
  };
  useEffect(() => {
    if (searchList?.data?.length > 0) { 
      if (order === "0") setSearchList({data :searchList?.data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))});
      if (order === "1") { setSearchList({data :
          searchList?.data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).reverse()
        });
      }
      if (order === "2"){ 
        setSearchList({data :searchList?.data.sort((a, b) => (a.set_code > b.set_code) ? 1 : ((b.set_code > a.set_code) ? -1 : 0))});
      }
    }
  }, [order]);

  useEffect(() => {
    if (!forceReload) {
      setReloadF(true);
      setTimeout(() => {
        setReloadF(false);
      }, 200);
    }
  }, [searchList]);

  useEffect(() => {
    if (user) {
      if (user?.role === "pendingvendor" && user?.pending_vendor_id != null) {
        setVorPV(true);
        getInfoVendorPending(user?.pending_vendor_id, (pendingVendor) => {
          console.log("pendingVendor", pendingVendor)
          if (pendingVendor === null){
            return;
          }
          setOpenVendorStep1(pendingVendor._step_1 === "pending");
          if (
            pendingVendor._step_1 === "complete" &&
            pendingVendor._step_2 === "pending" &&
            pendingVendor._step_3 === "pending"
          ) {
            setOpenVendorStep2(pendingVendor._step_2 === "pending");
          }
          if (
            pendingVendor._step_1 === "complete" &&
            pendingVendor._step_2 === "complete" &&
            pendingVendor._step_3 === "pending" &&
            pendingVendor._step_4 === "pending"
          ) {
            setOpenVendorStep3(pendingVendor._step_3 === "pending");
          }

          if (
            pendingVendor._step_1 === "complete" &&
            pendingVendor._step_2 === "complete" &&
            pendingVendor._step_3 === "complete" &&
            pendingVendor._step_4 === "pending" &&
            pendingVendor._step_5 === "pending"
          ) {
            setOpenVendorStep4(pendingVendor._step_4 === "pending");
          }

          if (
            pendingVendor._step_1 === "complete" &&
            pendingVendor._step_2 === "complete" &&
            pendingVendor._step_3 === "complete" &&
            pendingVendor._step_4 === "complete" &&
            pendingVendor._step_5 === "pending"
          ) {
            setOpenVendorStep4(false);
            setOpenVendorStep5(pendingVendor._step_5 === "pending");
          }

          if (
            pendingVendor._step_1 === "complete" &&
            pendingVendor._step_2 === "complete" &&
            pendingVendor._step_3 === "complete" &&
            pendingVendor._step_4 === "complete" &&
            pendingVendor._step_5 === "complete"
          ) {
            // vendeur valider
            if (pendingVendor.complete) {
              // hide devenir vendeur
              setHideDevenirVendeur(true); 
              
              db.collection("vendor").doc(pendingVendor.uid).get().then((doc) => {
                console.log("vendor", doc.exists)
                if (doc.exists === false){
                  db.collection("vendor")
                  .doc(pendingVendor.uid)
                  .set(pendingVendor)
                  .then(() => {
                    db.collection("users").doc(user?.uid).update({
                      role: "vendor",
                      _update_At: new Date(),
                    }).then(() => {
                      window.location.reload();
                    })

                  });
                }
              }) 
              if (window.location.href.includes("#becomevendor")) {
                history.push("/tableau-de-bord#dashboard");
                
              }
              
            } else {
              db.collection("vendor")
                .doc(pendingVendor.uid)
                .set(pendingVendor)
                .then(() => {
                  db.collection("users").doc(user?.uid).update({
                    role: "vendor",
                    _update_At: new Date(),
                  });
                });
            }
          }
        });
      } else {
        if (user?.role === "vendor") {
          setVorPV(true);
          setHideDevenirVendeur(true);
          setOpenVendorStep1(false);
          setOpenVendorStep2(false);
          setOpenVendorStep3(false);
          setOpenVendorStep4(false);
          setOpenVendorStep5(false);

          if (window.location.href.includes("#becomevendor")) {
            history.push("/tableau-de-bord#dashboard");
            setTimeout(() => {
              window.location.reload();
            }, 200);
          } else {
            prepareInfoVendor();
          }
        }
      }
    } else {
      setHideDevenirVendeur(false);
      setOpenVendorStep1(false);
      setOpenVendorStep2(false);
      setOpenVendorStep3(false);
      setOpenVendorStep4(false);
      setOpenVendorStep5(false);
    }
  }, [user]);

  const getInfoVendorPending = async (uid, cb) => {
    try {
      await App.firestore()
      .collection("pendingvendor")
      .doc(uid)
      .get()
      .then(async (docd) => {
        var pendingvendor = docd.data();
        pendingvendor.id = docd.id;
        cb(pendingvendor);
      })
      .catch((error) => {
        console.error('error pendingvendor', error)
        cb(null);
      });
    } catch (error) {
      console.error('error try pendingvendor', error)
      cb(null);
    }
  };

  const saveNewPassword = (value) => {
    if (loadingForm1) {
      return;
    }

    // TODO(you): prompt the user to re-provide their sign-in credentials
    if (
      value.newpassword &&
      value.confirmPassword &&
      value.newpassword === value.confirmPassword
    ) {
      setLoadingForm1(true);
      App.auth()
        .signInWithEmailAndPassword(
          App.auth().currentUser.email,
          value.actualPassword
        )
        .then((res) => {
          // User re-authenticated.
          // alert("Les mots de passe correspondent ");
          App.auth()
            .currentUser.updatePassword(value.newpassword)
            .then(() => {
              // Update successful.
              alert("Mot de passe mis à jour");
              hideChangePass();
            })
            .catch((error) => {
              // An error ocurred
              // ...
              setLoadingForm1(false);
              console.log(error);
              alert(
                "Une erreur s'est produite lors de la modification, réessayer plus tard."
              );
            });
        })
        .catch((error) => {
          // An error ocurred
          console.log("error", error);

          setLoadingForm1(false);
          alert("Votre mot de passe actuel ne correspond pas");
        });
    } else {
      setLoadingForm1(false);
      alert("Les mots de passe sont invalide/ne correspondent pas");
    }
  };

  const [loadSupportVendorForm, setLSVF] = React.useState(false);

  const sendClientSupport = (value) => {
    // console.log("sendVendorSupport", value);
    if (loadSupportVendorForm) {
      return;
    }
    getUser(App.auth().currentUser?.uid, (u) => {
      setLSVF(true);

      fetch(`${API_URL}sendEmailClient`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // eslint-disable-next-line object-shorthand
          userEmail: u.email,
          userName: u.displayName,
          userId: u.uid || App.auth().currentUser?.uid,
          message: value.messageclient,
          phone: value.phone,
          commande: cmdId,
          copy: value.messagecopieclient,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLSVF(false);
          console.log("sendEmailClient data", data);
          if (data.success) {
            alert("Votre demande à été reçu, merci de votre patience.");
            window.location.reload();
          } else {
            alert(
              "Une erreur s'est produite, réessayer dans quelques minutes."
            );
          }
        })
        .catch((err) => {
          console.log("sendEmailClient err", err);
          setLSVF(false);
          alert("Une erreur s'est produite, réessayer dans quelques minutes.");
        });
    });
  };
  const sendVendorSupport = (value) => {
    if (loadSupportVendorForm) {
      return;
    }
    getInfoVendor((vendor) => {
      // console.log("vendor", vendor);

      setLSVF(true);

      fetch(`${API_URL}sendEmailVendor`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // eslint-disable-next-line object-shorthand
          vendorEmail: vendor.email,
          vendorName: vendor.name,
          vendorId: vendor.uid,
          message: value.messagevendor,
          phone: value.phone,
          abonnement: actualABN,
          copy: value.messagecopie,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLSVF(false);
          console.log("sendEmailVendor data", data);
          if (data.success) {
            alert("Votre demande à été reçu, merci de votre patience.");
            window.location.reload();
          } else {
            alert(
              "Une erreur s'est produite, réessayer dans quelques minutes."
            );
          }
        })
        .catch((err) => {
          console.log("sendEmailVendor err", err);
          setLSVF(false);
          alert("Une erreur s'est produite, réessayer dans quelques minutes.");
        });
    });
  };

  const finishSetupPendingVendor = () => {
    db.collection("pendingvendor")
      .doc(user.pending_vendor_id)
      .update({
        _step_5: "complete",
        complete: true,
        _update_At: new Date(),
      })
      .then(() => {
        getInfoVendorPending(user.pending_vendor_id, (pendingVendor) => {
          db.collection("vendor")
            .doc(pendingVendor.id)
            .set(pendingVendor)
            .then(() => {
              db.collection("users")
                .doc(App.auth().currentUser.uid)
                .update({
                  role: "vendor",
                  isvendor: true,
                  pending_vendor_id: null,
                  _update_At: new Date(),
                })
                .then(() => {
                  console.log("Vendor successfully !");
                  // delete pending vendor here
                });
            });
        });
      })
      .catch((err) => {
        alert(
          "Une erreur s'est produite lors de l'activation de votre compte vendeur"
        );
      });
    hsCardP();
  };
  const [cardBank, setCards] = useState([]);

  const [loadCard, setLoadCard] = useState(false);
  const getCardsUser = async (customerId = user.customerStripeId) => {
    setCards([]);
    setLoadCard(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      customerId: customerId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(API_URL + "list-method-card", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data.cards) {
          // console.log("cardBank", data.cards.length);
          setCards(data.cards);
        } else {
          setCards([]);
        }
        setLoadCard(false);
      })
      .catch((err) => {
        setLoadCard(false);
        console.log(err);
        setCards([]);
        alert("Une erreur s'est produite");
      });
  };

  useEffect(() => {
    App.auth().onAuthStateChanged((u) => {
      if (u) {
        getUser(App.auth().currentUser?.uid, (u) => {
          setUser(u);
        });
      } else {
        history.push("/connexion");
      }
    });
  }, []);

  const logOut = () => {
    App.auth()
      .signOut()
      .then((res) => {
        history.push({ pathname: "/connexion", state: { logout: true } });
        //do something else with res
      })
      .catch((err) => {
        alert("Une erreur s'est produite, réessayer plus tard.");
      });
  };

  const [sendLinkEmail, setSendLinkEmail] = React.useState(false);

  const sendLinkEmailVerify = () => {
    if (sendLinkEmail) {
      return;
    }
    setSendLinkEmail(true);
    App.auth()
      .currentUser.sendEmailVerification({
        url: window.location.origin + "/tableau-de-bord#dashboard",
      })
      .then(() => {
        setSendLinkEmail(false);
        alert("Email envoyé !");
      })
      .catch((err) => {
        setSendLinkEmail(false);
        db.collection('logs').add({type: 'sendLinkEmailVerifyVendor', uid : App.auth().currentUser.uid, error: err})
        alert("Une erreur s'est produite, réessayer plus tard. " + err);
      });
  };

  const sendLinkEmailVerifyVendor = () => {
    if (sendLinkEmail) {
      return;
    }
    setSendLinkEmail(true);
    App.auth()
      .currentUser.sendEmailVerification({
        url: window.location.origin + "/tableau-de-bord#becomevendor",
      })
      .then(() => {
        setSendLinkEmail(false);
        alert("Email envoyé !");
      })
      .catch((err) => {
        setSendLinkEmail(false);
        db.collection('logs').add({type: 'sendLinkEmailVerifyVendor', uid : App.auth().currentUser.uid, error: err})
        alert("Une erreur s'est produite, réessayer plus tard. " + err);
      });
  };

  const handleSubmitSubscription = async (e) => {
    e.preventDefault();
    setLoadingStripe(true);
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    if (subscriptionData.clientSecret) {
      let { error, paymentIntent } = await stripe.confirmCardPayment(
        subscriptionData.clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: name,
            },
          },
        }
      );

      // Use card Element to tokenize payment details

      if (error) {
        setLoadingStripe(false);
        // show error and collect new card details.
        alert(error.message);
        return;
      }
      setPaymentIntent(paymentIntent);
      // console.log("ici paymentIntent", subscriptionData, user);
      if (paymentIntent && paymentIntent.status === "succeeded") {
        //  passé à l'étape suivante.

        if (user.pending_vendor_id || subscriptionData.reload) {
          if (subscriptionData.reload) {
            // se désabonner de l'actuel abonnement
            getInfoVendor((v) => {
              if (
                v.subscription_info?.subscription_id &&
                subscriptionData.subscriptionId !==
                  v.subscription_info?.subscription_id
              ) {
                fetch(`${API_URL}subscription`, {
                  method: "DELETE",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    // eslint-disable-next-line object-shorthand
                    subscription_id: v.subscription_info.subscription_id,
                  }),
                })
                  .then((res) => res.json())
                  .then(async (data) => {
                    // alert("actual subscription delete");
                    db.collection(
                      subscriptionData.reload ? "vendor" : "pendingvendor"
                    )
                      .doc(
                        subscriptionData.reload
                          ? user.id
                          : user.pending_vendor_id
                      )
                      .update({
                        subscription: "complete",
                        subscription_info: {
                          subscription_id: subscriptionData.subscriptionId,
                          name: subscriptionData.name,
                          price_id: subscriptionData.price.id,
                          pay_id: paymentIntent.id,
                          status: paymentIntent.status,
                          payment_method: paymentIntent.payment_method,
                          livemode: paymentIntent.livemode,
                          _create_At: new Date(),
                          _canceled_at: null,
                        },
                        _step_3: "complete",
                        _update_At: new Date(),
                      })
                      .then(() => {
                        if (subscriptionData.reload) {
                          window.location.reload();
                        }
                        setLoadingStripe(false);
                        setOpenVendorStep3(false);
                        setOpenVendorStep4(true);
                        modalPayHide();
                        alert(
                          `Votre abonnement ${subscriptionData.name} à été valider.`
                        );
                      })
                      .catch((err) => {
                        setLoadingStripe(false);
                        alert(
                          "Le paiement à été réaliser, une erreur s'est produite lors de l'enregistrement des données. Contactez nous."
                        );
                      });
                  });
              } else {
                db.collection(
                  subscriptionData.reload ? "vendor" : "pendingvendor"
                )
                  .doc(App.auth().currentUser?.uid)
                  .update({
                    subscription: "complete",
                    subscription_info: {
                      subscription_id: subscriptionData.subscriptionId,
                      name: subscriptionData.name,
                      price_id: subscriptionData.price.id,
                      pay_id: paymentIntent.id,
                      status: paymentIntent.status,
                      payment_method: paymentIntent.payment_method,
                      livemode: paymentIntent.livemode,
                      _create_At: new Date(),
                      _canceled_at: null,
                    },
                    _step_3: "complete",
                    _update_At: new Date(),
                  })
                  .then(() => {
                    if (subscriptionData.reload) {
                      window.location.reload();
                    }
                    setLoadingStripe(false);
                    setOpenVendorStep3(false);
                    setOpenVendorStep4(true);
                    modalPayHide();
                    alert(
                      `Votre abonnement ${subscriptionData.name} à été valider.`
                    );
                  })
                  .catch((err) => {
                    setLoadingStripe(false);
                    alert(
                      "Le paiement à été réaliser, une erreur s'est produite lors de l'enregistrement des données. Contactez nous."
                    );
                  });
              }
            });
          } else {
            db.collection(subscriptionData.reload ? "vendor" : "pendingvendor")
              .doc(subscriptionData.reload ? user.id : user.pending_vendor_id)
              .update({
                subscription: "complete",
                subscription_info: {
                  subscription_id: subscriptionData.subscriptionId,
                  name: subscriptionData.name,
                  price_id: subscriptionData.price.id,
                  pay_id: paymentIntent.id,
                  status: paymentIntent.status,
                  payment_method: paymentIntent.payment_method,
                  livemode: paymentIntent.livemode,
                  _create_At: new Date(),
                  _canceled_at: null,
                },
                _step_3: "complete",
                _update_At: new Date(),
              })
              .then(() => {
                if (subscriptionData.reload) {
                  window.location.reload();
                }
                setLoadingStripe(false);
                setOpenVendorStep3(false);
                setOpenVendorStep4(true);
                modalPayHide();
                alert(
                  `Votre abonnement ${subscriptionData.name} à été valider.`
                );
              })
              .catch((err) => {
                setLoadingStripe(false);
                alert(
                  "Le paiement à été réaliser, une erreur s'est produite lors de l'enregistrement des données. Contactez nous."
                );
              });
          }
        } else {
          getUser(App.auth().currentUser?.uid, (u) => {
            setUser(u);
            db.collection("pendingvendor")
              .doc(u.pending_vendor_id)
              .update({
                subscription: "complete",
                subscription_info: {
                  name: subscriptionData.name,
                  price_id: subscriptionData.price.id,
                  pay_id: paymentIntent.id,
                  status: paymentIntent.status,
                  payment_method: paymentIntent.payment_method,
                  livemode: paymentIntent.livemode,
                  _create_At: new Date(),
                  _canceled_at: null,
                },
                _step_3: "complete",
                _update_At: new Date(),
              })
              .then(() => {
                setLoadingStripe(false);
                setOpenVendorStep3(false);
                setOpenVendorStep4(true);
                modalPayHide();
              })
              .catch((err) => {
                setLoadingStripe(false);
                alert(
                  "Le paiement à été réaliser, une erreur s'est produite lors de l'enregistrement des données. Contactez nous."
                );
              });
          });
        }
      } else {
        setLoadingStripe(false);
        alert("Une erreur est survenue pendant le paiement.");
      }
    } else {
      setLoadingStripe(false);
      alert("Une erreur est survenue pendant le paiement.");
    }
  };

  const cancelABN = () => {
    if (confirm("Êtes vous sûr d'arrêter votre abonnement?")) {
      getInfoVendor((vendor) => {
        fetch(`${API_URL}subscription`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // eslint-disable-next-line object-shorthand
            subscription_id: vendor.subscription_info.subscription_id,
          }),
        })
          .then((res) => res.json())
          .then(async (data) => {
            // console.log("subscription delete", data);
            if (data.success) {
              if (data.data.status === "canceled") {
                // mettre a jour l'abonnement actuel
                if (user) {
                  var nsubscription_info = vendor.subscription_info;
                  nsubscription_info.status = "canceled";
                  nsubscription_info._canceled_at = new Date();
                  await App.firestore()
                    .collection("vendor")
                    .doc(user.id)
                    .update({
                      _update_At: new Date(),
                      subscription_info: {},
                    })
                    .then(async (docd) => {
                      var vendor = docd.data();
                      vendor.id = docd.id;
                      window.location.reload();
                    })
                    .catch((error) => {
                      console.log("err", error);
                      alert("Une erreur s'est produite, réessayer");
                    });
                }
              }
            }
          })
          .catch((err) => {
            console.log("subscription err", err);
          });
      });
    } else {
      // Do nothing!
      console.log("Thing was not saved to the database.");
    }
  };

  const [rarete, setRarete] = useState(null)
   function f(searchsd){
    let q = false ; 
    if (searchsd?.card_sets?.length <= 3 ){
      
      let occurrences = {};
      searchsd?.card_sets?.forEach((chaine) =>  { 
        occurrences[chaine.set_code] = (occurrences[chaine.set_code] || 0) + 1;
      });
    
      // Afficher les occurrences
      for (let chaine in occurrences) {
          console.log("'" + chaine + "' apparaît " + occurrences[chaine] + " fois");
          if (occurrences[chaine] > 1) {
            q = true;
          }
      }
    }
    return q
  }

  const DeleteMyCard = (e) => {
    e.preventDefault();
    if (loadAddSearchCard) {
      return;
    }
    setLoadAddSearchCard(true);
    db.collection("cards")
      .doc(infoMyCard.id)
      .delete()
      .then(() => {
        setLoadAddSearchCard(false);
        hImCOrder();
        loadSMyListCard();
        alert("La carte sera supprimée dans quelques minutes.");
      })
      .catch((err) => {
        setLoadAddSearchCard(false);
        console.log("DeleteSearchCard err", err);
        alert("Une erreur s'est produite.");
      });
  };
  const [searchFiltre, setSearchFiltre] = useState(null);

  function resultSearchs() {
    function getEx(data) {
      if (data?.expansion && data?.card?.card_sets) {
        var d = null;
        data?.card?.card_sets.forEach((e) => {
          if (e.set_name === data?.expansion) d = e;
        });
        return d;
      } else return null;
    }

    function getCode(data) {
      var d = getEx(data)?.set_code || "";
      if (data?.lang === "fr") {
        return d.replace("EN", data?.lang.toUpperCase());
      } else {
        return d;
      }
    }

    if (
      myCards &&
      myCards?.length > 0 &&
      searchFiltre !== null &&
      searchFiltre.length > 0
    ) {
      let res = [];
      myCards?.forEach((d) => {
        if (
          d.card.name.toLowerCase().includes(searchFiltre?.toLowerCase()) ||
          d?.card.name_en?.toLowerCase().includes(searchFiltre?.toLowerCase()) ||
          getCode(d).toLowerCase().includes(searchFiltre?.toLowerCase())
        ) {
          res.push(d);
        }
      });
      return res;
    } else {
      return myCards;
    }
  }

  const DeleteSearchCard = (e) => {
    e.preventDefault();
    if (loadAddSearchCard) {
      return;
    }
    setLoadAddSearchCard(true);
    db.collection("searchs")
      .doc(infoMySearch.id)
      .delete()
      .then(() => {
        setLoadAddSearchCard(false);
        hImSearch();
        loadSearchListCard();
        alert("Recherche supprimée.");
      })
      .catch((err) => {
        setLoadAddSearchCard(false);
        console.log("DeleteSearchCard err", err);
        alert("Une erreur s'est produite.");
      });
  };

  const updateMyCard = (e) => {
    e.preventDefault();
    if (loadAddSearchCard) {
      return;
    }

    if (stateCard === null) {
      alert("Veuillez choisir un état minimum pour la carte.");
      return;
    }
    var d = {
      price: Number(prix).toFixed(2),
      livraison: livraison,
      mainpropre: mainpropre,
      qte: Number(qteCard),
      etat: stateCard,
      lang: langCard,
      first: firstCard,
      _update_At: new Date(),
      state: Number(qteCard) > 0 ? 1 : 0,
    };
    setLoadAddSearchCard(true);

    db.collection("cards")
      .doc(infoMyCard.id)
      .update(d)
      .then(() => {
        setLoadAddSearchCard(false);
        hImCOrder();
        loadSMyListCard();
        alert("Carte modifiée.");
      })
      .catch((err) => {
        setLoadAddSearchCard(false);
        console.log("carte err", err);
        alert("Une erreur s'est produite.");
      });
  };

  const UpdateSearchCard = (e) => {
    e.preventDefault();
    if (loadAddSearchCard) {
      return;
    }
    if (stateCard === null) {
      alert("Veuillez choisir un état minimum pour la carte.");
      return;
    }
    var d = {
      qte: Number(qteCard),
      etat: stateCard,
      lang: langCard,
      first: firstCard,
      state: Number(publishCard), // 1 = publish; 0 : brouillon
      _update_At: new Date(),
    };
    setLoadAddSearchCard(true);
    db.collection("searchs")
      .doc(infoMySearch.id)
      .update(d)
      .then(() => {
        setLoadAddSearchCard(false);
        hImSearch();
        loadSearchListCard();
        alert("Recherche modifiée.");
      })
      .catch((err) => {
        setLoadAddSearchCard(false);
        console.log("UpdateSearchCard err", err);
        alert("Une erreur s'est produite.");
      });
  };

  const AddSellCard = (e) => {
    e.preventDefault();

    if (loadAddSearchCard) {
      return;
    }
    if (stateCard === null) {
      alert("Veuillez choisir un état minimum pour la carte.");
      return;
    }

    const d = {
      userId: user.id,
      card: searchCardSelect,
      expansion: expansionName,
      rarete: rarete,
      qte: Number(qteCard),
      etat: stateCard,
      lang: langCard,
      price: Number(prix).toFixed(2),
      first: firstCard,
      livraison: livraison,
      mainpropre: mainpropre,
      state: 1, // 1 = publish; 0 : retirer de la vente
      _create_At: new Date(),
      _update_At: new Date(),
      views: 0,
    };
    setLoadAddSearchCard(true);
    db.collection("cards")
      .add(d)
      .then(() => {
        setLoadAddSearchCard(false);
        hcCardP();
        loadSMyListCard();
        alert("Carte ajoutée. Sera visible sous 1h à la vente. Vous pouvez cependant partager directement le lien en cliquant sur l'oeil a vos acheteurs potentiels");
      })
      .catch((err) => {
        setLoadAddSearchCard(false);
        console.log("cards err", err);
        alert("Une erreur s'est produite.");
      });
  };
  function getPrix() {
    const cal = prix * 0.05;
    return `soit de ${Number(cal).toFixed(2)}€`;
  }

  const AddSearchCard = (e) => {
    e.preventDefault();

    if (loadAddSearchCard) {
      return;
    }
    if (stateCard === null) {
      alert("Veuillez choisir un état minimum pour la carte.");
      return;
    }
    var d = {
      userId: user.id,
      card: searchCardSelect,
      expansion: expansionName, 
      qte: Number(qteCard),
      etat: stateCard,
      lang: langCard,
      first: firstCard,
      state: Number(publishCard), // 1 = publish; 0 : brouillon
      _create_At: new Date(),
      _update_At: new Date(),
      views: 0,
      username: App.auth().currentUser?.displayName || "",
    };
    setLoadAddSearchCard(true);
    db.collection("searchs")
      .add(d)
      .then(() => {
        setLoadAddSearchCard(false);
        hcSearchP();
        loadSearchListCard();
        alert("Recherche ajoutée.");
      })
      .catch((err) => {
        setLoadAddSearchCard(false);
        console.log("AddSearchCard err", err);
        alert("Une erreur s'est produite.");
      });
  };

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return "Loading...";
  }

  let chatBoxProps = state.showChatBox
    ? {
        xs: 12,
        sm: 12,
      }
    : {
        xsHidden: true,
        smHidden: true,
      };

  let chatListProps = state.showChatList
    ? {
        xs: 12,
        sm: 12,
      }
    : {
        xsHidden: true,
        smHidden: true,
      };

  return (
    <>
      <div class="container marketing px-3 py-3 ">
        <div>
        
          <Tab.Container id="left-tabs-example" defaultActiveKey={key}>
            <Row>
              <Col sm={3}>
              <NavDropdown className={"sidebar-dash-menu"} defaultActiveKey={key}
                    title="Menu"
                    id={`offcanvasNavbarDropdown-expand-xl`}
                  >
                    <NavDropdown.Item onClick={() => {
                          if (user) loadVListOrder();
                        }} eventKey="dashboard" href="#dashboard"><i class="fa fa-home" /> Tableau de bord</NavDropdown.Item>
                     
                     {user && user?.role === "vendor" && (
                      <NavDropdown.Item eventKey="order" href="#order"
                        onClick={() => {
                          if (user) loadVListOrder();
                        }}
                        >
                          <i class="fa fa-file" /> Commandes vendeur{" "}
                          
                        <Badge bg="danger">
                          {hasUnreadOrder() !== "0" && hasUnreadOrder()}
                        </Badge>
                        </NavDropdown.Item>
                    )}
                     
                     
                     <NavDropdown.Item onClick={() => {
                         if (user) loadSMyListOrder();
                        }} eventKey="your-order" href="#your-order"><i class="fa fa-shopping-cart" /> Vos commandes</NavDropdown.Item>
                       {user && user?.role === "vendor" && (
                      <>
                        <NavDropdown.Item
                            eventKey="card"
                            href="#card"
                            onClick={() => {
                              if (user) loadSMyListCard();
                            }}
                          >
                            <i class="fa fa-bars" /> Cartes
                          </NavDropdown.Item>
                        <NavDropdown.Item eventKey="enchere" href="#enchere" disabled>
                            <i class="fa fa-exchange" /> Enchères{" "}
                            {/* <Badge bg="primary">pro</Badge> */}
                            <Badge bg="primary">En développement</Badge>
                          </NavDropdown.Item>
                        <NavDropdown.Item
                            eventKey="basededeck"
                            href="#basededeck"
                            disabled
                          >
                            <i class="fa fa-suitcase"></i> Base de Deck{" "}
                            <Badge bg="primary">En développement</Badge>
                          </NavDropdown.Item>
                      </>
                    )}
                    <NavDropdown.Item
                        eventKey="search"
                        href="#search"
                        onClick={() => {
                          if (user) loadSearchListCard();
                        }}
                      >
                        <i class="fa fa-search"></i> Je recherche
                      </NavDropdown.Item>
                    <NavDropdown.Item
                        eventKey="messages"
                        href="#messages"
                        onClick={() => {
                          refreshTchatList();
                        }}
                      >
                        <i class="fa fa-comments" /> Messages{" "}
                        <Badge bg="danger">
                          {hasUnreadMessage() !== "0" && hasUnreadMessage()}
                        </Badge>
                      </NavDropdown.Item>
                    <NavDropdown.Item eventKey="profil" href="#profil">
                        <i class="fa fa-user" /> Paramètres utilisateur
                      </NavDropdown.Item>
                      {!hideDevenirVendeur ? (
                      <NavDropdown.Item eventKey="becomevendor" href="#becomevendor" onClick={() => {
                        // refreshTchatList();
                      }}>
                          <i class="fa fa-money" /> Devenir Vendeur
                        </NavDropdown.Item>
                    ) : (
                      <NavDropdown.Item eventKey="settings" href="#settings">
                          <i class="fa fa-cog" /> Paramètres vendeur
                        </NavDropdown.Item>
                    )}  

                    {user && user?.role === "vendor" && (
                      <>
                        <NavDropdown.Item eventKey="subscription" href="#subscription">
                            <i class="fa fa-bank" /> Abonnement
                          </NavDropdown.Item>
                        <NavDropdown.Item eventKey="support" href="#support">
                            <i class="fa fa-phone" /> Support Vendeur
                          </NavDropdown.Item>
                      </>
                    )}  
                    <NavDropdown.Item eventKey="supportclient" href="#supportclient">
                        <i class="fa fa-phone" /> Support client
                      </NavDropdown.Item>
                    <NavDropdown.Item
                        eventKey="deconnexion"
                        href="#"
                        style={{ marginTop: 20 }}
                      >
                        Se déconnecter
                      </NavDropdown.Item>
                  </NavDropdown>

                  <Nav variant="pills" className="flex-column sidebar-dash">
                    <Nav.Item>
                      <Nav.Link eventKey="dashboard" href="#dashboard">
                        <i class="fa fa-home" /> Tableau de bord
                      </Nav.Link>
                    </Nav.Item>
                    {user && user?.role === "vendor" && (
                      <Nav.Item>
                        <Nav.Link eventKey="order" href="#order"
                        onClick={() => {
                          if (user) loadVListOrder();
                        }}
                        >
                          <i class="fa fa-file" /> Commandes vendeur{" "}
                          
                        <Badge bg="danger">
                          {hasUnreadOrder() !== "0" && hasUnreadOrder()}
                        </Badge>
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    <Nav.Item>
                      <Nav.Link
                        eventKey="your-order"
                        href="#your-order"
                        onClick={() => {
                          if (user) loadSMyListOrder();
                        }}
                      >
                        <i class="fa fa-shopping-cart" /> Vos commandes
                      </Nav.Link>
                    </Nav.Item>
                    {user && user?.role === "vendor" && (
                      <>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="card"
                            href="#card"
                            onClick={() => {
                              if (user) loadSMyListCard();
                            }}
                          >
                            <i class="fa fa-bars" /> Cartes
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="enchere" href="#enchere" disabled>
                            <i class="fa fa-exchange" /> Enchères{" "}
                            {/* <Badge bg="primary">pro</Badge> */}
                            <Badge bg="primary">En développement</Badge>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="basededeck"
                            href="#basededeck"
                            disabled
                          >
                            <i class="fa fa-suitcase"></i> Base de Deck{" "}
                            <Badge bg="primary">En développement</Badge>
                          </Nav.Link>
                        </Nav.Item>
                      </>
                    )}
                    <Nav.Item>
                      <Nav.Link
                        eventKey="search"
                        href="#search"
                        onClick={() => {
                          if (user) loadSearchListCard();
                        }}
                      >
                        <i class="fa fa-search"></i> Je recherche
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="messages"
                        href="#messages"
                        onClick={() => {
                          refreshTchatList();
                        }}
                      >
                        <i class="fa fa-comments" /> Messages{" "}
                        <Badge bg="danger">
                          {hasUnreadMessage() !== "0" && hasUnreadMessage()}
                        </Badge>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="profil" href="#profil">
                        <i class="fa fa-user" /> Paramètres utilisateur
                      </Nav.Link>
                    </Nav.Item>
                    {!hideDevenirVendeur ? (
                      <Nav.Item>
                        <Nav.Link eventKey="becomevendor" href="#becomevendor">
                          <i class="fa fa-money" /> Devenir Vendeur
                        </Nav.Link>
                      </Nav.Item>
                    ) : (
                      <Nav.Item>
                        <Nav.Link eventKey="settings" href="#settings">
                          <i class="fa fa-cog" /> Paramètres vendeur
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {user && user?.role === "vendor" && (
                      <>
                        <Nav.Item>
                          <Nav.Link eventKey="subscription" href="#subscription">
                            <i class="fa fa-bank" /> Abonnement
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="support" href="#support">
                            <i class="fa fa-phone" /> Support Vendeur
                          </Nav.Link>
                        </Nav.Item>
                      </>
                    )}
                    <Nav.Item>
                      <Nav.Link eventKey="supportclient" href="#supportclient">
                        <i class="fa fa-phone" /> Support client
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="deconnexion"
                        href="#"
                        style={{ marginTop: 20 }}
                      >
                        Se déconnecter
                      </Nav.Link>
                    </Nav.Item>
                  </Nav> 



              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="dashboard">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                      <h1 class="h2">Tableau de bord</h1>
                    </div>
                    {user ? (
                      (user?.role === "vendor" ||
                        user?.role === "pendingvendor") ? (
                        <>
                          {loadingprepare ? (
                            <p>Chargement ...</p>
                          ) : (
                            <>
                              {!accountIsValid ? (
                                <Alert variant="danger">
                                  <Alert.Heading>
                                    Activé votre compte
                                  </Alert.Heading>
                                  {!App.auth().currentUser.emailVerified && (
                                    <>
                                      <p>
                                        Votre compte est en
                                        attente de vérification, un email
                                        contenant un lien de validation vous à
                                        été envoyé.{" "}
                                        {sendLinkEmail ? (
                                          "Chargement ..."
                                        ) : (
                                          <a
                                            href="#dashboard"
                                            onClick={sendLinkEmailVerify}
                                          >
                                            Renvoyer
                                          </a>
                                        )}
                                        .
                                      </p>
                                      <hr />
                                    </>
                                  )}
                                  <p>
                                    Votre compte est en attente de validation,
                                    veuillez remplir les informations
                                    nécessaires dans "Paramètres vendeur" ou
                                    "Devenir Vendeur"
                                  </p>
                                  <hr />
                                  Veuillez créer/connecter votre compte stripe
                                  pour vendre sur notre plate-forme dans
                                  "Paramètres vendeur" ou "Devenir Vendeur".
                                </Alert>
                              ) : (
                                <>
                                  <Alert variant="success">
                                    <Alert.Heading>
                                      Votre compte est activé
                                    </Alert.Heading>
                                    <p>
                                      Vous pouvez vendre sur notre plate-forme.
                                      Les paiements sont réalisés tous les 7
                                      jours ouvrables sur votre compte bancaire
                                      pour les commandes marquées comme terminé.
                                    </p>
                                    <hr />
                                    Vous pouvez télécharger l'application mobile
                                    "Stripe connect" pour suivre vos paiements.
                                    Disponible sur iOS et Android.
                                  </Alert>
                                  Votre abonnement :{" "}
                                  <Badge>
                                    {actualABN === "Free"
                                      ? "Gratuit"
                                      : actualABN}
                                  </Badge>
                                </>
                              )}
                            </>
                          )}

                          <div class="product-wrap clearfix product-deatil">
                            <div class="row">
                              <div>
                                <div>
                                  <div class="certified">
                                    <ul>
                                      <li>
                                        <a href="javascript:void(0);">
                                          Total des ventes<span>{totalSold()} €</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          Commandes<span>{myVendorOrders.length}</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          Cartes vendus<span>{calNbrCartSold()}</span>
                                        </a>
                                      </li>
                                      {/* <li>
                                  <a href="javascript:void(0);">
                                    Enchères terminé<span>0</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    Base de deck vendus<span>0</span>
                                  </a>
                                </li> */}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ):(
                        <>
                        <p>Vous n'avez aucun message.</p>
                         {!App.auth().currentUser.emailVerified && (
                              <>
                                <p>
                                  Votre compte est en
                                  attente de vérification, un email
                                  contenant un lien de validation vous à
                                  été envoyé.{" "}
                                  {sendLinkEmail ? (
                                    "Chargement ..."
                                  ) : (
                                    <a
                                      href="#dashboard"
                                      onClick={sendLinkEmailVerify}
                                    >
                                      Renvoyer
                                    </a>
                                  )}
                                  .
                                </p>
                                <hr />
                              </>
                            )}
                              
                        </>
                      )
                      
                    ) : (
                      <p>Chargement ...</p>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="support">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                      <h1 class="h2">Support vendeur</h1>
                    </div>
                    <p>
                      Ce formulaire est uniquement destiné aux demandes pour les
                      vendeurs.
                      <br />
                      <span style={{ color: "red" }}>⚠️</span> Aucune demande
                      client ne sera traité ici. Veuillez utiliser le formulaire{" "}
                      <a
                        href="#"
                        onClick={() => {
                          window.location.href =
                            "/tableau-de-bord#supportclient";
                          window.location.reload();
                        }}
                      >
                        client
                      </a>{" "}
                      si besoin.
                    </p>
                    <div class="col-md-7 col-lg-8">
                      <form
                        class="needs-validation"
                        novalidate
                        onSubmit={handleSubmit(sendVendorSupport, (err) => {
                          console.log(err);
                        })}
                      >
                        <div class="row g-3">
                          <div class="col-12">
                            <label for="phone" class="form-label">
                              Téléphone{" "}
                              <span class="text-muted">(facultative)</span>
                            </label>
                            <input
                              type="phone"
                              class="form-control"
                              id="phone"
                              name="phone"
                              placeholder="Téléphone"
                              onChange={(e) =>
                                setValue("phone", e.target.value)
                              }
                              ref={register("phone")}
                            />
                          </div>

                          <div class="col-12">
                            <label for="message" class="form-label">
                              Votre demande
                            </label>
                            <textarea
                              type="text"
                              class="form-control textarea"
                              id="message"
                              placeholder="Veuillez indiqué toutes informations nécessaire pour comprendre votre demande, numéro de commande, client etc..."
                              required
                              name="messagevendor"
                              onChange={(e) =>
                                setValue("messagevendor", e.target.value)
                              }
                              ref={register("messagevendor")}
                            />
                          </div>
                        </div>
                        <div class="form-check my-4">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="messagecopie"
                            name="messagecopie"
                            onChange={(e) =>
                              setValue("messagecopie", e.target.checked)
                            }
                            ref={register("messagecopie")}
                          />
                          <label class="form-check-label" for="messagecopie">
                            Je souhaite recevoir une copie de la demande par
                            email
                          </label>
                        </div>
                        <hr class="my-4" />
                        <button
                          class="w-100 btn btn-dark btn-lg"
                          type="submit"
                          disabled={loadSupportVendorForm}
                        >
                          {loadSupportVendorForm ? "Chargement ..." : "Envoyer"}
                        </button>
                        {actualABN === "Free" && (
                          <>
                            <Badge>Gratuit</Badge>
                            <small>
                              {" "}
                              Votre demande sera traité sous 48 heures ouvrable.
                            </small>
                          </>
                        )}
                        {actualABN === "Pro" && (
                          <>
                            <Badge>Pro</Badge>
                            <small>
                              {" "}
                              Votre demande sera traité sous 24 heures ouvrable.
                            </small>
                          </>
                        )}
                        {actualABN === "Entreprise" && (
                          <>
                            <Badge>Entreprise</Badge>
                            <small>
                              {" Votre demande sera traité en priorité."}
                            </small>
                          </>
                        )}
                      </form>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="supportclient">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                      <h1 class="h2">Support client</h1>
                    </div>

                    <div class="col-md-7 col-lg-8">
                      <form
                        class="needs-validation"
                        novalidate
                        onSubmit={handleSubmit(sendClientSupport, (err) => {
                          console.log(err);
                        })}
                      >
                        <div class="row g-3">
                          <div class="col-12">
                            <label for="phone" class="form-label">
                              Téléphone{" "}
                              <span class="text-muted">(facultative)</span>
                            </label>
                            <input
                              type="phone"
                              class="form-control"
                              id="phone"
                              placeholder="Téléphone"
                              name="phone"
                              onChange={(e) =>
                                setValue("phone", e.target.value)
                              }
                              ref={register("phone")}
                            />
                          </div>
                          <div class="col-12">
                            <label for="address" class="form-label">
                              Numéro de commande
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="cmd"
                              placeholder="Numéro de commande"
                              value={cmdId}
                              name="commande"
                              onChange={(e) => {
                                {
                                  setValue("commande", e.target.value);
                                  setCmdId(e.target.value);
                                }
                              }}
                              ref={register("commande")}
                            />
                          </div>
                          <div class="col-12">
                            <label for="messageclient" class="form-label">
                              Votre demande
                            </label>
                            <textarea
                              type="text"
                              class="form-control textarea"
                              id="messageclient"
                              placeholder="Veuillez indiqué toutes informations nécessaire pour comprendre votre demande, etc..."
                              required
                              name="messageclient"
                              onChange={(e) =>
                                setValue("messageclient", e.target.value)
                              }
                              ref={register("messageclient")}
                            />
                          </div>
                        </div>

                        <div class="form-check my-4">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="same-address"
                            name="messagecopieclient"
                            onChange={(e) =>
                              setValue("messagecopieclient", e.target.checked)
                            }
                            ref={register("messagecopieclient")}
                          />
                          <label class="form-check-label" for="same-address">
                            Je souhaite recevoir une copie de la demande par
                            email
                          </label>
                        </div>

                        <hr class="my-4" />

                        <button
                          class="w-100 btn btn-dark btn-lg"
                          type="submit"
                          disabled={loadSupportVendorForm}
                        >
                          {loadSupportVendorForm ? "Chargement ..." : "Envoyer"}
                        </button>
                        <small>
                          Votre demande sera traité sous 48 heures ouvrable.
                        </small>
                      </form>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="order">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                      <h1 class="h2">Commandes vendeur</h1>
                      <div class="form">
                        <select
                          class="form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                        >
                          <option selected>Tous</option>
                          <option value="1">Payé</option>
                          <option value="2">Livré</option>
                          <option value="3">Annulé</option>
                        </select>
                      </div>
                    </div>
                    <p style={{ color: "grey", fontSize: 12 }}>
                      Ici sont affiché uniquement les commandes clients.
                    </p>
                    <div class="table-responsive">
                      <table class="table table-striped table-sm">
                        <thead>
                          <tr>
                            <th scope="col">Commande</th>
                            <th scope="col">Date</th>
                            <th scope="col">Mode de livraison</th>
                            <th scope="col">État</th>
                            <th scope="col">Total</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>

                        <PaginatedItemsMyOrder
                            isVendor={true}
                            items={myVendorOrders}
                            itemsPerPage={8}
                          />


                          {/* <tr>
                            <td>#1000 Filaumart Sébastien</td>
                            <td>28 Avril 2022</td>
                            <td>
                              Lettre + suivis
                              <br />
                              <a href="#">LB456789387839</a>
                            </td>
                            <td>Livré</td>
                            <td>8,50€</td>
                            <td>
                              <a
                                href="#"
                                onClick={() => {
                                  sOrder(1); //pass data to show
                                }}
                              >
                                <i class="fa fa-eye"></i>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Badge bg="danger">1</Badge> #0999 Patrick Lye
                            </td>
                            <td>27 Avril 2022</td>
                            <td>Main propre</td>
                            <td>Payé</td>
                            <td>18,30€</td>
                            <td>
                              <a
                                href="#"
                                onClick={() => {
                                  sOrder(1); //pass data to show
                                }}
                              >
                                <i class="fa fa-eye"></i>
                              </a>
                              <a
                                href="#"
                                onClick={() => {
                                  sEOrder(1); //pass data to edit
                                }}
                                style={{ paddingLeft: 20 }}
                              >
                                <i class="fa fa-edit"></i>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Badge bg="danger">1</Badge> #0998 John Doe
                            </td>
                            <td>26 Avril 2022</td>
                            <td>--</td>
                            <td>Annulé</td>
                            <td>48,50€</td>
                            <td>
                              <a
                                href="#"
                                onClick={() => {
                                  sOrder(1); //pass data to show
                                }}
                              >
                                <i class="fa fa-eye"></i>
                              </a>
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div> 
                    <Modal
                      show={showOrder}
                      onHide={hOrder}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          Commande #1000 Filaumart Sébastien
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {/* Afficher les information de la commandes */}
                        {orderInfo}
                        <div class="container">
                          <div class="row">
                            <div class="col-lg-8">
                              <div class="card mb-4">
                                <div class="card-body">
                                  <div class="mb-3 d-flex justify-content-between">
                                    <div>
                                      <span class="me-3">22/11/2022</span>
                                      <span class="me-3">Visa-1234</span>
                                      <span class="badge rounded-pill bg-info">
                                        LIVRAISON
                                      </span>
                                      <span class="badge rounded-pill bg-warning">
                                        EN MAIN PROPRE
                                      </span>
                                    </div>
                                    <div class="d-flex">
                                      <button class="btn btn-link p-0 me-3 d-none d-lg-block btn-icon-text">
                                        <i class="bi bi-download"></i>{" "}
                                        <span class="text">Facture</span>
                                      </button>
                                      <div class="dropdown">
                                        <button
                                          class="btn btn-link p-0 text-muted"
                                          type="button"
                                          data-bs-toggle="dropdown"
                                        >
                                          <i class="bi bi-three-dots-vertical"></i>
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-end">
                                          <li>
                                            <a class="dropdown-item" href="#">
                                              <i class="bi bi-pencil"></i> Edit
                                            </a>
                                          </li>
                                          <li>
                                            <a class="dropdown-item" href="#">
                                              <i class="bi bi-printer"></i>{" "}
                                              Print
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <table class="table table-borderless">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div class="d-flex mb-2">
                                            <div class="flex-shrink-0">
                                              <img
                                                src="https://via.placeholder.com/280x280/87CEFA/000000"
                                                alt=""
                                                width="35"
                                                class="img-fluid"
                                              />
                                            </div>
                                            <div class="flex-lg-grow-1 ms-3">
                                              <h6 class="small mb-0">
                                                <a href="#" class="text-reset">
                                                  Wireless Headphones with Noise
                                                  Cancellation Tru Bass
                                                  Bluetooth HiFi
                                                </a>
                                              </h6>
                                              <span class="small">
                                                Extension: LED9
                                              </span>
                                            </div>
                                          </div>
                                        </td>
                                        <td>1</td>
                                        <td class="text-end">$79.99</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div class="d-flex mb-2">
                                            <div class="flex-shrink-0">
                                              <img
                                                src="https://via.placeholder.com/280x280/FF69B4/000000"
                                                alt=""
                                                width="35"
                                                class="img-fluid"
                                              />
                                            </div>
                                            <div class="flex-lg-grow-1 ms-3">
                                              <h6 class="small mb-0">
                                                <a href="#" class="text-reset">
                                                  Smartwatch IP68 Waterproof GPS
                                                  and Bluetooth Support
                                                </a>
                                              </h6>
                                              <span class="small">
                                                Extension: LED9
                                              </span>
                                            </div>
                                          </div>
                                        </td>
                                        <td>1</td>
                                        <td class="text-end">$79.99</td>
                                      </tr>
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td colspan="2">Sous-total</td>
                                        <td class="text-end">$159,98</td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">LIVRAISON</td>
                                        <td class="text-end">20.00€</td>
                                      </tr>
                                      {/* <tr>
                                        <td colspan="2">
                                          Discount (Code: NEWYEAR)
                                        </td>
                                        <td class="text-danger text-end">
                                          -$10.00
                                        </td>
                                      </tr> */}
                                      <tr class="fw-bold">
                                        <td colspan="2">TOTAL</td>
                                        <td class="text-end">179,98€</td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                              <div class="card mb-4">
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-lg-6">
                                      <h3 class="h6">Methode de paiement</h3>
                                      <p>
                                        Visa-1234 <br />
                                        Total: 179,98€{" "}
                                        <span class="badge bg-success rounded-pill">
                                          PAYER
                                        </span>
                                      </p>
                                    </div>
                                    <div class="col-lg-6">
                                      <h3 class="h6">Adresse de facturation</h3>
                                      <address>
                                        <strong>John Doe</strong>
                                        <br />
                                        1355 Market St, Suite 900
                                        <br />
                                        San Francisco, CA 94103
                                        <br />
                                        <abbr title="Phone">P:</abbr> (123)
                                        456-7890
                                      </address>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="card mb-4">
                                <div class="card-body">
                                  <h3 class="h6">Note du client</h3>
                                  <p>
                                    Sed enim, faucibus litora velit vestibulum
                                    habitasse. Cras lobortis cum sem aliquet
                                    mauris rutrum. Sollicitudin. Morbi, sem
                                    tellus vestibulum porttitor.
                                  </p>
                                </div>
                              </div>
                              <div class="card mb-4">
                                <div class="card-body">
                                  <h3 class="h6">Information de livraison</h3>
                                  <strong>FedEx </strong>
                                  <span>
                                    <a
                                      href="#"
                                      class="text-decoration-underline"
                                      target="_blank"
                                    >
                                      FF1234567890
                                    </a>{" "}
                                    <i class="bi bi-box-arrow-up-right"></i>{" "}
                                  </span>
                                  <hr />
                                  <h3 class="h6">Adresse</h3>
                                  <address>
                                    <strong>John Doe</strong>
                                    <br />
                                    1355 Market St, Suite 900
                                    <br />
                                    San Francisco, CA 94103
                                    <br />
                                    <abbr title="Phone">P:</abbr> (123) 456-7890
                                  </address>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={hOrder}>
                          Fermer
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <Modal
                      show={editOrder}
                      onHide={hEOrder}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          Commande #1000 Filaumart Sébastien
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {/* Afficher les information de la commandes pour les
                        modifiés */}
                        {orderInfo}

                        <div class="container">
                          <div class="row">
                            <div class="col-lg-8">
                              <div class="card mb-4">
                                <div class="card-body">
                                  <div class="mb-3 d-flex justify-content-between">
                                    <div>
                                      <span class="me-3">22/11/2022</span>
                                      <span class="me-3">Visa-1234</span>
                                      <span class="badge rounded-pill bg-info">
                                        LIVRAISON
                                      </span>
                                      <span class="badge rounded-pill bg-warning">
                                        EN MAIN PROPRE
                                      </span>
                                    </div>
                                    <div class="d-flex">
                                      <button class="btn btn-link p-0 me-3 d-none d-lg-block btn-icon-text">
                                        <i class="bi bi-download"></i>{" "}
                                        <span class="text">Facture</span>
                                      </button>
                                      <div class="dropdown">
                                        <button
                                          class="btn btn-link p-0 text-muted"
                                          type="button"
                                          data-bs-toggle="dropdown"
                                        >
                                          <i class="bi bi-three-dots-vertical"></i>
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-end">
                                          <li>
                                            <a class="dropdown-item" href="#">
                                              <i class="bi bi-pencil"></i> Edit
                                            </a>
                                          </li>
                                          <li>
                                            <a class="dropdown-item" href="#">
                                              <i class="bi bi-printer"></i>{" "}
                                              Print
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <table class="table table-borderless">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div class="d-flex mb-2">
                                            <div class="flex-shrink-0">
                                              <img
                                                src="https://via.placeholder.com/280x280/87CEFA/000000"
                                                alt=""
                                                width="35"
                                                class="img-fluid"
                                              />
                                            </div>
                                            <div class="flex-lg-grow-1 ms-3">
                                              <h6 class="small mb-0">
                                                <a href="#" class="text-reset">
                                                  Wireless Headphones with Noise
                                                  Cancellation Tru Bass
                                                  Bluetooth HiFi
                                                </a>
                                              </h6>
                                              <span class="small">
                                                Extension: LED9
                                              </span>
                                            </div>
                                          </div>
                                        </td>
                                        <td>1</td>
                                        <td class="text-end">$79.99</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div class="d-flex mb-2">
                                            <div class="flex-shrink-0">
                                              <img
                                                src="https://via.placeholder.com/280x280/FF69B4/000000"
                                                alt=""
                                                width="35"
                                                class="img-fluid"
                                              />
                                            </div>
                                            <div class="flex-lg-grow-1 ms-3">
                                              <h6 class="small mb-0">
                                                <a href="#" class="text-reset">
                                                  Smartwatch IP68 Waterproof GPS
                                                  and Bluetooth Support
                                                </a>
                                              </h6>
                                              <span class="small">
                                                Extension: LED9
                                              </span>
                                            </div>
                                          </div>
                                        </td>
                                        <td>1</td>
                                        <td class="text-end">$79.99</td>
                                      </tr>
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td colspan="2">Sous-total</td>
                                        <td class="text-end">$159,98</td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">LIVRAISON</td>
                                        <td class="text-end">20.00€</td>
                                      </tr>
                                      {/* <tr>
                                        <td colspan="2">
                                          Discount (Code: NEWYEAR)
                                        </td>
                                        <td class="text-danger text-end">
                                          -$10.00
                                        </td>
                                      </tr> */}
                                      <tr class="fw-bold">
                                        <td colspan="2">TOTAL</td>
                                        <td class="text-end">179,98€</td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                              <div class="card mb-4">
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-lg-6">
                                      <h3 class="h6">Methode de paiement</h3>
                                      <p>
                                        Visa-1234 <br />
                                        Total: 179,98€{" "}
                                        <span class="badge bg-success rounded-pill">
                                          PAYER
                                        </span>
                                      </p>
                                    </div>
                                    <div class="col-lg-6">
                                      <h3 class="h6">Adresse de facturation</h3>
                                      <address>
                                        <strong>John Doe</strong>
                                        <br />
                                        1355 Market St, Suite 900
                                        <br />
                                        San Francisco, CA 94103
                                        <br />
                                        <abbr title="Phone">P:</abbr> (123)
                                        456-7890
                                      </address>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="card mb-4">
                                <div class="card-body">
                                  <h3 class="h6">Note du client</h3>
                                  <p>
                                    Sed enim, faucibus litora velit vestibulum
                                    habitasse. Cras lobortis cum sem aliquet
                                    mauris rutrum. Sollicitudin. Morbi, sem
                                    tellus vestibulum porttitor.
                                  </p>
                                </div>
                              </div>
                              <div class="card mb-4">
                                <div class="card-body">
                                  <h3 class="h6">Adresse de livraison</h3>
                                  <address>
                                    <strong>John Doe</strong>
                                    <br />
                                    1355 Market St, Suite 900
                                    <br />
                                    San Francisco, CA 94103
                                    <br />
                                    <abbr title="Phone">P:</abbr> (123) 456-7890
                                  </address>
                                  <b> Ajouter un numero de suivis</b>
                                  <Form>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>
                                        Nom du prestataire de livraison
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Colissimo, FedEx, ..."
                                      />
                                    </Form.Group>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>URL de suivis</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="http://example.com/suivis"
                                      />
                                    </Form.Group>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Numéro de suivis"
                                      />
                                    </Form.Group>
                                    <Button variant="success" type="submit">
                                      Enregistrer
                                    </Button>
                                  </Form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="primary" onClick={() => {}}>
                          Contacter le client
                        </Button>
                        <Button variant="secondary" onClick={hEOrder}>
                          Fermer
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Tab.Pane>
                  <Tab.Pane eventKey="your-order">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                      <h1 class="h2">Vos Commandes</h1>
                      {/* {myOrders?.length > 0 && (
                        <div class="form">
                          <select
                            class="form-select"
                            id="floatingSelect"
                            aria-label="Floating label select example"
                          >
                            <option selected>Tous</option>
                            <option value="1">Payé</option>
                            <option value="2">Livré</option>
                            <option value="3">Annulé</option>
                            <option value="4"> Remboursé</option>
                          </select>
                        </div>
                      )} */}
                    </div>
                    <p style={{ color: "grey", fontSize: 12 }}>
                      Ici sont affiché uniquement les commandes réaliser auprès
                      des vendeurs.
                    </p>
                    <div class="table-responsive">
                      <table class="table table-striped table-sm">
                        {myOrders?.length > 0 && (
                          <thead>
                            <tr>
                              <th scope="col">#Commande et Nom du vendeur</th>
                              <th scope="col">Date</th>
                              <th scope="col">Mode de livraison</th>
                              <th scope="col">État</th>
                              <th scope="col">Total</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                        )}
                        <tbody>
                          <PaginatedItemsMyOrder
                            items={myOrders}
                            itemsPerPage={8}
                          />
                          {/* -ff-
                          <tr>
                            <td>#0003 CardShop</td>
                            <td>28 Avril 2022</td>
                            <td>
                              Lettre + suivis
                              <br />
                              <a href="#">LB456789387839</a>
                            </td>
                            <td style={{ color: "green" }}>Livré</td>
                            <td>8,50€</td>
                            <td>
                              <a
                                href="#your-order"
                                onClick={() => {
                                  sYOrder(1); // set data to show
                                }}
                              >
                                <i class="fa fa-eye"></i>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>#0999 Patrick Lye</td>
                            <td>27 Avril 2022</td>
                            <td>Main propre</td>
                            <td>Payé</td>
                            <td>18,30€</td>
                            <td>
                              <a
                                href="#your-order"
                                onClick={() => {
                                  sYOrder(1); // set data to show
                                }}
                              >
                                <i class="fa fa-eye"></i>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Badge bg="danger">1</Badge> #0998 John Doe
                            </td>
                            <td>26 Avril 2022</td>
                            <td>--</td>
                            <td>Annulé</td>
                            <td>48,50€</td>
                            <td>
                              <a
                                href="#your-order"
                                onClick={() => {
                                  sYOrder(1); // set data to show
                                }}
                              >
                                <i class="fa fa-eye"></i>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td> #0997 John Doe</td>
                            <td>26 Avril 2022</td>
                            <td>--</td>
                            <td>
                              Remboursé
                              <br />
                              <span
                                style={{ fontSize: 12, fontWeight: "10px" }}
                              >
                                Partiellement <a href="#">10,20€</a>
                              </span>
                            </td>
                            <td>12,50€</td>
                            <td>
                              <a
                                href="#your-order"
                                onClick={() => {
                                  sYOrder(1); // set data to show
                                }}
                              >
                                <i class="fa fa-eye"></i>
                              </a>
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                    {/* <div>
                      <a href="#">Voir plus</a>
                    </div> */}

                    <Modal
                      show={seeYourOrder}
                      onHide={hYOrder}
                      size="xl"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Commande {`#${addLeadingZeros(
                          yourorderInfo?.order?.orderNumber,
                          5
                        )}`} {!yourorderInfo?.isVendor ?  getAllNameVendor(yourorderInfo?.order) : getUserById(yourorderInfo?.order?.userId)?.displayName || ""}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {/* Afficher les information de la commandes */}
                        {/* {console.log("yourorderInfo", yourorderInfo)} */}
                          {yourorderInfo && (
                        <div class="container">
                          <div class="row">
                            <div class="col-lg-8">
                              <div class="card mb-4">
                                <div class="card-body">
                                  <div class="mb-3 d-flex justify-content-between">
                                    <div>
                                      <span class="me-3">{typeof yourorderInfo?.order?.date?.toDate() !== undefined &&
                                      new Date(yourorderInfo?.order?.date?.toDate()).toLocaleDateString("fr-FR", {
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                      })}</span>
                                      <span class="me-3">{yourorderInfo?.payment.charges?.data[0]?.payment_method_details?.card?.brand}-{yourorderInfo?.payment.charges?.data[0]?.payment_method_details?.card?.last4}</span>
                                      
                                      {!yourorderInfo?.isVendor ? <>
                                        {yourorderInfo?.order?.livraisons != null && yourorderInfo?.order?.livraisons > 0 ? <span class="badge rounded-pill bg-info">
                                        LIVRAISON
                                      </span> : <span class="badge rounded-pill bg-warning">
                                        EN MAIN PROPRE
                                      </span>
                                      }
                                      </> : <>
                                      
                                      {yourorderInfo?.order?.data?.livraison != null && yourorderInfo?.order?.data?.livraison > 0 ? <span class="badge rounded-pill bg-info">
                                        LIVRAISON
                                      </span> : <span class="badge rounded-pill bg-warning">
                                        EN MAIN PROPRE
                                      </span>
                                      }
                                      </>}
                                      
                                      
                                    </div>
                                  {!yourorderInfo?.isVendor && (
                                    <div class="d-flex">
                                      <a target="_blank" href={!yourorderInfo?.isVendor && yourorderInfo?.payment.charges?.data[0]?.receipt_url} class="btn btn-link p-0 me-3 d-none d-lg-block btn-icon-text">
                                        <i class="bi bi-download"></i>{" "}
                                        <span class="text">Facture</span>
                                      </a> 
                                    </div>
                                  )}  
                                    
                                  </div>
                                  <table class="table table-borderless">
                                    <tbody>
                                      {yourorderInfo?.isVendor === false &&
                                      yourorderInfo?.order?.data?.map((d) => {
                                        const ps = d.products;
                                         return (<>
                                         {d.orderFinished ? (<p>
                                          {d.name} à <span style={{color:"white"}} class="badge bg-success rounded-pill">
                                            Traitée/envoyée</span> votre commande.
                                         </p>
                        ): (<p>
                          {d.name} <span style={{color:"white"}} class="badge bg-warning rounded-pill">
                            En cours</span> <a href="#" onClick={() => {

                              let message = prompt("Quel est votre message ?");
                          
                              if (message?.length > 0) {
                                // contacter l'utilisateur (pas le vendeur)
                                 
                                App.firestore()
                                  .collection("messages")
                                  .where("uids", "array-contains", App.auth().currentUser?.uid)
                                  .get()
                                  .then((snapshot) => {
                                    let dsd = null;
                                    snapshot.forEach((doc) => {
                                      // console.log(doc.id, "=>", doc.data());
                                      const uids = doc.data().uids;
                                      uids.forEach((id) => {
                                        if (d?.products[0]?.vendorId === id) {
                                    
                                   dsd = doc.id;
                                        }
                                      });
                                    });
                          
                                    const items = {};
                                    items[App.auth().currentUser?.uid] =
                                      App.auth().currentUser?.displayName;
                                    items[d?.products[0]?.vendorId] = d?.name;
                                    if (dsd) {
                                      // on va ajouter le message a cette identifiant
                                      App.firestore()
                                        .collection("messages")
                                        .doc(dsd)
                                        .collection("tchats")
                                        .add({
                                          createAt: new Date(),
                                          message: message,
                                          senderId: App.auth().currentUser?.uid,
                                          type: "text",
                                        })
                                        .then((res) => {
                                          App.firestore()
                                            .collection("messages")
                                            .doc(dsd)
                                            .update({
                                              unread: true,
                                              updateAt: new Date(),
                                              lastMessage: message,
                                              lastMessageBy: App.auth().currentUser?.uid,
                                            })
                                            .then((res) => { 
                                              fetch(API_URL + "sendMessageClientOrder", {
                                                method: "POST",
                                                headers: {
                                                  "Content-Type": "application/json",
                                                },
                                                body: JSON.stringify({
                                                  userId:  d?.products[0]?.vendorId, 
                                                  isOrder: false,
                                                  msg: "Vous avez un nouveau message, cliquez sur le lien pour le voir" ,
                                                  title: "Vous avez un nouveau message" ,
                                                }),
                                              })
                                              alert("Votre message à été envoyé.");
                                            })
                                            .catch((err) => { 
                                              console.log("sendmessage  update error", err);
                                              alert(
                                                "Une erreur s'est produite, réessayer ou contacter nous."
                                              );
                                            });
                                        })
                                        .catch((err) => { 
                                          console.log("sendmessage  create error", err);
                                          alert(
                                            "Une erreur s'est produite, réessayer ou contacter nous."
                                          );
                                        });
                                    } else {
                                      App.firestore()
                                        .collection("messages")
                                        .add({
                                          blocked: false,
                                          createAt: new Date(),
                                          displayName: items,
                                          lastMessage: message,
                                          lastMessageBy: App.auth().currentUser?.uid,
                                          uids: [App.auth().currentUser?.uid, d?.products[0]?.vendorId],
                                          unread: true,
                                          updateAt: new Date(),
                                        })
                                        .then((res) => {
                                          App.firestore()
                                            .collection("messages")
                                            .doc(res.id)
                                            .collection("tchats")
                                            .add({
                                              createAt: new Date(),
                                              message: message,
                                              senderId: App.auth().currentUser?.uid,
                                              type: "text",
                                            })
                                            .then((res) => { 
                                              fetch(API_URL + "sendMessageClientOrder", {
                                                method: "POST",
                                                headers: {
                                                  "Content-Type": "application/json",
                                                },
                                                body: JSON.stringify({
                                                  userId:  d?.products[0]?.vendorId, 
                                                  isOrder: false,
                                                  msg: "Vous avez un nouveau message, cliquez sur le lien pour le voir" ,
                                                  title: "Vous avez un nouveau message" ,
                                                }),
                                              })
                                              alert("Votre message à été envoyé.");
                                            })
                                            .catch((err) => { 
                                              console.log("sendmessage  create error", err);
                                              alert(
                                                "Une erreur s'esr produite, réessayer ou contacter nous."
                                              );
                                            });
                                        })
                                        .catch((err) => { 
                                          console.log("sendmessage error", err);
                                          alert(
                                            "Une erreur s'esr produite, réessayer ou contacter nous."
                                          );
                                        });
                                    }
                                  })
                                  .catch((err) => { 
                                    console.log("sendmessage load error", err);
                                    alert("Une erreur s'esr produite, réessayer ou contacter nous.");
                                  });
                              } 

                            }}>le contacter</a>
                         </p>) }
                                         {ps?.map((p) => {
                                          let product = p.product
                                          return (
                                            <tr>
                                              {/* <th scope="row">{index + 1}</th> */}
                                              <td>
                                          <div class="d-flex mb-2">
                                            <div class="flex-shrink-0">
                                              <img
                                                src={product?.card?.card_images[0]?.image_url}
                                                alt={product?.card?.name}
                                                width="35"
                                                class="img-fluid"
                                              />
                                            </div>
                                            <div class="flex-lg-grow-1 ms-3">
                                              <h6 class="small mb-0">
                                                <a href="#" class="text-reset">
                                                {product?.lang === "fr"
                                                  ? product?.card.name
                                                  : product?.card.name_en}
                                                </a>
                                              </h6>
                                              <span class="small">
                                                {product?.expansion} {product?.rarete}
                                              </span><br/>
                                              <span class="small">
                                                {d?.name}
                                              </span>
                                            </div>
                                          </div>
                                        </td> 
                                              <td>
                                              <div class="form-group mb-0">
                                                <p style={{ textAlign: "left" }}>x<b>{p.qte}</b><br/> 
                                                <span class="small">{product?.etat}</span>
                                                </p> 
                                              </div>
                                            </td>
                                            <td>{product?.price}€</td>
                                            <td class="text-right">
                                                  {Number(Number(product?.price) * p.qte).toFixed(2)}€
                                                </td>
                                            </tr>
                                          )
                                         })}
                                         </>)
                                      })
                                      } 
                                      {
                                        yourorderInfo?.isVendor && 

                                        yourorderInfo?.order?.data?.products?.map((p) => {
                                          let product = p.product 
                                          return (
                                            <tr>
                                              {/* <th scope="row">{index + 1}</th> */}
                                              <td>
                                          <div class="d-flex mb-2">
                                            <div class="flex-shrink-0">
                                              <img
                                                src={product?.card?.card_images[0]?.image_url}
                                                alt={product?.card?.name}
                                                width="35"
                                                class="img-fluid"
                                              />
                                            </div>
                                            <div class="flex-lg-grow-1 ms-3">
                                              <h6 class="small mb-0">
                                                <a href="#" class="text-reset">
                                                {product?.lang === "fr"
                                                  ? product?.card.name
                                                  : product?.card.name_en}
                                                </a>
                                              </h6>
                                              <span class="small">
                                                {product?.expansion}  {product?.rarete}
                                              </span><br/> 
                                            </div>
                                          </div>
                                        </td> 
                                              <td>
                                              <div class="form-group mb-0">
                                                <p style={{ textAlign: "left" }}>x<b>{p.qte}</b><br/> 
                                                <span class="small">{product?.etat}</span>
                                                </p> 
                                              </div>
                                            </td>
                                            <td>{product?.price}€</td>
                                            <td class="text-right">
                                                  {Number(Number(product?.price) * p.qte).toFixed(2)}€
                                                </td>
                                            </tr>
                                          )
                                         })
                                      }
                                    </tbody>
                                    <tfoot>
                                      {yourorderInfo != null && (
                                          <tr>
                                          <td colspan="2">Sous-total</td>
                                          {/* <td class="text-end">{!yourorderInfo?.isVendor ? yourorderInfo?.order?.soustotal?.toFixed(2) : totalsub(yourorderInfo?.order?.data?.products).toFixed(2)}€</td> */}
                                          </tr>
                                      )}
                                     
                                      {!yourorderInfo.isVendor && yourorderInfo?.order?.livraisons != null && yourorderInfo?.order?.livraisons > 0 && (
                                      <tr>
                                        <td colspan="2">LIVRAISON</td>
                                        <td class="text-end">{yourorderInfo?.order?.livraisons?.toFixed(2)}€</td>
                                      </tr>)
                                      }
                                      {yourorderInfo.isVendor && yourorderInfo?.order?.data?.livraison != null && yourorderInfo?.order?.data?.livraison > 0 && (
                                      <tr>
                                        <td colspan="2">LIVRAISON</td>
                                        <td class="text-end">{yourorderInfo?.order?.data?.livraison?.toFixed(2)}€</td>
                                      </tr>)
                                      }
                                      {/* <tr>
                                        <td colspan="2">
                                          Discount (Code: NEWYEAR)
                                        </td>
                                        <td class="text-danger text-end">
                                          -$10.00
                                        </td>
                                      </tr> */}
                                      <tr class="fw-bold">
                                        <td colspan="2">TOTAL</td>
                                        <td class="text-end">{!yourorderInfo.isVendor ? yourorderInfo?.order?.amount : Number(totalsub(yourorderInfo?.order?.data?.products) + (yourorderInfo?.order?.data?.livraison != null && yourorderInfo?.order?.data?.livraison > 0 ? yourorderInfo?.order?.data?.livraison : 0)).toFixed(2)}€</td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                              <div class="card mb-4">
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-lg-6">
                                      <h3 class="h6">Methode de paiement</h3> 
                                      <p>
                                      {yourorderInfo?.payment.charges?.data[0]?.payment_method_details?.card?.brand}-{yourorderInfo?.payment.charges?.data[0]?.payment_method_details?.card?.last4} <br />
                                        Total: {yourorderInfo?.order?.amount}€{" "}
                                        <span class="badge bg-success rounded-pill">
                                          PAYER
                                        </span>
                                      </p>
                                    </div>
                                    <div class="col-lg-6">
                                      <h3 class="h6">Adresse de facturation</h3>
                                      <address> 
                                        {yourorderInfo?.order?.facturation?.name}
                                        <br />
                                        {yourorderInfo?.order?.facturation?.complement && 
                                        (<>
                                        {yourorderInfo?.order?.facturation?.complement}
                                         <br />
                                        </>)
                                        } 
                                        {yourorderInfo?.order?.facturation?.city}, {yourorderInfo?.order?.facturation?.state} {yourorderInfo?.order?.facturation?.zipcode}
                                         
                                      </address>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="card mb-4">
                                <div class="card-body">
                                  <h3 class="h6">Note {yourorderInfo?.isVendor && 'du client' }</h3>
                                  <p>
                                    {yourorderInfo?.isVendor ? yourorderInfo?.order?.data?.comment : 
                                    yourorderInfo?.order?.data?.map((s) => {
                                      return `${s.comment != undefined ? s.comment : ''} `;
                                    })
                                    }
                                  </p>
                                </div>
                              </div>

                              {!yourorderInfo?.isVendor && <>
                                        {yourorderInfo?.order?.livraisons != null && yourorderInfo?.order?.livraisons > 0 &&
                                           
                                        <>
                                        {console.log("ici livraison ", yourorderInfo)}
                                        <div class="card mb-4">
                                        <div class="card-body">
                                          <h3 class="h6">Information de livraison</h3>
                                          {yourorderInfo?.order?.data?.map((d) => {
                                            return (<>
                                              {d.orderFinished && d?.numberDelivery && d?.numberDelivery?.length > 0 && (
                                                <>
                                                <strong>Numéro de livraison ({d.name}): </strong>
                                                <span>
                                                  <a
                                                    href={`https://www.laposte.fr/outils/suivre-vos-envois?code=${d?.numberDelivery}`}
                                                    class="text-decoration-underline"
                                                    target="_blank"
                                                  >
                                                    {d?.numberDelivery}
                                                  </a>{" "}
                                                  <i class="bi bi-box-arrow-up-right"></i>{" "}
                                                </span>
                                                <hr />
                                                </>
                                              )}
                                              </>)

                                            })}

<h3 class="h6">Adresse</h3>
                                            <address>
                                            {yourorderInfo?.order?.adresse?.name}
                                                  <br />
                                                  {yourorderInfo?.order?.adresse?.complement && 
                                                  (<>
                                                  {yourorderInfo?.order?.adresse?.complement}
                                                  <br />
                                                  </>)
                                                  } 
                                                  {yourorderInfo?.order?.adresse?.city}, {yourorderInfo?.order?.adresse?.state} {yourorderInfo?.order?.adresse?.zipcode}
                                                  
                                            </address>
                                          </div>
                                          </div>
                                        </>
                                     
                                      }
                                      </>}
                                      
                              {(yourorderInfo?.order?.livraisons != null && yourorderInfo?.order?.livraisons > 0) || (yourorderInfo?.isVendor && yourorderInfo?.order?.data?.livraison != null && yourorderInfo?.order?.data?.livraison > 0) && (
                                
                              <div class="card mb-4">
                              <div class="card-body">
                                <h3 class="h6">Information de livraison</h3>

                                {yourorderInfo?.isVendor && yourorderInfo?.order?.orderFinished && yourorderInfo?.order?.numberDelivery && yourorderInfo?.order?.numberDelivery?.length > 0 && <>
                                  <strong>Numéro de livraison: </strong>
                                <span>
                                  <a
                                    href={`https://www.laposte.fr/outils/suivre-vos-envois?code=${yourorderInfo?.order?.numberDelivery}`}
                                    class="text-decoration-underline"
                                    target="_blank"
                                  >
                                    {yourorderInfo?.order?.numberDelivery}
                                  </a>{" "}
                                  <i class="bi bi-box-arrow-up-right"></i>{" "}
                                </span>
                                <hr />
                                </>}
                                


                                <h3 class="h6">Adresse</h3>
                                <address>
                                {yourorderInfo?.order?.adresse?.name}
                                      <br />
                                      {yourorderInfo?.order?.adresse?.complement && 
                                      (<>
                                      {yourorderInfo?.order?.adresse?.complement}
                                       <br />
                                      </>)
                                      } 
                                      {yourorderInfo?.order?.adresse?.city}, {yourorderInfo?.order?.adresse?.state} {yourorderInfo?.order?.adresse?.zipcode}
                                       
                                </address>
                              </div>
                            </div>
                              )}
                            </div>
                          </div>
                        </div>
                        )}
                      </Modal.Body>
                      <Modal.Footer>
                         
                        
                        <Button
                          variant="danger"
                          onClick={() => {
                            window.location.href = !yourorderInfo?.isVendor ?
                              `/tableau-de-bord?cmd=${addLeadingZeros(
                                yourorderInfo?.order?.orderNumber,
                                5
                              )}#supportclient` : `/mon-compte#support`;
                            // window.location.reload();
                          }}
                        >
                          Réclamation
                        </Button>
                        {yourorderInfo?.isVendor && (
                          <Button
                          disabled={orderTraitement}
                          variant="success"
                          onClick={() => {  
                            // console.log('yourorderInfo?.order', yourorderInfo)
                            if (confirm('Voulez vous marquez la commande comme traité ?')){
                              let numberDelivery = ""
                              // console.log("icss", yourorderInfo?.order?.data?.livraison)
                              if (yourorderInfo?.order?.data?.livraison != null && yourorderInfo?.order?.data?.livraison > 0){
                                let pr = prompt("Avez vous un numéro de suivis?")

                                if (pr?.length > 0){
                                  numberDelivery = pr;
                                }
                              }
                             const dsds = {orderFinished: true, status: 'paid', transfer: true, orderFinishedDate: new Date(), numberDelivery: numberDelivery }
                              // console.log("yourorderInfo", yourorderInfo)
                              const oId = yourorderInfo?.order?.id || '';
                              
                              const mId = App.auth().currentUser?.uid
                              db.collection("orders")
                              .doc(yourorderInfo?.order?.orderId)
                              .get()
                              .then((dosc) => {
                                // let h = dosc.data()
                                let dt = dosc.data().data;
                                dosc.data().data?.forEach((j, i) => {
                                  if (mId === j.uid){
                                    let psd =j
                                    psd.orderFinished = true;
                                    psd.transfer =  true;
                                    psd.numberDelivery = numberDelivery;
                                    psd.orderFinishedDate = new Date();
                                    dt[i] = psd
                                  }
                                })
                                db.collection("orders")
                                .doc(yourorderInfo?.order?.orderId)
                                .update({data: dt}).then(() => { 
                                    fetch(API_URL + "sendMessageClientOrder", {
                                      method: "POST",
                                      headers: {
                                        "Content-Type": "application/json",
                                      },
                                      body: JSON.stringify({
                                        userId:  yourorderInfo?.order?.userId,
                                        orderNumber: yourorderInfo?.order?.orderNumber ,
                                        msg: "Le vendeur a noté la commande comme terminé, veuillez prendre note du numéro de livraison (pour une livraison) pour une récupération en main propre, prenez contact directement avec le vendeur si ce n'est pas déjà fait" ,
                                        title: "Votre commande à été traitée par le vendeur" ,
                                      }),
                                    })
                                      .then((res) => res.json())
                                      .then( (response) => {
                                        console.log("response email send", response)
                                      })
                                      .catch( (error) => {
                                        console.log("sendMessageClientOrder error!", error);
                                        
                                      })
                                })
                              })
                              
                              db.collection("vendor")
                              .doc(mId)
                              .collection("orders")
                              .doc(oId)
                              .update(dsds)
                              .then(() => { 
                                setorderTraitement(true)
                                loadVListOrder();
                              }).catch((err) => {
                                alert("Une erreur s'est produite")
                                console.error('error orderFinished', err)
                              })
                            }
                          }}
                        >
                          {orderTraitement ? 'Cette commande est traitée': 'Marquer comme traité/envoyé au client'}
                        </Button>
                        ) 
                        }
                        <Button variant="secondary" onClick={hYOrder}>
                          Fermer
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Tab.Pane>
                  <Tab.Pane eventKey="card">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                      <div>
                        <h1 class="h2">Vos Cartes</h1>
                        {myCards?.length > 0 && (
                          <Form.Control
                            placeholder="Filtrer par le mom ou code extension"
                            value={searchFiltre}
                            style={{ width: 300 }}
                            onChange={(e) => {
                              setSearchFiltre(e.target.value);
                            }}
                          />
                        )}
                      </div>
                      <button
                        class="btn btn-white btn-default"
                        onClick={hsCardP}
                      >
                        <i class="fa fa-plus"></i> Nouvelle carte
                      </button>
                    </div>
                    <div class="table-responsive">
                      {resultSearchs().length > 0 ? (
                        <table class="table table-striped table-sm">
                          <thead>
                            <tr>
                              <th scope="col">Nom de la carte (état)</th>
                              <th scope="col">Rareté</th>
                              <th scope="col">CODE</th>
                              <th scope="col">Stock</th>
                              <th scope="col">Prix (unité)</th>
                              <th scope="col">Date</th>
                              <th scope="col">Vues</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <PaginatedItemsMyCards
                              items={resultSearchs()}
                              itemsPerPage={8}
                            />
                          </tbody>
                        </table>
                      ) : (
                        <p>Aucune carte</p>
                      )}
                      {/* <div>
                        <a href="#">Voir plus</a>
                      </div> */}
                    </div>

                    <Modal
                      show={showCardPlus}
                      onHide={hcCardP}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Nouvelle carte</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <b>Rechercher la carte</b>
                        {searchCardSelect === null && (
                          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                            <Form
                              onSubmit={(e) => {
                                e.preventDefault();
                                searchCard();
                                //on va juste charger la liste des correspondances
                              }}
                              style={{
                                background: "rgba(1,33,105,0.05)",
                                padding: "40px 40px",
                                width: "100%",
                                borderRadius: 8,
                              }}
                            >
                              <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                                <Col>
                                  <p>
                                    <b>Extension</b>
                                  </p>
                                  <Form.Select
                                    name="expansion"
                                    value={expansion}
                                    onChange={(e) => {
                                      setExpansion(e.target.value);
                                      extensionList.forEach(element => {
                                        if (element.set_code === e.target.value){
                                          setExpansionName(element.set_name);
                                        }
                                      }); 
                                    }}
                                  >
                                    <option value={null}>Choisir</option>
                                    {extensionList.map((e) => {
                                      return (
                                        <option value={e?.set_code}>
                                          {e.set_code} - {e?.set_name}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                </Col>
                                <Col>
                                  <p>
                                    <b>Nom</b>
                                  </p>
                                  <Form.Control
                                    name="Nom"
                                    placeholder="Nom"
                                    value={search}
                                    onChange={(e) => {
                                      setSearch(e.target.value);
                                    }}
                                  />
                                </Col>
                                <Col>
                                  <p>
                                    <br />
                                  </p>

                                  <Button
                                    variant="dark"
                                    type="submit"
                                    disabled={loadSearch}
                                  >
                                    {loadSearch
                                      ? "Chargement ..."
                                      : "Rechercher"}
                                  </Button>
                                </Col>
                              </Row>
                              <Row  className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                                <Col >
                                  <p>
                                    <br />
                                    <b>Afficher par</b>
                                  </p>
                                  <Form.Select
                                    value={order}
                                    onChange={(e) => {
                                      setOrderBy(e.target.value);
                                    }}
                                  >
                                    <option value={"0"}>A-Z</option>
                                    <option value={"1"}>Z-A</option>
                                    <option value={"2"}>Numéro de carte</option>
                                  </Form.Select>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        )}
                        <div class="row row-cols-2 row-cols-sm-2 row-cols-md-4 g-3 py-3">
                          {searchCardSelect === null &&
                            searchList?.data &&
                            !forceReload && (
                              <>
                                <PaginatedItems
                                  items={searchList?.data}
                                  itemsPerPage={8}
                                  click={(card) => {
                                    if (
                                      searchCardSelect != null &&
                                      searchCardSelect?.id === card?.id
                                    ) {
                                      setSearchCardSelect(null);
                                      setExtensionListCard([]);
                                    } else {
                                      setSearchCardSelect(card);
                                      setExtensionListCard(card.card_sets);
                                    }
                                  }}
                                  searchCardSelect={searchCardSelect}
                                />
                              </>
                            )}

                          {searchList?.data?.map((card) => {
                            return (
                              <>
                                {searchCardSelect?.id === card?.id && (
                                  <ItemAddCard
                                    click={() => {
                                      if (
                                        searchCardSelect != null &&
                                        searchCardSelect?.id === card?.id
                                      ) {
                                        setSearchCardSelect(null); 
                                        setRarete(null) 
                                      } else {
                                        setSearchCardSelect(card);  
                                      }
                                    }}
                                    isSelect={
                                      searchCardSelect != null
                                        ? searchCardSelect?.id === card?.id
                                        : false
                                    }
                                    data={card}
                                    key={card?.id}
                                  />
                                )}
                              </>
                            );
                          })}
                        </div>
                        {searchCardSelect != null && (
                          <div class="">
                            <Form onSubmit={AddSellCard}>
                              <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                                <Col>
                                {f(searchCardSelect) && (
                                  <Form.Group
                                  className="mb-3"
                                  controlId="tarif"
                                >
                                  <Form.Label>Rareté</Form.Label> 
                                  <Form.Select
                                      value={rarete}
                                      onChange={(e) => {
                                        setRarete(e.target.value);
                                      }}
                                      required
                                    >
                                       {searchCardSelect?.card_sets?.map((sc) => {
                                        if (sc.set_code.includes(expansion))
                                        return <option value={sc.set_rarity}>
                                        {sc.set_code} {sc.set_rarity}
                                      </option>
                                       })}
                                    </Form.Select>
                                </Form.Group>
                                )}
                                  <Form.Group
                                    className="mb-3"
                                    controlId="tarif"
                                  >
                                    <Form.Label>Tarif €</Form.Label>
                                    <Form.Control
                                      type="number"
                                      step="0.01"
                                      placeholder="Entrer votre prix"
                                      required
                                      value={prix}
                                      onChange={(e) => {
                                        setPrix(e.target.value);
                                      }}
                                    />
                                  </Form.Group>
                                  {actualABN === "Free" && (
                                    <>
                                      <Badge>Gratuit</Badge>{" "}
                                      <small style={{ fontSize: 11 }}>
                                        (1) Frais de: 5% {getPrix()}
                                      </small>
                                    </>
                                  )}
                                </Col>

                                <Col>
                                  <Form.Group className="mb-3" controlId="qte">
                                    <Form.Label>Quantité </Form.Label>
                                    <Form.Control
                                      type="number"
                                      min={1}
                                      Placeholder="Quantité"
                                      required
                                      value={qteCard}
                                      onChange={(e) => {
                                        setQteCard(e.target.value);
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Badge>Entreprise</Badge>
                                  <Form.Group controlId="syncPriceCardmarket">
                                    <Form.Check
                                      disabled
                                      type="checkbox"
                                      label="Synchronisation du prix avec CardMarket"
                                    />
                                  </Form.Group>
                                  <p style={{ fontSize: 10 }}>
                                    Prix moyen cardMarket:{" "}
                                    <b>
                                      {
                                        searchCardSelect?.card_prices[0]
                                          ?.cardmarket_price
                                      }
                                      €
                                    </b>
                                    <br />
                                    Vous pouvez Synchronisé le prix card market
                                    + un tarif fixe
                                  </p>
                                </Col>
                              </Row>
                              <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                                <Col>
                                  <Form.Group className="mb-3">
                                    <input
                                      type="checkbox"
                                      class="form-check-input"
                                      id="firstEdition"
                                      name="firstEdition"
                                      checked={firstCard}
                                      onChange={(e) =>
                                        setFirstCard(e.target.checked)
                                      }
                                    />
                                    <label
                                      class="form-check-label"
                                      for="firstEdition"
                                    >
                                      {"Première edition"}
                                    </label>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Select
                                      value={langCard}
                                      onChange={(e) => {
                                        setLangCard(e.target.value);
                                      }}
                                      required
                                    >
                                      <option value="fr">
                                        Langue Française
                                      </option>
                                      <option value="en">
                                        Langue Anglaise
                                      </option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Select
                                      aria-label="Ajouter l'état de la carte"
                                      value={stateCard}
                                      onChange={(e) => {
                                        setStateCard(e.target.value);
                                      }}
                                      required
                                    >
                                      <option value={null}>
                                        Choisir l'état minimum de la carte
                                      </option>
                                      <option value="Mint">Mint</option>
                                      <option value="Neart-Mint">
                                        Neart-Mint
                                      </option>
                                      <option value="Excellent">
                                        Excellent
                                      </option>
                                      <option value="Bon état">Bon état</option>
                                      <option value="Assez Jouable">
                                        Assez Jouable
                                      </option>
                                      <option value="Jouable">Jouable</option>
                                      <option value="Mauvais">Mauvais</option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                                <Col>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="livraison"
                                  >
                                    <Form.Check
                                      type="checkbox"
                                      label="Livraison"
                                      class="form-check-input"
                                      id="Livraison"
                                      name="Livraison"
                                      checked={livraison}
                                      onChange={(e) =>
                                        setLivraison(e.target.checked)
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="mainpropre"
                                  >
                                    <Form.Check
                                      type="checkbox"
                                      label="Main propre"
                                      class="form-check-input"
                                      id="mainpropre"
                                      name="mainpropre"
                                      checked={mainpropre}
                                      onChange={(e) =>
                                        setMainPropre(e.target.checked)
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                {mainpropre && (
                                  <Col>
                                    <p style={{ fontSize: 10 }}>
                                      Vous pourrez discuter après la commande du
                                      lieu de transaction.
                                    </p>
                                  </Col>
                                )}

                                <Col>
                                  <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={loadAddSearchCard}
                                  >
                                    {loadAddSearchCard
                                      ? "Chargement ..."
                                      : "Ajouter"}
                                  </Button>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        )}
                      </Modal.Body>
                      <Modal.Footer>
                        <small>
                          Les autres frais sont à la charge du client finale.
                        </small>

                        <Button variant="secondary" onClick={hcCardP}>
                          Fermer
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <Modal
                      show={editMyCard}
                      onHide={hImCOrder}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>{infoMyCard?.card?.name}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {/* Afficher les information de la carte ici */}
                        {/* {console.log("infoMyCard", infoMyCard)} */}

                        <Form class="" onSubmit={updateMyCard}>
                          <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                            <Col>
                              <Form.Group className="mb-3" controlId="tarif">
                                <Form.Label>Tarif €</Form.Label>
                                <Form.Control
                                  type="number"
                                  step="0.01"
                                  placeholder="Entrer votre prix"
                                  required
                                  value={prix}
                                  onChange={(e) => {
                                    setPrix(e.target.value);
                                  }}
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              {actualABN === "Free" && (
                                <div style={{ marginTop: 30 }}>
                                  <Badge>Gratuit</Badge>{" "}
                                  <small style={{ fontSize: 11 }}>
                                    (1) Frais de: 5% {getPrix()}
                                  </small>
                                </div>
                              )}
                            </Col>
                          </Row>
                          <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                            <Col>
                              <Form.Group className="mb-3" controlId="qte">
                                <Form.Control
                                  type="number"
                                  min={0}
                                  Placeholder="Quantité"
                                  required
                                  value={qteCard}
                                  onChange={(e) => {
                                    setQteCard(e.target.value);
                                  }}
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Select
                                  aria-label="Choisir l'état minimum de la carte"
                                  value={stateCard}
                                  onChange={(e) => {
                                    setStateCard(e.target.value);
                                  }}
                                  required
                                >
                                  <option value={null}>
                                    Choisir l'état minimum de la carte
                                  </option>
                                  <option value="Mint">Mint</option>
                                  <option value="Neart-Mint">Neart-Mint</option>
                                  <option value="Excellent">Excellent</option>
                                  <option value="Bon état">Bon état</option>
                                  <option value="Assez Jouable">
                                    Assez Jouable
                                  </option>
                                  <option value="Jouable">Jouable</option>
                                  <option value="Mauvais">Mauvais</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                            <Col>
                              <Form.Group className="mb-3">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  id="firstEdition"
                                  name="firstEdition"
                                  checked={firstCard}
                                  onChange={(e) =>
                                    setFirstCard(e.target.checked)
                                  }
                                />
                                <label
                                  class="form-check-label"
                                  for="firstEdition"
                                >
                                  {"  Première edition"}
                                </label>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Select
                                  value={langCard}
                                  onChange={(e) => {
                                    setLangCard(e.target.value);
                                  }}
                                  required
                                >
                                  <option value="fr">Langue Française</option>
                                  <option value="en">Langue Anglaise</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                            <Col>
                              <Form.Group
                                className="mb-3"
                                controlId="livraison"
                              >
                                <Form.Check
                                  type="checkbox"
                                  label="Livraison"
                                  class="form-check-input"
                                  id="Livraison"
                                  name="Livraison"
                                  checked={livraison}
                                  onChange={(e) =>
                                    setLivraison(e.target.checked)
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group
                                className="mb-3"
                                controlId="mainpropre"
                              >
                                <Form.Check
                                  type="checkbox"
                                  label="Main propre"
                                  class="form-check-input"
                                  id="mainpropre"
                                  name="mainpropre"
                                  checked={mainpropre}
                                  onChange={(e) =>
                                    setMainPropre(e.target.checked)
                                  }
                                />
                              </Form.Group>
                            </Col>
                            {mainpropre && (
                              <Col>
                                <p style={{ fontSize: 10 }}>
                                  Vous pourrez discuter après la commande du
                                  lieu de transaction.
                                </p>
                              </Col>
                            )}
                            <Col>
                              <Button
                                variant="primary"
                                type="submit"
                                disabled={loadAddSearchCard}
                              >
                                {loadAddSearchCard
                                  ? "Chargement..."
                                  : "Mettre à jour"}
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          disabled={loadAddSearchCard}
                          variant="danger"
                          onClick={DeleteMyCard}
                        >
                          {loadAddSearchCard
                            ? "Chargement..."
                            : "Je n'ai plus cette carte"}
                        </Button>
                        <Button variant="secondary" onClick={hImCOrder}>
                          Fermer
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Tab.Pane>
                  <Tab.Pane eventKey="enchere">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                      <h1 class="h2">Vos enchères</h1>

                      <button
                        class="btn btn-white btn-default"
                        onClick={hsEnchereP}
                      >
                        <i class="fa fa-plus"></i> Nouvelle enchère
                      </button>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-striped table-sm">
                        <thead>
                          <tr>
                            <th scope="col">Nom de la carte (état)</th>
                            <th scope="col">Rareté</th>
                            <th scope="col">CODE</th>
                            <th scope="col">Stock</th>
                            <th scope="col">Prix (unité)</th>
                            <th scope="col">Date</th>
                            <th scope="col">Vues</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Dragon noir aux yeux rouge (EX)</td>
                            <td style={{ fontSize: 14 }}>UR</td>
                            <td style={{ fontSize: 14 }}>DDJ-F001</td>
                            <td>1</td>
                            <td style={{ fontSize: 14 }}>450,00€</td>
                            <td style={{ lineHeight: "13px", fontSize: 12 }}>
                              Dernière modification
                              <br />
                              <b style={{ fontSize: 10 }}>29/07/2022 à 11h14</b>
                            </td>
                            <td>105</td>
                            <td>
                              <a href="/enchere/<Edité-dans>/<code-card>/<code-product>">
                                <i class="fa fa-eye"></i>
                              </a>
                              <Badge bg="danger" style={{ marginLeft: 20 }}>
                                En attente de paiement
                              </Badge>
                            </td>
                          </tr>
                          <tr>
                            <td>Dragon noir aux yeux rouge (EX)</td>
                            <td style={{ fontSize: 14 }}>UR</td>
                            <td style={{ fontSize: 14 }}>DDJ-F001</td>
                            <td>1</td>
                            <td style={{ fontSize: 14 }}>450,00€</td>
                            <td style={{ lineHeight: "13px", fontSize: 12 }}>
                              Dernière modification
                              <br />
                              <b style={{ fontSize: 10 }}>29/07/2022 à 11h14</b>
                            </td>
                            <td>105</td>
                            <td>
                              <a href="/enchere/<Edité-dans>/<code-card>/<code-product>">
                                <i class="fa fa-eye"></i>
                              </a>
                              <a
                                href="#"
                                onClick={() => {
                                  sImEOrder(1); //info data to enchere
                                }}
                                style={{ paddingLeft: 20 }}
                              >
                                <i class="fa fa-edit"></i>
                              </a>
                              <Badge bg="warning">Vendu</Badge>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div>
                        <a href="#">Voir plus</a>
                      </div>
                    </div>

                    <Modal
                      show={showEncherePlus}
                      onHide={hcEnchereP}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Nouvelle enchère</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <b>Rechercher la carte</b>

                        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              // console.log('search', )
                              //on va juste charger la liste des correspondances
                              // window.location.href = `/search?q=${search}&type=${type}&expansion=${expansion}&order=${order}&page=1`;
                            }}
                            style={{
                              background: "rgba(1,33,105,0.05)",
                              padding: "40px 40px",
                              width: "100%",
                              borderRadius: 8,
                            }}
                          >
                            <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                              <Col>
                                <p>
                                  <b>Extension</b>
                                </p>
                                <Form.Select
                                  value={expansion}
                                  onChange={(e) => {
                                    setExpansion(e.target.value);
                                    
                                    extensionList.forEach(element => {
                                      if (element.set_code === e.target.value){
                                        setExpansionName(element.set_name);
                                      }
                                    })
                                  }}
                                >
                                  <option>Choisir</option>
                                  {extensionList.map((e) => {
                                    return (
                                      <option value={e?.set_name}>
                                        {e.set_code} - {e?.set_name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Col>
                              <Col>
                                <p>
                                  <b>Nom</b>
                                </p>
                                <Form.Control
                                  placeholder="Nom"
                                  value={search}
                                  onChange={(e) => {
                                    setSearch(e.target.value);
                                  }}
                                />
                              </Col>
                              <Col>
                                <p>
                                  <br />
                                </p>

                                <Button variant="dark" type="submit">
                                  Rechercher
                                </Button>
                              </Col>
                            </Row>
                            <Row className="row-cols-2 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                              <Col >
                                <p>
                                  <br />
                                  <b>Afficher par</b>
                                </p>
                                <Form.Select
                                  aria-label="Default select example"
                                  value={order}
                                  onChange={(e) => {
                                    setOrderBy(e.target.value);
                                  }}
                                >
                                  <option>Prix Croissant</option>
                                  <option value="1">Prix Décroissant</option>
                                  <option value="2">A-Z</option>
                                  <option value="3">Z-A</option>
                                </Form.Select>
                              </Col>
                            </Row>
                          </Form>
                        </div>

                        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3 py-3">
                          <ItemAddCard
                            click={() => {}}
                            isSelect={true}
                            data=""
                          />
                          <ItemAddCard click={() => {}} isSelect="" data="" />
                          <ItemAddCard click={() => {}} isSelect="" data="" />
                          <ItemAddCard click={() => {}} isSelect="" data="" />
                          <ItemAddCard click={() => {}} isSelect="" data="" />
                        </div>
                        <Form class="">
                          <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                            <Col>
                              <Form.Group className="mb-3" controlId="tarif">
                                <Form.Label>Tarif de lancement €</Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="Entrer votre prix"
                                  required
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="mb-3" controlId="tarif">
                                <Form.Label>Prix pour une enchère</Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="Entrer votre prix pour enchérir"
                                  required
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                            <Col>
                              <p>Date et heure de début</p>
                              <Form.Group className="mb-3" controlId="qte">
                                <Form.Control
                                  type="datetime-local"
                                  required
                                  placeholder="Date de début"
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              <p>
                                <br />
                              </p>
                              <Form.Group className="mb-3">
                                <Form.Select aria-label="Ajouter l'état de la carte">
                                  <option>Choisir l'état de la carte</option>
                                  <option value="1">Mint</option>
                                  <option value="2">Neart-Mint</option>
                                  <option value="3">Excellent</option>
                                  <option value="4">Bon état</option>
                                  <option value="5">Assez Jouable</option>
                                  <option value="6">Jouable</option>
                                  <option value="7">Mauvais</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                            <Col>
                              <Form.Group
                                className="mb-3"
                                controlId="livraison"
                              >
                                <Form.Check type="checkbox" label="Livraison" />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group
                                className="mb-3"
                                controlId="mainpropre"
                              >
                                <Form.Check
                                  type="checkbox"
                                  label="Main propre"
                                />
                              </Form.Group>
                            </Col>

                            <Col>
                              <p style={{ fontSize: 10 }}>
                                Vous pourrez discuter après la commande du lieu
                                de transaction.
                              </p>
                            </Col>
                            <Col>
                              <Button variant="primary" type="submit" disabled>
                                Ajouter
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={hcEnchereP}>
                          Fermer
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <Modal
                      show={editMyEnchere}
                      onHide={hImEOrder}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          Nom de la carte enchère{" "}
                          <Badge bg="success">En cours | Dans -1h</Badge>
                          <Badge bg="dark">Terminé</Badge>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {/* Afficher les information de l'enchère ici -- */}
                        {infoMyEnchere}

                        <Form class="">
                          <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                            <Col>
                              <Form.Group className="mb-3" controlId="tarif">
                                <Form.Label>Tarif de lancement €</Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="Entrer votre prix"
                                  required
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="mb-3" controlId="tarif">
                                <Form.Label>Prix pour une enchère</Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="Entrer votre prix pour enchérir"
                                  required
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                            <Col>
                              <p>Date et heure de début</p>
                              <Form.Group className="mb-3" controlId="qte">
                                <Form.Control
                                  type="datetime-local"
                                  required
                                  placeholder="Date de début"
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              <p>
                                <br />
                              </p>
                              <Form.Group className="mb-3">
                                <Form.Select aria-label="Ajouter l'état de la carte">
                                  <option>Choisir l'état de la carte</option>
                                  <option value="1">Mint</option>
                                  <option value="2">Neart-Mint</option>
                                  <option value="3">Excellent</option>
                                  <option value="4">Bon état</option>
                                  <option value="5">Assez Jouable</option>
                                  <option value="6">Jouable</option>
                                  <option value="7">Mauvais</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                            <Col>
                              <Form.Group
                                className="mb-3"
                                controlId="livraison"
                              >
                                <Form.Check type="checkbox" label="Livraison" />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group
                                className="mb-3"
                                controlId="mainpropre"
                              >
                                <Form.Check
                                  type="checkbox"
                                  label="Main propre"
                                />
                              </Form.Group>
                            </Col>

                            <Col>
                              <p style={{ fontSize: 10 }}>
                                Vous pourrez discuter après la commande du lieu
                                de transaction.
                              </p>
                            </Col>
                            <Col>
                              <Button variant="primary" type="submit" disabled>
                                Mettre à jour
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <p style={{ fontSize: 12, color: "gray" }}>
                          Impossible de modifier quand l'enchère est en cours, 1
                          heure avant et terminé
                        </p>
                        <Button variant="danger" onClick={hImEOrder}>
                          Supprimer
                        </Button>
                        <Button variant="secondary" onClick={hImEOrder}>
                          Fermer
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Tab.Pane>
                  <Tab.Pane eventKey="basededeck">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                      <h1 class="h2">Vos bases de deck</h1>
                      <button
                        class="btn btn-white btn-default"
                        onClick={hsDeckP}
                      >
                        <i class="fa fa-plus"></i> Nouvelle base de deck
                      </button>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-striped table-sm">
                        <thead>
                          <tr>
                            <th scope="col">Nom du deck</th>
                            <th scope="col">Nbre de cartes</th>
                            <th scope="col">Prix</th>
                            <th scope="col">Date</th>
                            <th scope="col">Vues</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Cyber Dragon</td>
                            <td>28</td>
                            <td style={{ fontSize: 14 }}>54,00€</td>
                            <td style={{ lineHeight: "13px", fontSize: 12 }}>
                              Dernière modification
                              <br />
                              <b style={{ fontSize: 10 }}>29/07/2022 à 11h14</b>
                            </td>
                            <td>105</td>
                            <td style={{ color: "grey", fontWeight: "bold" }}>
                              Brouillon
                            </td>
                            <td>
                              <a href="/deckbase/<code-deck>">
                                <i class="fa fa-eye"></i>
                              </a>
                              <a
                                href="#basededeck"
                                onClick={() => {
                                  sImBDD(1); // data send to modal
                                }}
                                style={{ paddingLeft: 20 }}
                              >
                                <i class="fa fa-edit"></i>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>Cyber Dragon</td>
                            <td>28</td>
                            <td style={{ fontSize: 14 }}>54,00€</td>
                            <td style={{ lineHeight: "13px", fontSize: 12 }}>
                              Dernière modification
                              <br />
                              <b style={{ fontSize: 10 }}>29/07/2022 à 11h14</b>
                            </td>
                            <td>105</td>
                            <td style={{ color: "green", fontWeight: "bold" }}>
                              Disponible
                            </td>
                            <td>
                              <a href="/deckbase/<code-deck>">
                                <i class="fa fa-eye"></i>
                              </a>
                              <a
                                href="#basededeck"
                                onClick={() => {
                                  sImBDD(1); // data send to modal
                                }}
                                style={{ paddingLeft: 20 }}
                              >
                                <i class="fa fa-edit"></i>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>Cyber Dragon</td>
                            <td>28</td>
                            <td style={{ fontSize: 14 }}>54,00€</td>
                            <td style={{ lineHeight: "13px", fontSize: 12 }}>
                              Dernière modification
                              <br />
                              <b style={{ fontSize: 10 }}>29/07/2022 à 11h14</b>
                            </td>
                            <td>105</td>
                            <td style={{ color: "orange", fontWeight: "bold" }}>
                              Vendu
                              <br />
                              <a
                                href="#your-order"
                                onClick={() => {
                                  window.location.href = "#your-order";
                                  setTimeout(() => {
                                    window.location.reload();
                                  }, 100);
                                }}
                                style={{ fontSize: 10 }}
                              >
                                Commande #10001
                              </a>
                            </td>
                            <td>
                              <a href="/deckbase/<code-deck>">
                                <i class="fa fa-eye"></i>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div>
                        <a href="#">Voir plus</a>
                      </div>
                    </div>

                    <Modal
                      show={showDeckPlus}
                      onHide={hcDeckP}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Nouvelle base de deck</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Attention possible de mettre en brouillon ou publier
                        directement
                        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              // console.log('search', )
                              //on va juste charger la liste des correspondances
                              // window.location.href = `/search?q=${search}&type=${type}&expansion=${expansion}&order=${order}&page=1`;
                            }}
                            style={{
                              background: "rgba(1,33,105,0.05)",
                              padding: "40px 40px",
                              width: "100%",
                              borderRadius: 8,
                            }}
                          >
                            <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                              <Col>
                                <p>
                                  <b>Nom de la base du deck</b>
                                </p>
                                <Form.Control
                                  placeholder="Nom du deck"
                                  value={search}
                                  onChange={(e) => {
                                    setSearch(e.target.value);
                                  }}
                                />
                              </Col>
                              <Col>
                                <p>
                                  <br />
                                </p>

                                <Button variant="dark" type="submit">
                                  Créer
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                        <Button
                          variant="link"
                          onClick={() => setOpenNewListCard(!openNewListCard)}
                          aria-controls="example-collapse-text"
                          aria-expanded={openNewListCard}
                        >
                          Voir la liste des cartes (1)
                        </Button>
                        <Button onClick={() => {}} size="sm">
                          Je valide ma liste
                        </Button>
                        <p style={{ fontSize: 12, color: "gray" }}>
                          Pour ajouter des cartes au deck, rechercher votre
                          carte puis cliquer sur la carte voulu.
                          <br />
                          Cliquer un nombre de fois sur la carte voulu pour
                          l'ajouter un nombre de fois souhaité.
                        </p>
                        <Collapse in={openNewListCard}>
                          <div class="table-responsive">
                            <table class="table table-striped table-sm">
                              <thead>
                                <tr>
                                  <th scope="col">Nom de la carte</th>
                                  <th scope="col">Nbre </th>
                                  <th scope="col">Extension</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Cyber Dragon</td>
                                  <td>3</td>
                                  <td style={{ fontSize: 14 }}>LED9-FR056</td>
                                  <td>
                                    <a
                                      href="#basededeck"
                                      onClick={() => {}}
                                      style={{
                                        textDecoration: "none",
                                        paddingLeft: 20,
                                      }}
                                    >
                                      -1
                                    </a>
                                    <a
                                      href="#basededeck"
                                      onClick={() => {}}
                                      style={{
                                        textDecoration: "none",
                                        paddingLeft: 20,
                                      }}
                                    >
                                      +1
                                    </a>
                                    <a
                                      href="#basededeck"
                                      onClick={() => {}}
                                      style={{
                                        paddingLeft: 20,
                                        color: "red",
                                        textDecoration: "none",
                                      }}
                                    >
                                      Supprimer
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Cyber Dragon</td>
                                  <td>3</td>
                                  <td style={{ fontSize: 14 }}>LED9-FR056</td>
                                  <td>
                                    <a
                                      href="#basededeck"
                                      onClick={() => {}}
                                      style={{
                                        textDecoration: "none",
                                        paddingLeft: 20,
                                      }}
                                    >
                                      -1
                                    </a>
                                    <a
                                      href="#basededeck"
                                      onClick={() => {}}
                                      style={{
                                        textDecoration: "none",
                                        paddingLeft: 20,
                                      }}
                                    >
                                      +1
                                    </a>
                                    <a
                                      href="#basededeck"
                                      onClick={() => {}}
                                      style={{
                                        paddingLeft: 20,
                                        color: "red",
                                        textDecoration: "none",
                                      }}
                                    >
                                      Supprimer
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Cyber Dragon</td>
                                  <td>3</td>
                                  <td style={{ fontSize: 14 }}>LED9-FR056</td>
                                  <td>
                                    <a
                                      href="#basededeck"
                                      onClick={() => {}}
                                      style={{
                                        textDecoration: "none",
                                        paddingLeft: 20,
                                      }}
                                    >
                                      -1
                                    </a>
                                    <a
                                      href="#basededeck"
                                      onClick={() => {}}
                                      style={{
                                        textDecoration: "none",
                                        paddingLeft: 20,
                                      }}
                                    >
                                      +1
                                    </a>
                                    <a
                                      href="#basededeck"
                                      onClick={() => {}}
                                      style={{
                                        paddingLeft: 20,
                                        color: "red",
                                        textDecoration: "none",
                                      }}
                                    >
                                      Supprimer
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Collapse>
                        <hr />
                        <p>Etape 2 recherche de carte</p>
                        <b>Rechercher la carte</b>
                        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              // console.log('search', )
                              //on va juste charger la liste des correspondances
                              // window.location.href = `/search?q=${search}&type=${type}&expansion=${expansion}&order=${order}&page=1`;
                            }}
                            style={{
                              background: "rgba(1,33,105,0.05)",
                              padding: "40px 40px",
                              width: "100%",
                              borderRadius: 8,
                            }}
                          >
                            <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                              <Col>
                                <p>
                                  <b>Extension</b>
                                </p>
                                <Form.Select
                                  value={expansion}
                                  onChange={(e) => {
                                    setExpansion(e.target.value);

                                    extensionList.forEach(element => {
                                      if (element.set_code === e.target.value){
                                        setExpansionName(element.set_name);
                                      }
                                    })
                                  }}
                                >
                                  <option>Choisir</option>
                                  {extensionList.map((e) => {
                                    return (
                                      <option value={e?.set_name}>
                                        {e.set_code} - {e?.set_name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Col>
                              <Col>
                                <p>
                                  <b>Nom</b>
                                </p>
                                <Form.Control
                                  placeholder="Nom"
                                  value={search}
                                  onChange={(e) => {
                                    setSearch(e.target.value);
                                  }}
                                />
                              </Col>
                              <Col>
                                <p>
                                  <br />
                                </p>

                                <Button variant="dark" type="submit">
                                  Rechercher
                                </Button>
                              </Col>
                            </Row>
                            <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                              <Col  >
                                <p>
                                  <br />
                                  <b>Afficher par</b>
                                </p>
                                <Form.Select
                                  aria-label="Default select example"
                                  value={order}
                                  onChange={(e) => {
                                    setOrderBy(e.target.value);
                                  }}
                                >
                                  <option>Prix Croissant</option>
                                  <option value="1">Prix Décroissant</option>
                                  <option value="2">A-Z</option>
                                  <option value="3">Z-A</option>
                                </Form.Select>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                        <div class="row row-cols-2 row-cols-sm-2 row-cols-md-4 g-3 py-3">
                          <ItemAddCard click={() => {}} />
                          <ItemAddCard click={() => {}} />
                          <ItemAddCard click={() => {}} />
                          <ItemAddCard click={() => {}} />
                          <ItemAddCard click={() => {}} />
                          <ItemAddCard click={() => {}} />
                          <ItemAddCard click={() => {}} />
                        </div>
                        <hr />
                        <p>
                          Etape 3 Prix + photos + brouillon ou publier
                          directement + livré ou main propre.
                        </p>
                        <Form class="">
                          <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                            <Col>
                              <Form.Group className="mb-3" controlId="tarif">
                                <Form.Label>Tarif €</Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="Entrer votre prix"
                                  required
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>
                                  <br />
                                </Form.Label>
                                <Form.Select aria-label="Ajouter l'état de la carte">
                                  <option>Choisir l'état du deck</option>
                                  <option value="1">Mint</option>
                                  <option value="2">Neart-Mint</option>
                                  <option value="3">Excellent</option>
                                  <option value="4">Bon état</option>
                                  <option value="5">Assez Jouable</option>
                                  <option value="6">Jouable</option>
                                  <option value="7">Mauvais</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                            <Col>
                              <Form.Group className="mb-3" controlId="file1">
                                <Form.Label>Ajouter une photo</Form.Label>
                                <Form.Control type="file" />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="mb-3" controlId="file2">
                                <Form.Label>Ajouter une autre photo</Form.Label>
                                <Form.Control type="file" />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                            <Col>
                              <Form.Group
                                className="mb-3"
                                controlId="livraison"
                              >
                                <Form.Check type="checkbox" label="Livraison" />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group
                                className="mb-3"
                                controlId="mainpropre"
                              >
                                <Form.Check
                                  type="checkbox"
                                  label="Main propre"
                                />
                              </Form.Group>
                            </Col>

                            <Col>
                              <Form.Group
                                className="mb-3"
                                controlId="publishdirectly"
                              >
                                <Form.Check
                                  type="checkbox"
                                  label="Publier directement"
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Button variant="primary" type="submit" disabled>
                                Publier | Brouillon
                              </Button>
                            </Col>
                          </Row>

                          <p style={{ fontSize: 10 }}>
                            Vous pourrez discuter après la commande du lieu de
                            transaction.
                          </p>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={hcDeckP}>
                          Fermer
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <Modal
                      show={editMyBDD}
                      onHide={hImBDD}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Nom de la base de deck</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {infoMyBDD}
                        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              // console.log('search', )
                              //on va juste charger la liste des correspondances
                              // window.location.href = `/search?q=${search}&type=${type}&expansion=${expansion}&order=${order}&page=1`;
                            }}
                            style={{
                              width: "100%",
                            }}
                          >
                            <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                              <Col>
                                <p>
                                  <b>Nom de la base du deck</b>
                                </p>
                                <Form.Control
                                  placeholder="Nom du deck"
                                  value={search}
                                  onChange={(e) => {
                                    setSearch(e.target.value);
                                  }}
                                />
                              </Col>
                              <Col>
                                <p>
                                  <br />
                                </p>

                                <Button variant="dark" type="submit">
                                  Modifier
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                        <Button
                          variant="link"
                          onClick={() => setOpenNewListCard(!openNewListCard)}
                          aria-controls="example-collapse-text"
                          aria-expanded={openNewListCard}
                        >
                          Voir la liste des cartes (1)
                        </Button>
                        <Button onClick={() => {}} size="sm" variant="success">
                          Je modifie ma liste
                        </Button>
                        <Button onClick={() => {}} size="sm">
                          Je valide ma liste
                        </Button>
                        <p style={{ fontSize: 12, color: "gray" }}>
                          Pour ajouter des cartes au deck, rechercher votre
                          carte puis cliquer sur la carte voulu.
                          <br />
                          Cliquer un nombre de fois sur la carte voulu pour
                          l'ajouter un nombre de fois souhaité.
                        </p>
                        <Collapse in={openNewListCard}>
                          <div class="table-responsive">
                            <table class="table table-striped table-sm">
                              <thead>
                                <tr>
                                  <th scope="col">Nom de la carte</th>
                                  <th scope="col">Nbre </th>
                                  <th scope="col">Extension</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Cyber Dragon</td>
                                  <td>3</td>
                                  <td style={{ fontSize: 14 }}>LED9-FR056</td>
                                  <td>
                                    <a
                                      href="#basededeck"
                                      onClick={() => {}}
                                      style={{
                                        textDecoration: "none",
                                        paddingLeft: 20,
                                      }}
                                    >
                                      -1
                                    </a>
                                    <a
                                      href="#basededeck"
                                      onClick={() => {}}
                                      style={{
                                        textDecoration: "none",
                                        paddingLeft: 20,
                                      }}
                                    >
                                      +1
                                    </a>
                                    <a
                                      href="#basededeck"
                                      onClick={() => {}}
                                      style={{
                                        paddingLeft: 20,
                                        color: "red",
                                        textDecoration: "none",
                                      }}
                                    >
                                      Supprimer
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Cyber Dragon</td>
                                  <td>3</td>
                                  <td style={{ fontSize: 14 }}>LED9-FR056</td>
                                  <td>
                                    <a
                                      href="#basededeck"
                                      onClick={() => {}}
                                      style={{
                                        textDecoration: "none",
                                        paddingLeft: 20,
                                      }}
                                    >
                                      -1
                                    </a>
                                    <a
                                      href="#basededeck"
                                      onClick={() => {}}
                                      style={{
                                        textDecoration: "none",
                                        paddingLeft: 20,
                                      }}
                                    >
                                      +1
                                    </a>
                                    <a
                                      href="#basededeck"
                                      onClick={() => {}}
                                      style={{
                                        paddingLeft: 20,
                                        color: "red",
                                        textDecoration: "none",
                                      }}
                                    >
                                      Supprimer
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Cyber Dragon</td>
                                  <td>3</td>
                                  <td style={{ fontSize: 14 }}>LED9-FR056</td>
                                  <td>
                                    <a
                                      href="#basededeck"
                                      onClick={() => {}}
                                      style={{
                                        textDecoration: "none",
                                        paddingLeft: 20,
                                      }}
                                    >
                                      -1
                                    </a>
                                    <a
                                      href="#basededeck"
                                      onClick={() => {}}
                                      style={{
                                        textDecoration: "none",
                                        paddingLeft: 20,
                                      }}
                                    >
                                      +1
                                    </a>
                                    <a
                                      href="#basededeck"
                                      onClick={() => {}}
                                      style={{
                                        paddingLeft: 20,
                                        color: "red",
                                        textDecoration: "none",
                                      }}
                                    >
                                      Supprimer
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Collapse>
                        <hr />
                        <p>Etape 2 recherche de carte</p>
                        <b>Rechercher la carte</b>
                        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              // console.log('search', )
                              //on va juste charger la liste des correspondances
                              // window.location.href = `/search?q=${search}&type=${type}&expansion=${expansion}&order=${order}&page=1`;
                            }}
                            style={{
                              background: "rgba(1,33,105,0.05)",
                              padding: "40px 40px",
                              width: "100%",
                              borderRadius: 8,
                            }}
                          >
                            <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                              <Col>
                                <p>
                                  <b>Extension</b>
                                </p>
                                <Form.Select
                                  value={expansion}
                                  onChange={(e) => {
                                    setExpansion(e.target.value);

                                    extensionList.forEach(element => {
                                      if (element.set_code === e.target.value){
                                        setExpansionName(element.set_name);
                                      }
                                    })
                                  }}
                                >
                                  <option>Choisir</option>

                                  {extensionList.map((e) => {
                                    return (
                                      <option value={e?.set_name}>
                                         {e.set_code} - {e?.set_name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Col>
                              <Col>
                                <p>
                                  <b>Nom</b>
                                </p>
                                <Form.Control
                                  placeholder="Nom"
                                  value={search}
                                  onChange={(e) => {
                                    setSearch(e.target.value);
                                  }}
                                />
                              </Col>
                              <Col>
                                <p>
                                  <br />
                                </p>

                                <Button variant="dark" type="submit">
                                  Rechercher
                                </Button>
                              </Col>
                            </Row>
                            <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                              <Col  >
                                <p>
                                  <br />
                                  <b>Afficher par</b>
                                </p>
                                <Form.Select
                                  aria-label="Default select example"
                                  value={order}
                                  onChange={(e) => {
                                    setOrderBy(e.target.value);
                                  }}
                                >
                                  <option>Prix Croissant</option>
                                  <option value="1">Prix Décroissant</option>
                                  <option value="2">A-Z</option>
                                  <option value="3">Z-A</option>
                                </Form.Select>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                        <div class="row row-cols-2 row-cols-sm-2 row-cols-md-4 g-3 py-3">
                          <ItemAddCard click={() => {}} />
                          <ItemAddCard click={() => {}} />
                          <ItemAddCard click={() => {}} />
                          <ItemAddCard click={() => {}} />
                          <ItemAddCard click={() => {}} />
                          <ItemAddCard click={() => {}} />
                          <ItemAddCard click={() => {}} />
                        </div>
                        <hr />
                        <p>
                          Etape 3 Prix + photos + brouillon ou publier
                          directement + livré ou main propre.
                        </p>
                        <Form class="">
                          <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                            <Col>
                              <Form.Group className="mb-3" controlId="tarif">
                                <Form.Label>Tarif €</Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="Entrer votre prix"
                                  required
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>
                                  <br />
                                </Form.Label>
                                <Form.Select aria-label="Ajouter l'état de la carte">
                                  <option>Choisir l'état du deck</option>
                                  <option value="1">Mint</option>
                                  <option value="2">Neart-Mint</option>
                                  <option value="3">Excellent</option>
                                  <option value="4">Bon état</option>
                                  <option value="5">Assez Jouable</option>
                                  <option value="6">Jouable</option>
                                  <option value="7">Mauvais</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                            <Col>
                              <Form.Group className="mb-3" controlId="file1">
                                <Form.Label>Ajouter une photo</Form.Label>
                                <Form.Control type="file" />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="mb-3" controlId="file2">
                                <Form.Label>Ajouter une autre photo</Form.Label>
                                <Form.Control type="file" />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                            <Col>
                              <Form.Group
                                className="mb-3"
                                controlId="livraison"
                              >
                                <Form.Check type="checkbox" label="Livraison" />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group
                                className="mb-3"
                                controlId="mainpropre"
                              >
                                <Form.Check
                                  type="checkbox"
                                  label="Main propre"
                                />
                              </Form.Group>
                            </Col>

                            <Col>
                              <Form.Group
                                className="mb-3"
                                controlId="publishdirectly"
                              >
                                <Form.Check
                                  type="checkbox"
                                  label="Publier directement"
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Button
                                variant="primary"
                                type="submit"
                                disabled
                                size="sm"
                              >
                                Enregistrer et Publier | Enregistrer le
                                Brouillon
                              </Button>
                            </Col>
                          </Row>

                          <p style={{ fontSize: 10 }}>
                            Vous pourrez discuter après la commande du lieu de
                            transaction.
                          </p>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <p style={{ fontSize: 12, color: "grey" }}>
                          Si vous l'avez vendu hors de la platforme, supprimer
                          le deck en utilisant le bouton si contre
                        </p>
                        <Button variant="danger" onClick={hImBDD}>
                          Supprimer
                        </Button>
                        <Button variant="secondary" onClick={hImBDD}>
                          Fermer
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Tab.Pane>
                  <Tab.Pane eventKey="search">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                      <h1 class="h2">Mes recherches</h1>
                      <button
                        class="btn btn-white btn-default"
                        onClick={hsSearchP}
                      >
                        <i class="fa fa-plus"></i> Nouvelle recherche
                      </button>
                    </div>
                    <div class="table-responsive">
                      {dataSearchList.length > 0 && (
                        <table class="table table-striped table-sm">
                          <thead>
                            <tr>
                              <th scope="col">
                                Nom de la carte (état minimum)
                              </th>
                              <th scope="col">Rareté</th>
                              <th scope="col">CODE</th>
                              <th scope="col">Nbre</th>
                              <th scope="col">Date</th>
                              <th scope="col">Visible</th>
                              <th scope="col">Vues</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <PaginatedItemsSearch
                              items={dataSearchList}
                              itemsPerPage={12}
                            />
                          </tbody>
                        </table>
                      )}
                    </div>

                    <Modal
                      show={showSearchPlus}
                      onHide={hcSearchP}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Nouvelle recherche de carte</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <b>Rechercher la carte</b>
                        {searchCardSelect === null && (
                          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                            <Form
                              onSubmit={(e) => {
                                e.preventDefault();
                                searchCard();
                                //on va juste charger la liste des correspondances
                              }}
                              style={{
                                background: "rgba(1,33,105,0.05)",
                                padding: "40px 40px",
                                width: "100%",
                                borderRadius: 8,
                              }}
                            >
                              <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                                <Col>
                                  <p>
                                    <b>Extension</b>
                                  </p>
                                  <Form.Select
                                    name="expansion"
                                    value={expansion}
                                    onChange={(e) => {
                                      console.log(e.target.value)
                                      setExpansion(e.target.value);

                                    extensionList.forEach(element => {
                                      if (element.set_code === e.target.value){
                                        setExpansionName(element.set_name);
                                      }
                                    })
                                    }}
                                  >
                                    <option value={null}>Choisir</option>
                                    {extensionList.map((e) => { 
                                      return (
                                        <option value={e.set_code}>
                                           {e.set_code} - {e?.set_name}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                </Col>
                                <Col>
                                  <p>
                                    <b>Nom</b>
                                  </p>
                                  <Form.Control
                                    name="Nom"
                                    placeholder="Nom"
                                    value={search}
                                    onChange={(e) => {
                                      setSearch(e.target.value);
                                    }}
                                  />
                                </Col>
                                <Col>
                                  <p>
                                    <br />
                                  </p>

                                  <Button
                                    variant="dark"
                                    type="submit"
                                    disabled={loadSearch}
                                  >
                                    {loadSearch
                                      ? "Chargement ..."
                                      : "Rechercher"}
                                  </Button>
                                </Col>
                              </Row>
                              <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                                <Col  >
                                  <p>
                                    <br />
                                    <b>Afficher par</b>
                                  </p>
                                  <Form.Select
                                    value={order}
                                    onChange={(e) => {
                                      setOrderBy(e.target.value);
                                    }}
                                  >
                                    <option value={"0"}>A-Z</option>
                                    <option value={"1"}>Z-A</option>
                                    <option value={"2"}>Numéro de carte</option>
                                  </Form.Select>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        )}

                        <div class="row row-cols-2 row-cols-sm-2 row-cols-md-4 g-3 py-3">
                          {searchCardSelect === null &&
                            searchList?.data &&
                            !forceReload && (
                              <>
                                <PaginatedItems
                                  items={searchList?.data}
                                  itemsPerPage={8}
                                  click={(card) => {
                                    if (
                                      searchCardSelect != null &&
                                      searchCardSelect?.id === card?.id
                                    ) {
                                      setSearchCardSelect(null);
                                      setExtensionListCard([]);
                                    } else {
                                      setSearchCardSelect(card);
                                      setExtensionListCard(card.card_sets);
                                    }
                                  }}
                                  searchCardSelect={searchCardSelect}
                                />
                              </>
                            )}

                          {searchList?.data?.map((card) => {
                            return (
                              <>
                                {searchCardSelect?.id === card?.id && (
                                  <ItemAddCard
                                    click={() => {
                                      if (
                                        searchCardSelect != null &&
                                        searchCardSelect?.id === card?.id
                                      ) {
                                        setSearchCardSelect(null);
                                      } else {
                                        setSearchCardSelect(card);
                                      }
                                    }}
                                    isSelect={
                                      searchCardSelect != null
                                        ? searchCardSelect?.id === card?.id
                                        : false
                                    }
                                    data={card}
                                    key={card?.id}
                                  />
                                )}
                              </>
                            );
                          })}
                        </div>
                        {searchCardSelect != null && (
                          <div class="">
                            <Form onSubmit={AddSearchCard}>
                              <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Select
                                      value={expansion}
                                      required
                                      onChange={(e) => {
                                        setExpansion(e.target.value);

                                    extensionList.forEach(element => {
                                      if (element.set_code === e.target.value){
                                        setExpansionName(element.set_name);
                                      }
                                    })
                                      }}
                                    >
                                      <option value={null}>
                                        Choisir l'extension
                                      </option>
                                      {extensionListCard.map((e) => {
                                        return (
                                          <option value={e?.set_name}>
                                              {e.set_code} - {e?.set_name}
                                          </option>
                                        );
                                      })}
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                                <Col>
                                  <Form.Group className="mb-3" controlId="qte">
                                    <Form.Control
                                      type="number"
                                      min={1}
                                      Placeholder="Quantité"
                                      required
                                      value={qteCard}
                                      onChange={(e) => {
                                        setQteCard(e.target.value);
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Select
                                      aria-label="Choisir l'état minimum de la carte"
                                      value={stateCard}
                                      onChange={(e) => {
                                        setStateCard(e.target.value);
                                      }}
                                      required
                                    >
                                      <option value={null}>
                                        Choisir l'état minimum de la carte
                                      </option>
                                      <option value="Mint">Mint</option>
                                      <option value="Neart-Mint">
                                        Neart-Mint
                                      </option>
                                      <option value="Excellent">
                                        Excellent
                                      </option>
                                      <option value="Bon état">Bon état</option>
                                      <option value="Assez Jouable">
                                        Assez Jouable
                                      </option>
                                      <option value="Jouable">Jouable</option>
                                      <option value="Mauvais">Mauvais</option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Select
                                      value={langCard}
                                      onChange={(e) => {
                                        setLangCard(e.target.value);
                                      }}
                                      required
                                    >
                                      <option value="fr">
                                        Langue Française
                                      </option>
                                      <option value="en">
                                        Langue Anglaise
                                      </option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Select
                                      value={publishCard}
                                      onChange={(e) => {
                                        setPublishCard(e.target.value);
                                      }}
                                      required
                                    >
                                      <option value="1">
                                        Publier directement
                                      </option>
                                      <option value="0">Brouillon</option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <input
                                      type="checkbox"
                                      class="form-check-input"
                                      id="firstEdition"
                                      name="firstEdition"
                                      onChange={(e) =>
                                        setFirstCard(e.target.checked)
                                      }
                                    />
                                    <label
                                      class="form-check-label"
                                      for="firstEdition"
                                    >
                                      {"  Première edition"}
                                    </label>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={loadAddSearchCard}
                                  >
                                    {loadAddSearchCard
                                      ? "Chargement..."
                                      : "Ajouter"}
                                  </Button>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        )}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={hcSearchP}>
                          Fermer
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <Modal
                      show={editMySearch}
                      onHide={hImSearch}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          {infoMySearch?.lang === "fr"
                            ? infoMySearch?.card?.name
                            : infoMySearch?.card?.name_en}
                        </Modal.Title>
                      </Modal.Header>
                      {/* Afficher les information de la rechercher */}
                      {/* {infoMySearch} */}
                      <Modal.Body>
                        <div class="">
                          <Form class="" onSubmit={UpdateSearchCard}>
                            <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                              <Col>
                                <Form.Group className="mb-3" controlId="qte">
                                  <Form.Control
                                    type="number"
                                    min={0}
                                    Placeholder="Quantité"
                                    required
                                    value={qteCard}
                                    onChange={(e) => {
                                      setQteCard(e.target.value);
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group className="mb-3">
                                  <Form.Select
                                    aria-label="Choisir l'état minimum de la carte"
                                    value={stateCard}
                                    onChange={(e) => {
                                      setStateCard(e.target.value);
                                    }}
                                    required
                                  >
                                    <option value={null}>
                                      Choisir l'état minimum de la carte
                                    </option>
                                    <option value="Mint">Mint</option>
                                    <option value="Neart-Mint">
                                      Neart-Mint
                                    </option>
                                    <option value="Excellent">Excellent</option>
                                    <option value="Bon état">Bon état</option>
                                    <option value="Assez Jouable">
                                      Assez Jouable
                                    </option>
                                    <option value="Jouable">Jouable</option>
                                    <option value="Mauvais">Mauvais</option>
                                  </Form.Select>
                                </Form.Group>
                              </Col>

                              <Col>
                                <Form.Group className="mb-3">
                                  <Form.Select
                                    value={langCard}
                                    onChange={(e) => {
                                      setLangCard(e.target.value);
                                    }}
                                    required
                                  >
                                    <option value="fr">Langue Française</option>
                                    <option value="en">Langue Anglaise</option>
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                              <Col>
                                <Form.Group className="mb-3">
                                  <Form.Select
                                    value={publishCard}
                                    onChange={(e) => {
                                      setPublishCard(e.target.value);
                                    }}
                                    required
                                  >
                                    <option value="1">Visible</option>
                                    <option value="0">Brouillon</option>
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group className="mb-3">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="firstEdition"
                                    name="firstEdition"
                                    checked={firstCard}
                                    onChange={(e) =>
                                      setFirstCard(e.target.checked)
                                    }
                                  />
                                  <label
                                    class="form-check-label"
                                    for="firstEdition"
                                  >
                                    {"  Première edition"}
                                  </label>
                                </Form.Group>
                              </Col>

                              <Col>
                                <Button
                                  variant="primary"
                                  type="submit"
                                  disabled={loadAddSearchCard}
                                >
                                  {loadAddSearchCard
                                    ? "Chargement..."
                                    : "Mettre à jour"}
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="danger" onClick={DeleteSearchCard}>
                          {loadAddSearchCard ? "Chargement..." : "Supprimer"}
                        </Button>
                        <Button variant="secondary" onClick={hImSearch}>
                          Fermer
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Tab.Pane>
                  <Tab.Pane eventKey="messages">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                      <h1 class="h2">Vos messages</h1>
                    </div>

                    <Container>
                      <Row className="show-grid">
                        <Col {...chatListProps} md={4}>
                          <UserList
                            myid={user?.uid}
                            userData={state.userChatData}
                            onChatClicked={(e) => {
                              onChatClicked(e);
                            }}
                          />
                        </Col>
                        <Col {...chatBoxProps} md={8}>
                          <ChatBox
                            myid={user?.uid}
                            signedInUser={user}
                            onSendClicked={createMessage.bind(this)}
                            onBackPressed={toggleViews.bind(this)}
                            targetUser={getTargetuser()}
                          />
                        </Col>
                      </Row>
                    </Container>
                    {/* <div class="table-responsive">
                      <table class="table table-striped table-sm">
                        <tbody>
                          <tr> 
                            <td
                              style={{ paddingLeft: 10 }}
                              onClick={() => {
                                sMessages(1); // data message
                              }}
                            >
                              <span>ShinFenix</span>{" "}
                              <Badge bg="danger">1</Badge>
                              <br />
                              <p
                                style={{
                                  color: "rgba(0, 0, 0, 0.5) !important",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  fontSize: 10,
                                }}
                              >
                                Bonsoir, merci pour le numéro de suivis bonne
                                soirée a vous, je vous informerais de la
                                livraison.
                              </p>
                            </td>
                            <td
                              style={{ lineHeight: "13px", fontSize: 12 }}
                              onClick={() => {
                                sMessages(1); // data message
                              }}
                            >
                              <b style={{ fontSize: 10 }}>29/07/2022 à 11h14</b>
                            </td>
                            <td
                              onClick={() => {
                                sMessages(1); // data message
                              }}
                            >
                              <i
                                class="fa fa-arrow-right"
                                style={{ marginTop: 17 }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ paddingLeft: 10 }}
                              onClick={() => {
                                sMessages(1); // data message
                              }}
                            >
                              <span>ShinFenix</span>
                              <br />
                              <p
                                style={{
                                  color: "rgba(0, 0, 0, 0.5) !important",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  fontSize: 10,
                                }}
                              >
                                Bonsoir, merci pour le numéro de suivis bonne
                                soirée a vous, je vous informerais de la
                                livraison.
                              </p>
                            </td>
                            <td
                              style={{ lineHeight: "13px", fontSize: 12 }}
                              onClick={() => {
                                sMessages(1); // data message
                              }}
                            >
                              <b style={{ fontSize: 10 }}>29/07/2022 à 11h14</b>
                            </td>
                            <td
                              onClick={() => {
                                sMessages(1); // data message
                              }}
                            >
                              <i
                                class="fa fa-arrow-right"
                                style={{ marginTop: 17 }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ paddingLeft: 10 }}
                              onClick={() => {
                                sMessages(1); // data message
                              }}
                            >
                              <span>ShinFenix</span>
                              <br />
                              <p
                                style={{
                                  color: "rgba(0, 0, 0, 0.5) !important",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  fontSize: 10,
                                }}
                              >
                                Bonsoir, merci pour le numéro de suivis bonne
                                soirée a vous, je vous informerais de la
                                livraison.
                              </p>
                            </td>
                            <td
                              style={{ lineHeight: "13px", fontSize: 12 }}
                              onClick={() => {
                                sMessages(1); // data message
                              }}
                            >
                              <b style={{ fontSize: 10 }}>29/07/2022 à 11h14</b>
                            </td>
                            <td
                              onClick={() => {
                                sMessages(1); // data message
                              }}
                            >
                              <i
                                class="fa fa-arrow-right"
                                style={{ marginTop: 17 }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div>
                        <a href="#">Voir plus</a>
                      </div>
                    </div> */}

                    <Modal
                      show={openMessage}
                      onHide={hMessages}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Nom du vendeur/client</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <a
                          target="_blank"
                          href="https://github.com/akash-kamat/firechat/blob/master/src/components/ChatRoom.js"
                        >
                          https://github.com/akash-kamat/firechat/blob/master/src/components/ChatRoom.js
                        </a>
                        Afficher les information de la conversation Afficher les
                        information de la conversation Afficher les information
                        de la conversation Afficher les information de la
                        conversation Afficher les information de la conversation
                        Afficher les information de la conversation Afficher les
                        information de la conversation Afficher les information
                        de la conversation Afficher les information de la
                        conversation Afficher les information de la conversation
                        Afficher les information de la conversation Afficher les
                        information de la conversation Afficher les information
                        de la conversation Afficher les information de la
                        conversation Afficher les information de la conversation
                        Afficher les information de la conversation Afficher les
                        information de la conversation
                        {dataMessages}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="dark" onClick={hMessages}>
                          Fermer
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Tab.Pane>
                  <Tab.Pane eventKey="profil">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                      <h1 class="h2">Votre compte utilisateur</h1>
                    </div>

                    <div class="row g-5">
                      <div class="col-md-5 col-lg-4 order-md-last">
                        {isVendorOrPendingVendor ? (
                          <p style={{ fontSize: 12 }}>
                            Type de compte : Vendeur (
                            {actualABN === "Free" ? "Gratuit" : actualABN})
                          </p>
                        ) : (
                          <p>Type de compte : Utilisateur</p>
                        )}

                        <button
                          type="submit"
                          class="btn btn-link"
                          onClick={showChangePass}
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          Changer son mot de passe
                        </button>
                        <button
                          type="submit"
                          class="btn btn-link"
                          onClick={sSCard}
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          Voir mes cartes enregistrer
                        </button>
                        <button
                          type="submit"
                          class="btn btn-link"
                          style={{
                            color: "red",
                            marginTop: 100,
                            textDecoration: "none",
                          }}
                          onClick={() => {
                            setPwdModal(true);
                          }}
                        >
                          Supprimer mon compte
                        </button>
                      </div>
                      <div class="col-md-7 col-lg-8">
                        {user && (
                          <form
                            class="needs-validation"
                            novalidate
                            enctype="multipart/form-data"
                            onSubmit={handleSubmit(updateUserForm, (err) => {
                              console.log(err);
                            })}
                          >
                            <div class="row g-3">
                              <div class="col-sm-6">
                                <label for="firstName" class="form-label">
                                  Prénom
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="firstName"
                                  placeholder=""
                                  required
                                  onChange={(e) =>
                                    setValue("firstName", e.target.value)
                                  }
                                  {...register("firstName")}
                                />
                                <div class="invalid-feedback">
                                  Prénom est requis
                                </div>
                              </div>

                              <div class="col-sm-6">
                                <label for="lastName" class="form-label">
                                  Nom
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="lastName"
                                  placeholder=""
                                  name="lastName"
                                  required
                                  onChange={(e) =>
                                    setValue("lastName", e.target.value)
                                  }
                                  {...register("lastName")}
                                />
                                <div class="invalid-feedback">
                                  Un Nom de famille valide est requis
                                </div>
                              </div>

                              <div class="col-12">
                                <label for="username" class="form-label">
                                  Nom d'utilisateur
                                </label>
                                <div class="input-group has-validation">
                                  <span class="input-group-text">@</span>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="username"
                                    placeholder="Username"
                                    required
                                    onChange={(e) =>
                                      setValue("username", e.target.value)
                                    }
                                    {...register("username")}
                                  />
                                  <div class="invalid-feedback">
                                    Votre nom d'utilisateur est obligatoire
                                  </div>
                                </div>
                              </div>

                              <div class="col-12">
                                <label for="email" class="form-label">
                                  Email
                                </label>
                                <input
                                  type="email"
                                  class="form-control"
                                  id="email"
                                  requis
                                  disabled
                                  onChange={(e) =>
                                    setValue("email", e.target.value)
                                  }
                                  {...register("email")}
                                />
                              </div>
                              <div class="col-12">
                                <label for="phone" class="form-label">
                                  Téléphone{" "}
                                  <span class="text-muted">(facultative)</span>
                                </label>
                                <input
                                  type="phone"
                                  class="form-control"
                                  id="phone"
                                  placeholder="Téléphone personnelle"
                                  onChange={(e) =>
                                    setValue("phoneperso", e.target.value)
                                  }
                                  {...register("phoneperso")}
                                />
                              </div>

                              {userformhasAdresseLocation && (
                                <h5>Adresse de facturation</h5>
                              )}

                              <div class="col-12">
                                <label for="address" class="form-label">
                                  Adresse
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="address"
                                  placeholder="1234 Main St"
                                  required
                                  onChange={(e) =>
                                    setValue("addressperso", e.target.value)
                                  }
                                  {...register("addressperso")}
                                />
                                <div class="invalid-feedback">
                                  Veuillez entrer une adresse valide pour la
                                  livraison / facturation
                                </div>
                              </div>

                              <div class="col-12">
                                <label for="address2" class="form-label">
                                  Adresse 2{" "}
                                  <span class="text-muted">(facultative)</span>
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="address2"
                                  placeholder="Appartement et complément d'adresse"
                                  onChange={(e) =>
                                    setValue("complementperso", e.target.value)
                                  }
                                  {...register("complementperso")}
                                />
                              </div>

                              <div class="col-md-6">
                                <label for="country" class="form-label">
                                  Pays
                                </label>
                                <select
                                  class="form-select"
                                  id="country"
                                  required
                                  onChange={(e) =>
                                    setValue("countryperso", e.target.value)
                                  }
                                  {...register("countryperso")}
                                >
                                  <option value="">Choisir...</option>
                                  <option value="France">France</option>
                                </select>
                                <div class="invalid-feedback">
                                  Please select a valid country.
                                </div>
                              </div>

                              <div class="col-md-6">
                                <label for="state" class="form-label">
                                  Etat / région
                                </label>
                                <select
                                  class="form-select"
                                  id="state"
                                  required
                                  onChange={(e) =>
                                    setValue("stateperso", e.target.value)
                                  }
                                  {...register("stateperso")}
                                >
                                  <option value="">Choisir...</option>
                                  <option value="France">
                                    France Métropolitaine
                                  </option>
                                  <option value="île de la Réunion">
                                    Île de la Réunion
                                  </option>
                                </select>
                              </div>
                              <div class="col-md-6">
                                <label for="city" class="form-label">
                                  Ville
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="city"
                                  name="city"
                                  required
                                  onChange={(e) =>
                                    setValue("cityperso", e.target.value)
                                  }
                                  {...register("cityperso")}
                                />
                              </div>

                              <div class="col-md-6">
                                <label for="zip" class="form-label">
                                  Code postale
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="zip"
                                  maxLength={5}
                                  placeholder=""
                                  required
                                  onChange={(e) =>
                                    setValue("zipcodeperso", e.target.value)
                                  }
                                  {...register("zipcodeperso")}
                                />
                                <div class="invalid-feedback">
                                  Zip code required.
                                </div>
                              </div>
                            </div>

                            <div class="form-check my-4">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="same-addresss"
                                name="same-address"
                                onChange={(e) => {
                                  setValue("same-address", e.target.checked);
                                  // alert(e);
                                  setUFHAL(e.target.checked);
                                }}
                                checked={
                                  userformhasAdresseLocation ? "checked" : null
                                }
                                ref={register("same-address")}
                              />
                              <label
                                class="form-check-label"
                                for="same-addresss"
                              >
                                L'adresse de livraison est différente que mon
                                adresse de facturation
                              </label>
                            </div>

                            {userformhasAdresseLocation && (
                              <>
                                <h5>Adresse de livraison</h5>
                                <div class="row g-3">
                                  <div class="col-12">
                                    <label
                                      for="addresslocation"
                                      class="form-label"
                                    >
                                      Adresse
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="addresslocation"
                                      placeholder="1234 Main St"
                                      required
                                      onChange={(e) =>
                                        setValue(
                                          "addresspersolocation",
                                          e.target.value
                                        )
                                      }
                                      {...register("addresspersolocation")}
                                    />
                                    <div class="invalid-feedback">
                                      Veuillez entrer une adresse valide pour la
                                      livraison / facturation
                                    </div>
                                  </div>

                                  <div class="col-12">
                                    <label
                                      for="address2location"
                                      class="form-label"
                                    >
                                      Adresse 2{" "}
                                      <span class="text-muted">
                                        (facultative)
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="address2location"
                                      placeholder="Appartement et complément d'adresse"
                                      onChange={(e) =>
                                        setValue(
                                          "complementpersolocation",
                                          e.target.value
                                        )
                                      }
                                      {...register("complementpersolocation")}
                                    />
                                  </div>

                                  <div class="col-md-6">
                                    <label
                                      for="countrylocation"
                                      class="form-label"
                                    >
                                      Pays
                                    </label>
                                    <select
                                      class="form-select"
                                      id="countrylocation"
                                      required
                                      onChange={(e) =>
                                        setValue(
                                          "countrypersolocation",
                                          e.target.value
                                        )
                                      }
                                      {...register("countrypersolocation")}
                                    >
                                      <option value="">Choisir...</option>
                                      <option value="France">France</option>
                                    </select>
                                    <div class="invalid-feedback">
                                      Please select a valid country.
                                    </div>
                                  </div>

                                  <div class="col-md-6">
                                    <label
                                      for="statelocation"
                                      class="form-label"
                                    >
                                      Etat / région
                                    </label>
                                    <select
                                      class="form-select"
                                      id="statelocation"
                                      required
                                      onChange={(e) =>
                                        setValue(
                                          "statepersolocation",
                                          e.target.value
                                        )
                                      }
                                      {...register("statepersolocation")}
                                    >
                                      <option value="">Choisir...</option>
                                      <option value="France">
                                        France Métropolitaine
                                      </option>
                                      <option value="île de la Réunion">
                                        Île de la Réunion
                                      </option>
                                    </select>
                                  </div>
                                  <div class="col-md-6">
                                    <label
                                      for="citylocation"
                                      class="form-label"
                                    >
                                      Ville
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="citylocation"
                                      name="citylocation"
                                      required
                                      onChange={(e) =>
                                        setValue(
                                          "citypersolocation",
                                          e.target.value
                                        )
                                      }
                                      {...register("citypersolocation")}
                                    />
                                  </div>

                                  <div class="col-md-6">
                                    <label for="ziplocation" class="form-label">
                                      Code postale
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="ziplocation"
                                      maxLength={5}
                                      placeholder=""
                                      required
                                      onChange={(e) =>
                                        setValue(
                                          "zipcodepersolocation",
                                          e.target.value
                                        )
                                      }
                                      {...register("zipcodepersolocation")}
                                    />
                                    <div class="invalid-feedback">
                                      Zip code required.
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            <hr class="my-4" />

                            <button
                              class="w-100 btn btn-dark btn-lg"
                              type="submit"
                              disabled={loadingForm1}
                            >
                              {loadingForm1
                                ? "Chargement ...."
                                : "Enregistrer et mettre à jour"}
                            </button>
                          </form>
                        )}
                      </div>
                    </div>

                    <Modal
                      show={pwdModal}
                      onHide={() => setPwdModal(false)}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Supprimer mon compte</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Pour valider la suppression de votre compte, indiquez
                        votre mot de passe et cliquer sur supprimer.
                        <Form onSubmit={deleteUser}>
                          <Form.Group
                            className="mb-3"
                            controlId="actualPassword"
                          >
                            <Form.Label>Mot de passe actuel</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Actuel"
                              required
                              onChange={(e) => {
                                setPWd(e.target.value);
                              }}
                            />
                          </Form.Group>
                          {user &&
                            (user?.role === "vendor" ||
                              user?.role === "pendingvendor") && (
                              <div class="form-check d-flex mb-3 ">
                                <input
                                  class="form-check-input me-2"
                                  type="checkbox"
                                  name="registerCheckVendor"
                                  id="registerCheckVendor"
                                  required
                                />
                                <label
                                  class="form-check-label"
                                  for="registerCheckVendor"
                                >
                                  ⚠️ Je suis vendeur et j'ai annulé tout mes
                                  abonnements en cours
                                </label>
                              </div>
                            )}

                          <Button
                            variant="danger"
                            type="submit"
                            disabled={pwdLoading}
                          >
                            {pwdLoading ? "Chargement ... " : "Supprimer"}
                          </Button>
                        </Form>
                      </Modal.Body>
                    </Modal>

                    <Modal show={openChangePass} onHide={hideChangePass}>
                      <Modal.Header closeButton>
                        <Modal.Title>Changer son mot de passe</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {/* Afficher le formulaire de changement de mot de passe
                        {infoMySearch} */}
                        <Form
                          onSubmit={handleSubmit(saveNewPassword, (err) => {
                            console.log(err);
                          })}
                        >
                          <Form.Group
                            className="mb-3"
                            controlId="actualPassword"
                          >
                            <Form.Label>Mot de passe actuel</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Actuel"
                              required
                              onChange={(e) =>
                                setValue("actualPassword", e.target.value)
                              }
                              ref={register("actualPassword")}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Nouveau mot de passe</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Nouveau"
                              required
                              minLength={8}
                              onChange={(e) =>
                                setValue("newpassword", e.target.value)
                              }
                              ref={register("newpassword")}
                            />
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPasswordConfirm"
                          >
                            <Form.Label>
                              Confirmation du nouveau mot de passe
                            </Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Confirmation"
                              required
                              minLength={8}
                              onChange={(e) =>
                                setValue("confirmPassword", e.target.value)
                              }
                              ref={register("confirmPassword")}
                            />
                          </Form.Group>
                          <Button
                            variant="primary"
                            type="submit"
                            disabled={loadingForm1}
                          >
                            {loadingForm1
                              ? "Chargement..."
                              : "Modifier mon mot de passe"}
                          </Button>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={hideChangePass}>
                          Annuler
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <Modal show={showCards} onHide={hsCard}>
                      <Modal.Header closeButton>
                        <Modal.Title>Mes Cartes enregistrer</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {/* Afficher les cartes enregistrer */}
                        {/* {infoMySearch} */}
                        {loadCard ? (
                          "Chargement ...."
                        ) : (
                          <>{cardBank.length === 0 && <p>Aucune carte</p>}</>
                        )}
                        {cardBank.map((data) => {
                          return (
                            <BankCard data={data} getCardsUser={getCardsUser} />
                          );
                        })}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={hsCard}>
                          Fermer
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Tab.Pane>
                  <Tab.Pane eventKey="settings">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                      <h1 class="h2">Paramètres de vendeur</h1>
                    </div>

                    <div class="row g-5">
                      <div class="col-md-5 col-lg-4 order-md-last">
                        <Alert>
                          <p style={{ fontSize: 10, color: "gray" }}>
                            ⚠️ Information: Nos solutions sont actuellement
                            disponible que pour la zone ïle de la réunion.
                          </p>
                        </Alert>
                        <hr />
                        {vendeur ? (
                          <button
                            type="submit"
                            class="btn btn-link"
                            disabled={loadingStripe}
                            onClick={linkStripe}
                          >
                            {loadingStripe
                              ? "Chargement ..."
                              : "Voir ses gains"}
                          </button>
                        ) : (
                          <button
                            type="submit"
                            class="btn btn-link"
                            disabled={loadingStripe}
                            onClick={linkStripe}
                          >
                            Ouvrir et activé son compte stripe
                          </button>
                        )}

                        <p style={{ fontSize: 10, color: "gray" }}>
                          Stripe est la solution bancaire permettant d'activé
                          votre compte vendeur afin de réaliser des ventes sur
                          notre plate-forme
                        </p>
                      </div>
                      <div class="col-md-7 col-lg-8">
                        {vendeur ? (
                          <form
                            class="needs-validation"
                            novalidate
                            enctype="multipart/form-data"
                            onSubmit={handleSubmit(updateVendorForm, (err) => {
                              console.log(err);
                            })}
                          >
                            <div class="row g-3">
                              <div class="col-sm-6">
                                <label for="logo" class="form-label">
                                  Logo de vendeur
                                </label>
                                <input
                                  type="file"
                                  class="form-control"
                                  id="logo"
                                  name="logo"
                                  accept="image/*"
                                  onChange={(e) =>
                                    setValue("logo", e.target.files[0])
                                  }
                                  ref={register("logo")}
                                />
                              </div>

                              <div class="col-sm-6">
                                <label for="banniere" class="form-label">
                                  Bannière vendeur
                                </label>
                                <input
                                  type="file"
                                  class="form-control"
                                  id="lastName"
                                  name="hero"
                                  accept="image/*"
                                  placeholder=""
                                  onChange={(e) =>
                                    setValue("hero", e.target.files[0])
                                  }
                                  ref={register("hero")}
                                />
                              </div>
                              <div class="col-12">
                                <label for="name" class="form-label">
                                  Nom vendeur
                                </label>
                                <div class="input-group has-validation">
                                  <span class="input-group-text">@</span>
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Nom vendeur"
                                    id="name"
                                    name="name"
                                    required
                                    onChange={(e) =>
                                      setValue("name", e.target.value)
                                    }
                                    {...register("name")}
                                  />
                                  <div class="invalid-feedback">
                                    Votre nom de vendeur est obligatoire
                                  </div>
                                </div>
                              </div>

                              <div class="col-12">
                                <label for="phone" class="form-label">
                                  Téléphone{" "}
                                  <span class="text-muted">(facultative)</span>
                                </label>
                                <input
                                  type="phone"
                                  class="form-control"
                                  id="phone"
                                  name="phone"
                                  placeholder="Téléphone professionnel"
                                  onChange={(e) =>
                                    setValue("phone", e.target.value)
                                  }
                                  {...register("phone")}
                                />
                              </div>

                              <div class="col-12">
                                <label for="address" class="form-label">
                                  Adresse de la boutique
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="address"
                                  name="address"
                                  placeholder="1234 Main St"
                                  required
                                  onChange={(e) =>
                                    setValue("address", e.target.value)
                                  }
                                  {...register("address")}
                                />
                                <div class="invalid-feedback">
                                  Veuillez entrer une adresse valide pour la
                                  livraison / facturation
                                </div>
                              </div>

                              <div class="col-12">
                                <label for="complement" class="form-label">
                                  Adresse 2{" "}
                                  <span class="text-muted">(facultative)</span>
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="complement"
                                  name="complement"
                                  required
                                  onChange={(e) =>
                                    setValue("complement", e.target.value)
                                  }
                                  {...register("complement")}
                                  placeholder="Appartement et complément d'adresse"
                                />
                              </div>

                              <div class="col-md-6">
                                <label for="country" class="form-label">
                                  Pays
                                </label>
                                <select
                                  class="form-select"
                                  id="country"
                                  name="country"
                                  required
                                  onChange={(e) =>
                                    setValue("country", e.target.value)
                                  }
                                  {...register("country")}
                                >
                                  <option value={null}>Choisir...</option>
                                  <option value="France">France</option>
                                </select>
                              </div>

                              <div class="col-md-6">
                                <label for="state" class="form-label">
                                  Etat / région
                                </label>
                                <select
                                  class="form-select"
                                  id="state"
                                  name="state"
                                  required
                                  onChange={(e) =>
                                    setValue("state", e.target.value)
                                  }
                                  {...register("state")}
                                >
                                  <option value={null}>Choisir...</option>
                                  <option value="France">
                                    France Métropolitaine
                                  </option>
                                  <option value="île de la Réunion">
                                    Île de la Réunion
                                  </option>
                                </select>
                              </div>

                              <div class="col-md-6">
                                <label for="city" class="form-label">
                                  Ville
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="city"
                                  name="city"
                                  required
                                  onChange={(e) =>
                                    setValue("city", e.target.value)
                                  }
                                  {...register("city")}
                                />
                              </div>

                              <div class="col-md-6">
                                <label for="zip" class="form-label">
                                  Code postale
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="zip"
                                  name="zipcode"
                                  minLength={5}
                                  maxLength={5}
                                  placeholder=""
                                  required
                                  onChange={(e) =>
                                    setValue("zipcode", e.target.value)
                                  }
                                  {...register("zipcode")}
                                />
                                <div class="invalid-feedback">
                                  Zip code required.
                                </div>
                              </div>
                            </div>

                            <hr class="my-4" />

                            <button
                              class="w-100 btn btn-dark btn-lg"
                              type="submit"
                              disabled={loadingForm1}
                            >
                              {loadingForm1
                                ? "Chargement ...."
                                : "Enregistrer et mettre à jour"}
                            </button>
                          </form>
                        ) : (
                          <p>Chargement ...</p>
                        )}
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="subscription">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                      <h1 class="h2">Abonnement</h1>
                    </div>
                    {actualABN !== "Free" && (
                      <>
                        <h4>Mon abonnement actuelle</h4>
                        <p>
                          <Badge>{actualABN}</Badge>

                          {actualABNInfo &&
                            actualABNInfo.current_period_end && (
                              <>
                                <p style={{ fontSize: 12 }}>
                                  Prochaine facture le{" "}
                                  {new Date(
                                    actualABNInfo.current_period_end * 1000
                                  ).toLocaleDateString("fr-FR", options)}
                                </p>
                              </>
                            )}
                        </p>
                      </>
                    )}

                    <div>
                      <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
                        <div class="col">
                          <div class="card mb-4 rounded-3 shadow-sm  border-primary">
                            <div class="card-header py-3 text-white bg-primary border-primary">
                              <h4 class="my-0 fw-normal">Pro</h4>
                            </div>
                            <div class="card-body">
                              <h1 class="card-title pricing-card-title">
                                5€
                                <small
                                  class="text-muted fw-light"
                                  style={{ fontSize: 12 }}
                                >
                                  /mois
                                </small>
                              </h1>
                              <ul class="list-unstyled mt-3 mb-4">
                                <li>_</li>
                                <li>Vendre des cartes</li>
                                {/* <li>Vendre des bases de deck</li> */}
                                {/* <li>
                                  <b>Vente d'enchères</b>
                                </li> */}
                                <li>
                                  Pas de commission{" "}
                                  <span style={{ fontSize: 10 }}>(5)</span>
                                </li>
                                <li>Support Email 24h</li>
                                <li>_</li>
                              </ul>
                              <button
                                type="button"
                                class={
                                  actualABN === "Pro"
                                    ? "w-100 btn btn-ls btn-outline-danger"
                                    : "w-100 btn btn-lg btn-primary"
                                }
                                disabled={loadingStripe}
                                onClick={() =>
                                  actualABN === "Pro"
                                    ? cancelABN
                                    : subs("Pro", true)
                                }
                              >
                                {loadingStripe
                                  ? "Chargement ..."
                                  : actualABN === "Pro"
                                  ? " Arrêter l'abonnement"
                                  : "S'abonner"}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <div class="card mb-4 rounded-3 shadow-sm border-primary">
                            <div class="card-header py-3 text-white bg-primary border-primary">
                              <h4 class="my-0 fw-normal">Entreprise</h4>
                            </div>
                            <div class="card-body">
                              <h1 class="card-title pricing-card-title">
                                19€
                                <small
                                  class="text-muted fw-light"
                                  style={{ fontSize: 12 }}
                                >
                                  /mois
                                </small>
                              </h1>
                              <ul class="list-unstyled mt-3 mb-4">
                                <li>Vendre des cartes</li>
                                {/* <li>Vendre des bases de deck</li>
                                <li>Vente d'enchères</li> */}
                                <li>
                                  <b>
                                    Mise à jour automatique des prix avec
                                    CardMarket
                                  </b>
                                </li>
                                <li>
                                  <b>Communication Emailing</b>
                                  <span style={{ fontSize: 10 }}>(6)</span>
                                </li>
                                <li>
                                  Pas de commission{" "}
                                  <span style={{ fontSize: 10 }}>(5)</span>
                                </li>
                                <li>Support prioritaire</li>
                              </ul>
                              <button
                                type="button"
                                class={
                                  actualABN === "Entreprise"
                                    ? "w-100 btn btn-ls btn-outline-danger"
                                    : "w-100 btn btn-lg btn-primary"
                                }
                                disabled={loadingStripe}
                                onClick={() =>
                                  actualABN === "Entreprise"
                                    ? cancelABN()
                                    : subs("Entreprise", true)
                                }
                              >
                                {loadingStripe
                                  ? "Chargement ..."
                                  : actualABN === "Entreprise"
                                  ? "Arrêter l'abonnement"
                                  : "S'abonner"}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <div class="card mb-4 rounded-3 shadow-sm">
                            <div class="card-header py-3">
                              <h4 class="my-0 fw-normal">Gratuit</h4>
                            </div>
                            <div class="card-body">
                              <h1 class="card-title pricing-card-title">
                                0€
                                <small
                                  class="text-muted fw-light"
                                  style={{ fontSize: 12 }}
                                >
                                  /mois
                                </small>
                              </h1>
                              <ul class="list-unstyled mt-3 mb-4">
                                <li>_</li>
                                <li>Vendre des cartes</li>
                                {/* <li>Vendre des bases de deck</li> */}
                                <li>
                                  Commission fixe de <b>5%</b>
                                </li>
                                <li>Support Email 48h</li>
                                <li>_</li>
                              </ul>
                              <button
                                type="button"
                                class="w-100 btn btn-lg btn-outline-primary"
                                disabled={actualABN === "Free"}
                                onClick={() => subs("Free", true)}
                              >
                                {loadingStripe
                                  ? "Chargement ..."
                                  : actualABN === "Free"
                                  ? "En cours"
                                  : "Continuer gratuitement"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {invoicesSubLoad ? (
                      "Chargement ..."
                    ) : (
                      <>
                        <h3>Mes Factures</h3>
                        {invoicesSub.length === 0 ? (
                          <p>Aucune facture disponible</p>
                        ) : (
                          <>
                            {invoicesSub.map((item) => (
                              <p>
                                Facture n° {item.number} - {item.date} -{" "}
                                <a
                                  href={item.hosted_invoice_url}
                                  target={"_blank"}
                                >
                                  Voir
                                </a>
                              </p>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="becomevendor">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                      <h1 class="h2">Devenir vendeur</h1>
                    </div>
                    {!openVendorStep2 &&
                      !openVendorStep3 &&
                      !openVendorStep4 &&
                      !openVendorStep5 && (
                        <p>
                          Bienvenue sur le formulaire d'inscription vendeur.
                          <br />
                          Veuillez remplir tous les champs nécessaire et valider
                          par la création d'un compte stripe{" "}
                          <span style={{ fontSize: 10 }}>(2)</span>
                        </p>
                      )}

                    <div class="col-md-7 col-lg-12">
                      {!openVendorStep1 &&
                        !openVendorStep2 &&
                        !openVendorStep3 &&
                        !openVendorStep4 &&
                        !openVendorStep5 && (
                          <Button
                            onClick={() => { 
                              setOpenVendorStep1(!openVendorStep1)
                            }}
                            aria-controls="example-collapse-text"
                            aria-expanded={openVendorStep1}
                          >
                            Commencer
                          </Button>
                        )}
                      <Collapse in={openVendorStep1}>
                        <form
                          class="needs-validation"
                          novalidate
                          enctype="multipart/form-data"
                          onSubmit={handleSubmit(registerVendorForm, (err) => {
                            console.log(err);
                          })}
                        >
                          <div class="row g-3">
                            <div class="col-sm-6">
                              <label for="logo" class="form-label">
                                Logo de vendeur
                              </label>
                              <input
                                type="file"
                                class="form-control"
                                id="logo"
                                name="logo"
                                accept="image/*"
                                onChange={(e) =>
                                  setValue("logo", e.target.files[0])
                                }
                                ref={register("logo")}
                              />
                            </div>

                            <div class="col-sm-6">
                              <label for="banniere" class="form-label">
                                Bannière vendeur
                              </label>
                              <input
                                type="file"
                                class="form-control"
                                id="lastName"
                                name="hero"
                                accept="image/*"
                                placeholder=""
                                onChange={(e) =>
                                  setValue("hero", e.target.files[0])
                                }
                                ref={register("hero")}
                              />
                            </div>

                            <div class="col-12">
                              <label for="name" class="form-label">
                                Nom vendeur
                              </label>
                              <div class="input-group has-validation">
                                <span class="input-group-text">@</span>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="name"
                                  name="name"
                                  placeholder="Nom vendeur / Pseudo"
                                  required
                                  onChange={(e) =>
                                    setValue("name", e.target.value)
                                  }
                                  ref={register("name")}
                                />
                                <div class="invalid-feedback">
                                  Votre nom de vendeur est obligatoire
                                </div>
                              </div>
                            </div>

                            <div class="col-12">
                              <label for="phone" class="form-label">
                                Téléphone{" "}
                                <span class="text-muted">(facultative)</span>
                              </label>
                              <input
                                type="phone"
                                class="form-control"
                                id="phone"
                                name="phone"
                                placeholder="Téléphone professionnel"
                                onChange={(e) =>
                                  setValue("phone", e.target.value)
                                }
                                ref={register("phone")}
                              />
                            </div>

                            <div class="col-12">
                              <label for="address" class="form-label">
                                Adresse de la boutique
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="address"
                                name="address"
                                placeholder="1234 Main St"
                                required
                                onChange={(e) =>
                                  setValue("address", e.target.value)
                                }
                                ref={register("address")}
                              />
                              <div class="invalid-feedback">
                                Veuillez entrer une adresse valide pour la
                                livraison / facturation
                              </div>
                            </div>

                            <div class="col-12">
                              <label for="complement" class="form-label">
                                Adresse 2{" "}
                                <span class="text-muted">(facultative)</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="complement"
                                name="complement"
                                required
                                onChange={(e) =>
                                  setValue("complement", e.target.value)
                                }
                                ref={register("complement")}
                                placeholder="Appartement et complément d'adresse"
                              />
                            </div>

                            <div class="col-md-5">
                              <label for="country" class="form-label">
                                Pays
                              </label>
                              <select
                                class="form-select"
                                id="country"
                                name="country"
                                required
                                onChange={(e) =>
                                  setValue("country", e.target.value)
                                }
                                ref={register("country")}
                              >
                                <option value={null}>Choisir...</option>
                                <option value="France">France</option>
                              </select>
                            </div>

                            <div class="col-md-4">
                              <label for="state" class="form-label">
                                Etat / région
                              </label>
                              <select
                                class="form-select"
                                id="state"
                                name="state"
                                required
                                onChange={(e) =>
                                  setValue("state", e.target.value)
                                }
                                ref={register("state")}
                              >
                                <option value={null}>Choisir...</option>
                                <option value="France">
                                  France Métropolitaine
                                </option>
                                <option value="île de la Réunion">
                                  Île de la Réunion
                                </option>
                              </select>
                            </div>

                            <div class="col-md-5">
                              <label for="city" class="form-label">
                                Ville
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="city"
                                name="city"
                                required
                                onChange={(e) =>
                                  setValue("city", e.target.value)
                                }
                                ref={register("city")}
                              />
                            </div>

                            <div class="col-md-3">
                              <label for="zip" class="form-label">
                                Code postale
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="zip"
                                name="zipcode"
                                minLength={5}
                                maxLength={5}
                                placeholder=""
                                required
                                onChange={(e) =>
                                  setValue("zipcode", e.target.value)
                                }
                                ref={register("zipcode")}
                              />
                              <div class="invalid-feedback">
                                Zip code required.
                              </div>
                            </div>
                          </div>

                          <hr class="my-4" />

                          <button
                            class="w-100 btn btn-dark btn-lg"
                            type="submit"
                          >
                            Continuer
                          </button>
                        </form>
                      </Collapse>
                      <Collapse in={openVendorStep2}>
                        <div>
                          <p>
                            Veuillez maintenant créer ou lié un compte stripe
                            existant
                            <br />
                            Ce compte permettera de récupérer les paiements des
                            clients directement
                            <span style={{ fontSize: 10 }}> (3)</span>.
                          </p>

                          <button
                            class=" btn btn-primary "
                            onClick={createStripe}
                            disabled={loadingStripe}
                          >
                            {loadingStripe
                              ? "Chargement ..."
                              : "Créer et lié un compte Stripe"}
                          </button>
                        </div>
                      </Collapse>
                      <Collapse in={openVendorStep3}>
                        <div>
                          <p>
                            Veuillez maintenant choisir votre abonnement
                            <br />
                            Certaines fonctionnalités du site sont accessibles
                            uniquement avec un abonnement.
                            <span style={{ fontSize: 10 }}> (4)</span>.
                          </p>
                          <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
                            <div class="col">
                              <div class="card mb-4 rounded-3 shadow-sm  border-primary">
                                <div class="card-header py-3 text-white bg-primary border-primary">
                                  <h4 class="my-0 fw-normal">Pro</h4>
                                </div>
                                <div class="card-body">
                                  <h1 class="card-title pricing-card-title">
                                    5€
                                    <small
                                      class="text-muted fw-light"
                                      style={{ fontSize: 12 }}
                                    >
                                      /mois
                                    </small>
                                  </h1>
                                  <ul class="list-unstyled mt-3 mb-4">
                                    <li>_</li>
                                    <li>Vendre des cartes</li>
                                    {/* <li>Vendre des bases de deck</li> */}
                                    {/* <li>
                                      <b>Vente d'enchères</b>
                                    </li> */}
                                    <li>
                                      Pas de commission{" "}
                                      <span style={{ fontSize: 10 }}>(5)</span>
                                    </li>
                                    <li>Support Email 24h</li>
                                    <li>_</li>
                                  </ul>
                                  <button
                                    type="button"
                                    class="w-100 btn btn-lg btn-primary"
                                    onClick={() => subs("Pro")}
                                    disabled={loadingStripe}
                                  >
                                    {loadingStripe
                                      ? "Chargement ..."
                                      : "S'abonner"}
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="col">
                              <div class="card mb-4 rounded-3 shadow-sm border-primary">
                                <div class="card-header py-3 text-white bg-primary border-primary">
                                  <h4 class="my-0 fw-normal">Entreprise</h4>
                                </div>
                                <div class="card-body">
                                  <h1 class="card-title pricing-card-title">
                                    19€
                                    <small
                                      class="text-muted fw-light"
                                      style={{ fontSize: 12 }}
                                    >
                                      /mois
                                    </small>
                                  </h1>
                                  <ul class="list-unstyled mt-3 mb-4">
                                    <li>Vendre des cartes</li>
                                    {/* <li>Vendre des bases de deck</li> */}
                                    {/* <li>Vente d'enchères</li> */}
                                    <li>
                                      <b>
                                        Mise à jour automatique des prix avec
                                        CardMarket
                                      </b>
                                    </li>
                                    <li>
                                      <b>Communication Emailing</b>
                                      <span style={{ fontSize: 10 }}>(6)</span>
                                    </li>
                                    <li>
                                      Pas de commission{" "}
                                      <span style={{ fontSize: 10 }}>(5)</span>
                                    </li>
                                    <li>Support prioritaire</li>
                                  </ul>
                                  <button
                                    type="button"
                                    class="w-100 btn btn-lg btn-primary"
                                    onClick={() => subs("Entreprise")}
                                    disabled={loadingStripe}
                                  >
                                    {loadingStripe
                                      ? "Chargement ..."
                                      : "S'abonner"}
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="col">
                              <div class="card mb-4 rounded-3 shadow-sm">
                                <div class="card-header py-3">
                                  <h4 class="my-0 fw-normal">Gratuit</h4>
                                </div>
                                <div class="card-body">
                                  <h1 class="card-title pricing-card-title">
                                    0€
                                    <small
                                      class="text-muted fw-light"
                                      style={{ fontSize: 12 }}
                                    >
                                      /mois
                                    </small>
                                  </h1>
                                  <ul class="list-unstyled mt-3 mb-4">
                                    <li>_</li>
                                    <li>Vendre des cartes</li>
                                    {/* <li>Vendre des bases de deck</li> */}
                                    <li>
                                      Commission fixe de <b>5%</b>
                                    </li>
                                    <li>Support Email 48h</li>
                                    <li>_</li>
                                  </ul>
                                  <button
                                    type="button"
                                    class="w-100 btn btn-lg btn-outline-primary"
                                    onClick={() => subs("Free")}
                                    disabled={loadingStripe}
                                  >
                                    {loadingStripe
                                      ? "Chargement ..."
                                      : "S'inscrire gratuitement"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <Modal
                            show={subscriptionModalPay}
                            onHide={modalPayHide}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>S'abonner</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Form onSubmit={handleSubmitSubscription}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Titulaire de la carte</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Nom et Prénom"
                                    autoFocus
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </Form.Group>

                                <div
                                  className="mb-3"
                                  style={{
                                    width: "350px",
                                    backgroundColor: "white",
                                    padding: 10,
                                  }}
                                >
                                  <CardElement />
                                </div>
                                <Button
                                  variant="success"
                                  type="submit"
                                  disabled={loadingStripe}
                                >
                                  {loadingStripe
                                    ? "Chargement ..."
                                    : "S'abonner"}
                                </Button>
                              </Form>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </Collapse>
                      <Collapse in={openVendorStep4}>
                        <div>
                          <p>
                            Veuillez maintenant valider votre email pour
                            bénéficier du status vendeur (pensez a vérifié vos
                            spam).
                          </p>
                          <button
                            class=" btn btn-primary "
                            onClick={sendLinkEmailVerifyVendor}
                          >
                            Envoyer un email de vérification
                          </button>
                        </div>
                      </Collapse>
                      <Collapse in={openVendorStep5}>
                        <div>
                          <p>Vous pouvez maintenant vendre sur notre site.</p>
                          <a
                            href="/tableau-de-bord#card"
                            onClick={finishSetupPendingVendor}
                          >
                            Vendre ma première carte
                          </a>
                        </div>
                      </Collapse>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="deconnexion">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                      <h1 class="h2">Déconnexion</h1>
                    </div>

                    <button class="btn btn-white btn-link" onClick={logOut}>
                      <i class="fa fa-lock"></i> Se déconnecter
                    </button>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};
export default DashboardVendor;
