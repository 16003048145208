"use strict";
const ABNPRO = "price_1OlVvQE4phYcw9jWZ0Gj1Kvp";
const ABNENTREPRISE = "price_1OlVvDE4phYcw9jWVCJzasmU";
const STRIPE_PK = "pk_live_pZmbO8RgVkZh8kCo5AG1Ipzh";

module.exports = {
  ABNPRO: ABNPRO,
  ABNENTREPRISE: ABNENTREPRISE,
  STRIPE_PK: STRIPE_PK,
};
