import React from "react";
import "../App.css";

const Confidentialite = () => {
  return (
    <>
      <div
        class="album py-5 bg-light"
        style={{
          boxShadow:
            "inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15)",
        }}
      >
        <div class="container">
          <h1>Confidentialité et protection des données</h1>
          <hr />

          <section style={{ marginBottom: "65.25", paddingBottom: "5rem" }}>
            <h2 id="1_General_remarks">1 Généralités</h2>
            <p class="ml-4">
              yugioh.re traite et utilise vos données personnelles sous réserve
              des dispositions strictes de protection des données française et
              européennes.À l’exception de données obligatoires spécifiques dont
              nous avons besoin pour pouvoir vous proposer nos services, vous
              avez la possibilité de décider vous-même des informations que vous
              souhaitez communiquer.
            </p>
            <p class="ml-4">
              Nous utilisons une technologie de transmission aussi sûre que
              possible sur notre site internet, par exemple le protocole
              cryptographique TLS. Néanmoins, la transmission de données sur
              internet, particulièrement lors de la communication par mail et
              malgré toutes les précautions, est susceptible d’être victime de
              failles de sécurité. Une protection absolue des données contre
              l’accès des tiers n’est pas possible.
            </p>
            <p class="ml-4">
              Par l’utilisation de la page web <a href="/">www.yugioh.re</a>{" "}
              (ci-dessous : plateforme en ligne) et au plus tard au moment de
              l’enregistrement en tant que membre, vous consentez à la
              collection, au traitement et à l’utilisation des données
              personnelles saisies par yugioh.re en tant qu’autorité responsable
              sur ses serveurs. Nous rappelons que les données que vous avez
              transmises dans le cadre de l’utilisation du site internet sont
              traitées et sauvegardées à l’aide d’une installation informatique.
              Les données personnelles sont évidemment traitées de manière
              responsable.{" "}
            </p>
            <p class="ml-4">
              Nous ne collectons les données personnelles, c’est-à-dire les
              données détaillées sur les comportements personnels ou factuels
              d’une personne physique identifiée ou identifiable, que lorsque
              nous en avons besoin pour le traitement de l’exécution du contrat
              et pour la fourniture des prestations contractuelles.La collecte
              des données intervient uniquement dans le cadre que vous avez
              déterminé. Le traitement des données personnelles comprend la
              sauvegarde, la modification, la transmission, le blocage et la
              suppression de ces données. Toutes les données personnelles ne
              sont sauvegardées que le temps nécessaire à l’objet pour lequel
              elles l’ont été ou aussi longtemps que la sauvegarde est
              légalement obligatoire.
            </p>
            <p class="ml-4">
              La visite de notre site internet peut entraîner la sauvegarde
              d’informations (par exemple : date, heure, page consultée) sur le
              serveur. Ces données n’appartiennent pas aux données personnelles,
              mais sont anonymisées, comme le nom du fournisseur internet, le
              type de navigateur internet, les pages visitées lors de la
              présence sur internet. Nous utilisons ces données exclusivement
              dans un but statistique et pour l’amélioration de notre offre. La
              visite du site internet peut également entraîner la sauvegarde de
              données sur votre ordinateur. Ces données sont appelées des
              «cookies» et facilitent l’utilisation du site internet. Vous avez
              néanmoins la possibilité de désactiver cette option depuis votre
              navigateur internet. Dans ce cas, il est possible que le confort
              d’utilisation de notre page internet diminue.
            </p>
            <p class="ml-4">
              Il n’existe pas de transmission des données personnelles de
              l’utilisateur, à l’exception seulement des partenaires de la
              prestation de service de yugioh.re qui sont nécessaires au
              développement de la relation contractuelle, par exemple le
              fournisseur du système de paiement (comme par exemple PayPal),
              ainsi que le transfert de données aux autorités dans le cadre de
              nos obligations légales. Dans ces cas, les exigences de la loi
              fédérale sur la protection des données (BDSG) sont strictement
              observées; l'étendue de la transmission de données est en tout
              état de cause limitée au minimum.
            </p>
            <p class="ml-4">
              Le droit dont vous disposez à révoquer votre consentement avec
              application pour le futur est illimité. Les données de contact
              pour l’utilisation du droit de rétractation sont disponibles dans
              les mentions de notre page internet.
            </p>
            <p class="ml-4">
              Vous disposez du droit à tout moment à obtenir un renseignement,
              une rectification, une suppression ou un blocage des données
              sauvegardées à condition qu’il s’agisse de données personnelles au
              sens du RGPD. Pour une prise de contact avec nous, vous pouvez
              utiliser les données de contact disponibles dans les mentions de
              notre page internet.
            </p>
            <h2 id="2_Controller">2 Autorité responsable</h2>
            <p class="ml-4">
              Le responsable au sens du règlement général sur la protection des
              données (RGPD) et de la loi fédérale sur la protection des données
              (BDSG) est :
            </p>
            <dl class="row ml-2 my-3">
              <dt class="col-12 col-md-3"></dt>
              <dd class="col-12 col-md-9 font-weight-bold">yugioh.re</dd>
              <dt class="col-12 col-md-3">Adresse</dt>
              <dd class="col-12 col-md-9">
                205 Rue jules bertaut
                <br />
                97430 LE TAMPON Ile de la Réunion
              </dd>
              <dt class="col-12 col-md-3">Téléphone</dt>
              <dt class="col-12 col-md-3">E-Mail</dt>
              <dd class="col-12 col-md-9">
                <a href="mailto:contact@yugioh.re">contact@yugioh.re</a>
              </dd>
            </dl>
            <p class="ml-4 font-weight-bold">Représenté par:</p>
            <p class="ml-4">FILAUMART SEBASTIEN CHARLES</p>
            <p class="ml-4">Gérants: Filaumart Sébastien</p>
            <dl class="row ml-2 my-3">
              <dt class="col-12 col-md-3">Registre des entreprises </dt>
              <dd class="col-12 col-md-9">Ile de la réunion </dd>
              <dt class="col-12 col-md-3">Numéro de registre </dt>
              <dd class="col-12 col-md-9">82485041600028</dd>
            </dl>
            <p class="ml-4 font-weight-bold">
              Responsable du contenu au sens du § 55 alinéa 2 du RStV:
            </p>
            <p class="ml-4">
              M. FILAUMART
              <br />
              205 Rue jules bertaut
              <br />
              97430 LE TAMPON 
            </p>
            <h2 id="4_General_information_on_data_processing">
              4 Généralités concernant le traitement des données
            </h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                <p>
                  Le traitement des données personnelles intervient en principe
                  seulement à la condition que la mise en place d’un site
                  internet avec ses contenus et ses prestations en état de
                  marche le nécessite. En règle générale, un traitement ne
                  survient qu’après l’accord de la personne concernée. De
                  manière exceptionnelle, un traitement peut se faire sans
                  l’accord de la personne concernée lorsque ceci est impossible
                  pour des raisons pratiques et que le traitement des données
                  est permis par des dispositions légales.{" "}
                </p>
              </li>
              <li>
                <p>
                  Art. 6, paragraphe 1 a du RGPD sert de base légale pour le
                  traitement des données personnelles si les opérations de
                  traitement des données personnelles ont obtenu l’accord de la
                  personne concernée.{" "}
                </p>
                <p>
                  Art. 6 paragraphe 1 b du RGPD sert de base légale pour le
                  traitement des données personnelles si l’exécution d’un
                  contrat dont une des parties est la personne concernée le
                  nécessite.Cela vaut également pour les opérations de
                  traitement nécessaires à la réalisation de mesures
                  précontractuelles.
                </p>
                <p>
                  Art. 6, paragraphe 1 c du RGPD sert de base légale pour le
                  traitement des données personnelles si le traitement des
                  données personnelles est nécessaire à l’exécution d’une
                  obligation légale de l’entreprise.{" "}
                </p>
                <p>
                  Art. 6 paragraphe 1 f du RGPD sert de base légale pour le
                  traitement des données personnelles si ceci est nécessaire
                  pour la sauvegarde d’un intérêt légitime de l’entreprise ou
                  d’un tiers et qu’il ne contrevient pas aux intérêts, droits
                  fondamentaux et libertés fondamentales de la personne
                  concernée par l’intérêt susnommé.
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  Les données personnelles de la personne concernée sont
                  supprimées ou bloquées dès que l’objet de la sauvegarde
                  disparaît. De plus, une sauvegarde peut survenir lorsque cela
                  a été prévu par les dispositions pertinentes nationales ou
                  européennes. Un blocage ou une suppression des données
                  survient également lorsqu’un délai prévu par les normes
                  législatives susnommées est écoulé, sauf s’il existe une
                  nécessité à la poursuite de la sauvegarde des données pour la
                  conclusion ou l’exécution d’un contrat.
                </p>
              </li>
            </ol>
            <h2 id="5_Use_of_the_website">5 Utilisation du site internet</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                <p>
                  À chaque consultation de la page internet, le système
                  comptabilise automatiquement des données et des informations
                  du système informatique de l’ordinateur utilisé.
                </p>
                <p>Les données suivantes sont ainsi collectées:</p>
                <ol>
                  <li>Adresse IP</li>
                  <li>Date et heure de la consultation</li>
                  <li>
                    La différence de fuseau horaire par rapport au Greenwich
                    Mean Time (GMT)
                  </li>
                  <li>Contenu de la page internet</li>
                  <li>État d’accès (statut HTTP)</li>
                  <li>Quantité de données transmise</li>
                  <li>Navigateur internet</li>
                  <li>Langue et version du navigateur</li>
                  <li>Système d’exploitation</li>
                  <li>
                    Site internet à partir duquel vous avez eu accès au site
                    internet
                  </li>
                </ol>
                <p></p>
                <p>
                  Les données sont sauvegardes dans les fichiers du journal du
                  système. La sauvegarde de ces données n’est pas adjointe aux
                  autres données personnelles de l’utilisateur.
                </p>
              </li>
              <li>
                <p>
                  La base de légale de ceci se trouve à l’article 6, paragraphe
                  1 f du RGPD.
                </p>
              </li>
              <li>
                <p>
                  La collecte et la sauvegarde temporaire de l’adresse IP est
                  nécessaire pour permettre l’affichage du site internet sur
                  votre appareil. Pour ce faire, votre adresse IP doit être
                  sauvegardée pendant la durée de la visite du site internet.
                  Ces données ne sont pas exploitées à des fins commerciales.
                </p>
              </li>
              <li>
                <p>
                  Les données sont supprimées lorsque la consultation est
                  terminée. Tant que ces données sont sauvegardées dans des
                  fichiers de journal, ceci survient sept jours après au plus
                  tard. Une sauvegarde plus longue est possible. Dans ce cas,
                  l’adresse IP de l’utilisateur est supprimée ou anonymisée, de
                  sorte à ce qu’une attribution des données au client
                  utilisateur ne soit pas possible.
                </p>
              </li>
              <li>
                <p>
                  La collecte des données pour l’affichage de la page internet
                  et la sauvegarde des données dans des fichiers de journal sont
                  absolument nécessaires pour la consultation du site internet.
                  Il n’existe donc pas de possibilité d’opposition.{" "}
                </p>
              </li>
            </ol>
            <h2 id="6_Registration">6 Fonction d’enregistrement</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                <p>
                  Le site internet offre la possibilité à l’utilisateur de
                  s’enregistrer en indiquant ses données personnelles. Les
                  données sont indiquées, transmises et sauvegardées dans un
                  masque d’entrée. Les données ne sont pas transmises aux tiers.
                  Les données suivantes sont collectées dans le cadre du
                  processus d’enregistrement :
                </p>
                <ol>
                  <li>Nom (prénom, nom de famille)</li>
                  <li>
                    Adresse de résidence (rue, numéro de maison, code postal,
                    ville, pays)
                  </li>
                  <li>Adresse e-mail</li>
                  <li>Date et heure d’enregistrement</li>
                </ol>
                <p></p>
                <p>
                  Pour les utilisateurs commerciaux, le nom de l’entreprise, le
                  numéro de TVA et le numéro de téléphone sont également exigés
                  et le cas échéant une attestation fiscale est requise
                  conformément à l’article 22f point 1 paragraphe 2 (le code
                  allemand de la TVA). Pour les utilisateurs qui utilisent notre
                  plate-forme en tant que vendeurs non commerciaux, la date de
                  naissance est demandée en plus des données ci-dessus.
                </p>
                <p>
                  Dans le cadre du processus d’enregistrement, un accord de
                  l’utilisateur est obtenu pour le traitement de ces données en
                  considération de la déclaration de confidentialité.
                </p>
              </li>
              <li>
                <p>
                  La base légale en cas d’obtention d’un accord de l’utilisateur
                  se trouve à l’article 6, paragraphe 1 a du RGPD.Si
                  l’enregistrement sert à l’exécution d’un contrat dont une des
                  parties est l’utilisateur ou l’exécution de mesures
                  précontractuelles, il existe une base légale supplémentaire
                  pour le traitement des données à l’article 6, paragraphe 1 b
                  du RGPD. Dans la mesure où nous sommes obligés de traiter des
                  informations provenant d'utilisateurs commerciaux et de
                  vendeurs privés, la base légale est l'article 6, paragraphe 1,
                  point c).
                </p>
              </li>
              <li>
                <p>
                  L’enregistrement de l’utilisateur est nécessaire pour
                  l’établissement d’un compte client. Celui-ci permet
                  l’identification de l’utilisateur et l’exécution du contrat
                  d’utilisation par l’intermédiaire du service. Les informations
                  fournies par les utilisateurs commerciaux ou les vendeurs
                  privés sont utilisées pour remplir nos obligations légales
                  envers les autorités fiscales.
                </p>
              </li>
              <li>
                <p>
                  Les données sont supprimées aussitôt que l’objet pour lequel
                  elles ont été sauvegardées ne l’exige plus. Ceci est le cas
                  lorsque, lors de la procédure d’enregistrement pour
                  l’exécution d’un contrat ou de mesures précontractuelles, les
                  données pour l’exécution du contrat ne sont plus nécessaires.
                  Même après la conclusion du contrat, la nécessité de
                  sauvegarder les données personnelles du partenaire contractuel
                  peut perdurer afin d’obéir à des obligations contractuelles ou
                  légales.
                </p>
              </li>
              <li>
                <p>
                  Les ayants droit peuvent à tout moment changer leurs données
                  d'utilisateurs depuis les paramètres de leurs comptes{" "}
                  <a href="/tableau-de-bord">www.yugioh.re/tableau-de-bord</a>{" "}
                  (sauf leur nom et date de naissance). Si les données sont
                  nécessaires à l’exécution d’un contrat ou à des mesures
                  précontractuelles, une suppression des données anticipée n’est
                  possible que si aucune obligation contractuelle ou légale ne
                  s’y oppose. Si le nom et/ou la date de votre anniversaire que
                  vous avez indiqué sont incorrects, veuillez contacter notre
                  service client pour rectifier cela.
                </p>
              </li>
            </ol>
            <h2 id="7_Use_of_the_online_marketplace">
              7 Utilisation du marché en ligne
            </h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                <p>
                  Pour l’utilisation du marché en ligne, des données existantes
                  (par exemple : nom et adresse, ainsi que données de contact de
                  l’utilisateur, nom d’utilisateur de l’utilisateur légitime),
                  des données contractuelles (par exemple : les performances, le
                  nom des personnes de contact, les informations de paiement)
                  sont traitées ainsi que l’adresse IP et le moment de chaque
                  utilisation ainsi que l’identifiant de l’utilisateur et les
                  URL consultés. Ces données sont sauvegardées dans les fichiers
                  de journal de notre système. En outre, les données indiquées
                  par l’utilisateur sont transmises à des tiers.
                </p>
              </li>
              <li>
                <p>
                  L’article 6, paragraphe 1 a du RGPD sert de base légale pour
                  le traitement des données en présence de l’accord de
                  l’utilisateur.L’accord est obtenu à la conclusion du contrat.
                </p>
                <p>
                  L’article 6 paragraphe 1 b du RGPD sert de base légale
                  supplémentaire dans la mesure où le traitement des données
                  mentionnées sert à l’exécution d’un contrat dont une des
                  parties est l’utilisateur ou l’exécution d’une mesure
                  précontractuelle.{" "}
                </p>
                <p>
                  En outre, le traitement pour l’amélioration de nos services
                  dans l’intérêt de l’analyse et de l’optimisation du domaine
                  économique de notre offre en ligne est soumis à l’article 6,
                  paragraphe 1 f du RGPD
                </p>{" "}
              </li>
              <li>
                <p>L’objet du traitement</p>
                <ol>
                  <li>
                    des données existantes (par exemple : nom et adresse ainsi
                    que les données de contact des utilisateurs) et des données
                    contractuelles (par exemple : les prestations, noms des
                    personnes de contact, les informations de paiement) est
                    l’exécution du contrat ou la facturation.
                  </li>
                  <li>
                    des noms d’utilisateur et des données de chaque utilisateur
                    est de garantir l’autorisation d’accès au service.
                  </li>
                  <li>
                    de l’adresse IP, du moment de chaque utilisation ainsi que
                    des URL utilisés est l’optimisation de nos services et
                    l’amélioration constante du confort d’utilisation.
                  </li>
                  <li>
                    des données indiquées par l’utilisateur est l’exécution du
                    contrat est la mise à disposition des prestations
                    contractuelles promises.
                  </li>
                </ol>
              </li>
              <p></p>
              <li>
                <p>
                  Les données sont supprimées aussitôt que l’objet pour lequel
                  elles ont été sauvegardées ne l’exige plus.
                </p>
                <p>
                  Ceci est le cas pour les données obtenues durant la procédure
                  d’enregistrement pour l’exécution d’un contrat ou l’exécution
                  des mesures précontractuelles, lorsque les données ne sont
                  plus nécessaires à l’exécution du contrat. Même après la
                  conclusion du contrat, la sauvegarde de données personnelles
                  de la partie au contrat peut s’avérer nécessaire pour se
                  conformer aux obligations légales ou contractuelles (notamment
                  les délais de conservation fiscaux).
                </p>{" "}
              </li>
              <li>
                <p>
                  Les personnes concernées peuvent modifier ou supprimer les
                  données sauvegardées à tout moment.
                </p>
                <p>
                  Si les données sont nécessaires à l’exécution d’un contrat ou
                  de mesures précontractuelles, une suppression anticipée n’est
                  possible que si les obligations contractuelles ou légales ne
                  s’y opposent pas. Les délais de résiliation des contrats
                  existants demeurent intacts.
                </p>
              </li>
            </ol>

            <h2 id="8_Payments_and_Payment_Service_Providers">
              8 Paiements et Fournisseurs de service de paiement
            </h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                <p>
                  Si vous êtes redirigé vers le site d'un fournisseur de service
                  de paiement, lors de la réalisation d'une transaction, les
                  données que vous indiquez seront traitées directement par le
                  fournisseur de service de paiement. Les Conditions
                  d'Utilisation du fournisseur de service de paiement concerné
                  s'appliquent.
                </p>
              </li>
              <li>
                <p>
                  L'Art. 6 (1) alinéa (a) de la RGPD est la base légale utilisée
                  pour le traitement des données lors des paiements de
                  l'utilisateur, avec le consentement de l'utilisateur.
                </p>
                <p>
                  De plus, l'art. 6 (1) alinéa (b) de la RGPD est la base légale
                  utilisée pour le traitement des données personnelles, qui sont
                  nécessaires à la réalisation du contrat.
                </p>
              </li>
              <li>
                <p>
                  La récolte de données est réalisées dans le cadre de la
                  complétion du contrat.
                </p>
              </li>
              <li>
                <p>
                  Les données concernées seront supprimées dès le moment où
                  elles ne sont plus nécessaires à la réalisation du but pour
                  lequel elles ont été collectées. Ce qui est le cas pour les
                  données personnelles, si la relaction contractuelle avec la
                  personne concernée a été arrêtée. Une relation contractuelle
                  est considérée comme arrêtée s'il est possible de considérer,
                  via les circonstances qui s'appliquent, que la personne
                  concernée n'est plus un client (par exemple : le compte
                  utilisateur est supprimé).
                </p>
              </li>
              <li>
                <p>
                  La personne concernée peut, à tout moment, retirer son
                  consentement au traitement de ses données personnelles.
                  L'obligation de réalisation du contrat n'est pas affecté par
                  cette décision.
                </p>
              </li>
            </ol>

            <h2 id="9_Comments_function">9 Fonction de commentaire</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                <p>
                  Le site internet offre la possibilité de commenter les
                  produits. Lors de l’utilisation de cette fonction, les données
                  inscrites dans le masque d’entrées sont sauvegardées et
                  transmises au site internet. Ces données sont :
                </p>
                <ol>
                  <li>Titre et nom (facultatif)</li>
                  <li>Adresse e-mail</li>
                  <li>Contenu de la question</li>
                </ol>
                <p></p>
                <p>
                  De plus, les données suivantes sont collectées lors de
                  l’inscription des données de contact :
                </p>
                <ol>
                  <li>Adresse IP de l’ordinateur appelant</li>
                  <li>Date et heure de la prise de contact</li>
                </ol>
                <p></p>
                <p>
                  Tant que la question est publiée sur le site internet, ceci se
                  déroule exclusivement sous frome anonymisée. Par ailleurs, il
                  n’existe pas de transmission de données à des tiers. Les
                  données sont exclusivement utilisées pour le traitement de la
                  conversation.
                </p>
              </li>
              <li>
                <p>
                  L’article 6 paragraphe 1 a du RGPD sert de base légale pour le
                  traitement des données en présence de l’accord de
                  l’utilisateur.Pour le reste, c’est l’article 6 paragraphe 1 f
                  du RGPD qui sert de base légale au traitement des données.
                </p>
              </li>
              <li>
                <p>
                  Le traitement des données personnelles dans le masque
                  d’entrées sert seul à répondre aux commentaires de chaque
                  produit. Notre intérêt légitime au traitement repose sur la
                  mise à disposition d’informations de produit utiles à d’autres
                  utilisateurs.
                </p>
                <p>
                  Les autres données personnelles traitées lors du processus
                  d’envoi servent à empêcher un abus du formulaire et à garantir
                  la sécurité du système informatisé.
                </p>
              </li>
              <li>
                <p>
                  Les données sont supprimées aussitôt que le but de leur
                  collecte ne les nécessite plus. C’est le cas pour les données
                  personnelles du formulaire lorsque la réponse complète est
                  donnée à la question.
                </p>
                <p>
                  Les données personnelles collectées en plus lors du processus
                  d’envoi sont supprimées au plus tard automatiquement après un
                  délai de sept jours.
                </p>
              </li>
              <li>
                <p>
                  La personne concernée a la possibilité à tout moment de
                  retirer son accord au traitement de ses données personnelles.
                  La sauvegarde des données personnelles peut être opposée à
                  tout moment. Dans un tel cas, aucune réponse ne pourra
                  cependant être apportée à la question. Toutes les données
                  personnelles qui ont été sauvegardées au cour de
                  l’interrogation sont, dans ce cas, supprimées. Si aucun
                  rapprochement avec une personne ne peut être effectué par la
                  question posée ou la réponse donnée, celles-ci restent
                  disponibles auprès de chaque produit.
                </p>
              </li>
            </ol>
            <h2 id="10_Use_of_data_via_application_programming_interface_(API)">
              10 Utilisation des données grâce à l’interface de programmation
              (API)
            </h2>
            <p class="ml-4">
              L’utilisation de l’API par l’Access-Token permanent vous permet
              d’avoir accès à vos propres données existantes ainsi qu’aux
              données publiquement accessibles, comme le prix des cartes, etc.
              Vous n’avez ni accès aux données des autres membres, ni eux aux
              vôtres.
            </p>
            <p class="ml-4">
              Pour le développement économique d’applications et d’autres
              programmes d’applications similaires, nous mettons une App-Key à
              disposition. Ici aussi, aucune transmission des données
              personnelles ne survient, resp. de concession de droits d’accès à
              ce genre de données.
            </p>
            <p class="ml-4">
              En revanche, si vous vous connectez avec vos identifiants (nom
              d’utilisateur et mot de passe) via une application tiers, vous
              l’autorisez à avoir accès pendant 24 heures à vos propres données.
              Nous ne sommes pas responsables des dommages éventuels,
              respectivement les utilisations abusives qui pourraient survenir à
              la suite de l’utilisation de telles applications tierces. Merci de
              vous tourner à ce propos directement vers les conditions
              d’utilisation et les stipulations de protection des données de ces
              applications tierces.
            </p>
            <h2 id="11_Contact_via_e-mail_or_contact_form">
              11 Contact par e-mail ou formulaire de contact
            </h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                <p>
                  Le site internet utilise des formulaires de contact qui
                  peuvent être utilisés pour l’enregistrement des contacts
                  électronique. Lors de son utilisation, les données saisies
                  dans le masque d’entrée sont transmises au site internet et y
                  sont sauvegardées. Ces données sont :
                </p>
                <ol>
                  <li>Adresse e-mail</li>
                  <li>
                    <p>Le contenu de l’enregistrement des données de contact</p>
                  </li>
                </ol>
                <p>
                  De plus, les données suivantes sont collectées lors de
                  l’inscription des données de contact :
                </p>
                <ol>
                  <li>Adresse IP de l’ordinateur appelant</li>
                  <li>
                    <p>Date et heure de la prise de contact</p>
                  </li>
                </ol>
                <p>
                  Dans ce contexte, aucune transmission de données aux tiers ne
                  survient. Les données sont exclusivement utilisées pour le
                  traitement de la conversation.
                </p>
              </li>
              <li>
                <p>
                  L’article 6 paragraphe 1 a du RGPD sert de base légale pour le
                  traitement des données en présence de l’accord de
                  l’utilisateur.{" "}
                </p>
                <p>
                  L’article 6 paragraphe 1 f du RGPD sert de base légale pour le
                  traitement des données qui sont transmis dans le cadre de
                  l’envoi d’un e-mail.
                </p>
                <p>
                  Si le contact de l’e-mail vise la conclusion ou l’exécution
                  d’un contrat, l’article 6 paragraphe 1 b du RGPD sert
                  également de base légale.{" "}
                </p>
              </li>
              <li>
                <p>
                  Le traitement des données personnelles depuis le masque
                  d’entrées sert seul au traitement de l’enregistrement des
                  données de contact. Dans le cas d’un enregistrement des
                  données de contact par e-mail, les intérêts légitimes et
                  nécessaires sont également liés au traitement des données.
                </p>
                <p>
                  Les autres données personnelles traitées lors du processus
                  d’envoi servent à empêcher un abus du formulaire de contact et
                  à garantir la sécurité du système informatisé.
                </p>
              </li>
              <li>
                <p>
                  Les données sont supprimées aussitôt que l’objet pour lequel
                  elles ont été sauvegardées ne l’exige plus. Pour les données
                  personnelles depuis le masque d’entrées du formulaire de
                  contact et celles transmises par e-mail, ceci survient à la
                  condition que la conversation avec la personne concernée est
                  terminée. La conversation est terminée lorsque il peut être
                  déduit des circonstances que la situation concernée semble
                  être définitivement clarifiée. Les données personnelles
                  collectées en plus lors du processus d’envoi sont supprimées
                  au plus tard après un délai de sept jours.
                </p>
              </li>
              <li>
                <p>
                  La personne concernée a la possibilité à tout moment de
                  retirer son accord au traitement de ses données personnelles.
                  En cas d’enregistrement des données de contact par e-mail, la
                  sauvegarde des données personnelles peut être refusée à tout
                  moment. Dans un tel cas, la conversation ne peut cependant pas
                  se poursuivre. Toutes les données personnelles qui ont été
                  sauvegardées au cours de l’enregistrement des données de
                  contact sont, dans ce cas, supprimées.
                </p>
              </li>
            </ol>
            <h2 id="12_Use_of_cookies">12 Utilisation de cookies</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                <p>
                  Le site internet utilise des cookies. Les cookies sont des
                  fichiers texte qui sont sauvegardés dans le navigateur
                  internet, respectivement par le navigateur internet dans le
                  système informatique de l’utilisateur. Ces cookies contiennent
                  une séquence de caractères particulière qui permet une
                  identification unique du navigateur lors de la prochaine
                  consultation du site internet. Les cookies ne peuvent pas
                  transmettre de virus au terminal ou exécuter des programmes.
                </p>
                <p>
                  Les cookies servent au confort d’utilisation du site internet.
                  Certains éléments du site internent exigent que le navigateur
                  utilisé puisse être identifié également après un changement de
                  page.{" "}
                </p>
                <p>
                  En ce qui concerne les cookies qui ne sont techniquement pas
                  nécessaires, ceux-là ne sont chargés uniquement quand
                  l’utilisateur en a donné le consentement. Pour ce faire, nous
                  reposons sur une extension qui ne collecte aucune donnée
                  personnelle. L’information par rapport à un consentement est
                  sauvegardée dans un cookie. Cependant, aucune donnée
                  personnelle n’est collectée pour ce faire.
                </p>
                <p>
                  Les cookies transitoires sont automatiquement supprimés
                  lorsque la session est fermée. Parmi ceux-ci, il existe
                  notamment des Session-Cookies qui sauvegardent lesdits
                  Session-ID au moyen des différentes demandes qui peuvent être
                  reliées à une session commune du navigateur internet utilisé.
                  Ils permettent de reconnaître le terminal lors d’une nouvelle
                  session.{" "}
                </p>
                <p>
                  Les cookies persistants sont supprimés automatiquement après
                  une durée de sauvegarde prédéfinie qui peut être différente
                  selon le cookie. Les réglages y relatifs peuvent être
                  supprimés à tout moment dans les réglages du navigateur
                  internet.
                </p>
                <p>
                  Les données suivantes sont sauvegardées dans les cookies :
                </p>
                <ol>
                  <li>Informations sur les identifiants</li>
                  <li>Réglages de langue</li>
                  <li>Termes recherchés saisis</li>
                  <li>Nombre des consultations du site internet</li>
                  <li>
                    <p>Utilisation de fonctions uniques du site internet</p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  L’article 6 paragraphe 1f du RGPD sert de base légale pour
                  l’utilisation de cookies nécessaires pour raisons techniques.
                </p>
              </li>
              <li>
                <p>
                  L’objet de l’utilisation de cookies techniques nécessaires est
                  de faciliter l’utilisation du site internet pour
                  l’utilisateur. Certaines fonctions du site internet ne peuvent
                  pas être offertes sans l’utilisation des cookies. Pour ce
                  faire, il est nécessaire que le navigateur puisse également
                  être reconnu après un changement de page.
                </p>
                <p>
                  Les données d’utilisateur collectées par des cookies
                  techniques et nécessaires ne sont pas utilisées pour la
                  création de profils d’utilisateurs.
                </p>
              </li>
              <li>
                <p>
                  L’utilisation légale des cookies nécessaires pour raisons
                  techniques s’appuie sur la base légale de l’article 6 section
                  1 du RGPD, et l’utilisateur peut donner son consentement pour
                  les autres cookies respectifs. Le but de l’application de
                  cookies techniquement non requis est d’analyser l’usage du
                  site web et d’améliorer en continue les différentes
                  fonctionnalités et offres, ainsi que l’expérience utilisateur.
                  En utilisant l’analyse statique de l’expérience utilisateur,
                  l’offre peut être conçue et améliorée afin d’être plus
                  intéressante pour l’utilisateur. Vous pouvez trouver des
                  détails additionnels dans les sections respectives de notre
                  déclaration de protection des données.
                </p>
              </li>
              <li>
                <p>
                  Les cookies sont sauvegardés sur l’ordinateur de l’utilisateur
                  et transmis par ce dernier à notre page. C’est la raison pour
                  laquelle vous avez, en tant qu’utilisateur, le contrôle total
                  sur l’utilisation des cookies. Par un changement des réglages
                  dans votre navigateur internet, vous pouvez désactiver ou
                  limiter la transmission des cookies séparément de la bannière
                  de consentement de cookies. Les cookies déjà sauvegardés
                  peuvent être supprimés à tout moment. Ceci peut survenir
                  automatiquement. Si les cookies sont désactivés pour notre
                  site internet, il est possible que certaines fonctions ne
                  soient plus entièrement utilisables sur le site internet.
                </p>
              </li>
            </ol>
            <h2 id="13_Google_analytics">13 Google Analytics</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                <p>
                  Le site internet utilise « Google Analytics », un service
                  d’analyse d’audience de Google Inc., 1600 Amphitheatre
                  Parkway, Mountain View, CA 94043, USA (désigné ci-dessous par
                  : « Google »). Google utilise des cookies, c’est à dire de
                  petits fichiers texte, qui sont sauvegardés sur le terminal et
                  qui permettent une analyse de l’utilisation du site internet.
                  Les informations reçues par les cookies sur l’utilisation du
                  site internet sont en règle générale transmises à un serveur
                  de Google aux USA et y sont sauvegardées. Lorsque une
                  anonymisation est activée sur le site internet à travers le
                  cookie qui transmet l’adresse IP par l’extension «
                  _anonymizeIp() » (ci-après désignée par : « anonymisation IP
                  »), l’adresse IP est abrégée au préalable par Google à
                  l’intérieur des États membres de l’Union européenne ou dans
                  les autres Etats membre de l’espace économique européen.
                  L’adresse IP n’est transmise à un serveur de Google aux USA et
                  y est abrégée que dans des cas exceptionnels. Google va
                  utiliser ces informations pour que, dans le cadre de la
                  mission du responsable, l’utilisation du site internet puisse
                  être analysée, va établir des rapports sur l’utilisation du
                  site internet et va fournir d’autres prestations de service
                  liées à l’utilisation de la page internet et d’internet. Ce
                  faisant, des profils d’utilisateurs sous pseudonyme peuvent
                  être créés à partir des données traitées. L’adresse IP
                  transmise par l’utilisation de Google Analytics n’est pas
                  associée avec d’autres données de Google.
                </p>
                <p>
                  La page internet utilise Google Analytics seulement avec
                  l’anonymisation susmentionnée de l’adresse IP activée. Cela
                  signifie que votre adresse IP n’est traitée par Google que de
                  façon abrégée. Une identification de l’utilisateur n’est ainsi
                  pas possible.
                </p>
              </li>
              <li>
                <p>
                  Google Analytics est activé uniquement via notre bannière de
                  consentement de cookies. De ce fait, le consentement de
                  l’utilisateur constitue la base légale du traitement pour
                  l’intérêt légitime à l’analyse, l’optimisation et le
                  fonctionnement économique du site internet au sens de
                  l’article 6 paragraphe 1 f du RGPD.{" "}
                </p>
              </li>
              <li>
                <p>
                  Le site internet utilise Google Analytics dans le but
                  d’analyser l’utilisation du site internet et d’améliorer
                  continuellement les fonctions individuelles et les offres
                  ainsi que le confort d’utilisation. Par l’analyse statistique
                  du comportement de l’utilisateur, l’offre peut être améliorée
                  et être plus intéressante pour l’utilisateur. Il y existe
                  également l’intérêt légitime du traitement des données
                  existantes par Google.
                </p>
              </li>
              <li>
                <p>
                  Séparément de la bannière de consentement de cookies, la
                  sauvegarde des cookies générés par Google Analytics peut être
                  empêchée par l’utilisation des réglages correspondants du
                  navigateur internet. Il est signalé qu’il est possible dans ce
                  cas que certaines fonctions du site internet ne puissent pas
                  être utilisables. Lorsque la collecte effectuée par les
                  cookies générés et les données liées au comportement de
                  l’utilisateur (notamment votre adresse IP) et le traitement de
                  ces données par Google doivent être empêchés, le plugin du
                  navigateur internet dont le lien se trouve ci-dessous peut
                  être téléchargé et installé :{" "}
                  <a
                    href="http://tools.google.com/dlpage/gaoptout"
                    target="_blank"
                    rel="nofollow"
                  >
                    http://tools.google.com/dlpage/gaoptout
                  </a>
                  .
                </p>
                <p>
                  Pour obliger Google à ne traiter les tâches des données
                  transmises que dans le cadre des instructions et dans le
                  respect des obligations légales de protection des données, le
                  responsable a conclu un contrat de traitement des tâches avec
                  Google.
                </p>
                <p>
                  Dans les cas exceptionnels dans lesquels des données
                  personnelles sont transmises aux USA, Google est soumis et a
                  été certifié par l’accord de Privacy-Shield passé entre
                  l’Union européenne et les USA. Google est ainsi tenu de
                  respecter les standards et les exigences du droit européen de
                  protection des données. Vous trouverez davantage
                  d’informations en cliquant sur le lien suivant :{" "}
                  <a
                    href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                    target="_blank"
                    rel="nofollow"
                  >
                    https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
                  </a>
                </p>
                <p>
                  Informations sur le fournisseur tiers : Google Dublin, Google
                  Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland,
                  Fax: +353 (1) 436 1001. Vous trouverez davantage
                  d’informations sur l’utilisation des données par Google, sur
                  les possibilités de réglage et d’opposition ainsi que sur la
                  protection des données en allant sur les sites internet de
                  Google suivants :
                </p>
                <ol>
                  <li>
                    Conditions d’utilisation : <br />
                    <a
                      href="http://www.google.com/analytics/terms/"
                      target="_blank"
                      rel="nofollow"
                    >
                      http://www.google.com/analytics/terms/
                    </a>
                  </li>
                  <li>
                    Résumé de la protection des données : <br />
                    <a
                      href="http://www.google.com/intl/de/analytics/learn/privacy.html"
                      target="_blank"
                      rel="nofollow"
                    >
                      http://www.google.com/intl/de/analytics/learn/privacy.html
                    </a>
                  </li>
                  <li>
                    Déclaration de confidentialité : <br />
                    <a
                      href="https://www.google.com/intl/de/policies/privacy/"
                      target="_blank"
                      rel="nofollow"
                    >
                      https://www.google.com/intl/de/policies/privacy/
                    </a>
                  </li>
                  <li>
                    Utilisation des données par Google lors de votre utilisation
                    des sites internet ou des applications de nos partenaires :{" "}
                    <br />
                    <a
                      href="https://www.google.com/intl/de/policies/privacy/partners/"
                      target="_blank"
                      rel="nofollow"
                    >
                      https://www.google.com/intl/de/policies/privacy/partners/
                    </a>
                  </li>
                  <li>
                    Utilisation des données à des fins publicitaires : <br />
                    <a
                      href="https://www.google.com/policies/technologies/ads/"
                      target="_blank"
                      rel="nofollow"
                    >
                      https://www.google.com/policies/technologies/ads/
                    </a>
                  </li>
                  <li>
                    <p>
                      Réglages pour des publicités personnalisées par Google :{" "}
                      <br />
                      <a
                        href="http://www.google.de/settings/ads"
                        target="_blank"
                        rel="nofollow"
                      >
                        http://www.google.de/settings/ads
                      </a>
                    </p>
                  </li>
                </ol>
                <p>
                  Pour les visiteurs qui consultent notre site internet depuis
                  la République Populaire de Chine (RPC), il existe une
                  possibilité que nous utilisions, à la place de Google
                  Analytics et pour le même objectif et dans les mêmes limites,
                  les services de Site Monitor du fournisseur Miaozhen Ltd.,
                  Beijing, RPC avec des serveurs en RPC. Pour désactiver les
                  services de Site Monitor, visitez la page internet suivante :{" "}
                  <a
                    href="http://i.miaozhen.com.cookie_opt.html"
                    target="_blank"
                    rel="nofollow"
                  >
                    http://i.miaozhen.com.cookie_opt.html
                  </a>
                </p>
              </li>
            </ol>
            <h2 id="14_Visual_website_optimizer">
              14 Visual Website Optimizer
            </h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                <p>
                  Le site internet utilise « VWO (Visual Website Optimizer) »,
                  un service de l’entreprise Wingify, 14th Floor, KLJ Tower
                  North, Netaji Subhash Place, Pitam Pura, Delhi 110034, Inde
                  (désignée ci-après : « VWO »). Lorsque ce service est utilisé,
                  des informations relatives à votre comportement d’utilisateur
                  sur notre site internet sont mémorisées et traitées par VWO.
                  Des Cookies sont, entre autres, également utilisés. Il s’agit
                  de petits fichiers de textes qui permettent l’analyse de
                  l’utilisation du site internet par l’utilisateur. Les Cookies
                  sont mémorisés localement sur votre dispositif terminal.
                </p>
                <p>
                  Les informations concernant l’utilisation du site Web créées
                  par le fichier de texte (Cookie) sont transmises sous forme
                  anonyme à Wingify. Il ne peut pas être exclu que des données
                  soient également transmises à l’étranger.
                </p>
              </li>
              <li>
                <p>
                  Visual Website Optimizer n’est utilisé que si vous en avez
                  donné le consentement via la bannière de cookie. La base
                  légale est faite selon le consentement de l’utilisateur selon
                  l’article 6 paragraphe 1 f du RGPD.
                </p>
              </li>
              <li>
                <p>
                  Le traitement des données avec VWO est effectué à des fins de
                  marketing et d’optimisation. Il nous permet d’analyser le
                  comportement des utilisateurs à l’intérieur de notre site
                  internet et d’améliorer ainsi des fonctions et des offres
                  individuelles. Ainsi, nous sommes en mesure d’améliorer
                  constamment l’expérience d’utilisation des visiteurs du site
                  web. Une évaluation statistique du comportement des visiteurs
                  de notre site web nous permet d’améliorer notre offre et de la
                  présenter aux utilisateurs de manière plus intéressante. Il y
                  existe également notre intérêt légitime relatif au traitement
                  des données existantes par le fournisseurs tiers.
                </p>
              </li>
              <li>
                <p>
                  Séparément de la bannière de consentement, l’installation de
                  cookies peut être empêchée en supprimant les cookies existants
                  et en désactivant une sauvegarde des cookies dans les réglages
                  du navigateur internet. Il convient de noter que, dans ce cas,
                  certaines fonctions du site internet ne puissent pas être
                  utilisées dans leur intégralité. Vous pouvez également
                  empêcher la saisie des données précitées par Wingify en
                  activant un cookie opt-out sur le site web lié ci-après :
                </p>
                <p>
                  <a
                    href="https://vwo.com/opt-out/"
                    target="_blank"
                    rel="nofollow"
                  >
                    https://vwo.com/opt-out/
                  </a>
                </p>
                <p>
                  Il convient de noter que le réglage Opt-out sera effacé
                  lorsque les cookies seront éliminés. Également séparément de
                  la bannière de consentement, en désactivant le JavaScript dans
                  le navigateur, vous pouvez objecter à la saisie et la
                  transmission de données à caractère personnel et empêcher le
                  traitement de celles-ci. En outre, vous avez la possibilité
                  d’empêcher entièrement l’exécution de code Java Script en
                  installant un Add-on correspondant pour votre navigateur (Par
                  exemple :{" "}
                  <a
                    href="https://noscript.net/"
                    target="_blank"
                    rel="nofollow"
                  >
                    https://noscript.net/
                  </a>
                  ). Dans le cas de l’utilisation de tels bloqueurs ou de la
                  désactivation de Java Script, il est possible que certaines
                  fonctions du site internet ne puissent pas être utilisées dans
                  leur intégralité.
                </p>
                <p>
                  Informations sur le fournisseur tiers : Wingify, 14th Floor,
                  KLJ Tower North, Netaji Subhash Place, Pitam Pura, Delhi
                  110034, Inde. Vous trouverez des informations supplémentaires
                  concernant la protection des données du fournisseur tiers sur
                  le site internet suivant :{" "}
                  <a
                    href="https://vwo.com/privacy-policy/"
                    target="_blank"
                    rel="nofollow"
                  >
                    https://vwo.com/privacy-policy/
                  </a>
                </p>
              </li>
            </ol>
            {/* <h2 id="16_Facebook_Pixel">16 Facebook Pixel</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                <p>
                  Le site internet utilise « Facebook Pixel » (désigné ci-après
                  par : « Pixel »), un programme d’analyse du réseau social «
                  Facebook.com » de Facebook Inc., 1 Hacker Way, Menlo Park, CA
                  94025, USA (désigné ci-après par « Facebook ») pour suivre les
                  actions des utilisateurs qui ont préalablement vu ou cliqué
                  sur une annonce publicitaire Facebook. Les données saisies
                  sont collectées anonymement et servent dans un but d’analyse
                  et d’étude de marché. Facebook peut lier ces données avec un
                  compte Facebook existant et également les utiliser pour
                  certains buts publicitaires, conformément aux lignes
                  directrices d’utilisation des données de Facebook
                </p>
                <p>
                  L’utilisateur peut permettre à Facebook et à ses partenaires
                  d’actionner des annonces publicitaires sur et en dehors de
                  Facebook. Dans ces buts-là, un cookie peut être sauvegardé sur
                  l’ordinateur.
                </p>
              </li>
              <li>
                <p>
                  Facebook Pixel ne sera activé que si vous en donnez le
                  consentement via notre bannière de cookies. Dans ce cas-là, la
                  base légale du traitement est faite selon le consentement de
                  l’utilisateur selon l’article 6 paragraphe 1 f du RGPD.
                </p>
              </li>
              <li>
                <p>
                  Le site internet utilise Pixel dans des buts de marketing et
                  d’optimisation, notamment pour actionner des annonces
                  pertinentes et intéressantes, d’améliorer les rapports des
                  performances des campagnes ou pour éviter que la même annonce
                  soit visualisée plusieurs fois. Il y existe également
                  l’intérêt légitime du traitement des données existantes.
                </p>
              </li>
              <li>
                <p>
                  L’installation de cookies peut être empêchée en supprimant les
                  cookies existants et en désactivant une sauvegarde des cookies
                  dans les réglages du navigateur internet. Il est signalé qu’il
                  est possible dans ce cas que certaines fonctions du site
                  internet ne puissent pas être complètement utilisables.
                </p>
                <p>
                  À l’exception du paramétrage des cookies pour notre site, le
                  consentement peut être rétracté ici :
                </p>
                <p>
                  <a
                    href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
                    target="_blank"
                    rel="nofollow"
                  >
                    https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
                  </a>
                </p>
                <p>
                  De plus, Facebook est soumis et a été certifié par l’accord de
                  Privacy-Shield passé entre l’Union européenne et les USA.
                  Facebook est ainsi tenu de respecter les standards et les
                  exigences du droit européen de protection des données. Des
                  informations plus précises peuvent être trouvées en suivant le
                  lien suivant :
                </p>
                <p>
                  <a
                    href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC"
                    target="_blank"
                    rel="nofollow"
                  >
                    https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC
                  </a>
                </p>
                <p>
                  Informations sur le fournisseur tiers : Facebook Ireland Ltd.
                  4 Grand Canal Square , Grand Canal Harbour, Dublin 4, IRELAND,
                  Fax:+0016505435325. Vous trouverez davantage d’informations
                  sur l’utilisation des données par Facebook, sur les
                  possibilités de réglage et d’opposition ainsi que sur la
                  protection des données en allant sur le site internet de
                  Facebook suivants :
                </p>
                <p>
                  <a
                    href="https://www.facebook.com/about/privacy/"
                    target="_blank"
                    rel="nofollow"
                  >
                    https://www.facebook.com/about/privacy/
                  </a>
                </p>
              </li>
            </ol> */}
            {/* <h2 id="17_Facebook_Connect">17 Facebook Connect</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                <p>
                  Le site internet utilise « Facebook Connect » (désigné
                  ci-après par « Connect »), un programme d’analyse du réseau
                  social « Facebook.com » de Facebook Inc., 1 Hacker Way, Menlo
                  Park, CA 94025, USA, (désigné ci-après par : « Facebook »)
                  afin de permettre une procédure d’enregistrement,
                  respectivement d’identification via Facebook au lieu d’un
                  enregistrement direct sur le site internet.
                </p>
                <p>
                  En cliquant sur Facebook Connect, l’utilisateur est redirigé
                  sur le site internet de Facebook pour s’y enregistrer ou s’y
                  identifier. Par ce lien, les données suivantes sont transmises
                  :
                </p>
                <ol>
                  <li>Nom Facebook</li>
                  <li>Profil et photo de couverture Facebook</li>
                  <li>Photo de couverture Facebook</li>
                  <li>L’adresse e-mail liée à Facebook</li>
                  <li>L’identifiant Facebook</li>
                  <li>Listes d’amis Facebook</li>
                  <li>Likes Facebook (mentions « j’aime »)</li>
                  <li>Anniversaire</li>
                  <li>Genre</li>
                  <li>Pays</li>
                  <li>
                    <p>Langue</p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  Facebook Connect ne sera activé uniquement si vous en donnez
                  le consentement via notre bannière de cookies ou via l’usage
                  de la fonctionnalité de connexion par l’utilisateur. De ce
                  fait, la base légale pour le traitement est le consentement de
                  l’utilisateur selon l’article 6 paragraphe 1f du RGPD. Les
                  données collectées seront supprimées quand le contrat sera
                  terminé ou interrompu. Les périodes légales de rétention ne
                  doivent pas être affectées.
                </p>
              </li>
              <li>
                <p>
                  Les données collectées sont supprimées après la conclusion ou
                  la fin du contrat. Les délais de conservation légaux restent
                  inchangés.
                </p>
              </li>
            </ol> */}
            {/* <h2 id="18_Twitter_Analytics">18 Twitter Analytics</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                <p>
                  Le site internet utilise « Twitter Analytics », un service
                  d’analyse d’audience de Twitter Inc., 795 Folsom St., Suite
                  600, San Francisco, CA 94107, USA (désigné ci-dessous par :
                  „Twitter“). Twitter utilise des cookies, c’est à dire de
                  petits fichiers texte qui sont sauvegardés sur le terminal et
                  qui permettent une analyse de l’utilisation du site internet.
                  Les informations reçues par les cookies sur l’utilisation du
                  site internet sont en règle générale transmises à un serveur
                  de Twitter aux USA et y sont sauvegardées. Twitter va utiliser
                  ces informations pour que, dans le cadre de la mission du
                  responsable, l’utilisation du site internet puisse être
                  analysée, pour établir des rapports sur l’utilisation du site
                  internet et pour fournir d’autres prestations de service liées
                  à l’utilisation de la page internet et d’internet. Ce faisant,
                  des profils d’utilisateurs sous pseudonyme peuvent être créés
                  à partir des données traitées. L’adresse IP transmise par
                  l’utilisation de Twitter Analytics n’est pas associée avec
                  d’autres données de Twitter.
                </p>
              </li>
              <li>
                <p>
                  Twitter Analytics ne sera utilisé que si vous en donnez le
                  consentement via notre bannière de cookies. De ce fait, la
                  base légale du traitement des données est basé sur le
                  consentement de l’utilisateur selon l’article 6 paragraphe 1 f
                  du RGPD.
                </p>
              </li>
              <li>
                <p>
                  Le site internet utilise Twitter Analytics dans le but
                  d’analyser l’utilisation du site internet et d’améliorer
                  continuellement les fonctions individuelles et les offres
                  ainsi que le confort d’utilisation. Par l’analyse statistique
                  du comportement de l’utilisateur, l’offre peut être améliorée
                  et être plus intéressante pour l’utilisateur. Il y existe
                  également l’intérêt légitime du traitement des données
                  existantes par Google.
                </p>
              </li>
              <li>
                <p>
                  Séparément de la bannière de consentement, la sauvegarde des
                  cookies générés par Twitter Analytics peut être empêchée par
                  l’utilisation des réglages correspondants du navigateur
                  internet. Il est signalé qu’il est possible dans ce cas que
                  certaines fonctions du site internet ne puissent pas être
                  utilisable.
                </p>
                <p>
                  Pour obliger Twitter à ne traiter les tâches des données
                  transmises que dans le cadre des instructions et dans le
                  respect des obligations légales de protection des données, le
                  responsable a conclu un contrat de traitement des tâches avec
                  Twitter.{" "}
                </p>
                <p>
                  Dans les cas exceptionnels dans lesquels des données
                  personnelles sont transmises aux USA, Twitter est soumis et a
                  été certifié par l’accord de Privacy-Shield passé entre
                  l’Union européenne et les USA. Twitter est ainsi tenu de
                  respecter les standards et les exigences du droit européen de
                  protection des données. Vous trouverez davantage
                  d’informations en cliquant sur le lien suivant:
                </p>
                <p>
                  <a
                    href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO"
                    target="_blank"
                    rel="nofollow"
                  >
                    https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO
                  </a>
                </p>
                <p>
                  Vous trouverez davantage d’informations sur l’utilisation des
                  données par Twitter, sur les possibilités de réglage et
                  d’opposition ainsi que sur la protection des données en allant
                  sur les sites internet de Twitter suivants:
                </p>
                <ol>
                  <li>
                    <p>
                      Conditions d’utilisation : <br />
                      <a
                        href="https://twitter.com/de/tos#current"
                        target="_blank"
                        rel="nofollow"
                      >
                        https://twitter.com/de/tos#current
                      </a>
                    </p>
                  </li>
                  <li>
                    <p>
                      Résumé de la protection des données : <br />
                      <a
                        href="https://twitter.com/privacy"
                        target="_blank"
                        rel="nofollow"
                      >
                        https://twitter.com/privacy
                      </a>
                    </p>
                  </li>
                </ol>
              </li>
            </ol> */}
            {/* <h2 id="19_Twitter_Ads">19 Twitter Ads</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                <p>
                  Le site internet utilise « Twitter Ads », un service de
                  publicité de Twitter Inc., 795 Folsom St., Suite 600, San
                  Francisco, CA 94107, USA (ci-après désignée « Twitter ») pour
                  suivre les actions des utilisateurs qui ont préalablement vu
                  ou cliqué sur une annonce publicitaire Twitter.Les données
                  saisies sont collectées anonymement et servent dans un but
                  d’analyse et d’étude de marché. Twitter peut lier ces données
                  avec un compte Twitter existant et également les utiliser pour
                  certains buts publicitaires, conformément aux lignes
                  directrices d’utilisation des données de Twitter.
                </p>
                <p>
                  L’utilisateur peut permettre à Twitter et à ses partenaires
                  d’actionner des annonces publicitaires sur et en dehors de
                  Twitter. Dans ces buts-là, un cookie peut être sauvegardé sur
                  l’ordinateur.
                </p>
              </li>
              <li>
                <p>
                  La base légale du traitement est l’intérêt légitime à
                  l’analyse, l’optimisation et le fonctionnement économique du
                  site internet au sens de l’article 6 paragraphe 1 f du RGPD.{" "}
                </p>
              </li>
              <li>
                <p>
                  Le site internet utilise Twitter Ads dans des buts de
                  marketing et d’optimisation, notamment pour actionner des
                  annonces pertinentes et intéressantes, pour améliorer les
                  rapports des performances des campagnes ou pour éviter que la
                  même annonce soit visualisée plusieurs fois. Il y existe
                  également l’intérêt légitime du traitement des données
                  existantes.
                </p>
              </li>
              <li>
                <p>
                  L’installation de cookies peut être empêchée en supprimant les
                  cookies existants et en désactivant une sauvegarde des cookies
                  dans les réglages du navigateur internet. Il est signalé qu’il
                  est possible dans ce cas que certaines fonctions du site
                  internet ne puissent pas être complètement utilisables.
                </p>
                <p>
                  S’il n’est pas souhaité que Twitter lie les données récoltées
                  via le site internet au compte Twitter, l’utilisateur doit se
                  déconnecter de son compte Twitter avant de visiter le site
                  internet. Le chargement du plugin de Twitter peut également
                  être complètement empêché par des Add-Ons, par exemple avec le
                  bloqueur de script « NoScript » (
                  <a
                    href="https://noscript.net/"
                    target="_blank"
                    rel="nofollow"
                  >
                    https://noscript.net/
                  </a>
                  ).
                </p>
                <p>
                  Vous trouverez davantage d’informations sur l’utilisation des
                  données par Twitter, sur les possibilités de réglage et
                  d’opposition ainsi que sur la protection des données en allant
                  sur les sites internet de Twitter suivants :
                </p>
                <ol>
                  <li>
                    <p>
                      Conditions d’utilisation :{" "}
                      <a
                        href="https://twitter.com/de/tos#current"
                        target="_blank"
                        rel="nofollow"
                      >
                        https://twitter.com/de/tos#current
                      </a>
                    </p>
                  </li>
                  <li>
                    <p>
                      Résumé de la protection des données :{" "}
                      <a
                        href="https://twitter.com/privacy"
                        target="_blank"
                        rel="nofollow"
                      >
                        https://twitter.com/privacy
                      </a>
                    </p>
                  </li>
                  <li>
                    <p>
                      Concernant la protection de données via{" "}
                      <a
                        href="https://policies.google.com/privacy"
                        target="_blank"
                        rel="nofollow"
                      >
                        https://policies.google.com/privacy
                      </a>
                      .{" "}
                    </p>
                  </li>
                </ol>
              </li>
            </ol> */}

            <p class="ml-4">
              Les données collectées par des cookies de conversion permettent
              d’établir des statistiques de conversion. Nous découvrons ainsi le
              nombre total d’utilisateurs qui ont réagi à notre annonce et qui
              ont été redirigés sur un site internet qui est pourvu d’un
              Reddit-Pixel. Ce faisant, nous n’obtenons aucune information qui
              nous permettrait de vous identifier personnellement en tant
              qu’utilisateur. Si vous souhaitez refuser cette procédure de
              tracking, vous pouvez désactiver la sauvegarde de cookies à partir
              de votre navigateur internet. Le cas échéant, vous povuez utiliser
              la fonction d’aide de votre navigateur pour plus d’informations.
              Vous pouvez obtenir davantage d’informations sur les dispositions
              de protection des données de Reddit en suivant ce lien :
            </p>
            <p class="ml-4">
              <a
                href="https://www.reddit.com/help/privacypolicy/"
                target="_blank"
                rel="nofollow"
              >
                https://www.reddit.com/help/privacypolicy/
              </a>
            </p>
            <h2 id="22_YouTube">22 YouTube</h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                <p>
                  Le site web intègre des vidéos dans la plateforme en ligne «
                  YouTube ». La société d’exploitation de YouTube est la YouTube
                  LLC, 901 Cherry Ave., San Bruno, CA 94066 USA. Il s’agit d’une
                  filiale de Google.
                </p>
                <p>
                  YouTube et Google recueillent des informations au sujet des
                  sous-pages visitées par la personne concernée lorsque celle-ci
                  est connectée sur YouTube. L’échange des données est effectué
                  seulement lorsque vous cliquez sur la vidéo.
                </p>
                <p>
                  Vous trouverez des informations supplémentaires au sujet de
                  YouTube sous le site web{" "}
                  <a
                    href="https://www.youtube.com/yt/about"
                    target="_blank"
                    rel="nofollow"
                  >
                    https://www.youtube.com/yt/about
                  </a>
                  .
                </p>
              </li>
              <li>
                <p>
                  Youtube ne sera activé uniquement si vous en donnez le
                  consentement en cliquant sur la vidéo. De ce fait, la base
                  légale du traitement des données est basé sur le consentement
                  de l’utilisateur selon l’article 6 paragraphe 1 f du RGPD.
                </p>
              </li>
              <li>
                <p>
                  L’objectif est une meilleure représentation du site web ainsi
                  qu’une manipulation facile des vidéos sans avoir besoin de
                  créer un lien vers d’autres plateformes.
                </p>
              </li>
              <li>
                <p>
                  YouTube et Google reçoivent l’information que la personne
                  concernée a visité notre site web seulement lorsque la
                  personne concernée a accédé à notre site web en étant
                  connectée sur YouTube en même temps ; cela se produit
                  indépendamment du fait qu’une vidéo YouTube ait été cliquée ou
                  non par la personne concernée. Cette transmission peut être
                  empêchée si la personne se déconnecte sur YouTube auparavant.
                </p>
              </li>
            </ol>
            <p class="ml-4">
              La politique de confidentialité de YouTube (que vous trouverez
              sous l’adresses{" "}
              <a
                href="https://www.google.de/intl/policies/privacy/"
                target="_blank"
                rel="nofollow"
              >
                https://www.google.de/intl/policies/privacy/
              </a>
              ) vous renseigne sur la collecte, le traitement et l’utilisation
              de données à caractère personnel par YouTube et Google.
            </p>
            <h2 id="23_Google_(Invisible)_reCAPTCHA">
              23 Google (Invisible) reCAPTCHA
            </h2>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                <p>
                  Le site internet utilise « Google reCAPTCHA », un test de
                  Turing de Google Inc., 1600 Amphitheatre Parkway, Mountain
                  View, CA 94043, USA (ci-après désigné par « Google »).Google
                  vérifie si les entrées de données sur le site internet
                  s’effectuent par une personne ou par un programme automatisé.
                  C’est ainsi que le comportement de l’utilisateur est analysé
                  sur base de plusieurs éléments. L’analyse débute
                  automatiquement aussitôt que le site internet est consulté.
                  Pour l’étude, reCAPTCHA analyse différentes information (par
                  exemple : l’adresse IP, le temps de la consultation et les
                  mouvements de la souris opérée par l’utilisateur). Les données
                  collectées lors de l’analyse sont transmises à Google.
                </p>
                <p>
                  Les analyses reCAPTCHA fonctionnent entièrement en
                  arrière-plan. Les utilisateurs du site internet ne sont pas
                  informés qu’une analyse a lieu.
                </p>
              </li>
              <li>
                <p>
                  La base légale du traitement se trouve à l’article 6
                  paragraphe 1f du RGPD.{" "}
                </p>
              </li>
              <li>
                <p>
                  Le responsable a l’intérêt légitime de protéger le site
                  internet contre l’espionnage abusif automatisé et contre les
                  SPAM.{" "}
                </p>
              </li>
              <li>
                <p>
                  D’autres informations sur Google reCAPTCHA ainsi que la
                  déclaration de confidentialité de Google sont disponibles sous
                  les liens suivants :{" "}
                </p>
                <ol>
                  <li>
                    <p>
                      <a
                        href="https://www.google.com/intl/de/policies/privacy/"
                        target="_blank"
                        rel="nofollow"
                      >
                        https://www.google.com/intl/de/policies/privacy/
                      </a>{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      <a
                        href="https://www.google.com/recaptcha/intro/android.html"
                        target="_blank"
                        rel="nofollow"
                      >
                        https://www.google.com/recaptcha/intro/android.html
                      </a>
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
            <h2 id="24_Encrypted_data_transfer">24 Transmission cryptée</h2>
            <p class="ml-4">
              Lors d’une inscription („Login„) ou d’une prise de contact, toutes
              les données sont transmises via la technologie TLS de manière
              cryptée. Le certificat nécessaire qui est installé sur les
              serveurs a été établi par une organisation indépendante.
            </p>
            <p class="ml-4">
              Une transmission cryptée est reconnaissable par le changement de
              l’adresse dans le navigateur : https:// au lieu de http://.
            </p>
            <p class="ml-4">
              Aussitôt que la liaison TLS cryptée est établie, vos entrées que
              vous avez transmises au Shop ne peuvent plus être lues par des
              tiers.
            </p>
            <h2 id="25_Applicant_data">25 Données du candidat</h2>
            <p class="ml-4">
              Vos données sont en règle générale supprimées automatiquement
              trois mois après la procédure de candidature. En outre, vous
              pouvez nous demander la suppression de vos données par e-mail à
              tout moment à l’adresse suivante :{" "}
              <a href="mailto:contact@yugioh.re?subject=JobApplicationFromOurSite">
                contact@yugioh.re
              </a>{" "}
              Veuillez noter que vous vous retirez dans ce cas de toutes les
              procédures de candidature en cours.
            </p>
            <h2 id="26_Rights_of_the_data_subject">
              26 Droits de la personne concernée
            </h2>
            <p class="ml-4">
              Si des données personnelles sont traitées, l’utilisateur est la «
              personne concernée » visée par le RGPD et il détient les droits
              suivants envers le responsable :{" "}
            </p>
            <ol type="a">
              <li>
                <h3>Le droit d’accès</h3>
                <p>
                  La personne concernée peut exiger une confirmation de la part
                  du responsable que ses données personnelles soient traitées.
                </p>
                <p>
                  Si un tel traitement existe, les informations suivantes
                  peuvent être demandées au responsable :
                </p>
                <ol>
                  <li>
                    les buts pour lesquels les données personnelles sont
                    traitées ;
                  </li>
                  <li>
                    les catégories de données personnelles qui sont traitées ;
                  </li>
                  <li>
                    le récepteur ou les catégories de récepteurs auprès desquels
                    les données personnelles concernées ont été ou sont
                    divulguées ;
                  </li>
                  <li>
                    la durée prévue de la sauvegarde des données personnelles
                    ou, si des indications concrètes ne sont pas possibles, les
                    critères de la détermination de la durée de sauvegarde ;
                  </li>
                  <li>
                    l’existence d’un droit de rectification ou de suppression
                    des données personnelles, d’un droit à une limitation du
                    traitement par le responsable ou un droit d’opposition
                    contre ce traitement ;{" "}
                  </li>
                  <li>
                    l’existence du droit à déposer plainte auprès d’une autorité
                    de contrôle ;
                  </li>
                  <li>
                    toutes les informations disponibles sur l’origine des
                    données, lorsque les données personnelles ne proviennent pas
                    de la personne concernée ;
                  </li>
                  <li>
                    <p>
                      l’existence d’une prise de décision automatisée, y compris
                      du profiling conformément à l’article 22 paragraphes 1 et
                      4 du RGPD et, du moins das ces cas-là, les informations
                      pertinentes sur la logique impliquée ainsi que la portée
                      et l’effet visé d’un tel traitement pour la personne
                      concernée.{" "}
                    </p>
                  </li>
                </ol>
                <p>
                  Il existe le droit d’exiger l’information selon laquelle les
                  données personnelles sont transmises à un pays tiers ou à une
                  organisation internationale. Dans ce contexte, il peut être
                  exigé de connaître les garanties appropriées conformément à
                  l’article 46 du RGPD en lien avec la transmission.
                </p>
              </li>
              <li>
                <h3>Droit à rectification</h3>
                <p>
                  Il existe un droit à rectification et/ou à compléter envers le
                  responsable si les données personnelles traitées sont
                  incorrectes ou incomplètes. Le responsable a l’obligation
                  d’effectuer la rectification.
                </p>
              </li>
              <li>
                <h3>Droit à une limitation du traitement</h3>
                <p>
                  Sous les conditions suivantes, la limitation du traitement des
                  données personnelles peut être exigée :
                </p>
                <ol>
                  <li>
                    lorsque vous contestez l’exactitude des données personnelles
                    pour une durée qui permet au responsable de vérifier
                    l’exactitude des données personnelles ;
                  </li>
                  <li>
                    si le traitement est illégitime et que la suppression des
                    données personnelles est refusée et qu’à la place c’est la
                    limitation de l’utilisation des données personnelles qui est
                    exigée ;
                  </li>
                  <li>
                    si le responsable n’a plus besoin des données personnelles
                    dans des buts de traitement, mais que celles-ci sont
                    néanmoins nécessaires pour la revendication, l’exercice ou
                    la défense des droits légitimes, ou
                  </li>
                  <li>
                    <p>
                      lorsqu’une contestation du traitement conformément à
                      l’article 21 paragraphe 1 du RGPD a été insérée sans avoir
                      été fixée sur le fait de savoir si les raisons légitimes
                      du responsable dépassaient celles des personnes
                      concernées.{" "}
                    </p>
                  </li>
                </ol>
                <p>
                  Si le traitement des données personnelles a été limité, ces
                  données ne peuvent, à l’exception de leur sauvegarde, être
                  traitées qu’avec l’accord de la personne concernée ou pour la
                  revendication, l’exercice ou la défense des droits légitimes
                  ou pour la protection des droits d’une autre personne physique
                  ou morale ou pour des raisons d’un intérêt important et public
                  de l’Union ou d’un pays membre.
                </p>
                <p>
                  Si la limitation du traitement a été faite selon les
                  conditions mentionnées ci-dessus, la personne concernée est
                  informée par le responsable avant que la limitation ne soit
                  arrêtée.
                </p>
              </li>
              <li>
                <h3>Le droit à la suppression</h3>
                <p class="font-weight-bold">Obligation de suppression</p>
                <p>
                  Il existe un droit d’exiger de la part du responsable que les
                  données personnelles soient immédiatement supprimées, et le
                  responsable est obligé de supprimer ces données immédiatement,
                  si une des raisons suivantes est constatée :
                </p>
                <ol>
                  <li>
                    Les données personnelles ne sont plus nécessaires pour les
                    buts pour lesquels elles ont été collectées ou traitées
                    d’une autre manière.
                  </li>
                  <li>
                    Le consentement peut être rétracté sur base des articles 6
                    paragraphe 1 a ou 9 paragraphe 2 a du RGPD, et s’il manque
                    une autre base légale pour le traitement.
                  </li>
                  <li>
                    Une opposition est faite conformément à l’article 21
                    paragraphe 1 du RGPD et il n’existe pas de raisons
                    prioritaires ou légitimes pour le traitement ou une
                    opposition est opérée conformément à l’article 21 paragraphe
                    2 du RGPD.{" "}
                  </li>
                  <li>
                    Les données personnelles ont été traitées de manière
                    illégale.{" "}
                  </li>
                  <li>
                    La suppression des données personnelles est nécessaire pour
                    se conformer aux dispositions légales européennes ou au
                    droit des Etats membres de l’Union et doit être faite par le
                    responsable.{" "}
                  </li>
                  <li>
                    <p>
                      Les données personnelles ont été collectées en lien avec
                      des services proposés par une société d’informations
                      conformément à l’article 8 paragraphe 1 du RGPD.{" "}
                    </p>
                  </li>
                </ol>
                <p class="font-weight-bold">Informations aux tiers</p>
                <p>
                  Si le responsable a rendu les données personnelles publiques
                  et s’il est tenu de les effacer conformément à l’article 17
                  paragraphe 1 du RGPD, il décide des mesures en considérant la
                  technologie disponible et les coûts d’implantation, également
                  de façon technique, pour que les responsables du traitement de
                  données qui traitent les données personnelles puissent être
                  informés que certaines personnes ont réclamé la suppression de
                  tous les liens à ces données personnelles ou des copies ou
                  réplications de ces données personnelles.{" "}
                </p>
                <p class="font-weight-bold">Exceptions</p>
                <p>
                  Il n’existe pas de droit à suppression lorsque le traitement
                  est nécessaire
                </p>
                <ol>
                  <li>
                    pour l’exercice des droits de liberté d’expression et
                    d’information ;
                  </li>
                  <li>
                    pour l’exécution d’une obligation légale, qui exige le
                    traitement selon le droit de l’Union ou des Etats membres et
                    qui doit être exécutée par le responsable, ou pour
                    l’exercice d’une tâche d’intérêt public ou d’exercice de
                    l’autorité publique qui a été transmise au responsable ;
                  </li>
                  <li>
                    pour des raisons d’intérêt public en matière de santé
                    publique conformément à l’article 9 paragraphe 2 h et i
                    ainsi que l’article 9 paragraphe 3 du RGPD ;
                  </li>
                  <li>
                    pour des raisons d’archives dans l’intérêt public, pour des
                    raisons de recherches scientifiques ou historiques ou pour
                    des raisons statistiques, conformément à l’article 89
                    paragraphe 1 du RGPD, si le droit mentionné à la section a)
                    rend probablement impossible la réalisation des buts de ce
                    traitement ou les compromet sérieusement, ou
                  </li>
                  <li>
                    <p>
                      pour la revendication, l’exercice ou la défense d’un droit
                      légitime.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <h3>Droit à l’information</h3>
                <p>
                  Si le droit à rectification, suppression ou limitation du
                  traitement est usé à l’encontre du responsable, celui-ci est
                  tenu d’informer tous les destinataires qui ont eu accès aux
                  données personnelles de cette rectification ou suppression des
                  données ou de la limitation du traitement, à moins que cela
                  s’avère impossible ou possible au prix d’un effort
                  disproportionné.
                </p>
                <p>
                  Il existe le droit à l’encontre du responsable d’être informé
                  des destinataires.
                </p>
              </li>
              <li>
                <h3>Droit à la portabilité des données</h3>
                <p>
                  Il existe le droit d’obtenir les données personnelles mises à
                  disposition du responsable dans un format structuré, courant
                  et en lecture informatisée. De plus, il existe le droit de
                  transmettre ces données à un autre responsable sans entrave de
                  la part du responsable pour qui les données personnelles ont
                  été mises à disposition, à condition que
                </p>
                <ol>
                  <li>
                    le traitement repose sur un consentement conformément à
                    l’article 6 paragraphe 1 a et à l’article 9 paragraphe 2a du
                    RGPD ou sur un contrat conformément à l’article 6 paragraphe
                    1 b du RGPD et{" "}
                  </li>
                  <li>
                    <p>
                      que le traitement s’effectue à l’aide d’une procédure
                      automatisée.
                    </p>
                  </li>
                </ol>
                <p>
                  De plus, lors de l’exercice de ce droit existe également
                  celui, à développer, à ce que les données personnelles soient
                  transmises directement par un responsable à un autre
                  responsable, à la condition que cela soi techniquement
                  faisable. Les libertés et les droits des autres personnes ne
                  peuvent pas être entravés.
                </p>
                <p>
                  Le droit à la portabilité des données ne vaut pas pour un
                  traitement des données personnelles qui est nécessaire pour la
                  réalisation d’une tâche d’intérêt public ou d’exercice de
                  l’autorité publique qui aurait été transmise au responsable.
                </p>
              </li>
              <li>
                <h3>Droit d’opposition</h3>
                <p>
                  Il existe le droit, pour des raisons qui dépendent de la
                  situation particulière de la personne concernée, d’opposition
                  à tout moment à l’encontre du traitement des données
                  personnelles sur base de l’article 6 paragraphe 1 e ou f du
                  RGPD ; cela vaut également pour un profiling sur base des
                  mêmes dispositions.
                </p>
                <p>
                  Le responsable cesse de traiter les données personnelles, à
                  moins qu’il ne justifie de raisons contraignantes et légitimes
                  pour le traitement qui surpassent les intérêts, les droits et
                  les libertés de la personne concernée, ou que le traitement
                  sert de revendication, d’exercice ou de défense de droits
                  légitimes.
                </p>
                <p>
                  Si les données personnelles sont traitées afin de faire de la
                  publicité directe, il existe le droit d’opposition à tout
                  moment à l’encontre du traitement des données personnelles
                  dans le but de les utiliser pour de telles publicités ; cela
                  vaut également pour le profiling, dans la mesure où il est lié
                  à ce genre de publicité directe.
                </p>
                <p>
                  Si le traitement pour une publicité directe a été opposé, les
                  données personnelles ne sont plus traitées dans ce but.
                </p>
                <p>
                  Il existe la possibilité, en lien avec l’utilisation de
                  services des sociétés d’information et malgré la ligne
                  directrice 2002/58/EG, d’utiliser son droit d’opposition à
                  l’aide d’un processus automatisé à partir duquel des
                  spécifications techniques sont utilisées.
                </p>
              </li>
              <li>
                <h3>
                  Le droit à la révocation de la déclaration de consentement sur
                  la protection des données
                </h3>
                <p>
                  Il existe le droit de révoquer à tout moment la déclaration de
                  consentement sur la protection des données. La légalité du
                  traitement sous le règne du consentement n’est pas concernée
                  par la révocation de la déclaration.
                </p>
              </li>
              <li>
                <h3>
                  Décision automatisée dans un cas particulier, y compris le
                  profiling
                </h3>
                <p>
                  Il existe le droit de ne pas être soumis à une décision
                  exclusivement basée sur un traitement automatisé (notamment le
                  profiling) qui entraîne des effets juridiques ou assimilés à
                  l’encontre de la personne concernée. Cela ne vaut pas, lorsque
                  la décision
                </p>
                <ol>
                  <li>
                    est nécessaire pour la conclusion ou l’exécution d’un
                    contrat entre la personne concernée et le responsable,
                  </li>
                  <li>
                    est autorisée sur base des dispositions légales de l’Union
                    ou des Etats membres auxquels est soumis le responsable et
                    que ces dispositions légales contiennent des mesures
                    adaptées à la préservation de vos droits et libertés ainsi
                    que de vos intérêts légitimes, ou
                  </li>
                  <li>
                    intervient avec l’accord explicite de la personne concernée.
                  </li>
                </ol>
                <p>
                  Néanmoins, ces décisions ne peuvent pas reposer sur des
                  catégories spécifiques des données personnelles telles que
                  celles de l’article 9 paragraphe 1 du RGPD, tant que l’article
                  9 paragraphe 2 a ou g s’applique et que des mesures adaptées à
                  la protection des droits et des libertés ainsi que de
                  l’intérêt légitime ont été adoptées.
                </p>
                <p>
                  En ce qui concerne les (1) et (3) des cas cités, le
                  responsable adopte les mesures adaptées pour protéger les
                  droits et les libertés ainsi que les intérêts légitimes de la
                  personne concernée, auxquels appartient au moins le droit
                  d’obtenir l’intervention d’une personne du responsable sur
                  présentation de sa propre position et de recours contre la
                  décision.
                </p>
              </li>
              <li>
                <h3>
                  Droit de dépôt de plainte auprès d’une autorité de
                  surveillance
                </h3>
                <p>
                  Sous réserve d’un autre recours administratif ou judiciaire,
                  il existe le droit de recours auprès d’une autorité de
                  surveillance, notamment dans l’État membre de l’emplacement de
                  la personne concernée, du lieu de travail ou du lieu de
                  l’infraction supposée lorsqu’il est possible que le traitement
                  des données personnelles viole le RGPD.
                </p>
                <p>
                  L’autorité de surveillance auprès de laquelle la plainte a été
                  déposée informe le plaignant sur l’état et sur les résultats
                  de la plainte, y compris sur la possibilité d’un recours
                  judiciaire conformément à l’article 78 du RGPD.
                </p>
              </li>
            </ol>
            <h2 id="27_Distribution_de_Newsletter_en_utilisant_Mailchimp">
              27 Distribution de Newsletter en utilisant MailChimp
            </h2>
            <p class="ml-4">
              Vous disposez de la possibilité de vous abonner à une newsletter.
              Nous utilisons le service de newsletter Mailchimp pour la
              distribution de ladite newsletter. Le prestataire est le Rocket
              Science Group LLC, 675 Ponce De Leon Ave NE, Suite 5000, Atlanta,
              GA 30308, USA. MailChimp est un service utilisé pour
              l’organisation et l’analyse de la distribution de newsletter.
            </p>
            <p class="ml-4">
              Afin de constituer la preuve de consentement et d’annulation
              conformément à la loi, nous conservons, pour chaque profil
              d’utilisateur créé à l’aide d’adresse e-mail confirmé via
              procédure Double-Opt-In, les données suivantes lors d’événements,
              inscription, modification, confirmation et annulation de la
              newsletter :
            </p>
            <ol>
              <li>Adresse e-mail</li>
              <li>Date et heure</li>
              <li>Adresse IP</li>
            </ol>
            <p class="ml-4">
              Dès que vous vous êtes abonné à notre newsletter, nous traitons
              aussi les informations suivantes (données personnelles aussi)
              obtenues de vous :
            </p>
            <ol>
              <li>Objet et référence de profil</li>
              <li>
                Type d’action (Enregistrement, mise à jour, modification du
                consentement)
              </li>
              <li>Métadonnée de l’action</li>
            </ol>
            <p class="ml-4">
              MailChimp établit et conserve des Cookies afin de rendre possible
              des enquêtes statistiques et pour établir des profils
              d’intérêts.&nbsp;
            </p>
            <p class="ml-4">
              Le traitement des données est fait par nous, MailChimp et – si
              disponibles – des sous-traitants de MailChimp en tant que partie
              du traitement des données. Les sous-traitants traitent les données
              selon nos instructions et en notre nom de manière similaire à
              MailChimp.
            </p>
            <ol class="ol-list ml-3 ml-md-5">
              <li>
                <p>
                  Votre accord conformément à l’Art. 6 (1) lit. a du RGPD est la
                  base légale pour le traitement des données personnelles qui
                  sont nécessaires pour les prestations techniques de la
                  newsletter ainsi que pour le traitement des cookies et mesure
                  des données.
                </p>
                <p>
                  En vous abonnant à la newsletter, vous consentez au traitement
                  de vos données personnelles conformément à l’Art. 6 (1) lit. a
                  du RGPD tout en prenant compte que nous sécurisons
                  l’enregistrement avec une procédure Double-Opt-In:&nbsp;&nbsp;
                </p>
                <p>
                  Tout d’abord, vous devez remplir le champ obligatoire
                  «&nbsp;Adresse E-mail&nbsp;» et consentez au traitement de vos
                  données personnelles en activant l’option correspondante
                  «&nbsp;S’abonner&nbsp;». Ensuite, vous recevrez un courriel de
                  confirmation automatique contenant un lien d’activation.&nbsp;
                  Merci de confirmer via ce lien. Cette procédure nous assure
                  que l’adresse e-mail fournie sur notre site web vous
                  appartient.
                </p>
                <p>
                  La base légale pour le traitement des données personnelles
                  supplémentaires et notre intérêt légitime conformément à
                  l’Art. 6 (1) lit. f du RGPD. Nous avons un intérêt légitime à
                  être capables de prouver que vous nous avez accordé votre
                  consentement. Nous avons aussi un intérêt légitime à pouvoir
                  prévenir ou prouver un mauvais usage de notre newsletter.
                </p>
                <p>
                  Nous avons passé un «&nbsp;Accord pour le Traitement des
                  Données&nbsp;» avec MailChimp. Il s’agit d’un contrat dans
                  lequel MailChimp s’engage à protéger et à traiter les données
                  uniquement et conformément aux prescriptions de protection des
                  données en vigueur, en notre nom et en suivant nos
                  indications. Pour plus d’informations au sujet, merci de
                  visiter:{" "}
                  <a
                    href="https://mailchimp.com/legal/privacy/"
                    target="_blank"
                    rel="nofollow"
                  >
                    https://mailchimp.com/legal/privacy/
                  </a>{" "}
                  et{" "}
                  <a
                    href="https://mailchimp.com/legal/data-processing-addendum/"
                    target="_blank"
                    rel="nofollow"
                  >
                    https://mailchimp.com/legal/data-processing-addendum/
                  </a>
                  .
                </p>
              </li>
              <li>
                <p>
                  Le but de la conservation et traitement des adresses e-mail
                  des utilisateurs est d’être capables de distribuer notre. Le
                  but de la conservation et traitement des données personnelles
                  supplémentaires est de prévenir le mauvais usage de notre
                  newsletter ou de l’adresse e-mail utilisée. De plus, le but du
                  traitement en question est d’être capables de prouver le
                  consentement accordé par vous. Le but du traitement des
                  cookies et du données mesurées est d’être capables d’effectuer
                  un suivi du succès et atteinte de notre newsletter et donc
                  d’optimiser notre newsletter. C’est là que réside notre
                  intérêt légitime.
                </p>
              </li>
              <li>
                <p>
                  Les données seront supprimées au moment où le but de leur
                  conservation soit accompli. Donc, vos données d’enregistrement
                  sont conservées seulement pendant la durée de votre abonnement
                  à la newsletter. Le suivi et les données des cookies sont
                  supprimés au bout de 7 jours. Si vous ne confirmez votre
                  adresse e-mail pour la procédure Double-Opt-In, votre
                  enregistrement sera supprimé automatiquement au bout de 48
                  heures. La fourniture de données personnelles n'est prescrite
                  ni par la loi ni par un contrat et n'est pas nécessaire à la
                  conclusion d'un contrat. Aussi vous n’êtes pas obligé de
                  fournir d’informations à caractère personnel. Cependant, la
                  conséquence d’un refus de fourniture serait que nous ne
                  serions pas en mesure de vous distribuer une newsletter.
                </p>
              </li>
              <li>
                <p>
                  L’abonnement à une newsletter peut être annulé par la personne
                  concernée à tout moment. Il y a un lien à cet effet dans
                  chaque newsletter. Ceci rend aussi possible l’annulation du
                  consentement accordé pour la conservation des données pendant
                  le processus d’enregistrement.
                </p>
              </li>
            </ol>
          </section>
        </div>
      </div>
    </>
  );
};
export default Confidentialite;
