import React from "react";
import "../App.css";

const Action3 = () => {
  return (
    <>
      <div class="container marketing px-3 py-3 ">
        <div class="row featurette">
          <div class="col-md-7">
            <h2 class="featurette-heading fw-normal lh-1">
              Vendez et achetez vos <span class="text-muted">cartes.</span>
            </h2>
            <p class="lead">
              Some great placeholder content for the first featurette here.
              Imagine some exciting prose here.
            </p>
          </div>
          <div class="col-md-5">
            <svg
              class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
              width="500"
              height="500"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              aria-label="Placeholder: 500x500"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            >
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="#eee" />
              <text x="50%" y="50%" fill="#aaa" dy=".3em">
                500x500
              </text>
            </svg>
          </div>
        </div>

        <hr class="featurette-divider" />

        <div class="row featurette">
          <div class="col-md-7 order-md-2">
            <h2 class="featurette-heading fw-normal lh-1">
              Vendez vos bases de <span class="text-muted">deck.</span>
            </h2>
            <p class="lead">
              Another featurette? Of course. More placeholder content here to
              give you an idea of how this layout would work with some actual
              real-world content in place.
            </p>
          </div>
          <div class="col-md-5 order-md-1">
            <svg
              class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
              width="500"
              height="500"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              aria-label="Placeholder: 500x500"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            >
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="#eee" />
              <text x="50%" y="50%" fill="#aaa" dy=".3em">
                500x500
              </text>
            </svg>
          </div>
        </div>

        <hr class="featurette-divider" />

        <div class="row featurette">
          <div class="col-md-7">
            <h2 class="featurette-heading fw-normal lh-1">
              Des <span class="text-muted">enchères</span> au plus simple.
            </h2>
            <p class="lead">
              And yes, this is the last block of representative placeholder
              content. Again, not really intended to be actually read, simply
              here to give you a better view of what this would look like with
              some actual content. Your content.
            </p>
          </div>
          <div class="col-md-5">
            <svg
              class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
              width="500"
              height="500"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              aria-label="Placeholder: 500x500"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            >
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="#eee" />
              <text x="50%" y="50%" fill="#aaa" dy=".3em">
                500x500
              </text>
            </svg>
          </div>
        </div>

        <hr class="featurette-divider" />
      </div>
    </>
  );
};

const Apropos = ({ navigation }) => (
  <>
    <div class="container">
      <h1 class="mt-5">A propos</h1>
      <img
        alt=""
        src={process.env.PUBLIC_URL + "/assets/images/Yu-Gi-Oh!_(Logo).jpeg"}
        style={{
          maxWidth: 255,
          height: 90,
        }}
      />{" "}
      {/* <span class="fs-4">UNITY CARD</span> */}
      <p class="lead">
      UNITY CARD - YGO est représentée par FILAUMART SEBASTIEN CHARLES.
      </p>
      <div class="row mb-5">
        <div class="col-12 col-md-4 color-primary">Adresse</div>
        <div class="col-12 col-md-8">
        205 Rue jules bertaut
                      <br />
                      97430 Le tampon
        </div>
        {/* <div class="col-12 col-md-4 color-primary">Téléphone</div> */}
        {/* <div class="col-12 col-md-8">
          +262 693 32 25 25{" "}
          <small class="font-italic">
            {" "}
            (Ne pas utiliser pour contacter le Service Client)
          </small>
        </div> */}
        <div class="col-12 col-md-4 color-primary">E-Mail</div>
        <div class="col-12 col-md-8">
          <a href="mailto:contact@yugioh.re" class="unselectable">
            contact@yugioh.re
          </a>
        </div>

        <div class="col-12 col-md-4 color-primary">
          No. d'identification fiscale
        </div>
        <div class="col-12 col-md-8">82485041600028</div>
      </div>
      <p>
        Information concernant la résolution de litiges en ligne: La Commission
        Européenne a créé un site web afin de traiter les litiges en ligne (ODR
        en français). Le site de la ODR est un lieu qui assiste à l'accord
        extrajudiciaire de litiges provenant des obligations contractuelles
        issues de contrats d'achat et vente en ligne. Le client peut accéder au
        site de la ODR en cliquant sur le lien suivant:{" "}
        <a
          href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=FR"
          target={"_blank"}
        >
          http://ec.europa.eu/consumers/odr/
        </a>
      </p>
    </div>
  </>
);
export default Apropos;
