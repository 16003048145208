export const ItemAddCard = ({ click, isSelect = false, data }) => {
  
  const f = () => {
    let q = false ; 
    if (data?.card_sets?.length <= 3 ){
      
      let occurrences = {};
      data?.card_sets?.forEach(function(chaine) {
        occurrences[chaine] = (occurrences[chaine] || 0) + 1;
      });
    
      // Afficher les occurrences
      for (let chaine in occurrences) {
          console.log("'" + chaine + "' apparaît " + occurrences[chaine] + " fois");
          if (occurrences[chaine] > 1) {
            q = true;
          }
      }
    }
    return q
  }

  return (
    <div class="col">
      <a
        class="card shadow-sm btn btn-sm btn-outline"
        onClick={click}
        style={{
          borderColor: isSelect ? "green" : null,
          borderWidth: isSelect ? 3 : null,
        }}
      >
        <img
          width="100%"
          style={{
            justifyItems: "center",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          className="d-block"
          src={data?.card_images[0]?.image_url_small}
          alt="First slide"
        />

        <div class="card-body">
          <h6 class="price-container">{data?.name}</h6>
          <h6 class="price-container">
            {data?.card_sets.length <= 3
              ? data?.card_sets.map((set, index) => {
                  if (data?.card_sets[index + 1] != null) {
                    return (
                      <>
                        <span>{f ? set?.set_rarity : set?.set_code}</span>{f && ','}<br />
                      </>
                    );
                  } else {
                    return <span>{f ? set?.set_rarity : set?.set_code}</span> 
                  }
                })
              : "Plus de 3 extensions"}
          </h6>
        </div>
      </a>
    </div>
  );
};
