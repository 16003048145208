import React from "react";
import { useParams } from "react-router-dom";
import "../App.css";

const Body = () => {
  const { userProduct } = useParams();
  return (
    <>
      <div class="container marketing px-3 py-3 ">
        <p>Edition du produit vendeur {userProduct}</p>
        <p>redirection du produit si card = /tableau-de-bord#card</p>
        <p>redirection du produit si enchère = /tableau-de-bord#enchere</p>
        <p>
          redirection du produit si base de deck = /tableau-de-bord#basededeck
        </p>
        <p>redirection du produit si recherche = /tableau-de-bord#search</p>
      </div>
    </>
  );
};

const EditProduct = ({ navigation }) => (
  <>
    <Body />
  </>
);
export default EditProduct;
